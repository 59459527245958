import React from 'react';
import { InfoServicio } from '../App';
import { isNullOrUndefined } from 'util';
import { IonRouterLink, IonModal, IonGrid, IonRow, IonCol, IonButton, IonPage, IonContent, IonCardHeader, IonCardTitle, IonCard, IonCardContent, IonItem, IonLabel, IonCardSubtitle, IonTextarea, IonLoading } from '@ionic/react';
import DataTable from 'react-data-table-component';
import { Redirect, RouteComponentProps } from 'react-router';
import Pago from './Pago';
import { callGetBitacoraServicios } from '../firebaseConfig';
import { checkAccessToModule } from '../helper';

interface BitacoraProps extends RouteComponentProps<{
    pacienteID: string
}> {}

type State = {
    showModal: boolean,
    infoServicio: InfoServicio,
    servicios: InfoServicio[],
    showLoading: boolean
}

export class BitacoraServiciosPage extends React.Component<RouteComponentProps, State>{
    //servicios: Array<InfoServicio> = [];    
    formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol'
      });
    
    openDetails(info: InfoServicio){        
        this.setState({
            showModal: true,
            infoServicio: info            
        })
    }
    closeModal(){
        this.setState({
            showModal:false
        })
        //this.servicios = JSON.parse(window.localStorage['bitacoraServicios'])
    }

    totalSort = (rowA: any, rowB: any) => {
        const a = rowA.subtotal + rowA.impuestos * 1
        const b = rowB.subtotal + rowB.impuestos * 1
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };
    
    hideCuota(){
        return this.state.infoServicio.monto === undefined 
        ||  
        (this.state.infoServicio.monto !== undefined 
            && this.state.infoServicio.monto <= 0)                
    }
    hideTarifa(){
        return this.state.infoServicio.tarifa === undefined 
        || this.state.infoServicio.tarifa == "Regular"
        || this.state.infoServicio.tarifa == ""
    }
    hideCuoTarifaItem(){
        return this.hideCuota() && this.hideTarifa()
    }
    columns = [
        {
            name: 'Fecha',
            selector: (row: InfoServicio) => row.fecha,
            sortable:true
        },
        {
            name: 'Servicio',
            selector: (row: any) => row.servicio,
            sortable: true
        },
        {
            name: 'Total',
            selector: (row: any) => this.formatter.format(row.total),            
            sortable: true,
            sortFunction: this.totalSort
        },
        {
            name: 'Forma Pago',
            selector: (row: any) => row.formaPago,
            sortable: true
        },
        /* {
            name: 'Responsable',
            selector: (row: any) => row.responsable,
            sortable:true
        }, */
        {
            name: 'Propietario',
            selector: (row: any) => row.propietario.nombre,
            sortable:true
        },
        {
            name: 'Paciente',
            selector: (row: any) => row.paciente? row.paciente.nombre : ""
        },
        {
            name: '',
            selector: (row: any) => row.fechaPago ? row.fechaPago : 
                <IonRouterLink onClick={
                    ()=>this.openDetails(row)}>Detalles</IonRouterLink>,
            sortable: true
        }
    ];
    
    constructor(props:any){
        super(props);
        this.state = {
            showModal: false,
            infoServicio: {} as InfoServicio,
            servicios: [],
            showLoading: true
        }
        /*
        if(!isNullOrUndefined(window.localStorage['bitacoraServicios']))
            this.servicios = JSON.parse(window.localStorage['bitacoraServicios'])
        */
       callGetBitacoraServicios().then(res=>{
        const resServicios = res as InfoServicio[]
        let servicios = []
        if(props.match.params.pacienteID!==undefined){
            console.log("se recibió id de paciente: " +  props.match.params.pacienteID)
            //console.log("servicos en bitácora: " + JSON.stringify(resServicios))
            servicios = resServicios.filter(
                item=> {
                    if(item.paciente){
                        //console.log("id de paciente: " + item.paciente.id)
                        if(item.paciente.id == props.match.params.pacienteID)
                            return item
                        else
                            return null
                    }                 
                    else
                        return null;                           
                })
            //console.log("Resultado del mapeo: " + JSON.stringify(servicios))
        }
        else
            servicios = resServicios
        this.setState({
            servicios: servicios,
            showLoading: false
        })
       })        
            
        
       // this.closeModal = this.closeModal.bind(this);

    }
    render(){   
        if(!checkAccessToModule('Bitácora de Servicios'))
            return <Redirect to="/pages/home"/>    
        return(
        <IonPage>
            <IonContent>
                <IonLoading isOpen={this.state.showLoading}
                    message="Cargando..."
                    />
                <DataTable data={this.state.servicios} columns={this.columns}
                    pagination={true}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[5,10]}
                    title="Bitácora"
                    dense={true}
                    striped
                    noDataComponent={
                        <IonGrid>                                                    
                            <IonRow>
                                <IonCol size="4"></IonCol>
                                <IonCol>
                                    No hay servicios registrados
                                </IonCol>
                            </IonRow>
                            <IonRow><IonCol>&nbsp;</IonCol></IonRow>
                        </IonGrid>
                    }
                    />
                  

                <IonModal isOpen={this.state.showModal} backdropDismiss={false}>                   
                    <IonContent>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>
                                    Servicio de {this.state.infoServicio.servicio}
                                </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonItem>
                                    <IonLabel className="formKeyLabel">
                                        Fecha
                                    </IonLabel>
                                    <IonLabel>
                                        {this.state.infoServicio.fecha}
                                    </IonLabel>
                                    {/* <IonLabel className="formKeyLabel">
                                        Responsable
                                    </IonLabel>
                                    <IonLabel>
                                        {this.state.infoServicio.responsable}
                                    </IonLabel>                                             */}
                                     <IonLabel className="formKeyLabel">
                                        Paciente
                                    </IonLabel>
                                    <IonLabel>
                                        {this.state.infoServicio.paciente !== undefined ?
                                            this.state.infoServicio.paciente.nombre :
                                            "No registrado" }
                                    </IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel className="formKeyLabel">
                                        Total
                                    </IonLabel>
                                    <IonLabel>
                                        {this.state.infoServicio.total}
                                    </IonLabel>
                                    <IonLabel className="formKeyLabel">
                                        Forma de Pago
                                    </IonLabel>
                                    <IonLabel>
                                        {this.state.infoServicio.formaPago}
                                    </IonLabel>
                                </IonItem>
                                <IonItem hidden={this.hideCuoTarifaItem()}>
                                    <IonLabel className="formKeyLabel"
                                            hidden={this.hideCuota()}>
                                        Cuota
                                    </IonLabel>
                                    <IonLabel hidden={this.hideCuota()}>
                                        {this.state.infoServicio.monto}
                                    </IonLabel>
                                    <IonLabel className="formKeyLabel"  hidden={this.hideTarifa()}>
                                        Tarifa
                                    </IonLabel>
                                    <IonLabel  hidden={this.hideTarifa()}>
                                        {this.state.infoServicio.tarifa}
                                    </IonLabel>
                                </IonItem>
                                <IonItem>
                                <IonLabel className="formKeyLabel">
                                        Propietario
                                    </IonLabel>
                                    <IonLabel>
                                        {this.state.infoServicio.propietario !== undefined ?
                                            this.state.infoServicio.propietario.nombre :
                                            "No registrado" }
                                    </IonLabel>
                                    {/* <IonLabel className="formKeyLabel" 
                                        hidden={
                                            this.state.infoServicio.propietario.telefono
                                                === undefined ||
                                            this.state.infoServicio.propietario.telefono 
                                                == ""}>
                                        Teléfono
                                    </IonLabel>
                                    <IonLabel
                                        hidden={
                                            this.state.infoServicio.propietario.telefono 
                                                === undefined ||
                                            this.state.infoServicio.propietario.telefono 
                                                == ""}>
                                        {this.state.infoServicio.propietario.telefono}
                                    </IonLabel>    */}                              
                                </IonItem>
                                <IonItem hidden={
                                            this.state.infoServicio.subjetivo 
                                                === undefined ||
                                            this.state.infoServicio.subjetivo 
                                                == ""}>                                                                    
                                    <IonLabel className="objetivoKeyLabel">
                                        Subjetivo
                                    </IonLabel>
                                    <IonGrid>                                   
                                        <IonRow>
                                            <IonCol>
                                                <IonTextarea
                                                    value={this.state.infoServicio.subjetivo}
                                                    readonly                                                
                                                />   
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid> 
                                </IonItem>    
                                <IonItem hidden={
                                    this.state.infoServicio.objetivo === undefined ||
                                    JSON.stringify(this.state.infoServicio.objetivo) 
                                        == "{}" ||
                                    JSON.stringify(this.state.infoServicio.objetivo) == 
                                    '{"Observaciones":""}'}>
                                    <IonLabel className="formKeyLabel">
                                        Objetivo
                                    </IonLabel>                                
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol className="objetivoLabel">EM</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.EM}</IonCol>
                                            <IonCol className="objetivoLabel">RT</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.RT}</IonCol>
                                            
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="objetivoLabel">MM</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.MM}</IonCol>
                                            <IonCol className="objetivoLabel">RD</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.RD}</IonCol>
                                            
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="objetivoLabel">TLLC</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.TLLC}</IonCol>
                                            <IonCol className="objetivoLabel">FC</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.FC}</IonCol>
                                            
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="objetivoLabel">LN</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.LN}</IonCol>
                                            <IonCol className="objetivoLabel">FR</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.FR}</IonCol>
                                            
                                        </IonRow>
                                        <IonRow><IonCol>&nbsp;</IonCol></IonRow>
                                        <IonRow>
                                            <IonCol size="4"></IonCol>
                                            <IonCol className="objetivoLabel">Observaciones</IonCol>
                                        </IonRow>                                         
                                        <IonRow><IonCol>&nbsp;</IonCol></IonRow>                                                                       
                                    </IonGrid>
                                    <IonGrid>
                                        <IonRow>
                                            
                                            <IonCol className="objetivoLabel">PP</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.PP}</IonCol>
                                            <IonCol className="objetivoLabel">T</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.T}</IonCol>
                                        </IonRow>
                                        <IonRow>
                                            
                                            <IonCol className="objetivoLabel">CP</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.CP}</IonCol>
                                            <IonCol className="objetivoLabel">Peso</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.Peso}</IonCol>
                                        </IonRow>
                                        <IonRow>
                                            
                                            <IonCol className="objetivoLabel">PA</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.PA}</IonCol>
                                            <IonCol className="objetivoLabel">CC</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.CC}</IonCol>
                                        </IonRow>
                                        <IonRow>
                                            
                                            <IonCol className="objetivoLabel">Pulso</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.Pulso}</IonCol>
                                            <IonCol className="objetivoLabel">DH</IonCol>
                                            <IonCol>{this.state.infoServicio.objetivo?.DH}</IonCol>
                                        </IonRow>                                                                         
                                        <IonRow >
                                            <IonCol>
                                                 <IonTextarea readonly
                                                value={this.state.infoServicio.objetivo?.Observaciones}/>
                                                
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid> 
                                </IonItem> 
                                <IonItem hidden={
                                            this.state.infoServicio.interpretacion 
                                                === undefined ||
                                            this.state.infoServicio.interpretacion 
                                                == ""}>
                                    <IonLabel className="objetivoKeyLabel">
                                        Interpretación
                                    </IonLabel>
                                    <IonGrid>
                                        <IonRow>                                            
                                            <IonCol>
                                                <IonTextarea
                                                value={this.state.infoServicio.interpretacion}
                                                readonly />   
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid> 
                                </IonItem>
                                <IonItem hidden={
                                            this.state.infoServicio.plan 
                                                === undefined ||
                                            this.state.infoServicio.plan 
                                                == ""}>
                                    <IonLabel className="objetivoKeyLabel">
                                        Plan
                                    </IonLabel>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol>
                                                <IonTextarea
                                                value={this.state.infoServicio.plan }
                                                readonly />   
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid> 
                                </IonItem>                                                              
                                <IonItem hidden={this.state.infoServicio.articulos === undefined
                                    || this.state.infoServicio.articulos.length <= 0}>
                                    <IonLabel className="formKeyLabel">
                                        Artículos
                                    </IonLabel>
                                    <IonGrid>
                                        <IonRow className="objetivoLabel">
                                            <IonCol >Producto</IonCol>
                                        </IonRow>
                                        {this.state.infoServicio.articulos?.map(art=>
                                            <IonRow  className="articulosGridItem">
                                                <IonCol>{art.producto.nombre}</IonCol>
                                            </IonRow>
                                            )}
                                    </IonGrid>
                                    <IonGrid>
                                        <IonRow className="objetivoLabel">
                                            <IonCol>Cantidad</IonCol>
                                            <IonCol>Precio</IonCol>
                                        </IonRow>
                                        {this.state.infoServicio.articulos?.map(art=>
                                            <IonRow>
                                                <IonCol>{art.cantidad}</IonCol>
                                                <IonCol>{art.precioVenta}</IonCol>
                                            </IonRow>
                                            )}
                                    </IonGrid>
                                </IonItem> 
                                <IonItem hidden={this.state.infoServicio.extras === undefined
                                    || this.state.infoServicio.extras.length <= 0}>
                                    <IonLabel className="formKeyLabel">
                                        Extras
                                    </IonLabel>
                                    <IonGrid>
                                        <IonRow className="objetivoLabel">
                                            <IonCol >Concepto</IonCol>
                                        </IonRow>
                                        {this.state.infoServicio.extras?.map(ext=>
                                            <IonRow  className="articulosGridItem">
                                                <IonCol>{ext.nombre}</IonCol>
                                            </IonRow>
                                            )}
                                    </IonGrid>
                                    <IonGrid>
                                        <IonRow className="objetivoLabel">                                            
                                            <IonCol>Cargo</IonCol>
                                        </IonRow>
                                        {this.state.infoServicio.extras?.map(ext=>
                                            <IonRow>
                                                <IonCol>{ext.monto}</IonCol>
                                            </IonRow>
                                            )}
                                    </IonGrid>
                                </IonItem>                               
                                <IonItem>
                                    <IonButton onClick={()=>this.setState({showModal:false})}>Cerrar</IonButton>
                                </IonItem>
                    
                            </IonCardContent>
                        </IonCard>
                    </IonContent>
                </IonModal>
            </IonContent>

        </IonPage>)
    }
}