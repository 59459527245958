import React, { useEffect, useState } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom"
import { isNullOrUndefined } from "util";
import { IonCardTitle, IonContent, IonHeader, IonTitle, IonCard, IonCardContent, IonList, IonItem, IonButton, IonRouterLink, IonGrid, IonRow, IonCol, IonMenuButton, IonToolbar, IonFab, IonFabButton, IonIcon, IonModal, IonPage, IonRouterOutlet } from "@ionic/react";
import MedicamentoCard from "../components/MedicamentoCard";
import DataTable from 'react-data-table-component';
import { add } from "ionicons/icons";
import { modalController} from '@ionic/core'
import { EntradasInventarioPage } from "./EntradasInventarioPage";
import Compra from "../components/Compra";
import ESCard from "../components/ESCard";
import { callGetEntradasInv, callGetProductos, callGetSalidasInv } from "../firebaseConfig";
import { SalidasInventarioPage } from "./SalidasInventarioPage";
import { checkAccessToModule } from "../helper";

interface InventarioProps extends RouteComponentProps<{}> {}

export const InventarioPage: React.FC<InventarioProps> = ({history}) =>{

    const [accion, setAccion] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [detailRow, setDetailRow] = useState({} as any)
    const [tipoDetalle, setTipoDetalle] = useState("entrada")
    const [entradas, setEntradas] = useState([] as any[])
    const [salidas, setSalidas] = useState([] as any[])
    const [productos, setProductos] = useState([] as any[])
    const [faltantes, setFaltantes] = useState([] as any[])
    const [gridSalidasMsg, setGridSalidasMsg] = useState("Cargando...")
    const [gridEntradasMsg, setGridEntradadasMsg] = useState("Cargando...")
    const [gridFaltantesMsg, setGridFaltantesMsg] = useState("Cargando...")
    /* const [medicamentos, setMedicamentos] = useState([]);
    const [faltantes, setFaltantes] = useState([] as any[]);
    const [entradas, setEntradas] = useState([]);
    const [salidas,setSalidas] = useState([]); */
    
    //var productos: any[] = [];
    //var faltantes: any[] = [];
    //var entradas: any[] = [];
    //var salidas: any[] = [];

    const formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol'
      });

    const columns = [
        {
            name: 'Fecha',
            selector: (row: any) => row.fecha,
            format: (row: any) => new Date(row.fecha).toLocaleDateString(),
            sortable:true
        },
        {
            name: 'Factura',
            selector: (row: any) => row.factura,
            sortable: true
        },
        {
            name: 'Monto',
            selector: (row: any) => row.total,
            format: (row: any) => formatter.format(row.total as number),
            sortable: true
        }
    ];

    const columnsSalidas = [
        {
        name: 'Fecha',
        selector: (row: any) => row.fecha,
        format: (row: any) => new Date(row.fecha).toLocaleDateString(),
        sortable: true
        },
        {
        name: 'Concepto',
        selector: (row: any) => row.concepto,
        sortable: true
        }
    ]

    const styles = {
        wrap: {            
          display: "flex",
          height: "80%"
        },
        left: {
          marginRight: "10px"
        },
        main: {
          flexGrow: "1"
        },
        topdiv: {
            align: "center"
        }

      };
    
      function closeModal() {
          setAccion(1)
          //if(!isNullOrUndefined(window.localStorage['entradas']))
          //  entradas = JSON.parse(window.localStorage['entradas'])
          getEntradasFromCloud();
      }

    //constructor(props:any){
      //  super(props);
        /* if(!isNullOrUndefined(window.localStorage['productos']))
            productos = JSON.parse(window.localStorage['productos']);
            //setMedicamentos(JSON.parse(window.localStorage['medicamentos']));
        productos.map((med: any)=>{
            if(med.stockActual < med.stockMinimo)
                faltantes.push(med);
        }); */
        
        useEffect(()=>{
            getEntradasFromCloud();
            getSalidasFromCloud();
            getProductosFromCloud();
            }, [])            
        /*
        if(!isNullOrUndefined(window.localStorage['entradas']))
        entradas = JSON.parse(window.localStorage['entradas'])
            //setEntradas(JSON.parse(window.localStorage['entradas']));
        
            if(!isNullOrUndefined(window.localStorage['salidas']))
            salidas = JSON.parse(window.localStorage['salidas'])
            //setSalidas(JSON.parse(window.localStorage['salidas']));
        */
        //setAccion(1)
           function getEntradasFromCloud(){
            callGetEntradasInv().then(res=>{
                setEntradas(res as [])
                setGridEntradadasMsg("No hay registros")
            })
           }
           
           function getSalidasFromCloud(){
            callGetSalidasInv().then(res=>{
                setSalidas(res as [])
                setGridSalidasMsg("No hay registros")
            })
           }

           function getProductosFromCloud(){
            callGetProductos().then(res=>{
                setProductos(res as [])
                const prodTemp = res as []
                let faltantesTemp: any[] = []                
                console.log("getProductosFromCloud Se iterarán " + 
                    productos.length +  " productos")
                setGridFaltantesMsg("No hay medicamentos con faltante de stock")
                prodTemp.map((prod: any)=>{
                    //console.log("prod: " +  JSON.stringify(prod))
                    if(!prod.granel && (prod.stockActual < prod.stockMinimo)
                        || prod.granel && (
                        (prod.stockGranel < prod.stockMinimo) && prod.stockActual < 1
                        )){
                        //console.log("el stock actual es menor que el mín")
                        faltantesTemp.push(prod);
                    }
                    //else
                        //console.log("el stock actual es mayor o igual al mín")
                        
                });
                setFaltantes(faltantesTemp)
            })
           }
    //}

    //render(){
        if(!checkAccessToModule('Inventario'))
            return(<Redirect to="/pages/home"/>)

        return (
            <IonPage>   
                <IonRouterOutlet>
                    <Route exact path="/salidas" 
                        component={SalidasInventarioPage} />                    
                </IonRouterOutlet>             
                <IonContent>
                    <div className="wrap">
                        <IonGrid>
                            <IonRow>                           
                                <IonCol>
                                    <DataTable data={entradas} columns={columns}
                                        pagination={true}
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[5,10]}
                                        title="Entradas"
                                        dense={true}
                                        striped
                                        onRowClicked={(row, event)=>{
                                            setDetailRow(row)
                                            setShowModal(true);
                                            setTipoDetalle("entrada")
                                        }}
                                        noDataComponent={
                                            <IonGrid>                                                    
                                                <IonRow>
                                                    <IonCol size="4"></IonCol>
                                                    <IonCol>
                                                        {gridEntradasMsg}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow><IonCol>&nbsp;</IonCol></IonRow>
                                            </IonGrid>
                                        }/>                                    
                                    <IonFab horizontal="end" vertical="top">
                                        <IonFabButton onClick={()=>{
                                           /*  modalController.create({
                                                component: EntradasInventarioPage,
                                                backdropDismiss: false
                                            }).then(modal=>{
                                                modal.present();
                                            },  error=>alert(error)).catch(error=>alert(JSON.stringify(error)))
                                            */
                                            
                                            setAccion(2)
                                            }}>
                                            <IonIcon icon={add}/>
                                        </IonFabButton>
                                    </IonFab>
                                    <IonModal isOpen={accion == 2} cssClass="my-custom-modal-css"
                                        backdropDismiss={false}>
                                            <Compra closeAction={closeModal}/>
                                    </IonModal>
                                </IonCol>
                                <IonCol>
                                    <DataTable data={salidas} columns={columnsSalidas}
                                        pagination={true}
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[5,10]}
                                        title="Salidas"
                                        dense={true}
                                        onRowClicked={(row, event)=>{
                                            setDetailRow(row)
                                            setShowModal(true);
                                            setTipoDetalle("salida")
                                        }}
                                        striped
                                        noDataComponent={
                                            <IonGrid>                                                    
                                                <IonRow>
                                                    <IonCol size="4"></IonCol>
                                                    <IonCol>
                                                        {gridSalidasMsg}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow><IonCol>&nbsp;</IonCol></IonRow>
                                            </IonGrid>
                                        }/>
                                        <IonRouterLink href="/inventario/salidas">
                                        <IonFab horizontal="end" vertical="top">
                                        <IonFabButton onClick={()=>{
                                            //history.push("/salidas")
                                            
                                        }}>
                                            <IonIcon icon={add}/>
                                        </IonFabButton>
                                    </IonFab>
                                        </IonRouterLink>
                                    
                                </IonCol>                                                                    
                            </IonRow>                                                                                                
                        </IonGrid>                         
                    </div>
                    <div>
                        <IonCard>
                            <IonCardTitle>
                                Faltantes
                            </IonCardTitle>
                            <IonCardContent>                                
                                <IonList>
                                <div className="faltantes">
                                {
                                faltantes.length > 0?                
                                    faltantes.map((med:any)=>
                                        <MedicamentoCard nombre={med.nombre} codigo={med.codigo} 
                                            codigoBarras={med.codigoBarras} 
                                            fabricante={med.fabricante.nombre}
                                            stockMinimo={med.stockMinimo} 
                                            stockActual={med.stockActual}/>
                                        )
                                    :<IonItem>{gridFaltantesMsg}</IonItem>
                                }
                                </div>
                                </IonList>
                            </IonCardContent>   
                        </IonCard>
                    </div>
                    <IonModal isOpen={showModal} backdropDismiss={false}>
                        <IonContent>
                        <ESCard tipo={tipoDetalle} id={detailRow.id}
                            fecha={detailRow.fecha} formaPago={detailRow.formaPago}
                            productos={tipoDetalle == "entrada" ? detailRow.articulos:
                            detailRow.productos} 
                            proveedor={detailRow.proveedor}
                            comentarios={detailRow.comentarios}
                            responsable={detailRow.responsable}
                            concepto={detailRow.concepto}
                            factura={detailRow.factura}
                            />
                            <IonButton onClick={()=>{
                                setShowModal(false)
                            }}>
                                Cerrar
                            </IonButton>                                   
                        </IonContent>                        
                    </IonModal>

                                  {/* <IonRow>
                            <IonCol size="6"></IonCol>
                            <IonCol size="3"><IonRouterLink href="/inventario/salidas">Salidas</IonRouterLink></IonCol>
                            <IonCol size="3"><IonRouterLink href="/inventario/entradas">Entradas</IonRouterLink></IonCol>
                        </IonRow> */}      
                         {/* <IonCard>                                
                                <IonCardContent> */}
                                    {/* <IonList>
                                        {
                                        this.entradas.length > 0?
                                            this.entradas.map((entrada:any)=>
                                                <IonItem>
                                                   Fecha: {entrada.fecha} Factura: {entrada.numFactura}
                                                </IonItem>) : <IonItem>No hay entradas registradas</IonItem>
                                        }
                                    </IonList> */}
                    
                </IonContent>
            </IonPage>);
    }
//render}