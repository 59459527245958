import React from "react";
import { IonContent, IonHeader, IonToolbar, IonGrid, IonRow, IonCol, IonTitle, IonRouterLink, IonMenuButton, IonToast, IonList, IonItem, IonButton, IonInput, IonCardContent, IonCard, IonSelect, IonSelectOption, IonModal, IonFabButton, IonIcon, IonAlert, IonLoading, IonCheckbox, IonText } from "@ionic/react";
import { Link, RouteComponentProps } from "react-router-dom";
import CatalogoHeader from "../components/CatalogoHeader";
import { isNullOrUndefined } from "util";
import MedicamentoCard from "../components/MedicamentoCard";
import EntitySearch from "../components/EntitySearch";
import { callDeleteProducto, callGetFabricantes, callGetProductos, callModifyProducto, callNewProducto } from "../firebaseConfig";
import { create, trash } from 'ionicons/icons'
import DataTable from 'react-data-table-component';

type State ={    
    nombre: string,
    codigoBarras: string,
    fabricante: string,
    stockMinimo: number,
    toastState: boolean,
    showModal: boolean,
    action: number,
    showPickFabricante: boolean,
    productos: any[],
    id: string,
    showConfirm: boolean,
    showLoading: boolean,
    loadingMsg: string,
    granel: boolean,
    capacidad: number,
    unidad: string,
    stockActual?: number,
    stockGranel?: number,
    filterText?: string
}

export class ProductosPage extends React.Component<RouteComponentProps, State>{

    //productos: any[] = [];
    proveedores: any[] = [];
    fabricantes: any[] = [];        
    constructor(props: any){
        super(props);
        /*
        if(!isNullOrUndefined(window.localStorage['productos']))
            this.productos = JSON.parse(window.localStorage['productos']);
        else{
            this.productos = [{"nombre":"Pouch Razas Pequeñas Pollo","id":1,"codigoBarras":"","fabricante":{"codigo":2,"tipo":"alimento","nombre":"Pedigree"},"stockMinimo":"20","stockActual":0},{"nombre":"Vendas 5m","id":2,"codigoBarras":"","fabricante":{"codigo":3,"tipo":"material","nombre":"Degasa"},"stockMinimo":"10","stockActual":0},{"nombre":"Ranitidina 150mg","id":3,"codigoBarras":"","fabricante":{"codigo":1,"tipo":"laboratorio","nombre":"Astra Zeneca"},"stockMinimo":"10","stockActual":0}]
            window.localStorage['productos'] = JSON.stringify(this.productos);
            window.localStorage['productoNewId'] = "4"
        }
        */
        
        if(!isNullOrUndefined(window.localStorage['proveedores']))
            this.proveedores = JSON.parse(window.localStorage['proveedores']);
       
        /*    
            if(!isNullOrUndefined(window.localStorage['fabricantes']))
            this.fabricantes = JSON.parse(window.localStorage['fabricantes']);
       */
        this.getFabricantesFromCloud();        
        this.getProductosFromCloud();
            this.state ={
            nombre: "",        
            codigoBarras: "",
            fabricante: "",
            stockMinimo: 0,
            toastState: false,
            showModal: false,
            action: 1,
            showPickFabricante: false,
            productos: [],
            showConfirm: false,
            id:"",
            showLoading: true,
            loadingMsg: "Cargando...",
            granel: false,
            capacidad: 0,
            unidad: "",
            stockActual:0,
            stockGranel:0,
            filterText:''            
        }
        //console.log(JSON.stringify(this.state));   
        this.deleteEntry = this.deleteEntry.bind(this)

        window.localStorage.removeItem("fabricanteSelected")
    }
    getFabricantesFromCloud() {
        callGetFabricantes().then(res=>{
            //console.log(
            //    "ProductosPage.constructor resultado callGetFabricantes: " +
            //    JSON.stringify(res))
            this.fabricantes = res as any[]
        })
    }

   filteredItems = ()=>{
    return isNullOrUndefined(this.state.productos)? 
        []: this.state.productos.filter(
            item=>item.nombre && item.nombre.toLowerCase().includes(
                this .state.filterText?.toLowerCase()),)
    }
    filterTextChange = (event: CustomEvent)=>{
        this.setState({filterText : event.detail.value})
    }
    //console.log("filteredItems: " + filteredItems)
        /* this.state.productos.filter(
        item=>item.nombre && item.nombre.toLoweCase().includes(
            this .state.filterText?.toLowerCase()),) */

    nombreChange = (event: CustomEvent) => {        
        this.setState({ nombre: event.detail.value });
      }
      /*
    codigoChange = (event: CustomEvent) => {        
        this.setState({ codigo: event.detail.value });
        }
        */
    codigoBarrasChange = (event: CustomEvent) => {        
        this.setState({ codigoBarras: event.detail.value });
        }
    fabricanteChange = (event: CustomEvent) => {        
        this.setState({ fabricante: event.detail.value });
        }
    stockMinimoChange = (event: CustomEvent) => {
        if(event.detail.value == null)
            this.setState({ stockMinimo: 0})                        
        else
            this.setState({ stockMinimo: event.detail.value});                
            
        }  
    granelChange = (event: CustomEvent) => {        
        this.setState({ granel: event.detail.checked})        
    }
    capacidadChange = (event: CustomEvent) => {
        if(event.detail.value == null)
            this.setState({ capacidad: 0})                        
        else
            this.setState({ capacidad: event.detail.value});                
            
    }
    unidadChange = (event: CustomEvent) => {
        this.setState({unidad: event.detail.value})
    }
    stockActualChange = (event: CustomEvent)=>{
        this.setState({stockActual: event.detail.value})
    }
    stockGranelChange = (event: CustomEvent) =>{
        this.setState({stockGranel: event.detail.value})
    }
    
    submitProducto = (tag: any) =>{       
        if(this.state.nombre == ""){
            alert("Por favor, indique el nombre del producto para continuar")
            return
        }
        if(window.localStorage["fabricanteSelected"]===undefined){
            alert("Por favor, seleccione un fabricante")
            return
        }
        if(this.state.granel){
            if(this.state.capacidad <= 0 || this.state.unidad == ""){
                alert("Por favor indique la capacidad del envase")
                return;
            }            
        }
        if(this.state.stockMinimo <= 0){
            alert("Por favor, indique el stock mínimo para el producto")
            return
        }
        this.setState({
            showLoading: true,
            loadingMsg: "Guardando..."
        })
        let productoData = {            
            "nombre": this.state.nombre,
            "id": this.state.id,
            "codigoBarras": this.state.codigoBarras,
            "fabricante": JSON.parse(window.localStorage['fabricanteSelected']),
            "stockMinimo": this.state.stockMinimo,
            //"stockActual": 0,
            "granel" : this.state.granel,
            "capacidad" : this.state.capacidad,
            "unidad" : this.state.unidad,
            "stockActual": this.state.stockActual,
            "stockGranel": this.state.stockGranel
        }
        
        //console.log("Se agrega el objeto al arreglo " + JSON.stringify(proveedorData));
        /*
        this.productos.push(productoData);        
        window.localStorage['productos'] = JSON.stringify(this.productos);          
        console.log("Arreglo productos en localstorage despues de guardar: " + 
            JSON.stringify(window.localStorage['productos']));*/
        if(this.state.action == 2)
            callNewProducto(productoData).then(res=>{
                //console.log("ProductosPage resultado callNewProducto: " + 
                //    JSON.stringify(res));
                this.setState({
                    toastState: true,
                    nombre: "",             
                    codigoBarras: "", 
                    fabricante: "",
                    stockMinimo: 0,
                    showLoading: false,
                    granel: false,
                    capacidad: 0,
                    unidad: "",
                    stockActual: 0,
                    stockGranel: 0
                });
                this.changeAction(1);
                window.localStorage.removeItem("fabricanteSelected")
                this.getProductosFromCloud();
            })
        else
            callModifyProducto(productoData).then(res=>{
                this.setState({
                    toastState: true,
                    nombre: "",             
                    codigoBarras: "", 
                    fabricante: "",
                    stockMinimo: 0,
                    showLoading: false,
                    granel: false,
                    capacidad: 0,
                    unidad: "",
                    stockActual: 0,
                    stockGranel: 0
                });
                this.changeAction(1);
                window.localStorage.removeItem("fabricanteSelected")
                this.getProductosFromCloud();
            })        
        }
    
    
        clickLink(arg:number){
        //console.log("Click: " + arg );        
        this.changeAction(arg);
    }

    changeAction(arg:number){
        this.setState({action:arg})
    }   

    getNewId(){
        var idReturn: number = -1;
        if(isNullOrUndefined(window.localStorage['productoNewId']))
            idReturn = 1;
        else
            idReturn = Number.parseInt(window.localStorage['productoNewId']);
        window.localStorage['productoNewId'] = idReturn+1;
        return idReturn;
    }

    getProductosFromCloud(){
        callGetProductos().then(res=>{
            this.setState({
                productos: res as [],
                showLoading: false
            })            
            //console.log("ProductosPage.getProductosFromCloud this.productos: " +
            //    JSON.stringify(this.state.productos))
        })
    }
    deleteEntry(){
        console.log("deleteEntry id: " +  this.state.id)
        this.setState({
            showLoading: true,
            loadingMsg: "Guardando..."
        })
        callDeleteProducto(this.state.id).then(res=>{
            this.setState({
                id:"",
                showConfirm:false,
                showLoading: false
            })
            this.getProductosFromCloud()
        })        
    }
    editProducto(id: string){        
        let prod = this.state.productos.filter(item=>item.id == id)
        console.log("editProducto prod: " + JSON.stringify(prod))
        this.setState({
            action: 3,
            nombre: prod[0].nombre,
            stockMinimo: prod[0].stockMinimo,
            id: id,
            granel: prod[0].granel,
            capacidad: prod[0].capacidad,
            unidad: prod[0].unidad,
            stockActual: prod[0].stockActual,
            stockGranel: prod[0].stockGranel    
        })
        window.localStorage['fabricanteSelected'] = 
            JSON.stringify(prod[0].fabricante)
    }

    validateKey(event: any){                
        //console.log("validateKey data: " + event.data + 
        //" test: " + /\d/.test(event.data))        
        if(!/\d/.test(event.data))
            event.preventDefault();            
    }

    columns = [
        {
            name: 'Nombre',
            selector: (row: any) => (row.nombre as string).toUpperCase(),
            sortable:true
        },
        {
            name: 'Fabricante',
            selector: (row: any) => row.fabricante.nombre,
            sortable: true
        },
        {
            name: 'Stock',
            selector: (row: any) => row.stockGranel? 
                row.stockActual + " + " +  row.stockGranel + " " + row.unidad
                : row.stockActual,            
            sortable: true
        },        
        {
            name: '',
            selector: (row: any) =>
                <IonGrid>
                    <IonRow>
                    <IonCol>
                        <IonFabButton size="small" 
                            onClick={()=>this.editProducto(row.id)}>
                            <IonIcon icon={create}/>
                        </IonFabButton>
                    </IonCol>
                    <IonCol>
                        <IonFabButton size="small" color="danger"
                            onClick={()=>{
                                this.setState({ id:row.id, showConfirm:true})
                            }}>
                            <IonIcon icon={trash}/>
                        </IonFabButton>
                    </IonCol>
                    </IonRow>
                </IonGrid>,
            sortable: true
        }
    ];
    render(){
        return(
            <>
            <CatalogoHeader title="PRODUCTOS" linkCaption="Nuevo" clickFunc={()=>this.clickLink(2)}></CatalogoHeader>
            <IonContent>
                <IonLoading isOpen={this.state.showLoading}
                    message={this.state.loadingMsg}
                    />
                <IonToast
                    isOpen={this.state.toastState}
                    onDidDismiss={() => 
                    this.setState(() => ({ toastState: false,
                        action: 1
                        }))                              
                    }
                    message= "Se guardó la información"
                    duration={400}
                ></IonToast>
                <IonCard>
                    <IonCardContent>
                    {/* <IonGrid hidden= {this.state.action != 1}>
                    {                  
                        this.state.productos.map((med:any) =>
                        <IonRow key={med.id}>
                            <IonCol size="4">{med.nombre}</IonCol>  
                            <IonCol size="4">Fabricante: {med.fabricante.nombre}</IonCol>
                            <IonCol size="2">Stock Mínimo: {med.stockMinimo} </IonCol>                            
                            <IonCol>
                                <IonFabButton size="small" 
                                    onClick={()=>this.editProducto(med.id)}>
                                    <IonIcon icon={create}/>
                                </IonFabButton>
                            </IonCol>
                            <IonCol>
                                <IonFabButton size="small" color="danger"
                                    onClick={()=>{
                                        this.setState({ id:med.id, showConfirm:true})
                                    }}>
                                    <IonIcon icon={trash}/>
                                </IonFabButton>
                            </IonCol>
                        </IonRow>                        
                    )}
                    </IonGrid> */}
                <div hidden={this.state.action != 1}>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="9">

                            </IonCol>
                            <IonCol>
                                <IonInput type="search" clearInput={true}
                                    onIonChange={this.filterTextChange}
                                    placeholder="Filtrar por nombre"/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <DataTable data={this.filteredItems()} 
                                    columns={this.columns}
                                    pagination={true}
                                    paginationPerPage={5}
                                    paginationRowsPerPageOptions={[5,10]}                    
                                    dense={true}                    
                                    striped
                                    noDataComponent={
                                        <IonGrid>                                                    
                                            <IonRow>
                                                <IonCol size="4"></IonCol>
                                                <IonCol>
                                                    No hay registros
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>&nbsp;</IonCol>
                                            </IonRow>
                                        </IonGrid>
                                        }
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>                
                    
                </div>                
                <form>
                <IonGrid hidden={this.state.action == 1}>
                    <IonRow>
                        <IonCol size="12">
                            Ingrese los datos del producto
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            Nombre
                        </IonCol>
                        <IonCol>
                            <IonInput type="text" value={this.state.nombre} 
                                onIonChange={this.nombreChange}></IonInput>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                    {/* <IonCol>
                            Codigo
                        </IonCol>
                        <IonCol>
                            <IonInput type="text" value={this.state.codigo} onIonChange={this.codigoChange}></IonInput>
                        </IonCol> */}
                    </IonRow>
                    <IonRow>
                    <IonCol>
                            Fabricante
                        </IonCol>
                        <IonCol>
                            <IonInput type="search" 
                                placeholder="Ingrese el fabricante"
                                value={
                                    isNullOrUndefined(window.localStorage
                                    ["fabricanteSelected"])? 
                                    "Seleccione un fabricante" : JSON.parse(
                                    window.localStorage["fabricanteSelected"])
                                    .nombre}
                                onIonFocus={()=>this.setState({ 
                                    showPickFabricante:true})}                                     
                            ></IonInput>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            Venta a granel
                        </IonCol>
                        <IonCol>
                            <IonCheckbox 
                            checked={this.state.granel}
                            onIonChange={this.granelChange}/>
                        </IonCol>
                    </IonRow>
                    <IonRow hidden={!this.state.granel}>
                        <IonCol>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                    Capacidad envase
                                    </IonCol>
                                </IonRow>
                            </IonGrid>                            
                        </IonCol>
                        <IonCol>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        <IonInput type="tel"
                                            onBeforeInput={this.validateKey}
                                            onIonChange={this.capacidadChange}
                                            value={this.state.capacidad == 0 ? 
                                            null:this.state.capacidad?.toString()}
                                            placeholder="Ingrese la cantidad"
                                        />
                                    </IonCol>
                                    <IonCol>
                                        <IonSelect placeholder="Elija unidad"
                                            onIonChange={this.unidadChange}
                                            value={this.state.unidad}>
                                            <IonSelectOption>
                                                Tabletas
                                            </IonSelectOption>
                                            <IonSelectOption>
                                                Mililitros
                                            </IonSelectOption>
                                            <IonSelectOption>
                                                Dósis
                                            </IonSelectOption>
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>                                                        
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            Stock Mínimo
                        </IonCol>
                        <IonCol>
                            <IonInput type="tel" 
                            value={
                                this.state.stockMinimo==0?
                                null:this.state.stockMinimo.toString()} 
                                onIonChange={this.stockMinimoChange}
                                onBeforeInput={this.validateKey}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            Stock actual(envases completos)
                        </IonCol>
                        <IonCol>
                            <IonInput type="tel" onIonChange={this.stockActualChange}
                                value={this.state.stockActual?.toString()}/>
                        </IonCol>
                    </IonRow>
                    <IonRow  hidden={!this.state.granel}>
                        <IonCol>
                            Stock actual(unidades sueltas)
                        </IonCol>
                        <IonCol>
                            <IonInput type="tel" onIonChange={this.stockGranelChange}
                                value={this.state.stockGranel?.toString()}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRouterLink onClick={()=>{
                                window.localStorage.removeItem(
                                    "fabricanteSelected")
                                this.changeAction(1)
                                this.setState({                                    
                                    nombre: "",             
                                    codigoBarras: "", 
                                    fabricante: "",
                                    stockMinimo: 0,
                                    granel: false,
                                    capacidad: 0,
                                    unidad: "",
                                    stockActual: 0,
                                    stockGranel: 0                                    
                                });
                            }}>
                                Regresar</IonRouterLink>
                        </IonCol>
                        <IonCol>
                            <IonButton onClick={this.submitProducto}>Continuar</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                </form>
                    </IonCardContent>
                </IonCard>
                    <IonModal isOpen={this.state.showPickFabricante} backdropDismiss={false}>
                        <EntitySearch tipoEntidad="fabricante"/>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton onClick={()=>this.setState({showPickFabricante:false})} 
                                        color="medium">
                                    Cancelar
                                    </IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={()=>this.setState({ showPickFabricante:false})}>
                                        Continuar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>                                        
                    </IonModal>  
                    <IonAlert isOpen={this.state.showConfirm}
                        onDidDismiss={()=>this.setState({showConfirm:false})}                    
                        header="Advertencia"
                        message="¿Realmente desea eliminar el registro?"
                        buttons={[
                            {
                                text: "Cancelar",
                                role: "cancel",
                                cssClass: "cancelButton",
                                handler: () => this.setState({showConfirm:false})
                            },
                            {
                                text: "Continuar",
                                cssClass: "dangerButton",
                                handler: this.deleteEntry
                            }
                        ]}
                    />              
            </IonContent>
            </>
        )
    }

}