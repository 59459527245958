import React from "react";
import { isNullOrUndefined } from "util";
import CatalogoHeader from "../components/CatalogoHeader";
import { IonContent, IonCardContent, IonCard, IonButton, IonList, IonToast, 
    IonGrid, IonRow, IonCol, IonInput, IonSelect, IonSelectOption, IonModal, 
    IonItem, IonLoading, IonFabButton, IonIcon, IonCheckbox, IonLabel } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import ESCard from "../components/ESCard";
import ProductoESCard from "../components/ProductoESCard";
import { callGetFormasPago, callGetProductos, callGetSalidasInv, 
    callNewMovimiento, callNewSalidaInv, callUpdateStock } from 
    "../firebaseConfig";
import { trash } from "ionicons/icons";
import EntitySearch from "../components/EntitySearch";

type State ={
    fecha:string,
    articulos: any[],
    //proveedor: string,
    action: number,
    toastState: boolean,
    productoId: string,
    cantidad: number,
    //precioCompra: number,
    precioVenta: number,
    precioMayoreo: number,
    numeroLote: string,
    concepto: string,
    comentarios: string,
    showAddMed: boolean,
    formaPago: string,
    showLoading: boolean,
    granel: boolean,
    cantidadSizeCol: string,
    showPickProducto: boolean
}

export class SalidasInventarioPage extends React.Component<RouteComponentProps,
    State>{

    salidas: any[] = [];
    //proveedores: any[] = [];
    productos: any[] = [];    
    formasPago: any[] = [];

    nuevaClick(action:number){
        this.setState({action:action});
    }

    curFormatter  = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol'
      });

    constructor(props:any){
        super(props)
       /*
        if(!isNullOrUndefined(window.localStorage['salidas']))
            this.salidas = JSON.parse(window.localStorage['salidas']);
       */
            //if(!isNullOrUndefined(window.localStorage['proveedores']))
       //    this.proveedores = JSON.parse(window.localStorage['proveedores']);
       /* 
       if(!isNullOrUndefined(window.localStorage['medicamentos']))
            this.medicamentos = JSON.parse(window.localStorage['medicamentos']);
       */
        /*
        this.formasPago = [{id:1, nombre:"Efectivo"}, {id:2, nombre:"Tarjeta"}];
        */
       this.getSalidasFromCloud()
       this.getProductosFromCloud()
       this.getFormasPagoFromCloud()
        this.state ={
            fecha: "",
            articulos: [],
            //proveedor: "",
            action: 2,
            toastState: false,
            productoId: "",
            cantidad: 0,
            //precioCompra: 0,
            precioVenta:0,
            precioMayoreo: 0,
            numeroLote: "",
            concepto: "",
            comentarios: "",
            showAddMed: false,
            formaPago: "",
            showLoading: false,
            granel: false,
            cantidadSizeCol: "8",
            showPickProducto: false
        }
    }

    getSalidasFromCloud(){
        callGetSalidasInv().then(res=>{
            this.salidas = res as []
        })
    }
    getProductosFromCloud(){
        callGetProductos().then(res=>{
            this.productos = res as []
        })
    }
    getFormasPagoFromCloud(){
        callGetFormasPago().then(res=>{
            this.formasPago = res as []
        })
    }
    fechaChange = (event: CustomEvent) => {
        this.setState({ fecha: event.detail.value});
        } 
    /*proveedorChange = (event: CustomEvent) => {
        this.setState({ proveedor: event.detail.value});
        }*/
    medicamentoChange = (event: CustomEvent) => {
        this.setState({ productoId: event.detail.value});
        }
    cantidadChange = (event: CustomEvent) => {
        this.setState({ cantidad: event.detail.value});
        }
    /* precioCompraChange = (event: CustomEvent) => {
        this.setState({ precioCompra: event.detail.value});
        }*/
    precioVentaChange = (event: CustomEvent) => {
        const regex = /^[0-9]+(\.[0-9]{1,2}){0,1}$/
        if(regex.test(event.detail.value))
            this.setState({ precioVenta: event.detail.value});
        } 
    precioMayoreoChange = (event: CustomEvent) => {
        const regex = /^[0-9]+(\.[0-9]{1,2}){0,1}$/
        if(regex.test(event.detail.value))
            this.setState({ precioMayoreo: event.detail.value});
        }
    numeroLoteChange = (event: CustomEvent) => {
        this.setState({ numeroLote: event.detail.value});
        }
    conceptoChange = (event: CustomEvent) => {
        this.setState({concepto: event.detail.value});
    }
    comentariosChange = (event: CustomEvent) => {
        this.setState({comentarios: event.detail.value});
    }
    formaPagoChange = (event: CustomEvent) => {
        this.setState({formaPago: event.detail.value});
    }
    granelChanged = (event: CustomEvent)=>{        
        if(event.detail.checked == true){
            this.setState({
                granel: true,
                cantidadSizeCol:"4"
            })
        }            
        else{
            this.setState({
                granel: false,
                cantidadSizeCol:"8"
            })
        }        
    }


    isCurProdGranel(){
        if(!window.localStorage["productoSelected"]){
            console.log("isCurProdGranel no hay producto seleccionado")
            return false;
        }
            
        const prod = JSON.parse(window.localStorage["productoSelected"])
        if(prod.granel == true)
            return true;
        else
            return false;
    }

    getUnidadGranel(){
        if(!window.localStorage["productoSelected"]){
            console.log("getUnidadGranel no hay producto seleccionado")
            return ""
        }
        const prod = JSON.parse(window.localStorage["productoSelected"])
        if(prod.granel == true){
            console.log("getUnidadGranel sí es granel: " + prod.unidad)
            return prod.unidad;
        }            
        else{
            console.log(
                "getUnidadGranel el producto seleccionado no se vende a granel")
            return "";
        }
    }


    validateKey = (event: any)=>{                
        //console.log("validateKey data: " + event.data + 
        //" test: " + /\d/.test(event.data))        
        if(!/\d/.test(event.data))
            event.preventDefault();            
    }

    validateKeyCurrency = (event: any) => {        
        const regex = /^[0-9]|\./        
        if(!regex.test(event.data))
            event.preventDefault();
    }

    getNewId(){
        var idReturn: number = -1;
        if(isNullOrUndefined(window.localStorage['salidaNewId']))
            idReturn = 1;
        else
            idReturn = Number.parseInt(window.localStorage['salidaNewId']);
        window.localStorage['salidaNewId'] = idReturn+1;
        return idReturn;
    }
    
    submitSalida = (tag: any) =>{            
        if(this.state.fecha == ""){
            alert("Ingrese la fecha de salida de los artículos")
            return;
        }
        if(this.state.concepto == ""){
            alert("Elija el concepto de salida")
            return;
        }
        if(this.state.formaPago == ""){
            alert("Elija la forma de pago")
            return;
        }
        if(this.state.articulos.length < 1){
            alert("Ingrese los artículos que saldrán del inventario")
            return;
        }

        this.setState({
            showLoading: true
        })

        var montoSalida: number = 0;
        var unidadesSalida: number = 0;

        this.state.articulos.map(producto=>{
            montoSalida += parseFloat(producto.precioVenta) * 
                parseInt(producto.cantidad);
            unidadesSalida += parseInt(producto.cantidad);
        })
        
        let salidaData = {
            //"id": this.getNewId(),        
            "id": "no asignado",        
            "fecha": this.state.fecha,
            //"proveedor": this.state.proveedor,
            "productos": this.state.articulos,
            "responsable": window.localStorage['username'],
            "concepto": this.state.concepto,
            "comentarios": this.state.comentarios,
            "formaPago": this.state.formaPago,
            "activo" : 1
        }
        
        console.log("Se va a guardar el objeto " + JSON.stringify(salidaData));
        
        /*
        this.salidas.push(salidaData);        
        window.localStorage['salidas'] = JSON.stringify(this.salidas);
        */
        callNewSalidaInv(salidaData).then(res=>{
            
            salidaData.productos.map((prod:any)=>{
                callUpdateStock(prod.producto.id, prod.cantidad * -1,
                    prod.granel)
                .then(res=>{
                    console.log("resultado updateStock: " + JSON.stringify(res))                                        
                })
                //console.log("producto actual: " + prod.medicamento);                
            })
            let movimientoData = {
                tipo: this.state.concepto == "venta"? 1:2,
                id: "undefined",        
                concepto: "Salida Inventario",
                monto: montoSalida,
                fecha: this.state.fecha,
                detalle: this.state.concepto + " " + unidadesSalida + " unidades",
                formaPago: this.state.formaPago
            }
            callNewMovimiento(movimientoData).then(res=>{
                this.setState({
                    toastState: true,
                    fecha: "", 
                    //proveedor: "", 
                    articulos: [],
                    concepto: "",
                    comentarios: "",
                    formaPago: "",
                    showLoading: false
                });
                this.props.history.replace("/inventario")
            })                    
    
            /* let movimientoData = {
                tipo: this.state.concepto == "venta"? 1:2,
                id: this.getNewMovimientoId(),        
                concepto: "Salida Inventario",
                monto: montoSalida,
                fecha: this.state.fecha,
                detalle: this.state.concepto + " " + unidadesSalida + " unidades",
                formaPago: this.state.formaPago
            }
    
            var movimientos = isNullOrUndefined(window.localStorage["movimientos"])?
                []: JSON.parse(window.localStorage["movimientos"]);
            movimientos.push(movimientoData);
            window.localStorage["movimientos"] = JSON.stringify(movimientos);
     */

            //console.log("Arreglo entradas en localstorage despues de guardar: " + 
            //    JSON.stringify(window.localStorage['entradas']));            
            //this.setState({action:1});            

        })

        /* salidaData.productos.map((prod:any)=>{
            //console.log("producto actual: " + prod.medicamento);
            this.medicamentos.map((med:any)=>{
                //console.log("medicamento actual: " + med.codigo);
                if(med.codigo === prod.medicamento){
                    //console.log("producto y medicamento son iguales");
                    //console.log("stock actual de medicamento: " + med.stockActual);
                    med.stockActual = Number.parseInt(med.stockActual) - 
                        Number.parseInt(prod.cantidad);
                    //console.log("nuevo stock: " + med.stockActual);
                }
            })
        })        
        window.localStorage['medicamentos'] = JSON.stringify(this.medicamentos);

        let movimientoData = {
            tipo: this.state.concepto == "venta"? 1:2,
            id: this.getNewMovimientoId(),        
            concepto: "Salida Inventario",
            monto: montoSalida,
            fecha: this.state.fecha,
            detalle: this.state.concepto + " " + unidadesSalida + " unidades",
            formaPago: this.state.formaPago
        }

        var movimientos = isNullOrUndefined(window.localStorage["movimientos"])?
            []: JSON.parse(window.localStorage["movimientos"]);
        movimientos.push(movimientoData);
        window.localStorage["movimientos"] = JSON.stringify(movimientos);

        //console.log("Arreglo entradas en localstorage despues de guardar: " + 
        //    JSON.stringify(window.localStorage['entradas']));
        this.setState({
            toastState: true,
            fecha: "", 
            //proveedor: "", 
            productos: [],
            concepto: "",
            comentarios: "",
            formaPago: ""
        });
        this.setState({action:1}); */
        }

    getNewMovimientoId(){
        var idReturn: number = -1;
        if(isNullOrUndefined(window.localStorage['movimientoNewId']))
            idReturn = 1;
        else
            idReturn = Number.parseInt(window.localStorage['movimientoNewId']);
        window.localStorage['movimientoNewId'] = idReturn+1;
        return idReturn;
    }

    showAddProductoModal(){
        this.setState({showAddMed: true}); 
        //console.log("valor de shoeAddMed: " + this.state.showAddMed);     
    }   
    

    submitProducto = (tag: any) =>{    
        if(!window.localStorage["productoSelected"])
            {
                alert("Elija el producto a agregar")
                return;
            }
        if(this.state.cantidad <= 0){
            alert("Indique la cantidad de artículos a agregar")
            return;
        }
        if(this.state.precioVenta <= 0)
        {
            alert("Indique el precio de venta del artículo")
            return;
        }
        const med = JSON.parse(window.localStorage["productoSelected"])
        //this.productos.map((med:any)=>{            
            //if(med.id === this.state.productoId){
        var newStock = 0;
        if(this.state.granel)
            newStock = Number.parseFloat(med.stockGranel)- 
                this.state.cantidad;
        else
            newStock = Number.parseInt(med.stockActual) - 
                this.state.cantidad;
        //console.log("el nuevo stock de " + med.nombre + " será: " +  newStock)
        if(newStock < 0){
            alert("No hay existencias suficientes de este producto (" +
                med.stockActual + ").");
                return;
        }
        else{
            let productoData = {
                producto: med,
                cantidad: this.state.cantidad,
                //precioCompra: this.state.precioCompra,
                precioVenta: this.state.precioVenta,
                precioMayoreo: this.state.precioMayoreo,
                numeroLote: this.state.numeroLote,
                granel: this.state.granel
            }
            
            //console.log("Se agrega el objeto al arreglo " + JSON.stringify(proveedorData));
            this.state.articulos.push(productoData);        
            //window.localStorage['entradas'] = JSON.stringify(this.entradas);          
            //console.log("Arreglo entradas en localstorage despues de guardar: " + 
            //    JSON.stringify(window.localStorage['entradas']));
            this.setState({
                productoId: "",
                cantidad: 0,
                //precioCompra: 0,
                precioVenta:0,
                precioMayoreo: 0,
                numeroLote: "",
                showAddMed: false,
                granel: false
            });
            window.localStorage.removeItem("productoSelected")
                    //this.setState({action:1});
            }
                
            //}
        //})               
        }

        cancelNewSalida(){
            this.setState({                
                fecha: "", 
                //proveedor: "", 
                articulos: [],
                concepto: "",
                comentarios: "",
                formaPago: ""
            });
            //this.setState({action:1});
            this.props.history.goBack()
        }
        cancelAddProduct(){
            this.setState({
                productoId: "",
                cantidad: 0,
                //precioCompra: 0,
                precioVenta:0,
                precioMayoreo: 0,
                numeroLote: "",
                showAddMed: false,
                granel: false
            });
            window.localStorage.removeItem("productoSelected")
        }

        removeItem(articulo:any){
            console.log("SalidasInventarioPage.removeItem recibido: " + 
                articulo.producto.id)
            for (var i = 0; i < this.state.articulos.length; i++) {                
                if(this.state.articulos[i].producto.id ==
                    articulo.producto.id){                                    
                        this.state.articulos.splice(i,1)                
                        this.setState({
                            articulos: this.state.articulos
                        })
                    }                    
            }
        }

    render(){        
        return(
        <>            
            <CatalogoHeader title="SALIDAS" linkCaption="Nueva" clickFunc={()=>this.nuevaClick(2)}/>
            <IonContent>
                <IonLoading 
                    isOpen={this.state.showLoading}
                    message="Guardando..."
                    />
                <IonToast
                    isOpen={this.state.toastState}
                    onDidDismiss={()=>this.setState({ toastState: false})}
                    message= "Se guardó la información"
                    duration={400}
                />                                                    
                <IonCard>
                    <IonCardContent hidden= {this.state.action != 1}>
                        <IonList>
                            <IonGrid>
                                <IonRow>
                                <IonCol  className="gridHeaderCol" size="2">Fecha
                                    </IonCol>
                                {/* <IonCol  className="gridHeaderCol"size="5">Proveedor</IonCol> */}
                                <IonCol className="gridHeaderCol" size="2">
                                    Responsable</IonCol>
                                <IonCol className="gridHeaderCol" size ="2">
                                    Concepto</IonCol>
                                <IonCol className="gridHeaderCol" size="2">
                                    Comentarios</IonCol>
                                <IonCol className="gridHeaderCol" size="2">
                                    Forma de Pago</IonCol>
                                <IonCol className="gridHeaderCol" size ="2">
                                    &nbsp;</IonCol> 
                            </IonRow>                            
                            {
                            this.salidas.length > 0?
                                this.salidas.map((salida)=>
                                    <ESCard tipo="salida" id={salida.id} 
                                        fecha={salida.fecha} 
                                        proveedor={salida.concepto} 
                                        responsable={salida.responsable}
                                        concepto={salida.concepto}
                                        comentarios={salida.comentarios}
                                        productos={salida.productos}
                                        formaPago={salida.formaPago}/>
                                ):
                                <IonItem>
                                    No hay salidas de inventario registradas
                                </IonItem>
                            }
                            </IonGrid>
                        </IonList>
                    </IonCardContent>
                    <IonCardContent hidden= {this.state.action != 2}>
                        <form>
                        <IonGrid hidden={this.state.action != 2}>                            
                            <IonRow>
                                <IonCol size="2" className="formKeyLabel">
                                    Fecha</IonCol>
                                <IonCol size="3">
                                    <IonInput type="date" value={this.state.fecha} 
                                        onIonChange={this.fechaChange}></IonInput>
                                </IonCol>
                                <IonCol size="2" className="formKeyLabel">
                                    Concepto</IonCol>
                                <IonCol>
                                    <IonSelect 
                                        placeholder="Seleccione uno de la lista"
                                        onIonChange={this.conceptoChange}
                                        value={this.state.concepto}>
                                        <IonSelectOption value="venta">
                                            Venta</IonSelectOption>
                                        <IonSelectOption value="interno">
                                            Uso interno</IonSelectOption>
                                        <IonSelectOption value="otro">
                                            Otro</IonSelectOption>
                                    </IonSelect>
                                </IonCol>
                                {/* <IonCol size="2" className="formKeyLabel">Proveedor</IonCol>
                                <IonCol size="5">
                                    <IonSelect placeholder="Seleccione uno de la lista" value={this.state.proveedor} 
                                        onIonChange={this.proveedorChange}>
                                        {this.proveedores.map((prov)=>
                                        <IonSelectOption value={prov.razonSocial}>
                                            {prov.razonSocial}
                                        </IonSelectOption>)}
                                    </IonSelect>
                                </IonCol> */}
                            </IonRow>
                            <IonRow>
                                <IonCol size="2" className="formKeyLabel">
                                    Comentarios:
                                </IonCol>
                                <IonCol size="10">
                                    <IonInput 
                                        placeholder=
                                            "Agregue información adicional aquí"
                                        onIonChange={this.comentariosChange} 
                                        type="text"
                                        value={this.state.comentarios}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="formKeyLabel">
                                    Forma de Pago</IonCol>
                                <IonCol>
                                    <IonSelect onIonChange={this.formaPagoChange}>
                                        {this.formasPago.map((fdp)=>
                                            <IonSelectOption value={fdp.nombre}>
                                                {fdp.nombre}
                                            </IonSelectOption>   
                                        )}
                                    </IonSelect>
                                </IonCol>
                            </IonRow>
                            <IonRow>                                
                                <IonCol size="2" className="formKeyLabel">
                                    Artículos:</IonCol>
                                <IonCol size="10">
                                    <IonItem>
                                    <IonGrid fixed={true}>
                                        <IonRow>                                            
                                            <IonCol
                                                className="gridHeaderCol" size="6">
                                                Producto</IonCol>
                                            <IonCol 
                                                className="gridHeaderCol" size="2">
                                                Cant</IonCol>
                                            <IonCol 
                                                className="gridHeaderCol" size="3">
                                                Venta</IonCol>                                                                                        
                                            <IonCol
                                                className="gridHeaderCol" size="1"/>                                                
                                        </IonRow>                                
                                    
                                        {
                                        this.state.articulos.length > 0?
                                            this.state.articulos.map((prod:any)=>
                                            <IonRow className="colAlineada">
                                                <IonCol size="6">
                                                {prod.producto.nombre}
                                                </IonCol>
                                                <IonCol>
                                                {prod.cantidad} 
                                                </IonCol>
                                                <IonCol>
                                                {this.curFormatter.format(
                                                    prod.cantidad * 
                                                    prod.precioVenta)} 
                                                </IonCol>                                                
                                                <IonCol>
                                                    <IonFabButton color="danger"
                                                        onClick={()=>{
                                                            this.removeItem(prod)
                                                            }
                                                            }>
                                                        <IonIcon icon={trash}
                                                        size="small"/>
                                                    </IonFabButton>
                                                </IonCol>
                                            </IonRow>
                                        ): <IonRow>
                                            <IonCol size="12">
                                                No se han agregado artículos
                                            </IonCol>
                                        </IonRow>
                                        }
                                    </IonGrid>
                                    </IonItem>
                                </IonCol>
                             </IonRow>
                            <IonRow>
                                <IonCol size="9"></IonCol>
                                <IonCol size="3">
                                    <IonButton onClick={
                                        ()=>this.setState({showAddMed:true})}
                                        color="secondary">
                                        Agregar Artículo
                                    </IonButton>
                                </IonCol>
                            </IonRow>                            
                        </IonGrid>
                        </form>
                        <IonButton onClick={this.submitSalida}>Guardar</IonButton>
                        <IonButton onClick={()=>this.cancelNewSalida()} 
                            color="medium">
                            Cancelar</IonButton>
                    </IonCardContent>                    
                </IonCard>
                <IonModal isOpen={this.state.showAddMed} backdropDismiss={false}>
                    <IonCard>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow>
                                <IonCol size='4'>Producto:</IonCol>
                                <IonCol size="8">
                                   {/*  <IonSelect value={this.state.productoId} 
                                        onIonChange={this.medicamentoChange}>
                                        {this.productos.map((med:any)=>
                                            <IonSelectOption value={med.id}>
                                                {med.nombre}
                                            </IonSelectOption>
                                        )}
                                    </IonSelect> */}
                                    <IonInput type="search" 
                                    placeholder="Ingrese el producto"
                                    value=
                                        {isNullOrUndefined(
                                            window.localStorage["productoSelected"])?"Seleccione un producto" : 
                                            JSON.parse(window.localStorage["productoSelected"]).nombre}
                                    onIonFocus={()=>this.setState(
                                        {showPickProducto:true})}                                     
                                />
                                </IonCol>
                                </IonRow>
                                <IonRow hidden={!this.isCurProdGranel()}>
                                    <IonCol>
                                        Venta parcial
                                    </IonCol>
                                    <IonCol>
                                        <IonCheckbox checked={this.state.granel}
                                         onIonChange={this.granelChanged}/>                                        
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='4'>Precio Venta:</IonCol>
                                <IonCol size='8'>
                                    <IonInput 
                                        value={this.state.precioVenta==0? null:
                                            this.state.precioVenta.toString()}
                                        onIonChange={this.precioVentaChange}
                                        onBeforeInput={this.validateKeyCurrency}/>
                                </IonCol>
                                </IonRow>                                
                                <IonRow>
                                <IonCol size='4'>Cantidad:</IonCol>
                                <IonCol size={this.state.cantidadSizeCol}>
                                    <IonInput type="number"
                                        value={this.state.cantidad.toString()}
                                        onIonChange={this.cantidadChange}
                                        // onBeforeInput={this.validateKey}
                                        />
                                </IonCol>
                                <IonCol size='4'>
                                <IonLabel hidden={
                                        !this.state.granel}>
                                            {this.getUnidadGranel()}
                                    </IonLabel>
                                </IonCol>
                                </IonRow>
                                {/* <IonRow>
                                <IonCol>Número de Lote:</IonCol>
                                <IonCol>
                                    <IonInput value={this.state.numeroLote} type="text" 
                                        onIonChange={this.numeroLoteChange}/>
                                </IonCol>
                                </IonRow>
                                <IonRow><IonCol>Precio Compra:</IonCol>
                                <IonCol>
                                    <IonInput value={this.state.precioCompra.toString()} 
                                        type="number"  onIonChange={this.precioCompraChange}/>
                                </IonCol>
                                </IonRow> */}                                
                                {/* <IonRow>
                                    <IonCol>Precio Mayoreo:</IonCol>
                                <IonCol>
                                    <IonInput value={this.state.precioMayoreo.toString()}
                                        type="number" onIonChange={this.precioMayoreoChange}/>
                                </IonCol>
                                </IonRow> */}
                                <IonRow>
                                    <IonCol>
                                        <IonButton 
                                            onClick={this.submitProducto}>
                                                Agregar</IonButton>
                                        <IonButton 
                                            onClick={()=>this.cancelAddProduct()} 
                                            color="medium">
                                            Cancelar</IonButton>
                                    </IonCol>
                                </IonRow>                                
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </IonModal>
                <IonModal isOpen={this.state.showPickProducto} backdropDismiss={false}>
                    <EntitySearch tipoEntidad="producto"/>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={()=>this.setState({
                                    showPickProducto:false})} 
                                    color="medium">
                                Cancelar
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={()=>this.setState({
                                    showPickProducto:false})}>
                                    Continuar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>                                        
                </IonModal>
            </IonContent>
        </>
        );
    }

}