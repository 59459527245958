import React, { useState } from "react";
import { IonItem, IonGrid, IonRow, IonCol, IonPopover, IonRouterLink, IonModal, IonButton } from "@ionic/react";
import ProductoESCard from "./ProductoESCard";
import './ESCard.css';

type Props={
    tipo: string,
    id: string,
    fecha: string,    
    proveedor?: string,
    responsable?: string,
    concepto?: string,
    comentarios?: string,
    productos: Array<any>,
    formaPago?: string,
    factura?: string
}

const ESCard: React.FunctionComponent<Props> = (props) =>{      

    const [showProductos, setShowProductos] = useState(false);    

    if(props.tipo == "entrada")
    return(                
        <IonGrid style={{width:"100%"}}>
            <IonRow >
                <IonCol class="itemCardTitle">
                    Fecha
                </IonCol>
                <IonCol class="itemCardValue">
                    {props.fecha}
                </IonCol>            
            </IonRow>
            <IonRow>
                <IonCol class="itemCardTitle">
                    Proveedor
                </IonCol>
                <IonCol class="itemCardValue">
                    {props.proveedor}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol class="itemCardTitle">
                    Responsable
                </IonCol>
                <IonCol class="itemCardValue">
                    {props.responsable}
                </IonCol>            
            </IonRow>
            <IonRow>
                <IonCol class="itemCardTitle">
                    Forma de Pago
                </IonCol>
                <IonCol class="itemCardValue">
                    {props.formaPago}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol class="itemCardTitle">
                    No. Factura
                </IonCol>
                <IonCol>
                    {props.factura}
                </IonCol>
            </IonRow>                
            <IonRow>
                    <IonGrid className="gridProductos">
                        <IonRow>
                            <IonCol className="gridHeaderCol" size="3">Medicamento</IonCol>
                            <IonCol className="gridHeaderCol" size="1">Cant</IonCol>
                            <IonCol className="gridHeaderCol" size="2">Lote</IonCol>
                            <IonCol className="gridHeaderCol" size="2">Compra</IonCol>
                            <IonCol className="gridHeaderCol" size="2">Venta</IonCol>
                            <IonCol className="gridHeaderCol" size="2">Mayoreo</IonCol>
                        </IonRow>                                
                    
                        {
                        
                           props.productos?.map((prod:any)=>
                            <ProductoESCard tipo="entrada"
                                producto={prod.nombre}
                                cantidad={prod.cantidad} 
                                numeroLote={prod.numeroLote}
                                precioCompra={prod.precioCompra}
                                precioVenta={prod.precioVenta}
                                precioMayoreo={prod.precioMayoreo}/>
                        )
                        }
                    </IonGrid>                    
            </IonRow>
        
        </IonGrid>
        
    );
    else
    return(            
        <IonGrid style={{width:"100%"}}>
        <IonRow >
            <IonCol class="itemCardTitle">
                Fecha
            </IonCol>
            <IonCol class="itemCardValue">
                {props.fecha}
            </IonCol>            
        </IonRow>
        <IonRow>
            <IonCol class="itemCardTitle">
                Responsable
            </IonCol>
            <IonCol class="itemCardValue">
                {props.responsable}
            </IonCol>            
        </IonRow>
        <IonRow>
            <IonCol class="itemCardTitle">
                Forma de Pago
            </IonCol>
            <IonCol class="itemCardValue">
                {props.formaPago}
            </IonCol>
        </IonRow>
        <IonRow>
                <IonGrid className="gridProductos">
                    <IonRow>
                        <IonCol className="gridHeaderCol" size="3">Medicamento</IonCol>
                        <IonCol className="gridHeaderCol" size="5">Cant</IonCol>
                        <IonCol className="gridHeaderCol" size="4">Venta</IonCol>                        
                    </IonRow>                                
                
                    {
                    
                       props.productos?.map((prod:any)=>
                        <ProductoESCard tipo="entrada"
                            producto={prod.producto.nombre}
                            cantidad={prod.cantidad} 
                            precioVenta={prod.precioVenta}
                            />
                    )
                    }
                </IonGrid>                    
        </IonRow>
    
    </IonGrid>
        
    );        
}
export default ESCard