import React from "react";
import { callGetProveedores } from '../firebaseConfig';
import { IonButton } from "@ionic/react";

export class testPage extends React.Component{
  
    getProveedores = () => {
       callGetProveedores().then((res)=>{
            console.log("Recibido de la funcion de la app: " + JSON.stringify(res));
       })
       //console.log("Recibido: " + getHelloWorld("Oso"));
    }
    
    render(){
        return(
            <>
            <IonButton 
             onClick={this.getProveedores}
            >Llamar</IonButton>
            </>
        )
    }
}