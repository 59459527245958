import React, { ChangeEvent } from "react";
import { isNullOrUndefined } from "util";
import { ImagePicker} from '@ionic-native/image-picker'
import { IonButton, IonInput, IonIcon, IonModal, IonGrid, IonRow, IonCol, IonList, IonFab, IonFabButton, IonContent, IonHeader, IonToolbar, IonTitle, IonMenuButton, IonRouterLink, IonItem, IonToast } from "@ionic/react";
import {images} from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import ImagenCard from "../components/ImagenCard";
import {add} from "ionicons/icons";
import "./ArchivoImagenes.css";
import {uploadImage, downloadImage} from "../firebaseConfig";
import { stringify } from "querystring";

type State = {
    inputElement?: HTMLInputElement,
    newImage: string,
    showNewForm: boolean,
    fecha: string,
    comentarios: string,
    link: string,
    showToast: boolean,
    toastMessage: string
}

export class ArchivoImagenesPage extends React.Component<RouteComponentProps, State>{

    usuario: any = {};
    fileInput: any;
    reader = new FileReader();
    imagenes: Array<any> = [];
    constructor(props: any){
        super(props);
        this.fileInput = React.createRef();
        this.openFileInput = this.openFileInput.bind(this);
        this.handleFileRead = this.handleFileRead.bind(this);
        this.submitImagen = this.submitImagen.bind(this);
        this.sanitizeImageString = this.sanitizeImageString.bind(this);
        this.getImageUrl = this.getImageUrl.bind(this);
        if(!isNullOrUndefined(window.localStorage['pacienteSelected']))
            this.usuario = JSON.parse(window.localStorage['pacienteSelected']);
        if(!isNullOrUndefined(window.localStorage['imagenesUsuario'+this.usuario.id]))
            this.imagenes = JSON.parse(window.localStorage["imagenesUsuario" + 
            this.usuario.id]);
        this.state={
            newImage:"",
            showNewForm: false,
            fecha: "",
            comentarios: "",
            link: "/pacientes/" + this.usuario.id,
            showToast: false,
            toastMessage: ""
        }
        //this.reader.onload = this.handleFileRead;
        this.cardClick = this.cardClick.bind(this);
    }         

    openFileInput(){
        //alert("click")
        this.fileInput.current.click();
    }

    openPicker(){
        ImagePicker.getPictures({title: "Elija la imagen"}).then(results=>{
            //alert(JSON.stringify(results));
        });    
    }
    
     handleChangeFile = (file: any) => {
        //this.reader = new FileReader();
        console.log("handleChangeFile");
        this.reader.onloadend = this.handleFileRead;
        this.reader.readAsDataURL(file);        
      } 

      handleFileRead(event:any) {
        /*
        let temp: string = JSON.stringify(event.target.result);
        let index = temp.indexOf(',');
        let res = temp.substring(index + 1);
        temp =  temp.replace('"','');
        */
        //console.log("Hay comillas en " +  temp.lastIndexOf('"'));              
       //window.localStorage.setItem("FILE_KEY", temp.substring(0, temp.length-1));       
       this.setState({newImage: event.target.result})       
       
   }

   sanitizeImageString(input: string){
    let temp: string = JSON.stringify(input); 
    temp =  temp.replace('"','');
    return temp.substring(0, temp.length-1);
   }

   getNewId(){
    /*
    var idReturn: number = -1;
    
    if(isNullOrUndefined(window.localStorage['imagenNewId']))
        idReturn = 1;
    else
        idReturn = Number.parseInt(window.localStorage['imagenNewId']);
    window.localStorage['imagenNewId'] = idReturn+1;
    return idReturn;
    */
    return Date.now();    
    }

    fechaChange = (event: CustomEvent) => {
        this.setState({fecha: event.detail.value});
    }

    comentariosChange = (event: CustomEvent) => {
        this.setState({comentarios: event.detail.value});
    }

    submitImagen(){
        try{
            if(this.state.newImage == ""){
                this.setState({showToast: true, toastMessage: "Por favor elija la imagen a guardar"});
                return;
            }
            if(this.state.fecha == ""){
                this.setState({showToast: true, toastMessage: "Por favor ingrese la fecha de la imagen"});
                return false;
            }        
            let imagenData = {
                id: this.getNewId(),
                url: {},
                fecha: this.state.fecha,
                comentarios: this.state.comentarios
            }
            
            //window.localStorage['imagen_'+imagenData.id] = this.state.newImage;
            let newImagePath =  "user_images/" + imagenData.id + ".jpg";
             uploadImage(this.state.newImage, newImagePath).then(
                snapshot=>{
                    //console.log("uploaded: " + JSON.stringify(snapshot.downloadURL));             
                    downloadImage(newImagePath).then(url=>{
                        imagenData.url = url;
                        this.imagenes.push(imagenData);
                    window.localStorage['imagenesUsuario' + this.usuario.id] = 
                        JSON.stringify(this.imagenes);        
                        this.setState({
                            newImage: "",
                            fecha: "",
                            comentarios: "",
                            showNewForm: false
                        })        
                    });                
                }
            ); 
        }catch(error){
            const typedError = error as Error;
            this.setState({showToast: true, toastMessage: typedError.message});
            console.log("error al guardar imagen: " + JSON.stringify(error))            
        }
               
    }    

    cardClick(e:any){
        //console.log(JSON.stringify(e.target.parent));
    }


    getClassName(id: number){
        return "selectedCard";
    }

    async getImageUrl(e:any){
        console.log("argumento: " + JSON.stringify(e));
        await downloadImage("user_images/" + e.id + ".jpg").then(
            url=>{return url}
        );
    }

    render(){
        return <>        
        <IonModal isOpen={this.state.showNewForm}>        
        <div className="upper">
        <IonToolbar>
        <IonTitle>Datos de la nueva imagen</IonTitle>
        </IonToolbar>
            <IonGrid>            
                <IonRow>
                    <IonCol className="colAlineada" size="6">
                        Fecha
                    </IonCol>
                    <IonCol size="6">
                        <IonInput type="date" onIonChange={this.fechaChange}
                            value={this.state.fecha}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="colAlineada" size="6">
                        Comentarios
                    </IonCol>
                    <IonCol size="6">
                        <IonInput  onIonChange={this.comentariosChange}
                            value={this.state.comentarios}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="3">&nbsp;</IonCol>
                <IonCol className="colAlineada" size="6">
                    <IonButton onClick={this.openFileInput} expand="block" color="secondary">
                    <input
                        accept="image/*"
                        hidden
                        ref={this.fileInput}            
                        type="file"                        
                        onChange={ 
                            e => this.handleChangeFile(e.target.files?.item(0))}
                    />
                    <IonIcon slot="start" icon={images}/>
                    Elija la imagen
                    </IonButton>
                </IonCol>
                <IonCol size="3">&nbsp;</IonCol>
            </IonRow>
            </IonGrid>
        </div>
        <div className="middle">
            
                <img src={this.sanitizeImageString(this.state.newImage)} />
            
        </div>        
            <IonGrid>
            
            <IonRow>
                <IonCol size="6">
                    <IonButton color="medium" expand="block"
                    onClick={()=>this.setState({showNewForm:false})}>Cancelar
                    </IonButton>
                </IonCol>
                <IonCol size="6">
                <IonButton expand="block"
                    onClick={this.submitImagen}>Guardar
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>                                            
        </IonModal>
        <IonHeader>
                <IonToolbar>
                <IonTitle>
                    Archivo de Imágenes
                </IonTitle>
                <IonMenuButton slot="start"></IonMenuButton>
                </IonToolbar>
                
            </IonHeader>
            <br></br>
            <IonToolbar>
                <IonGrid>
                    <IonRow>
                        <IonCol size="3">
                        <label>Paciente: </label>
                        </IonCol>
                        <IonCol size="9">
                            &nbsp;<IonRouterLink href={this.state.link}>
                            {this.usuario.nombreCompleto}
                            </IonRouterLink>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>           
        <IonContent>
        <IonList >
            {
            this.imagenes.length > 0 ? 
            this.imagenes.map((imagen)=>
            <IonItem             
            className="selectedCard">
             <ImagenCard imagenPaciente={imagen} clickFunction={this.getImageUrl}/>
             </IonItem>
            ): <IonItem>No hay imágenes para este paciente</IonItem>
            }
        </IonList>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        </IonContent>
        <IonFab horizontal="end" vertical="center">
            <IonFabButton onClick={()=>this.setState({
                showNewForm: true,
                newImage: "",
                fecha: "",
                comentarios: ""})}>
                <IonIcon icon={add}/>
            </IonFabButton>
        </IonFab>
        <IonToast isOpen={this.state.showToast} message={this.state.toastMessage}
         onDidDismiss={()=>this.setState({showToast: false})} duration={1000}/>
        </>
    }
} 