import { CatalogosMenu } from "./CatalogosMenu";
import React from "react";
import { IonHeader, IonToolbar, IonGrid, IonRow, IonCol, IonTitle, IonRouterLink, IonMenuButton } from "@ionic/react";

type Props = {
    title: string,
    linkCaption: string,
    clickFunc: any
}

const CatalogoHeader:React.FunctionComponent<Props> = (props)=>  {
    return (<IonHeader>
        <IonToolbar>
            <IonGrid>
                <IonRow>
                    <IonCol size="8">
                        <IonTitle>{props.title}</IonTitle>
                    </IonCol>
                    <IonCol size="4">
                        <IonRouterLink onClick={props.clickFunc }>{props.linkCaption}</IonRouterLink>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonMenuButton slot="start"></IonMenuButton>
        </IonToolbar>
    </IonHeader>);
}
export default CatalogoHeader;