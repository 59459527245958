import React from 'react';
import { Factura } from '../App';
import { IonRouterLink, IonModal, IonLoading } from '@ionic/react';
import DataTable from 'react-data-table-component';
import { Redirect, RouteComponentProps } from 'react-router';
import Pago from './Pago';
import { callGetFacturas } from '../firebaseConfig';
import { checkAccessToModule } from '../helper';

type State = {
    showModal: boolean,
    facturaPago: string,
    monto: number,
    facturas: any[],
    showLoading: boolean
}

export class FacturasPage extends React.Component<RouteComponentProps, State>{
    //facturas: Array<Factura> = [];    
    formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol'
      });
    
    openPago(numFactura: string, monto: number){        
        this.setState({
            showModal: true,
            facturaPago: numFactura,
            monto: monto
        })
        window.localStorage['conceptoPago'] = "Factura"
        window.localStorage['facturaPago'] = this.state.facturaPago
        //alert("factura a pagar: " +  this.state.facturaPago)
    }
    closeModal(){
        this.setState({
            showModal:false
        })
        //this.facturas = JSON.parse(window.localStorage['facturas'])
        this.getFacturasFromCloud()
    }

    getFacturasFromCloud(){
        callGetFacturas().then(res=>{
            this.setState({
                facturas: res as [],
                showLoading:false
            })
        })
    }

    totalSort = (rowA: any, rowB: any) => {
        const a = rowA.subtotal + rowA.impuestos * 1
        const b = rowB.subtotal + rowB.impuestos * 1
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };
      
    columns = [
        {
            name: 'Fecha Emisión',
            selector: (row: Factura) => row.fechaEmision,
            format: (row: Factura) => 
                new Date(row.fechaEmision).toLocaleDateString(),
            sortable:true
        },
        {
            name: 'No. Factura',
            selector: (row: any) => row.numFactura,
            sortable: true
        },
        {
            name: 'Total',
            selector: (row: any) => this.formatter.format(row.subtotal + row.impuestos * 1),            
            sortable: true,
            sortFunction: this.totalSort
        },
         {
            name: 'Provedor',
            selector: (row:any) => row.proveedor,
            sortable: true            
        }, 
        {
            name: 'Fecha Pago',
            selector: (row: any) => row.fechaPago ? row.fechaPago : 
                <IonRouterLink onClick={
                    ()=>this.openPago(row.numFactura, row.subtotal + row.impuestos * 1)}>Pagar</IonRouterLink>,
            sortable: true
        }
    ];
    
    constructor(props:any){
        super(props);
        this.state = {
            showModal: false,
            facturaPago: "",
            monto: 0,
            facturas: [],
            showLoading: true
        }
        
        this.getFacturasFromCloud()
        /*
        if(!isNullOrUndefined(window.localStorage['facturas']))
            this.facturas = JSON.parse(window.localStorage['facturas'])
        */
        this.closeModal = this.closeModal.bind(this);

    }
    render(){    
        
        if(!checkAccessToModule('Facturas'))
            return(<Redirect to="/pages/home"/>)
        return(
        <>
          <IonLoading 
            isOpen={this.state.showLoading}
            message="Cargando..."
            />
          <DataTable data={this.state.facturas} columns={this.columns}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[5,10]}
                title="Facturas"
                dense={true}
                striped
            />  

            <IonModal isOpen={this.state.showModal} backdropDismiss={false}>
                <Pago tipo={2} closeAction={this.closeModal} operacion={1}
                    numFactura={this.state.facturaPago} concepto={"Factura"} 
                    monto={this.state.monto} 
                    detalle={"Pago factura " + this.state.facturaPago}/>
            </IonModal>


        </>)
    }
}