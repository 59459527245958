import { Redirect, RouteComponentProps } from "react-router";
import React from "react";
import { isNullOrUndefined } from "util";
import { uploadImage, downloadImage, callModifyPaciente, callNewPaciente, 
    callGetPacientes, callGetEspecies, callGetRazas, callGetColores, 
    callDeletePaciente } from "../firebaseConfig";
import { IonHeader, IonToolbar, IonTitle, IonMenuButton, IonAlert, IonModal, 
    IonContent, IonGrid, IonRow, IonCol, IonInput, IonButton, IonIcon, 
    IonSelect, IonSelectOption, IonFab, IonFabButton, IonFabList, IonCard, 
    IonCardContent, IonLabel, IonToast, IonPage, IonLoading } 
    from "@ionic/react";
import { images, add, more, book, stats, removeCircle, create, cash } 
    from "ionicons/icons";
import EntitySearch from "../components/EntitySearch";
import './PacientesVetPage.css'
import { checkAccessToModule } from "../helper";
import { threadId } from "worker_threads";

interface PacientesVetProps extends RouteComponentProps<{
    pacienteID: string
}> {}

type State={    
    showNew: boolean,
    id: string,    
    nombre: string,
    especie: string,
    raza: string,
    sexo: string,
    peso: string,
    color: string,
    cicatrices: string,
    cirugias: string,
    condicion: string,    
    fechaNacimiento: string,  
    edadAcogida: string,
    fechaAcogida: string,
    procedencia: string,
    entorno: string,
    estiloVida: string,
    nutricion: string,    
    observaciones: string,
    showEdit: boolean,
    foto: string,
    showToast: boolean,
    toastMessage: string,
    showConfirm: boolean,
    /* propietario: string,
    telefono: string,
    correo: string,
    direccion: string,
    colonia: string,
    cp: string, */
    opcionesRazas?: Array<any>,
    pacientes: any[],
    especies: any[],
    razas: any[],
    colores: any[],
    /* nombrePropietario: string,
    idPropietario: string, */
    showLoading: boolean,
    showPickCli: boolean
}

export class PacientesVetPage extends React.Component<PacientesVetProps, State>{

    //pacientes: Array<any> = [];
    fileInput: any;
    reader = new FileReader();
    /* especies: Array<any> = [];
    razas: Array<any> = [];
    colores: Array<any> = []; */

    constructor(props: any){
        super(props);        

        this.fileInput = React.createRef();
        
        this.getPacientesFromCloud().then(res=>{
            let selected = !isNullOrUndefined(this.props.match.params.pacienteID);
        let paciente:any = {};
        if(selected){
            //console.log("PacientesVetPage.constructor selected!")
            for(let i = 0; i < this.state.pacientes.length; i++){
                //console.log("PacientesVetPage.constructor id encontrado!")
                if(this.state.pacientes[i].id == 
                    this.props.match.params.pacienteID){
                    //console.log("PacientesVetPage.constructor id encontrado!")
                     paciente = this.state.pacientes[i];                     
                     window.localStorage['pacienteSelected'] = JSON.stringify(paciente);                     
                     this.clickSearch();
                     this.state= {
                        showNew: false,
                        id : paciente.id, 
                        nombre: paciente.nombre, 
                        especie: paciente.especie,
                        raza: paciente.raza,
                        sexo: paciente.sexo,
                        peso: paciente.peso,
                        color: paciente.color,
                        cicatrices: paciente.cicatrices,
                        cirugias: paciente.cirugias,
                        condicion: paciente.condicion,    
                        fechaNacimiento: paciente.fechaNacimiento,  
                        edadAcogida: paciente.edadAcogida,
                        fechaAcogida: paciente.fechaAcogida,
                        procedencia: paciente.procedencia,
                        entorno: paciente.entorno,
                        estiloVida: paciente.estiloVida,
                        nutricion: paciente.nutricion,
                        observaciones: paciente.observaciones,
                        foto: paciente.urlFotoPerfil,
                        showEdit: false,
                        showToast: false,
                        toastMessage: "",
                        showConfirm: false,
                        /* propietario: "",
                        telefono: "",
                        correo: "",
                        direccion: "",
                        colonia: "",
                        cp: "", */
                        opcionesRazas: [],
                        pacientes: [],
                        especies: [],
                        razas: [],
                        colores: [],
                        /* nombrePropietario: "",
                        idPropietario: "", */
                        showLoading: false,
                        showPickCli: false
                        }
                }
            }
            /* paciente = 
                this.getPacienteByID(this.props.match.params.pacienteID); */

                
        }
        
        //console.log("paciente: " + JSON.stringify(paciente));
        /* if(selected)
        this.state= {
            showNew: false,
            id : paciente.id, 
            nombre: paciente.nombre, 
            especie: paciente.especie,
            raza: paciente.raza,
            sexo: paciente.sexo,
            peso: paciente.peso,
            color: paciente.color,
            cicatrices: paciente.cicatrices,
            cirugias: paciente.cirugias,
            condicion: paciente.condicion,    
            fechaNacimiento: paciente.fechaNacimiento,  
            edadAcogida: paciente.edadAcogida,
            fechaAcogida: paciente.fechaAcogida,
            procedencia: paciente.procedencia,
            entorno: paciente.entorno,
            estiloVida: paciente.estiloVida,
            nutricion: paciente.nutricion,
            observaciones: paciente.observaciones,
            foto: paciente.urlFotoPerfil,
            showEdit: false,
            showToast: false,
            toastMessage: "",
            showConfirm: false,
            propietario: "",
            telefono: "",
            correo: "",
            direccion: "",
            colonia: "",
            cp: "",
            opcionesRazas: [],
            pacientes: [],
            especies: [],
            razas: [],
            colores: [],
            showLoading: false
            } */
        });
        //if(!isNullOrUndefined(window.localStorage['pacientesVet']))
        //    this.pacientes = JSON.parse(window.localStorage['pacientesVet']);        
        
        
        this.getCatalogosFromCloud()
        /* if(!isNullOrUndefined(window.localStorage['especies']))
            this.especies = JSON.parse(window.localStorage['especies']);        
        if(!isNullOrUndefined(window.localStorage['razas']))
            this.razas = JSON.parse(window.localStorage['razas']);
        if(!isNullOrUndefined(window.localStorage['colores']))
            this.colores = JSON.parse(window.localStorage['colores']);         */
        
            //console.log("Pacientes Vet selected: " + selected);
        
            //alert("selected: " + this.props.match.params.pacienteID);
         this.state =  {
                showNew: false,
                id: "",
                nombre: "", 
                especie: "",
                raza: "",
                sexo: "",
                peso: "",
                color: "",
                cicatrices: "",
                cirugias: "",
                condicion: "",    
                fechaNacimiento: "",  
                edadAcogida: "",
                fechaAcogida: "",
                procedencia: "",
                entorno: "",
                estiloVida: "",
                nutricion: "",            
                observaciones: "",
                foto: "",
                showEdit: false,
                showToast: false,
                toastMessage: "",
                showConfirm: false,
                /* propietario: "",
                telefono: "",
                correo: "",
                direccion: "",
                colonia: "",
                cp: "", */
                opcionesRazas: [],
                pacientes: [],
                especies: [],
                razas: [],
                colores: [],
                /* nombrePropietario: "",
                idPropietario: "", */
                showLoading: false,
                showPickCli: false
                };

        //console.log("state: " + JSON.stringify(this.state));    
        this.showNewModal = this.showNewModal.bind(this);    
        this.clickSearch = this.clickSearch.bind(this);        
    }

    componentDidMount(){
        //console.log("componentDidMount")        
        this.submitPaciente = this.submitPaciente.bind(this);        
        this.showEditModal = this.showEditModal.bind(this);
        this.openFileInput = this.openFileInput.bind(this);
        this.handleFileRead = this.handleFileRead.bind(this);
        this.deletePaciente = this.deletePaciente.bind(this);
        this.updateRazasSelect = this.updateRazasSelect.bind(this);
        this.openBitacora = this.openBitacora.bind(this);
    }

    async getPacientesFromCloud(){
        return callGetPacientes().then(res=>{
            this.setState({pacientes: res as []})
        })
    }

    getCatalogosFromCloud(){
        callGetEspecies().then(especies=>{
            callGetRazas().then(razas=>{
                callGetColores().then(colores=>{
                    this.setState({
                        especies: especies as [],
                        razas: razas as [],
                        colores: colores as []
                    })
                })
            })
        })
    }

  /*   getPacienteByID(id: string){
        for(let i = 0; i < this.state.pacientes.length; i++){
            if(this.state.pacientes[i].id == id)
                return this.state.pacientes[i];
        }
        return {};
    } */

    clickSearch(){
        console.log("PacientesVetPage.clickSearch pacienteSelected: " + 
            window.localStorage['pacienteSelected']);
        let paciente = JSON.parse(window.localStorage['pacienteSelected']);
        if(paciente.idPropietario){
            const cliObj = {
                id: paciente.idPropietario,
                nombre: paciente.nombrePropietario
            }
            window.localStorage['clienteSelected'] = 
                JSON.stringify(cliObj)
         }
        this.setState({
            id: paciente.id, 
            nombre: paciente.nombre, 
            especie: paciente.especie,
            raza: paciente.raza,
            sexo: paciente.sexo,
            peso: paciente.peso,
            color: paciente.color,
            cicatrices: paciente.cicatrices,
            cirugias: paciente.cirugias,
            condicion: paciente.condicion,    
            fechaNacimiento: paciente.fechaNacimiento,  
            edadAcogida: paciente.edadAcogida,
            fechaAcogida: paciente.fechaAcogida,
            procedencia: paciente.procedencia,
            entorno: paciente.entorno,
            estiloVida: paciente.estiloVida,
            nutricion: paciente.nutricion,
            observaciones: paciente.observaciones,
            foto: paciente.urlFotoPerfil,
            /* propietario: paciente.propietario,
            telefono: paciente.telefono,
            correo: paciente.correo,
            direccion: paciente.direccion,
            colonia: paciente.colonia,
            cp: paciente.cp */
            /* nombrePropietario: paciente.nombrePropietario? 
                paciente.nombrePropietario : "",
            idPropietario: paciente.idPropietario ? paciente.idPropietario : "" */
        });
    }

    openFileInput(){
        //alert("click")
        this.fileInput.current.click();
    }
    
    handleChangeFile = (file: any) => {
        //this.reader = new FileReader();
        //console.log("handleChangeFile");
        this.reader.onloadend = this.handleFileRead;
        this.reader.readAsDataURL(file);        
      }
    
      handleFileRead(event:any) {
        let temp: string = JSON.stringify(event.target.result);
        let index = temp.indexOf(',');
        let res = temp.substring(index + 1);
        temp =  temp.replace('"','');
        //console.log("Hay comillas en " +  temp.lastIndexOf('"'));              
       //window.localStorage.setItem("FILE_KEY", temp.substring(0, temp.length-1));       
       this.setState({foto: temp.substring(0, temp.length-1)})       
       
   }

   resetState(){                
       this.setState(
        {
        showNew: false,
        id: "",
        nombre: "", 
        especie: "",
        raza: "",
        sexo: "",
        peso: "",
        color: "",
        cicatrices: "",
        cirugias: "",
        condicion: "",    
        fechaNacimiento: "",  
        edadAcogida: "",
        fechaAcogida: "",
        procedencia: "",
        entorno: "",
        estiloVida: "",
        nutricion: "",            
        observaciones: "",
        foto: "",
        /* nombrePropietario: "",
        idPropietario: "", */
        showEdit: false,
        showToast: false,
        toastMessage: "",
        showConfirm: false,
        /* propietario: "",
        telefono: "",
        correo: "",
        direccion: "",
        colonia: "",
        cp: "", */
        opcionesRazas: []      
        });
        window.localStorage.removeItem("clienteSelected")
   }

    nombreChange = (event: CustomEvent) => {        
        this.setState({ nombre: event.detail.value });
        }
    especieChange = (event: CustomEvent) => {      
        //console.log("evento:" + JSON.stringify(event));  
        this.setState({ especie: event.detail.value });
        this.updateRazasSelect();
        }
    razaChange = (event: CustomEvent) => {        
        this.setState({ raza: event.detail.value });
        }
    sexoChange = (event: CustomEvent) => {        
        this.setState({ sexo: event.detail.value });
        }
    pesoChange = (event: CustomEvent) => {        
        this.setState({ peso: event.detail.value });
        }
    colorChange = (event: CustomEvent) => {        
        this.setState({ color: event.detail.value });
        }
    cicatricesChange = (event: CustomEvent) => {        
        this.setState({ cicatrices: event.detail.value });
        }
    cirugiasChange = (event: CustomEvent) => {        
        this.setState({ cirugias: event.detail.value });
        }
    condicionChange = (event: CustomEvent) => {        
        this.setState({ condicion: event.detail.value });
        }
    fechaNacimientoChange = (event: CustomEvent) => {        
        this.setState({ fechaNacimiento: event.detail.value });
        }
    edadAcogidaChange = (event: CustomEvent) => {        
        this.setState({ edadAcogida: event.detail.value });
        }
    fechaAcogidaChange = (event: CustomEvent) => {        
        this.setState({ fechaAcogida: event.detail.value });
        }
    procedenciaChange = (event: CustomEvent) => {        
        this.setState({ procedencia: event.detail.value });
        }
    entornoChange = (event: CustomEvent) => {        
        this.setState({ entorno: event.detail.value });
        }
    estiloVidaChange = (event: CustomEvent) => {        
        this.setState({ estiloVida: event.detail.value });
        }
    nutricionChange = (event: CustomEvent) => {        
        this.setState({ nutricion: event.detail.value });
        }
    observacionesChange = (event: CustomEvent) => {        
        this.setState({ observaciones: event.detail.value });
        }
    /* propietarioChange = (event: CustomEvent) => {        
        this.setState({ propietario: event.detail.value });
        } 
    telefonoChange = (event: CustomEvent) => {        
        this.setState({ telefono: event.detail.value });
        }
    correoChange = (event: CustomEvent) => {        
        this.setState({ correo: event.detail.value });
        }
    direccionChange = (event: CustomEvent) => {        
        this.setState({ direccion: event.detail.value });
        }
    coloniaChange = (event: CustomEvent) => {        
        this.setState({ colonia: event.detail.value });
        }
    cpChange = (event: CustomEvent) => {        
        this.setState({ cp: event.detail.value });
        } */

    /***** */


    submitPaciente(){
        try{
            if(this.state.nombre == ""){
                this.setState({showToast: true, 
                toastMessage: "Por favor, ingrese el nombre"})
                return;
            }
            if(this.state.fechaNacimiento == ""){
                this.setState({showToast: true, 
                toastMessage: "Por favor, ingrese la fecha de nacimiento"})
                return;
            }
            //TODO: Validar que se haya seleccionado cliente
            if(window.localStorage.getItem('clienteSelected') == null){
                this.setState({showToast: true, 
                    toastMessage: "Por favor, indique un propietario"})
                return;
            }
            this.setState({
                showLoading: true
            })            
            const propObj = JSON.parse(window.localStorage['clienteSelected'])
            /*
            if(this.state.foto == ""){
                this.setState({showToast: true, 
                toastMessage: "Por favor, elija una foto de perfil"})
                return;
            }
            */
            let newPaciente:any = {
                id: this.state.showEdit ? this.state.id : "undefined", 
                nombre: this.state.nombre, 
                especie: this.state.especie,
                raza: this.state.raza,
                sexo: this.state.sexo,
                peso: this.state.peso,
                color: this.state.color,
                cicatrices: this.state.cicatrices,
                cirugias: this.state.cirugias,
                condicion: this.state.condicion,    
                fechaNacimiento: this.state.fechaNacimiento,  
                edadAcogida: this.state.edadAcogida,
                fechaAcogida: this.state.fechaAcogida,
                procedencia: this.state.procedencia,
                entorno: this.state.entorno,
                estiloVida: this.state.estiloVida,
                nutricion: this.state.nutricion,
                observaciones: this.state.observaciones,
                urlFotoPerfil:{},
                nombrePropietario: propObj.nombre,
                idPropietario: propObj.id
                /* propietario: this.state.propietario,
                telefono: this.state.telefono,
                correo: this.state.correo,
                direccion: this.state.direccion,
                colonia: this.state.colonia,
                cp: this.state.cp */
            }
            let imagenPerfilPath =  "vet_profile_images/" + newPaciente.id + ".jpg";
            //console.log(this.state.foto);
            if(this.state.foto.startsWith("data"))
            uploadImage(this.state.foto, imagenPerfilPath)
                .then(()=>{
                    downloadImage(imagenPerfilPath).then(url=>{
                        newPaciente.urlFotoPerfil = url;
                        if(this.state.showEdit){
                            /* for(var i=0; i<this.pacientes.length; i++){
                                if(this.pacientes[i].id == newPaciente.id){
                                    this.pacientes.splice(i, 1, newPaciente);
                                }
                            } */
                            callModifyPaciente(newPaciente).then(res=>{  
                                window.localStorage['pacienteSelected'] = 
                                    JSON.stringify(newPaciente);
                                window.location.replace(
                                    "/pacientesVet/"+newPaciente.id);
                            }).catch(errMod=>{
                                console.log("Error en callModifyPaciente: " + 
                                    JSON.stringify(errMod))
                                this.setState({
                                    showToast: true,
                                    toastMessage: 
                                        "Ocurrió un error al guardar, intente" +
                                        " más tarde",
                                    showLoading: false
                                })
                            }); 
                        }
                        else
                            callNewPaciente(newPaciente).then(res=>{
                                newPaciente.id = res as string
                                window.localStorage['pacienteSelected'] = 
                                    JSON.stringify(newPaciente);                    
                                window.location.replace("/pacientesVet/"+newPaciente.id);
                                    }).catch(errNew=>{
                                        console.log("Error en callNewPaciente: "
                                            + JSON.stringify(errNew))
                                        this.setState({
                                            showToast: true,
                                            toastMessage: 
                                                "Ocurrió un error al guardar," +
                                                " intente más tarde",
                                            showLoading: false
                                        })
                                    }); 
                            /* this.pacientes.push(newPaciente);
                        window.localStorage['pacientesVet'] = 
                            JSON.stringify(this.pacientes);
                        window.localStorage['pacienteSelected'] = 
                            JSON.stringify(newPaciente);                        
                       this.resetState();
                       this.clickSearch(); */
                    }).catch(errDld=>{
                        console.log("Error en downloadImage: " + JSON.stringify(
                            errDld))
                        this.setState({
                            showToast: true,
                            toastMessage: 
                                "Ocurrió un error al subir la foto, intente " +
                                "más tarde",
                            showLoading: false
                        })
                    });                
            }).catch(errUpld=>{
                console.log("Error en UploadImage: " +  JSON.stringify(errUpld))
                this.setState({
                    showToast: true,
                    toastMessage: 
                        "Ocurrió un error al subir la foto, intente más tarde",
                    showLoading: false
                })
            });
            else{
                newPaciente.urlFotoPerfil = this.state.foto
                if(this.state.showEdit){
                    //console.log("Es modo edición")
                    /* for(var i=0; i<this.pacientes.length; i++){
                        if(this.pacientes[i].id == newPaciente.id){
                            //console.log("se encontró el registro existente: " +  
                            //    JSON.stringify(this.pacientes[i]));
                            this.pacientes.splice(i, 1, newPaciente);
                            //console.log("array después de splice: " +
                            //    JSON.stringify(this.pacientes))
                        }
                    } */
                    callModifyPaciente(newPaciente).then(res=>{    
                        window.localStorage['pacienteSelected'] = 
                            JSON.stringify(newPaciente);                    
                        window.location.replace("/pacientesVet/" + 
                            newPaciente.id);
                    }).catch(errMod=>{
                        console.log("Error en callModifyPaciente: " + 
                            JSON.stringify(errMod))
                        this.setState({
                            showToast: true,
                            toastMessage: 
                                "Ocurrió un error al guardar, intente más tarde",
                            showLoading: false
                        })
                    }); 
                }
                else
                    callNewPaciente(newPaciente).then(res=>{                                                
                        newPaciente.id = res as string
                        window.localStorage['pacienteSelected'] = 
                            JSON.stringify(newPaciente);                    
                        window.location.replace("/pacientesVet/"+newPaciente.id);
                        /*
                        this.getPacientesFromCloud()                        
                        this.resetState();
                        this.clickSearch();
                        */
                    }).catch(errNew=>{
                        console.log("Error en callNewPaciente: " + 
                            JSON.stringify(errNew))
                        this.setState({
                            showToast: true,
                            toastMessage: 
                                "Ocurrió un error al guardar, intente más tarde",
                            showLoading: false
                            })
                        }); 
                    /* this.pacientes.push(newPaciente);
                this.resetState();
                window.localStorage['pacientesVet'] = 
                    JSON.stringify(this.pacientes);
                window.localStorage['pacienteSelected'] = 
                    JSON.stringify(newPaciente);                                       
               this.clickSearch(); */
            }
            
        }catch(error: any ){
            console.log("error al guardar el paciente: " + JSON.stringify(error));
            alert("Ocurrió un error al guardar los datos del paciente: " +
                error.message);
            this.setState({
                showLoading: false
            })
        }     
        

        //alert("state reseteado: " + JSON.stringify(this.state));
    }

    
    /******* */


    getNewId(){
         /* var idReturn: number = -1;
         if(isNullOrUndefined(window.localStorage['pacienteVetNewId']))
             idReturn = 1;
         else
             idReturn = Number.parseInt(window.localStorage['pacienteVetNewId']);
         window.localStorage['pacienteVetNewId'] = idReturn+1;
         return idReturn; */
         return Date.now();
     }


     showNewModal(){
        this.resetState();                    
        this.setState({showNew: true})        
    }

    showEditModal(){
        this.updateRazasSelect().then(()=>{
            this.setState({showEdit: true})
        })              
    }

    deletePaciente(){
        callDeletePaciente(this.state.id).then(res=>{
            this.resetState()
            window.location.replace("/pacientesVet");
        })
        /*
        for(var i=0; i<this.state.pacientes.length; i++){
            if(this.state.pacientes[i].id == this.state.id){ 
                //console.log("paciente a eliminar:" + JSON.stringify(this.pacientes[i]))               
                //console.log("pacientes antes: " + JSON.stringify(this.pacientes))
                this.state.pacientes.splice(i, 1);
                //console.log("pacientes después: " + JSON.stringify(this.pacientes))
                window.localStorage["pacientesVet"] = 
                    JSON.stringify(this.state.pacientes);
                this.resetState();
            }
        }
        */
    }

    async updateRazasSelect(){
        const especieId = this.getEspecieId(this.state.especie)
        let colReturn = this.state.razas.filter(
            entity=>entity.padre.id == especieId);
        console.log("especie:" + this.state.especie)
        console.log("razas: " + JSON.stringify(colReturn));
        return this.setState({
            opcionesRazas: colReturn
        })
    }

    getEspecieId(nombre: string){
        const temp = 
            this.state.especies.filter(especie=>especie.nombre == nombre)
        if(temp.length > 0)
            return temp[0].id
        else
        return -1
    }

    openBitacora(){        
        //let pac = JSON.parse(window.localStorage["pacienteSelect"])
        //this.props.history.push("www.google.com")
        this.props.history.push("/bitacora/"+this.state.id)
    }

    render(){ 
        
        if(!checkAccessToModule('Pacientes'))
        return(
            <Redirect to="/pages/home"/>
        )

        return(
            <IonPage>
            <IonHeader>
                <IonToolbar>
                <IonTitle>
                    Pacientes
                </IonTitle>
                <IonMenuButton slot="start"></IonMenuButton>
                </IonToolbar>
                
            </IonHeader>
            <IonAlert isOpen={this.state.showConfirm}
                onDidDismiss={()=>this.setState({showConfirm: false})}                    
                header="Advertencia"
                message="¿Realmente desea eliminar el registro?"
                buttons={[
                    {
                        text: "Cancelar",
                        role: "cancel",
                        cssClass: "cancelButton",
                        handler: () =>{
                            this.setState({showConfirm:false});
                        }
                    },
                    {
                        text: "Continuar",
                        cssClass: "dangerButton",
                        handler: () =>this.deletePaciente()                                    
                    }
                ]}
            />
            <IonModal isOpen={this.state.showNew || this.state.showEdit} backdropDismiss={false}>
            <IonContent>
            <IonLoading 
                isOpen={this.state.showLoading}
                message="Guardando..."
            />
            <form>
                <IonToolbar>
                    <IonTitle>Datos del paciente</IonTitle>
                </IonToolbar>                
                <IonGrid>
                    <IonRow>
                        <IonCol size="3.5">
                            Nombre
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.nombreChange}
                                value={this.state.nombre}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5">
                           Fecha de Nacimiento
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.fechaNacimientoChange}
                                value={this.state.fechaNacimiento} type="date"/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5">
                            Propietario
                        </IonCol>
                        <IonCol>
                            <IonInput type="search" 
                                value={
                                    !isNullOrUndefined(
                                        window.localStorage['clienteSelected'])?
                                        JSON.parse(
                                            window.localStorage['clienteSelected'])
                                            .nombre : "Selecciona uno"} 
                                onIonFocus={()=>this.setState({showPickCli:true})}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="colAlineada">
                            Foto de perfil
                        </IonCol>
                        <IonCol>
                            <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <img src={this.state.foto != "" ? this.state.foto
                                        : "/assets/logo_perfil.png"
                                    } />
                                </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton onClick={this.openFileInput} 
                                            expand="block" color="secondary">
                                            <input
                                                accept="image/*"
                                                hidden
                                                ref={this.fileInput}            
                                                type="file"                        
                                                onChange={ 
                                                    e => this.handleChangeFile(
                                                        e.target.files?.item(0))}
                                            />
                                            <IonIcon slot="start" icon={images}/>
                                            Elegir
                                            </IonButton>
                                    </IonCol>
                                </IonRow>                                
                            </IonGrid>                                                    
                        </IonCol>    
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Especie
                        </IonCol>
                        <IonCol size="8.5">
                            <IonSelect onIonChange={this.especieChange} 
                                value={this.state.especie}>
                            {this.state.especies.map((esp)=>
                                <IonSelectOption value={esp.nombre}
                                    key="esp.nombre">
                                    {esp.nombre}</IonSelectOption>)}
                            </IonSelect>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Raza
                        </IonCol>
                        <IonCol size="8.5">
                            <IonSelect onIonChange={this.razaChange} value={this.state.raza}>
                            {this.state.opcionesRazas?.map((raza)=>
                                <IonSelectOption value={raza.nombre}>{raza.nombre}</IonSelectOption>)}
                            </IonSelect>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Sexo
                        </IonCol>
                        <IonCol size="8.5">
                            <IonSelect onIonChange={this.sexoChange} value={this.state.sexo}>
                                <IonSelectOption value="hembra">
                                    Hembra
                                </IonSelectOption>
                                <IonSelectOption value="macho">
                                    Macho
                                </IonSelectOption>
                            </IonSelect>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Peso
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.pesoChange}
                                value={this.state.peso}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Color
                        </IonCol>
                        <IonCol size="8.5">
                        <IonSelect onIonChange={this.colorChange} value={this.state.color}>
                            {this.state.colores.map((color)=>
                                <IonSelectOption value={color.nombre}
                                    key={color.nombre}>{color.nombre}</IonSelectOption>)}
                            </IonSelect>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Cicatrices
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.cicatricesChange}
                                value={this.state.cicatrices}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Cirugías
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.cirugiasChange}
                             value={this.state.cirugias}/>                        
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Condición
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.condicionChange}
                                value={this.state.condicion}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Observaciones
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.observacionesChange}
                                value={this.state.observaciones}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Edad al acogerlo
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.edadAcogidaChange}
                                value={this.state.edadAcogida}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5">
                           Fecha de acogida
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.fechaAcogidaChange}
                                value={this.state.fechaAcogida} type="date"/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Procedencia
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.procedenciaChange}
                                value={this.state.procedencia}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Entorno
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.entornoChange}
                                value={this.state.entorno}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Estilo de Vida
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.estiloVidaChange}
                                value={this.state.estiloVida}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Nutrición
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.nutricionChange}
                                value={this.state.nutricion}/>
                        </IonCol>
                    </IonRow>
                    {/* <IonRow>
                        <IonCol size="12" className="colAlineada">
                            Datos del Propietario
                        </IonCol>                    
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Nombre
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.propietarioChange}
                                value={this.state.propietario}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Teléfono
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.telefonoChange}
                                value={this.state.telefono}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Correo
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.correoChange}
                                value={this.state.correo}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Dirección
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.direccionChange}
                                value={this.state.direccion}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            Colonia
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.coloniaChange}
                                value={this.state.colonia}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="3.5" className="colAlineada">
                            C.P.
                        </IonCol>
                        <IonCol size="8.5">
                            <IonInput onIonChange={this.cpChange}
                                value={this.state.cp}/>
                        </IonCol>
                    </IonRow> */}
                    <IonRow>
                        <IonCol>
                        <IonButton onClick={()=>{
                            if(this.state.showEdit)
                                this.clickSearch();
                            this.setState({showNew: false,
                                showEdit: false})
                        }} 
                        color="medium">Cancelar</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton onClick={this.submitPaciente}>Guardar</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>                                
                </form> 
                </IonContent>
             </IonModal>
             <div className="upper">
                <EntitySearch tipoEntidad="pacienteVet" callback={this.clickSearch} />
             
             </div>
             <IonFab vertical="top" horizontal="end">
                <IonFabButton onClick={this.showNewModal} color="success" >
                    <IonIcon icon={add}/>
                </IonFabButton> 
             </IonFab>
             <div className="lower">   
             <br></br>             
                    <IonHeader hidden={this.state.nombre==""}>
                    <IonToolbar>
                    <IonTitle>Datos del Paciente</IonTitle>
                    </IonToolbar>
                    </IonHeader>
                
                 <IonContent> 
                 <IonFab vertical="top" horizontal="end" slot="fixed" 
                hidden={this.state.nombre==""}>
                <IonFabButton>
                    <IonIcon icon={more}></IonIcon>
                </IonFabButton>
                <IonFabList>
                    {/* <IonFabButton color="secondary" href="/imagenes">
                        <IonIcon icon={images}></IonIcon>
                    </IonFabButton>
                    <IonFabButton color="tertiary">
                        <IonIcon icon={book}></IonIcon>
                    </IonFabButton>
                    <IonFabButton color="light">
                        <IonIcon icon={stats}></IonIcon>
                    </IonFabButton> */}
                    <IonFabButton color="danger"
                        onClick={()=>this.setState({showConfirm: true})}>
                        <IonIcon icon={removeCircle}></IonIcon>
                    </IonFabButton>
                    <IonFabButton onClick={this.showEditModal} color="warning">
                        <IonIcon icon={create}/>
                    </IonFabButton>
                    <IonFabButton color="secondary" onClick={this.openBitacora} 
                        title={"Bitácora de " + this.state.nombre}>
                        <IonIcon icon={book}/>
                    </IonFabButton>    
                    <IonFabButton color="tertiary" onClick={()=>{
                        const objCliente =
                            JSON.parse(window.localStorage["clienteSelected"])
                        const objPaciente =
                            JSON.parse(window.localStorage["pacienteSelected"])
                        this.props.history.push("/servicio/cliente/" + 
                            objCliente.id + "/paciente/" + objPaciente.id
                        )
                        //return <Redirect to="/servicio"/>

                    }} title="Registrar servicio">
                        <IonIcon icon={cash} />
                    </IonFabButton>
                    </IonFabList>                                                                  
            </IonFab>                    
            <IonCard hidden={this.state.nombre==""}>                
                <IonCardContent>
                    <IonGrid>                                                
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Nombre</label> 
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.nombre}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Propietario</label> 
                            </IonCol>
                            <IonCol>
                            <IonLabel>{window.localStorage.getItem('clienteSelected') != null ?
                                JSON.parse(window.localStorage['clienteSelected']).nombre : ""}
                            </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Especie</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.especie}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Raza</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.raza}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Sexo</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.sexo}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Peso</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.peso}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Color</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.color}</IonLabel>
                            </IonCol>    
                        </IonRow>                                
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Cicatrices</label> 
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.cicatrices}</IonLabel>
                            </IonCol>
                        </IonRow>                                                   
                        <IonRow>                            
                            <IonCol>
                            <label className="keyLabel">Cirugías</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.cirugias}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Condición Corporal</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.condicion}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Fecha de nacimiento</label> 
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.fechaNacimiento}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Edad al acogerlo</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.edadAcogida}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Fecha de acogida</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.fechaAcogida}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Procedencia</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.procedencia}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Entorno</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.entorno}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Estilo de vida</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.estiloVida}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Nutrición</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.nutricion}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Observaciones</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.observaciones}</IonLabel>
                            </IonCol>
                        </IonRow>
                       {/*  <IonRow>
                            <IonCol>
                            <label className="keyLabel">Propietario</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.propietario}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Teléfono</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.telefono}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Correo</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.correo}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Dirección</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.direccion}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">Colonia</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.colonia}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">C.P.</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>{this.state.cp}</IonLabel>
                            </IonCol>
                        </IonRow> */}
                        <IonRow>
                            <IonCol>
                            <label className="keyLabel">&nbsp;</label>
                            </IonCol>
                            <IonCol>
                            <IonLabel>&nbsp;</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                {/* <div className="imageDiv"> */}
                                <div>
                                    <img  src={this.state.foto != "" ? this.state.foto
                                        : "/assets/logo_perfil.png"
                                    } className="profileImage" />
                                </div>                
                            </IonCol>
                        </IonRow>
                    </IonGrid>                                                                                                                                    
                </IonCardContent>
            </IonCard>            
            </IonContent>
            </div>
            <IonModal isOpen={this.state.showPickCli} backdropDismiss={false}>
                    <EntitySearch tipoEntidad="cliente"/>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={
                                    ()=>this.setState({showPickCli:false})} 
                                    color="medium">
                                Cancelar
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={()=>{
                                    this.setState({showPickCli:false});                                                                        
                                    //setPaciente(JSON.parse(
                                    //        window.localStorage["pacienteSelected"]))
                                    }}>
                                    Continuar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>                                        
                </IonModal>
            <IonToast isOpen={this.state.showToast} 
                message={this.state.toastMessage}
                onDidDismiss={()=>this.setState({showToast: false})} 
                duration={1000}/>
            </IonPage>
            );
        }
}