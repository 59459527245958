import {
  IonCard,IonCardContent,IonCardHeader,IonCardSubtitle,IonCardTitle
  ,IonContent,IonHeader,
  IonIcon,IonItem,IonLabel,IonList,IonListHeader,
  IonPage,IonTitle,IonToolbar,
  IonButton,
  IonRouterLink, getPlatforms, isPlatform, useIonViewDidEnter, IonGrid, IonRow, IonCol, IonModal
} from '@ionic/react';
import { book, build, colorFill, grid } from 'ionicons/icons';
import React, { useState } from 'react';
import './Tab1.css';
import { RouteComponentProps, Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import ProveedorCard from '../components/ProveedorCard';
import { isNullOrUndefined } from 'util';
import ProveedorComponent from '../components/ProveedorComponent';
import { CatalogosMenu } from '../components/CatalogosMenu';
import { checkAccessToModule } from '../helper';

interface Tab1Props extends RouteComponentProps<{}> {}





const Tab1: React.FunctionComponent<Tab1Props> = ({history}) => {  
  var Proveedores = [];  
  if(!isNullOrUndefined(window.localStorage['proveedores']))
    Proveedores = JSON.parse(window.localStorage['proveedores']);
  //console.log("proveedores:" + JSON.stringify(Proveedores));
  /*Proveedores.forEach(element => {
    console.log(JSON.stringify(element));
  });*/

  var username: string = "";
  if(!isNullOrUndefined(window.localStorage['username']))
    username = (window.localStorage['username']).toString().toUpperCase();
  //console.log("tab1.username = " + username);
  //console.log("Inicio de Tab 1");
      
  //if(username=="")
  //  return <Redirect to="/pages/home"/>          
  
  if(!checkAccessToModule("Catálogos"))
    return <Redirect to="/pages/home"/>          
  else{        
      return(
        <CatalogosMenu></CatalogosMenu>
      );
};
}

export default withRouter(Tab1);
