import React from 'react';
import {  IonContent, IonButton, IonInput, IonToast, isPlatform, IonGrid, IonRow, IonCol, IonHeader, IonTitle, IonRouterLink, IonCard, IonCardContent, IonLoading } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { isNullOrUndefined } from 'util';
import { withRouter } from "react-router-dom";
import "./NewProveedor.css";
import { callNewProveedor } from '../firebaseConfig';


type Props = { props: {
    match: any
} };
type State = { 
    error: any, isLoaded: boolean
    //, value: any, tab: any
    //, articleBody: string, description: String, title: String
    , razonSocial: string, direccion: string, colonia: string, cp: string, pais: string, 
    estado: string, ciudad: string, telefono: string, email: string
    //, tags: Array<string>
    ,toastState: boolean,
    toastMessage: string,
    showLoading: boolean,
    doDismiss: boolean
    };

class NewProveedor extends React.Component<Props & RouteComponentProps, State>    {
    constructor(props: any) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,                   
          //value: "Write your article",
          //tab: "write",
          /*
          articleBody: '',
          description: '',
          tags: [],
          title: '',
          */
         razonSocial: "", 
         direccion: "", 
         colonia: "", 
         cp: "", 
         pais: "", 
        estado: "", 
        ciudad: "", 
        telefono: "", 
        email: "",
        toastState: false,
        toastMessage: "",
        showLoading: false,
        doDismiss: false
        };                    
        
     
      }
      
      razonSocialChange = (event: CustomEvent) => {        
        this.setState({ razonSocial: event.detail.value });
      }
      direccionChange = (event: CustomEvent) => {
        this.setState({ direccion : event.detail.value });          
      }
      coloniaChange = (event: CustomEvent) => {        
        this.setState({ colonia: event.detail.value });
      }
      cpChange = (event: CustomEvent) => {
        this.setState({ cp : event.detail.value });          
      }
      paisChange = (event: CustomEvent) => {        
        this.setState({ pais: event.detail.value });
      }
      estadoChange = (event: CustomEvent) => {
        this.setState({ estado : event.detail.value });          
      }
      ciudadChange = (event: CustomEvent) => {        
        this.setState({ ciudad: event.detail.value });
      }
      telefonoChange = (event: CustomEvent) => {
        this.setState({ telefono : event.detail.value });          
      }
      emailChange = (event: CustomEvent) => {
        this.setState({ email : event.detail.value });          
      }     
      submitProveedor = (tag: any) =>{ 
        
        if(this.state.razonSocial == ""){
          this.setState({
            toastMessage: "Ingrese la razón social",
            toastState: true
          })
          return;
        }       
        this.setState({showLoading:true}) 
        let proveedorData = {            
            "razonSocial": this.state.razonSocial,
            "direccion": this.state.direccion,
            "colonia": this.state.colonia,
            "cp": this.state.cp,
            "pais": this.state.pais,
            "estado": this.state.estado,
            "ciudad": this.state.ciudad,
            "telefono": this.state.telefono,
            "email": this.state.email
        }

        callNewProveedor(proveedorData).then(()=>{  
          console.log(
            "submitProveedor callNewProveedor se ejecutó correctamente")          
          this.setState({
            toastState: true,
            toastMessage: "Se guardó la información",
            //showLoading: false,
            doDismiss: true,
            //title: "",
            //description: "",
            //articleBody: "",
            //tags: []
            razonSocial: "", 
            direccion: "", 
            colonia: "", 
            cp: "", 
            pais: "", 
            estado: "", 
            ciudad: "", 
            telefono: "", 
            email: ""
          });            
      }).catch(error=>{
          console.log("Error callNewProveedor: " +  JSON.stringify(error))
          alert("Error al guardar los datos del proveedor")
      })
/*
          var arrProveedor = [];
          if(!isNullOrUndefined(window.localStorage['proveedores']))
            arrProveedor = JSON.parse(window.localStorage['proveedores']);
          arrProveedor.push(proveedorData);
          
          window.localStorage['proveedores'] = JSON.stringify(arrProveedor);          
*/
          }                 
      
    render(){

      if(isPlatform('desktop'))
        return(
          <>
            <IonHeader>
              <IonGrid>
                <IonRow >
                  <IonCol size="10">
                    <IonTitle>Ingresa los datos del nuevo Proveedor</IonTitle>
                  </IonCol>
                  <IonCol size="2">
                  <IonRouterLink href="/tab1">Regresar</IonRouterLink>
                  </IonCol>
                </IonRow>
              </IonGrid>                            
            </IonHeader>           
            <IonContent>
            <IonLoading isOpen={this.state.showLoading}
              message="Guardando..."
              />            
            <IonToast
        isOpen={this.state.toastState}
        onDidDismiss={() => {
          //alert("Ahora debería cerrarse, doDismiss: " + this.state.doDismiss)
          if(this.state.doDismiss){
            window.location.href ="/proveedores";
          }          
            
          }          
        } 
        position="middle"
        message= {this.state.toastMessage}
        duration={400}
      ></IonToast>                      
    <form onSubmit={this.submitProveedor}>
      <IonCard>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                &nbsp;
              </IonCol>
            </IonRow>
            <IonRow>                            
              <IonCol>
                <IonInput placeholder="Razón social" type="text" onIonChange={this.razonSocialChange}/>
              </IonCol>              
              <IonCol>
              <IonInput type="text" placeholder="Dirección" onIonChange={this.direccionChange}/>
              </IonCol>
            </IonRow>
            <IonRow>            
              <IonCol>
                <IonInput type="text" placeholder="Colonia" onIonChange={this.coloniaChange}/>
              </IonCol>            
              <IonCol>
              <IonInput type="text" placeholder="Código Postal" onIonChange={this.cpChange}/>
              </IonCol>
              <IonCol>
              <IonInput type="text" placeholder="País" onIonChange={this.paisChange}/>
              </IonCol>
            </IonRow>
            <IonRow>              
              <IonCol>
              <IonInput type="text" placeholder="Estado" onIonChange={this.estadoChange}/>
              </IonCol>
              <IonCol>
              <IonInput type="text" placeholder="Ciudad" onIonChange={this.ciudadChange}/>
              </IonCol>              
              <IonCol>
              <IonInput type="text" placeholder="Teléfono" onIonChange={this.telefonoChange}/>
              </IonCol>
            </IonRow>
            <IonRow>                            
              <IonCol size="4">
              <IonInput type="text" placeholder="Correo electrónico" onIonChange={this.emailChange}/>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                
              </IonCol>
              <IonCol size="2">
                <IonButton expand="block" onClick={this.submitProveedor}>Guardar</IonButton>    
              </IonCol>
              <IonCol size="5">
              
              </IonCol>
          </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>                    
      </form>      
    </IonContent>
          </>
        );
      else
        return(
            <>
            <IonLoading isOpen={this.state.showLoading}
              message="Guardando..."
              />
             <IonToast
        isOpen={this.state.toastState}
        /* onDidDismiss={() => {
          this.setState(() => ({ toastState: false }))
          window.location.href ="/proveedores";
          }  } */
        
        message= "Se guardó la información"
        duration={400}
      ></IonToast>
    {/*<Header title="New Article" />     */}
    <IonContent>        
    <form onSubmit={this.submitProveedor}>
      <IonInput type="text" placeholder="Razón social" onIonChange={this.razonSocialChange} 
        class="border-input"></IonInput>
      <IonInput type="text" placeholder="Dirección"  onIonChange={this.direccionChange} 
        class="border-input"></IonInput>
      <IonInput type="text"  placeholder="Colonia" class="border-input"  
        onIonChange={this.coloniaChange}></IonInput>
      <IonInput type="number" placeholder="Código Postal" onIonChange={this.cpChange} 
        class="border-input"></IonInput>
      <IonInput type="text" placeholder="País"  onIonChange={this.paisChange} 
        class="border-input"></IonInput>
      <IonInput type="text"  placeholder="Estado" class="border-input"  
        onIonChange={this.estadoChange}></IonInput>
      <IonInput type="text" placeholder="Ciudad" onIonChange={this.ciudadChange} 
        class="border-input"></IonInput>
      <IonInput type="tel" placeholder="Teléfono"  onIonChange={this.telefonoChange} 
        class="border-input"></IonInput>
      <IonInput type="email"  placeholder="Correo electrónico" class="border-input"  
        onIonChange={this.emailChange}></IonInput>
      <IonButton expand="block" onClick={this.submitProveedor}>Guardar</IonButton>        
      </form>      
    </IonContent>
  </>
        )
    }

}
  
export default withRouter(NewProveedor);