//import * as firebase from 'firebase/app'
//import * as functions from 'firebase-functions'
//import firebase from "firebase/compat/app';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadString, StringFormat, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable} from "firebase/functions"
import { https } from 'firebase-functions/v1';


//import functions from 'firebase-functions/app'

const firebaseConfig = {
    apiKey: "AIzaSyDAeRfu6E7aAfPHGflWfpOoZ8Um3HBCnZw",
    authDomain: "fir-cloud-a589a.firebaseapp.com",
    databaseURL: "https://fir-cloud-a589a.firebaseio.com",
    projectId: "fir-cloud-a589a",
    storageBucket: "fir-cloud-a589a.appspot.com",
    messagingSenderId: "304641434898",
    appId: "1:304641434898:web:fd99ae967b697e103dbfcb",
    measurementId: "G-96JSVLWJ9E"
  };

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);
  const functions = getFunctions(app);
  //var functions = firebase.functions();
  var x;

  const getProveedores = httpsCallable(functions ,'getProveedores');
  const modifyProveedor = httpsCallable(functions, 'modifyProveedor');
  const newProveedor = httpsCallable(functions, 'newProveedor');
  const deleteProveedor = httpsCallable(functions, 'deleteProveedor');

  const getFabricantes = httpsCallable(functions, 'getFabricantes');
  const modifyFabricante = httpsCallable(functions, 'modifyFabricante');
  const newFabricante = httpsCallable(functions, "newFabricante");
  const deleteFabricante = httpsCallable(functions, 'deleteFabricante');
  
  const getProductos = httpsCallable(functions, 'getProductos');
  const newProducto = httpsCallable(functions, 'newProducto');
  const modifyProducto = httpsCallable(functions, 'modifyProducto');
  const deleteProducto = httpsCallable(functions, 'deleteProducto');

  const getEspecies = httpsCallable(functions, 'getEspecies');
  const modifyEspecie = httpsCallable(functions, 'modifyEspecie');
  const newEspecie = httpsCallable(functions, "newEspecie");
  const deleteEspecie = httpsCallable(functions, 'deleteEspecie');

  const getRazas = httpsCallable(functions, 'getRazas');
  const modifyRaza = httpsCallable(functions, 'modifyRaza');
  const newRaza = httpsCallable(functions, "newRaza");
  const deleteRaza = httpsCallable(functions, 'deleteRaza');

  const getColores = httpsCallable(functions, 'getColores');
  const modifyColor = httpsCallable(functions, 'modifyColor');
  const newColor = httpsCallable(functions, "newColor");
  const deleteColor = httpsCallable(functions, 'deleteColor');

  const getFormasPago = httpsCallable(functions, "getFormasPago");

  const getEntradasInv = httpsCallable(functions, "getEntradasInv");
  const newEntradaInv = httpsCallable(functions, "newEntradaInv");

  const updateStock = httpsCallable(functions, "updateStock");
  
  const getSalidasInv = httpsCallable(functions, "getSalidasInv");
  const newSalidaInv = httpsCallable(functions, "newSalidaInv");

  const getMovimientos = httpsCallable(functions, 'getMovimientos');
  const modifyMovimiento = httpsCallable(functions, 'modifyMovimiento');
  const newMovimiento = httpsCallable(functions, "newMovimiento");
  const deleteMovimiento = httpsCallable(functions, 'deleteMovimiento');

  const getFacturas = httpsCallable(functions, 'getFacturas');
  const modifyFactura = httpsCallable(functions, 'modifyFactura');
  const newFactura = httpsCallable(functions, "newFactura");
  const deleteFactura = httpsCallable(functions, 'deleteFactura');
  const getFacturaByNumero = httpsCallable(functions, 'getFacturaByNumero');

  const getConceptos = httpsCallable(functions, 'getConceptos')

  const getPacientes = httpsCallable(functions, 'getPacientes');
  const modifyPaciente = httpsCallable(functions, 'modifyPaciente');
  const newPaciente = httpsCallable(functions, "newPaciente");
  const deletePaciente = httpsCallable(functions, 'deletePaciente');

  const getServicios = httpsCallable(functions, 'getServicios');

  const getColaboradores = httpsCallable(functions, 'getColaboradores');

  const newRegistroServicio = httpsCallable(functions, 'newRegistroServicio')

  const getBitacoraServicios = httpsCallable(functions, 'getBitacoraServicios');

  const getUsuarios = httpsCallable(functions, 'getUsuarios');
  const modifyUsuario = httpsCallable(functions, 'modifyUsuario');
  const newUsuario = httpsCallable(functions, "newUsuario");
  const deleteUsuario = httpsCallable(functions, 'deleteUsuario');
  const getUsuarioLogin = httpsCallable(functions, "getUsuarioLogin")

  const getPerfilById = httpsCallable(functions, "getPerfilById")

  const getClientes = httpsCallable(functions, "getClientes")
  const newCliente = httpsCallable(functions, "newCliente")
  const getClienteByEmail = httpsCallable(functions, "getClienteByEmail")
  const getClienteById = httpsCallable(functions, "getClienteById")

  const getPacientesByCliente = httpsCallable(functions, 'getPacientesByCliente')

  const getPuntosCliente = httpsCallable(functions, "getPuntosCliente")
  const redeemPuntosCliente = httpsCallable(functions, "redeemPuntosCliente")

  export async function uploadImage(imageData: string, filePath: string){
    const storageRef = ref(storage, filePath);
      return await uploadString(storageRef, imageData, StringFormat.DATA_URL);
  }

  export async  function downloadImage(filePath: string){
    //console.log("downloadFile: " + filePath);
    let storageRef = ref(storage, filePath);
    //const imageRef = storageRef.child(filePath);      
    return await getDownloadURL(storageRef);
  }

  
  export async function callGetProveedores(){
    //return null 
    return getProveedores({}).then((result)=>{
      //console.log("Se recibió " + JSON.stringify(result) + " desde Firebase")
      return result.data;
    }
    ).catch(function(error){
      console.log("error: " + JSON.stringify(error))
      return JSON.stringify(error);
    }); 
  }
  export async function callModifyProveedor(proveedor: any){
    return modifyProveedor(proveedor)
  }
  export async function callNewProveedor(proveedor: any){
    return newProveedor(proveedor)
  }
  export async function callDeleteProveedor(id: String){
    return deleteProveedor({"id": id})
  }


  export async function callGetFabricantes(){
    return getFabricantes({}).then(ret=>{
      return ret.data;
    });
  }
  export async function callModifyFabricante(fabricante: any){
    return modifyFabricante(fabricante);
  }
  export async function callNewFabricante(fabricante: any){
    return newFabricante(fabricante)
  }
  export async function callDeleteFabricante(id: String){
    return deleteFabricante({"id":id})
  }


  export async function callGetProductos(){  
    return getProductos({}).then((result)=>{
      //console.log("callGetProductos result: " + JSON.stringify(result) + " desde Firebase")
      return result.data;
    }
    ).catch(function(error){
      console.log("callGetProductos error: " + JSON.stringify(error))
      return JSON.stringify(error);
    });    
  }
  export async function callNewProducto(producto: any){
    return newProducto(producto)
  }
  export async function callModifyProducto(producto: any){
    return modifyProducto(producto);
  }
  export async function callDeleteProducto(id: string){
    return deleteProducto({"id":id})
  }


  export async function callGetEspecies(){
    return getEspecies({}).then(ret=>{
      return ret.data;
    });
  }
  export async function callModifyEspecie(especie: any){
    return modifyEspecie(especie);
  }
  export async function callNewEspecie(especie: any){
    return newEspecie(especie)
  }
  export async function callDeleteEspecie(id: String){
    return deleteEspecie({"id":id})
  }


  export async function callGetRazas(){
    return getRazas({}).then(ret=>{
      return ret.data;
    });
  }
  export async function callModifyRaza(especie: any){
    return modifyRaza(especie);
  }
  export async function callNewRaza(especie: any){
    return newRaza(especie)
  }
  export async function callDeleteRaza(id: String){
    return deleteRaza({"id":id})
  }


  export async function callGetColores(){
    return getColores({}).then(ret=>{
      return ret.data;
    });
  }
  export async function callModifyColor(color: any){
    return modifyColor(color);
  }
  export async function callNewColor(color: any){
    return newColor(color)
  }
  export async function callDeleteColor(id: String){
    return deleteColor({"id":id})
  }


  export async function callGetFormasPago(){
    return getFormasPago({}).then(ret=>{
      return ret.data;
    });
  }



  export async function callNewEntradaInv(entrada: any){
    return newEntradaInv({"entradaData":entrada})
  }
  export async function callGetEntradasInv(){
    return getEntradasInv({}).then(ret=>{
      return ret.data;
    })
  }

  export async function callUpdateStock(id: string, cambio: number, 
    granel: boolean){
    return updateStock({id:id, cambio:cambio, granel:granel})
  }

  export async function callNewSalidaInv(salida: any){
    const arg = {"salidaData":salida}
    //console.log("callNewSalidaInv arg: " +  JSON.stringify(arg))
    return newSalidaInv(arg)
  }
  export async function callGetSalidasInv(){
    return getSalidasInv({}).then(ret=>{
      return ret.data;
    })
  }



  export async function callGetMovimientos(){
    return getMovimientos({}).then(ret=>{
      return ret.data;
    });
  }
  export async function callModifyMovimiento(mov: any){
    return modifyMovimiento(mov);
  }
  export async function callNewMovimiento(mov: any){
    return newMovimiento(mov)
  }
  export async function callDeleteMovimiento(id: String){
    return deleteMovimiento({"id":id})
  }



  export async function callGetFacturas(){
    return getFacturas({}).then(ret=>{
      return ret.data;
    });
  }
  export async function callModifyFactura(fact: any){
    return modifyFactura(fact);
  }
  export async function callNewFactura(fact: any){
    return newFactura(fact)
  }
  export async function callDeleteFactura(id: String){
    return deleteFactura({"id":id})
  }
  export async function callGetFacturaByNumero(numFactura: string){
    return getFacturaByNumero({"numFactura": numFactura }).then(ret=>{
      return ret.data
    })
  }

  export async function callGetConceptos(){
    return getConceptos({}).then(ret=>{
      return ret.data;
    });
  }



  export async function callGetPacientes(){
    return getPacientes({}).then(ret=>{
      return ret.data;
    });
  }
  export async function callModifyPaciente(pacnte: any){
    return modifyPaciente(pacnte);
  }
  export async function callNewPaciente(pacnte: any){
    return newPaciente(pacnte).then(ret=>{
      return ret.data
    })
  }
  export async function callDeletePaciente(id: String){
    return deletePaciente({"id":id})
  }


  export async function callGetServicios(){
    return getServicios({}).then(ret=>{
      return ret.data;
    });
  }


  export async function callGetColaboradores(){
    return getColaboradores({}).then(ret=>{
      return ret.data;
    });
  }

  export async function callNewRegistroServicio(infoServicio: any){
    return newRegistroServicio(infoServicio).then(ret=>{
      return ret.data
    })
  }

  export async function callGetBitacoraServicios(){
    return getBitacoraServicios({}).then(ret=>{
      return ret.data;
    });
  }


  export async function callGetUsuarios(){
    return getUsuarios({}).then(ret=>{
      return ret.data;
    });
  }
  export async function callModifyUsuario(usuario: any){
    return modifyUsuario(usuario);
  }
  export async function callNewUsuario(usuario: any){
    return newUsuario(usuario).then(ret=>{
      return ret.data
    })
  }
  export async function callDeleteUsuario(id: String){
    return deleteUsuario({"id":id})
  }
  export async function callGetUsuarioLogin(usuario: any){
    return getUsuarioLogin({usr:usuario}).then(ret=>{
      return ret.data;
    });
  }

  export async function callGetPerfilById(id: String){
    return getPerfilById({idPerfil:id}).then(ret=>{
      return ret.data;
    });
  }

  export async function callGetClientes(){
    return getClientes({}).then(ret=>{
      return ret.data;
    })
  }

  export async function callNewCliente(cliente: any){
    return newCliente({cli:cliente}).then(ret=>{
      return ret.data
    })
  }

  export async function callGetPacientesByCliente(id:String){
    return getPacientesByCliente({idPropietario:id}).then(ret=>{
      return ret.data;
    })
  }

  export async function callGetPuntosCliente(id:String, puntosIniciales:boolean,
      fechaRegistro: string){
    return getPuntosCliente({
      idCliente:id,
      puntosIniciales: puntosIniciales,
      fechaRegistro: fechaRegistro}).then(ret=>{
      return ret.data
    }) 
  }

  export async function callRedeemPuntosCliente(id:String){
    return redeemPuntosCliente({idCliente:id}).then(ret=>{
      return ret.data
    })
  }

  export async function callGetClienteByEmail(email:String){
     //console.log("callGetClienteByEmail email: " + email)
    return getClienteByEmail({email:email}).then(ret=>{
      return ret.data;
    })
  }

  export async function callGetClienteById(id:String){
    //console.log("callGetClienteByEmail email: " + email)
   return getClienteById({id:id}).then(ret=>{
     return ret.data;
   })
 }

  