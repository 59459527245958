import React, { useEffect, useState } from 'react'
import { IonPage, IonCardTitle, IonCardContent, IonCardHeader, IonCard, 
    IonGrid, IonRow, IonCol, IonSelect, IonInput, IonContent, IonModal, 
    IonButton, IonSelectOption, IonFabButton, IonIcon, IonFab, IonToast, 
    IonTextarea, IonLoading, IonCheckbox, IonLabel, IonRadioGroup, IonRadio, IonTitle, IonToolbar, IonAlert, isPlatform, IonDatetime, IonHeader, IonMenuButton } from '@ionic/react';
import { isNullOrUndefined } from 'util';
import EntitySearch from '../components/EntitySearch';
import { Servicio, FormServicio, Tarifa, InfoServicio, Objetivo } 
    from '../App';
import { trash, add } from 'ionicons/icons';
import DataTable from 'react-data-table-component';
import { callGetColaboradores, callGetColores, callGetEspecies, callGetFormasPago, callGetPuntosCliente, callGetRazas, callGetServicios, 
    callNewCliente, 
    callNewMovimiento, callNewPaciente, callNewRegistroServicio, callNewSalidaInv, 
    callRedeemPuntosCliente, 
    callUpdateStock, 
    callGetPacientesByCliente,
    callGetClienteByEmail,
    callNewEntradaInv,
    callGetClienteById} from '../firebaseConfig';
import { checkAccessToModule } from '../helper';
import { Redirect, RouteComponentProps } from 'react-router';
import { error, log } from 'console';

type servicioProps = {
    clienteEmail: string
     servicio?: string,
    responsable?: string,
    clienteID?: string,
    pacienteID?: string
}
const RegistroServicio: React.FC<RouteComponentProps<servicioProps>> = (props) =>{
    
    const defaultFormServicio: FormServicio = {
        monto: true,
        tarifas: false,
        celPropietario: false,
        paciente: true,
        productos: false,
        propietario: true,
        soip: false,
        extras: false,
        usoInterno: false
    }

    const[showAddArt, setShowAddArt] = useState(false)
    const[cantidad, setCantidad] = useState(0)
    const[showPickProducto, setShowPickProducto] = useState(false)
    const[servicio, setServicio] = useState<undefined|string>(undefined)
    const[responsable, setResponsable] = useState<undefined|string>(undefined)
    const[fecha, setFecha] = useState("")
    const[formServicio, setFormServicio] = useState(defaultFormServicio)
    const[showPickPaciente, setShowPickPaciente] = useState(false)
    const[formaPago, setFormaPago] = useState("")
    const[tarifa, setTarifa] = useState("")
    const[tarifas, setTarifas] = useState([] as Tarifa[])
    const[extras, setExtras] = useState([] as Tarifa[])
    const[extrasDisponibles, setExtrasDisponibles] = useState([] as Tarifa[])
    const[total, setTotal] = useState(0)
    const[showAddExtra, setShowAddExtra] = useState(false)
    const[extra, setExtra] = useState("")
    const[articulos, setArticulos] = useState([] as any[])
    const[precioVenta, setPrecioVenta] = useState(0)
    const[monto, setMonto] = useState(0)
    const[showToast, setShowToast] = useState(false)
    const[toastMessage, setToastMessage] = useState("")
    const[validaciones, setValidaciones] = useState([] as string[])
    const[subjetivo, setSubjetivo] = useState("")
    const[objetivo, setObjetivo] = useState<Objetivo>({})
    const[celPropietario, setCelPropietario] = useState("")
    const[interpretacion, setInterpretacion] = useState("")
    const[plan, setPlan] = useState("")
    const[servicios, setServicios] = useState([] as Servicio[])
    const[colaboradores, setColaboradores] = useState([] as any[])
    const[formasPago, setFormasPago] = useState([] as any[])
    const[showLoading, setShowLoading] = useState(false)
    const[granel, setGranel] = useState(false)
    const[cantidadSizeCol, setCantidadSizeCol] = useState("8")
    const[tipoPropietario, setTipoPropietario] = useState("existente")
    const[showPickPropietario, setShowPickPropietario] = useState(false)
    const[showNewCliente, setShowNewCliente] = useState(false)
    const[nombreCli, setNombreCli] = useState("")
    const[fechaNacCli, setFechaNacCli] = useState("")
    const[emailCli, setEmailCli] = useState("")
    const[telefonoCli, setTelefonoCli] = useState("")
    const[showNewPaciente, setshowNewPaciente] = useState(false)
    const[nombrePac, setNombrePac] = useState("")
    const[fechaNacPac, setFechaNacPac] = useState("")
    const[especie, setEspecie] = useState("")
    const[especies, setEspecies] = useState([])
    const[raza, setRaza] = useState("")
    const[opcionesRazas, setOpcionesRazas] = useState([])
    const[razasFiltradas, setRazasFiltradas] = useState([])
    const[sexo, setSexo] = useState("")
    const[peso, setPeso] = useState("")
    const[color, setColor] = useState("")
    const[colores, setColores] = useState([])
    const[edadAcogida, setEdadAcogida] = useState("")
    const[fechaAcogida, setFechaAcogida] = useState("")
    //const[paciente, setPaciente] = useState({} as any)
    const[pacientes, setPacientes] = useState([] as any[])
    const[newPacExtCli, setNewPacExtCli] = useState(false)
    const[saldoCliente, setSaldoCliente] = useState(-1)
    const[showSaldo, setShowSaldo] = useState(false)
    const[showRedimirBtn, setShowRedimirBtn] = useState(false)
    const[showRedimirPopup, setShowRedimirPopup] = useState(false)
    const[pctDescCli,setPctDescCli] = useState(0)
    const[pctAcumCli, setPctAcumCli] = useState(0.1)
    const[metaPtosCli, setMetaPtosCli] = useState(1000)    
    const[showInvAlert, setShowInvAlert] = useState(false)

    const[showFecha, setShowFecha] = useState(false)

    const[artUsoInt, setArtUsoInt] = useState([] as any[])
    const[showAddArtUsoInt, setShowAddArtUsoInt] = useState(false)

    const formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol'
      });

    

    /*
    if(window.localStorage["servicios"] === undefined){
        let serviciosDefault: Servicio[] = [
            {
                "nombre": "Consulta",
                "formServicio":  { 
                    "monto" : false, 
                    "tarifas": false,
                    "paciente": true,
                    "propietario": false,
                    "celPropietario": false,
                    "soip": true,
                    "productos": true,
                    "extras": false
                  }
                ,"tarifas": [{"nombre" : "Regular", monto : 300}]
                , "validaciones": ["paciente"]
            }
            ,{
                nombre: "Revisión",
                formServicio:  { 
                    monto: false, 
                    tarifas: false,
                    paciente: true,
                    propietario: false,
                    celPropietario: false,
                    soip: true,
                    productos: true,
                    extras: false
                  }
                ,"tarifas": [{"nombre" : "Regular", monto : 200}]
            }
            ,{
                nombre: "Estética",
                formServicio:  {  
                    monto: false,
                    tarifas: true,
                    paciente: true,
                    propietario: false,
                    celPropietario: true,
                    soip: false,
                    productos: true,
                    extras: true
                  }
                ,"tarifas": [
                    {"nombre" : "Grande", monto : 300}, 
                    {"nombre" : "Mediano", monto : 200},
                    {"nombre" : "Pequeño", monto : 130}
                    ]
                ,"extras": [ {"nombre" : "Pelo enredado", monto : 100}]
            }
            ,{
                nombre: "Venta Medicamentos",
                formServicio:  {  
                    monto: false,
                    tarifas: false,
                    paciente: true,
                    propietario: false,
                    celPropietario: false,
                    soip: false,
                    productos: true,
                    extras: false
                  }                
            }
            ,{
                nombre: "Consulta Especializada",
                formServicio:  {  
                    monto: true,
                    tarifas: false,
                    paciente: true,
                    propietario: false,
                    celPropietario: false,
                    soip: true,
                    productos: true,
                    extras: false
                  }
            }
            ,{
                nombre: "Terapia",
                formServicio:  {  
                    monto: false,
                    tarifas: true,
                    paciente: true,
                    propietario: false,
                    celPropietario: false,
                    soip: false,
                    productos: true,
                    extras: false
                  }
                ,"tarifas": [
                    {"nombre" : "Grande", monto : 300}, 
                    {"nombre" : "Mediano", monto : 200},
                    {"nombre" : "Pequeño", monto : 130}
                    ]
            }
            ,{
                nombre: "Laboratorio",
                formServicio:  {
                    monto: false,
                    tarifas: true,
                    paciente: true,
                    propietario: false,
                    celPropietario: false,
                    soip: false,
                    productos: false,
                    extras: false
                  }
                ,"tarifas": [
                    {"nombre" : "Química sanguínea", monto : 800}, 
                    {"nombre" : "Placas", monto : 500}
                    ]
            }
        ]
        window.localStorage["servicios"] = JSON.stringify(serviciosDefault)
    }*/
    
    useEffect(()=>{
        getServiciosFromCloud()
        callGetColaboradores().then(res=>{
            setColaboradores(res as [])
        })
        callGetFormasPago().then(res=>{
            setFormasPago(res as [])
        })        
        callGetEspecies().then(especies=>{
            callGetRazas().then(razas=>{
                callGetColores().then(colores=>{
                    setEspecies(especies as [])
                    setOpcionesRazas(razas as [])
                    setColores(colores as [])
                    })
                })
            })  
        if(!isNullOrUndefined(props.match.params.clienteEmail))
            {
                //console.log("props.clienteEmail: " + props.match.params.clienteEmail)
                //console.log("props: " + JSON.stringify(props))
                callGetClienteByEmail(props.match.params.clienteEmail).then(
                    resCli=>{
                    //console.log("cliente recibido: " + JSON.stringify(resCli))
                    window.localStorage["clienteSelected"] = JSON.stringify(resCli)
                    getPacientesAndSaldo()
                    
                }).catch(err=>console.log("Error a obtener cliente: " + 
                    JSON.stringify(err)))
            }
        else
        {
            //window.localStorage.removeItem("clienteSelected")    
            console.log("no se recibió email del cliente")
        }
        if(!isNullOrUndefined(props.match.params.clienteID)){
            console.log("id de cliente: " + props.match.params.clienteID)
            callGetClienteById(props.match.params.clienteID).then(
                resCli=>{
                //console.log("cliente recibido: " + JSON.stringify(resCli))
                window.localStorage["clienteSelected"] = JSON.stringify(resCli)
                getPacientesAndSaldo()
                
            }).catch(err=>console.log("Error a obtener cliente: " + 
                JSON.stringify(err)))
        }
        else{
            console.log("no se recibió id de cliente")

        }
        if(isNullOrUndefined(props.match.params.clienteEmail)&&
            isNullOrUndefined(props.match.params.clienteID))
            window.localStorage.removeItem("clienteSelected")  
    }, [])

    function getServiciosFromCloud(){
        callGetServicios().then(ret=>{
            setServicios(ret as [])
        })
    }
  /*  
    if(window.localStorage["colaboradores"] === undefined){
        var colabDefault = [ {nombre: "Edson Bocardo"}]
        window.localStorage["colaboradores"] = JSON.stringify(colabDefault)
    }

    if(window.localStorage["formasPago"] === undefined){
        var fPagoDefault = [{id: 1, nombre: "Efectivo"}, {id:2, nombre: "Tarjeta Bancaria"},
            {id: 3, nombre: "Transferencia"}]
        window.localStorage["formasPago"] = JSON.stringify(fPagoDefault)
    }
    */
    //var servicios: Servicio[] = JSON.parse(window.localStorage["servicios"])
    //var colaboradores: any[] = JSON.parse(window.localStorage["colaboradores"])
    var propsFormServicio = {} as FormServicio
    //console.log("localStorage: " + window.localStorage["formasPago"])
    //var formasPago: any[] = JSON.parse(window.localStorage["formasPago"])
    //console.log("formasPago: " + JSON.stringify(formasPago))
    


    if(props.match.params.servicio!==undefined){
        servicios.map((servicio)=>{
            if(servicio.nombre == props.match.params.servicio)
            {
                if(servicio.formServicio !== undefined)
                    propsFormServicio = servicio.formServicio
            }
        })   
    }
    
    function getSoipVisibility(){
        var blnReturn = false
        if(propsFormServicio.soip !== undefined)
            blnReturn = propsFormServicio.soip;
        else
            blnReturn = formServicio?.soip;
        return blnReturn;
    }

    function getProductosVisibility(){
        var blnReturn = false
        if(propsFormServicio.productos !== undefined)
            blnReturn = propsFormServicio.productos;
        else
            blnReturn = formServicio?.productos;
        return blnReturn;
    }

    function getCelVisibility(){
        var blnReturn = false
        if(propsFormServicio.celPropietario !== undefined)
            blnReturn = propsFormServicio.celPropietario;
        else
            blnReturn = formServicio?.celPropietario;
        return blnReturn;
    }

    function getPropietarioVisibility(){
        var blnReturn = false
        if(propsFormServicio.propietario !== undefined)
            blnReturn = propsFormServicio.propietario;
        else
            blnReturn = formServicio?.propietario;
        return blnReturn;
    }

    function getPacienteVisibility(){
        var blnReturn = false
        if(propsFormServicio.paciente !== undefined)
            blnReturn = propsFormServicio.paciente;
        else
            blnReturn = formServicio?.paciente;
        return blnReturn;
    }

    function getMontoVisibility(){
        var blnReturn = false
        if(propsFormServicio.monto !== undefined)
            blnReturn = propsFormServicio.monto;
        else
            blnReturn = formServicio?.monto;
        return blnReturn;
    }

    function getTarifasVisibility(){
        var blnReturn = false
        if(propsFormServicio.tarifas !== undefined)
            blnReturn = propsFormServicio.tarifas;
        else
            blnReturn = formServicio?.tarifas;
        return blnReturn;
    }

    function getExtrasVisibility(){
        var blnReturn = false
        if(propsFormServicio.extras !== undefined)
            blnReturn = propsFormServicio.extras;
        else
            blnReturn = formServicio?.extras;
        return blnReturn;
    }

    function getUsoInternoVisibility(){
        var blnReturn = false
        if(propsFormServicio.usoInterno !== undefined)
            blnReturn = propsFormServicio.usoInterno;
        else
            blnReturn = formServicio?.usoInterno;
        return blnReturn;
    }

    function resetForm(){
        setTotal(0)
        setArticulos([])
        setExtras([])
        setTarifa("")
        setMonto(0)
        setArtUsoInt([])
        window.localStorage.removeItem("productoSelected")        
    }

    function resetClienteForm(){
        setNombreCli("")
        setFechaNacCli("")
        setEmailCli("")
        setTelefonoCli("")        
    }

    function resetPacienteForm(){
        setNombrePac("")
        setFechaNacPac("")
        setEspecie("")        
        setRaza("")
        setSexo("")
        setPeso("")
        setColor("")
        setEdadAcogida("")
        setFechaAcogida("")
    }

    function servicioChange(event: CustomEvent){
        setServicio(event.detail.value)        
        resetForm()
        servicios.map(svc=>{
            if(svc.nombre == event.detail.value){
                if(svc.formServicio !== undefined){
                    console.log("formService de " +  svc.nombre + ": " +  
                        JSON.stringify(svc.formServicio))
                    setFormServicio(svc.formServicio)
                }
                if(svc.tarifas !== undefined){
                    setTarifas(svc.tarifas)
                    if(svc.tarifas.length == 1){
                        setTarifa(svc.tarifas[0].nombre)
                        setTotal(svc.tarifas[0].monto)
                    }
                }
                    
                if(svc.extras !== undefined){
                    //alert("svc.extras: " + JSON.stringify(svc.extras))
                    setExtrasDisponibles(svc.extras)
                }
                if(svc.validaciones !== undefined)
                    setValidaciones(svc.validaciones)
                
            }
                
        })
    }
    function precioVentaChange(event: CustomEvent){
        setPrecioVenta(event.detail.value)
    }
    function cantidadChange(event: CustomEvent){
        setCantidad(event.detail.value)
    }
    function responsableChange(event: CustomEvent){
        setResponsable(event.detail.value)
    }
    function fechaChange(event: CustomEvent){
            setFecha(event.detail.value)
            if(!isPlatform('desktop'))
                setShowFecha(false)
    }
    function formaPagoChange(event: CustomEvent){
        setFormaPago(event.detail.value)
    }
    function celPropietarioChange(event: CustomEvent){
        setCelPropietario(event.detail.value)
    }
    function subjetivoChange(event: CustomEvent){
        setSubjetivo(event.detail.value)
    }
    /* Campos de Objetivo */
    function emChange(event: CustomEvent){
        objetivo.EM = event.detail.value
    }
    function rtChange(event: CustomEvent){
        objetivo.RT = event.detail.value
    }
    function ppChange(event: CustomEvent){
        objetivo.PP = event.detail.value
    }
    function tChange(event: CustomEvent){
        objetivo.T = event.detail.value
    }
    function mmChange(event: CustomEvent){
        objetivo.MM = event.detail.value
    }
    function rdChange(event: CustomEvent){
        objetivo.RD = event.detail.value
    }
    function cpChange(event: CustomEvent){
        objetivo.CP = event.detail.value
    }
    function pesoChange(event: CustomEvent){
        objetivo.Peso = event.detail.value
    }
    function tllcChange(event: CustomEvent){
        objetivo.TLLC = event.detail.value
    }
    function fcChange(event: CustomEvent){
        objetivo.FC = event.detail.value
    }
    function paChange(event: CustomEvent){
        objetivo.PA = event.detail.value
    }
    function ccChange(event: CustomEvent){
        objetivo.CC = event.detail.value
    }
    function lnChange(event: CustomEvent){
        objetivo.LN = event.detail.value
    }
    function frChange(event: CustomEvent){
        objetivo.FR = event.detail.value
    }
    function pulsoChange(event: CustomEvent){
        objetivo.Pulso = event.detail.value
    }
    function dhChange(event: CustomEvent){
        objetivo.DH = event.detail.value
    }
    function observacionesChange(event: CustomEvent){
        objetivo.Observaciones = event.detail.value
    }
    /* Campos de Objetivo */
    function interpretacionChange(event: CustomEvent){
        setInterpretacion(event.detail.value)
    }
    function planChange(event: CustomEvent){
        setPlan(event.detail.value)
    }
    function tarifaChange(event: CustomEvent){
        var totalExtras = 0;
        var montoTarifa = 0;
        var totalArticulos = 0;
        for (let index = 0; index < tarifas.length; index++) {
            const element = tarifas[index];
            if(element.nombre == event.detail.value)
                montoTarifa = element.monto
        }
        for (let index = 0; index < extras.length; index++) {
            const element = extras[index];
            totalExtras += element.monto;
        }
        for (let index = 0; index < articulos.length; index++) {
            const element = articulos[index];
            totalArticulos += (element.precioVenta * element.cantidad);
        }        
        setTarifa(event.detail.value)
        setTotal(montoTarifa + totalExtras + totalArticulos)
    }
    function extraChange(event: CustomEvent){
        setExtra(event.detail.value)
    }
    function montoChange(event: CustomEvent){
        const montoVal = event.detail.value ? parseFloat(event.detail.value) : 0
        setMonto(montoVal)
        var newTotal = event.detail.value
        for (let index = 0; index < articulos.length; index++) {
            const element = articulos[index];
                newTotal += (element.precioVenta * element.cantidad)
        }
        setTotal(newTotal)

    }
    function granelChanged(event: CustomEvent){
        setGranel(event.detail.checked)
        if(event.detail.checked == true)
            setCantidadSizeCol("4")
        else
            setCantidadSizeCol("8")
    }
    function pctDescChange(event: CustomEvent){
        const descVal = event.detail.value ? parseFloat(event.detail.value) : 0
        setPctDescCli(descVal)
    }
    /*** Cliente nuevo ***/
    function nombreCliChange(event: CustomEvent){
        setNombreCli(event.detail.value)
    }
    function fechaNacCliChange(event: CustomEvent){
        setFechaNacCli(event.detail.value)
    }
    function emailCliChange(event: CustomEvent){
        setEmailCli(event.detail.value)
    }
    function telefonoCliChange(event: CustomEvent){
        setTelefonoCli(event.detail.value)
    }
    /*** Paciente ***/
    function nombrePacChange(event: CustomEvent){
        setNombrePac(event.detail.value) }
    function fechaNacPacChange(event: CustomEvent){
            setFechaNacPac(event.detail.value) }
    function especieChange(event: CustomEvent){
            setEspecie(event.detail.value) 
            //console.log("RegistroServicio.especieChange event.detail.value: " +
            //    JSON.stringify(event.detail.value))
            //console.log("RegistroServicio.especieChange opcionesRazas: " +
            //    JSON.stringify(opcionesRazas))
            let idEspecie = getIdEspecieByName(event.detail.value)
            setRazasFiltradas(opcionesRazas.filter(
                (opcion:any)=>opcion.padre.id==idEspecie))
        }
    function getIdEspecieByName(nombreEspecie:string){
        for (let index = 0; index < especies.length; index++) {
            const especie = especies[index] as any;
            if(especie.nombre == nombreEspecie)
            return especie.id
        }
        return -1
    }
    function razaChange(event: CustomEvent){
            setRaza(event.detail.value) }
    function sexoChange(event: CustomEvent){
            setSexo(event.detail.value) }
    function colorChange(event: CustomEvent){
            setColor(event.detail.value) }
    function edadAcogidaChange(event: CustomEvent){
            setEdadAcogida(event.detail.value) }
    function fechaAcogidaChange(event: CustomEvent){
            setFechaAcogida(event.detail.value) }
    function pacienteChanged(event: CustomEvent){
        console.log("getPropietarioExistente(): " + getPropietarioExistente())
        if(getPropietarioExistente()&&!newPacExtCli)
        {
            console.log("pacienteChanged event.detail.value: " + 
                JSON.stringify(event.detail.value))
            if(event.detail.value == "nuevo")
                {
                    setshowNewPaciente(true)
                    setNewPacExtCli(true)
                }
            else
                window.localStorage["pacienteSelected"] = JSON.stringify(
                    getPacienteById(event.detail.value))    
        }        
    }
    
    function submitArticulo(validarInv: boolean){
        if(window.localStorage["productoSelected"] === undefined){
            setShowToast(true)                            
            setToastMessage("Por favor seleccione el producto")
            return;
        }
        if(precioVenta <= 0){
            setShowToast(true)                            
            setToastMessage("Por favor ingrese un precio de venta válido")
            return;
        }
        if(cantidad == 0){
            setShowToast(true)                            
            setToastMessage("Por favor ingrese la cantidad")
            return;
        }                    
        const prod = JSON.parse(window.localStorage["productoSelected"])
        if(validarInv){ 
            //console.log("Registroservicio.submitArticulo granel: " + granel)
            //console.log("Registroservicio.submitArticulo prod.stockGranel: " 
            //    + prod.stockGranel)
            //console.log("Registroservicio.submitArticulo 1erElComp: " 
            //    + ((prod.stockActual * prod.capacidad) + (prod.stockGranel*1)))
            //console.log("Registroservicio.submitArticulo cantidad: " 
            //    + cantidad)
            if((!granel && prod.stockActual < cantidad) ||
                (granel && ( 
                    (prod.stockActual * prod.capacidad) + (prod.stockGranel*1)) 
                    < cantidad)){
            //setShowToast(true)
            //setToastMessage(
            //    "No hay existencias suficientes del producto")            
            setShowInvAlert(true)
            return;
            }
        }
        let articuloData = {
            producto: prod,
            precioVenta: precioVenta,
            cantidad: cantidad,
            granel: granel,
            ajustarInv: validarInv ? 0 : prod.granel == false ? 
            cantidad - prod.stockActual : 
            cantidad - ((prod.stockActual*prod.capacidad) + (prod.stockGranel*1))
        }
        if(!validarInv)
            console.log("Se va a ajustar el artículo: " +  
                JSON.stringify(articuloData))
        articulos.push(articuloData)
        let montoArticulo: number = precioVenta * cantidad
        let newTotal:number = (total*1) + (montoArticulo*1)
        //alert(newTotal)
        setTotal(newTotal)
        setShowAddArt(false)
        window.localStorage.removeItem("productoSelected")
        setPrecioVenta(0)
        setCantidad(0)
        setGranel(false)
    }

    function cancelAddProduct(){
        setShowAddArt(false)
        setCantidad(0)
        setGranel(false)
        window.localStorage.removeItem("productoSelected")
    }


    /** Inicio Uso interno **/

    function submitArtUsoInt(validarInv: boolean){
        if(window.localStorage["productoSelected"] === undefined){
            setShowToast(true)                            
            setToastMessage("Por favor seleccione el producto")
            return;
        }        
        if(cantidad == 0){
            setShowToast(true)                            
            setToastMessage("Por favor ingrese la cantidad")
            return;
        }                    
        const prod = JSON.parse(window.localStorage["productoSelected"])
        if(validarInv){ 
            //console.log("Registroservicio.submitArticulo granel: " + granel)
            if((!granel && prod.stockActual < cantidad) ||
                (granel && ( 
                    (prod.stockActual * prod.capacidad) + prod.stockGranel) 
                    < cantidad)){
            //setShowToast(true)
            //setToastMessage(
            //    "No hay existencias suficientes del producto")            
            setShowInvAlert(true)
            return;
            }
        }
        let articuloData = {
            producto: prod,            
            cantidad: cantidad,
            granel: granel,
            ajustarInv: validarInv ? 0 : prod.granel == false ? 
            cantidad - prod.stockActual : 
            cantidad - ((prod.stockActual*prod.capacidad) + prod.stockGranel)
        }
        if(!validarInv)
            console.log("Se va a ajustar el artículo: " +  
                JSON.stringify(articuloData))
        artUsoInt.push(articuloData)
        setShowAddArtUsoInt(false)
        window.localStorage.removeItem("productoSelected")        
        setCantidad(0)
        setGranel(false)
    }

    function cancelAddProdUsoInt(){
        setShowAddArtUsoInt(false)
        setCantidad(0)
        setGranel(false)
        window.localStorage.removeItem("productoSelected")
    }

    /** Fin Uso Interno **/

    function cancelAddExtra(){
        setShowAddExtra(false)
        setExtra("")
    }

    function submitExtra(){
        var newTotal = 0;
        for (let index = 0; index < extrasDisponibles.length; index++) {
            const element = extrasDisponibles[index];
            if(element.nombre == extra){
                extras.push(element)
                setTotal(total + element.monto)
            }                
        }
        /* for (let index = 0; index < extras.length; index++) {
            const element = extras[index];            
            newTotal += element.monto            
        }
        for (let index = 0; index < tarifas.length; index++) {
            const element = tarifas[index];
            if(element.nombre == tarifa)
                newTotal += element.monto            
        }
        setTotal(newTotal) */
        //alert("No se pudo agregar el extra")
        cancelAddExtra()
    }

    function removeExtra(extra: Tarifa){
        for (let index = 0; index < extras.length; index++) {
            const element = extras[index];
            if(element == extra){
                extras.splice(index, 1)
                setTotal(total - extra.monto)
                console.log("RegistroServicio.removeExtra Nuevo total: " +
                    (total - extra.monto))
            }
        }
    }

    function removeArticulo(art: any){
        for (let index = 0; index < articulos.length; index++) {
            const element = articulos[index];
            if(element == art){
                articulos.splice(index, 1)
                setTotal(total - (art.precioVenta * art.cantidad))
            }
            
        }
    }

    /* Inicio Uso Interno */
    function removeArtUsoInt(art: any){
        for (let index = 0; index < artUsoInt.length; index++) {
            const element = artUsoInt[index];
            if(element == art){
                artUsoInt.splice(index, 1)                
            }
            
        }
    }
    /* Fin Uso Interno */

    function submitServicio(){
        if(servicio == "" || servicio === undefined)
        {
            setShowToast(true)                            
            setToastMessage("Por favor indique el servicio prestado") 
            return;         
        }    
        if(responsable == "" || responsable === undefined){
            setShowToast(true)                            
            setToastMessage("Por favor indique el colaborador responsable del servicio")
            return;
        }    
        if(fecha == "" || fecha === undefined){
            setShowToast(true)                            
            setToastMessage("Por favor indique la fecha en que se brindó el servicio")
            return;
        }
        if(getTarifasVisibility() && tarifa == ""){
            setShowToast(true)                            
            setToastMessage("Por favor seleccione la tarifa del servicio")
            return;
        }
        if(formaPago == "" || formaPago === undefined){
            setShowToast(true)                            
            setToastMessage("Por favor indique la forma de pago")
            return;
        }   
        if(window.localStorage["pacienteSelected"] === undefined && 
            validaciones.includes("paciente")){
            setShowToast(true)                            
            setToastMessage("Por favor seleccione el paciente atendido")
            return;
        } 
        if(getMontoVisibility() && monto <= 0){
            setShowToast(true)                            
            setToastMessage("Por favor ingrese la cuota del servicio")
            return;
        }
        if(total <= 0){
            setShowToast(true)                            
            setToastMessage("El monto total es incorrecto, verifique los datos")
            return;
        }
        if(isNullOrUndefined(window.localStorage["clienteSelected"])){
            setShowToast(true)                            
            setToastMessage("No se ha especificado un cliente")
            return;
        }

        //TODO: si no se agregan artículos de uso interno, pedir confirmación
        //      antes de continuar????
        setShowLoading(true)

        const cliente = getClienteObj()
        let newBitacoraId = getNewBitacoraId()
        let infoServicio: InfoServicio = {
            id: newBitacoraId,
            servicio: servicio,
            responsable: responsable,
            fecha: fecha,
            formaPago: formaPago,
            total: pctDescCli > 0 ? total * (1 - (pctDescCli * 0.01)) : total,
            paciente: getPacienteObj(),
            propietario: cliente,
            tarifa: tarifa,   
            monto: monto,                     
            //celPropietario: getCelVisibility() ? celPropietario : undefined,
            subjetivo: subjetivo,
            objetivo: objetivo,
            interpretacion: interpretacion,
            plan: plan,
            articulos: articulos,
            extras: extras,
            puntos: getPuntosForService(),
            articulosUsoInt: artUsoInt

        }
        callNewRegistroServicio(infoServicio).then(regServId=>{
            setToastMessage("Se guardó la información")
            let movimientoData = {
                tipo: 1,
                id: getNewMovimientoId(),        
                concepto: "Cobro Servicio",
                monto: total,
                fecha: fecha,
                detalle: "Servicio: " + servicio + ", referencia: " + 
                    regServId,
                formaPago: formaPago
            }
            callRedeemPuntosCliente(cliente?.id).then(ret=>{
                console.log("redeemPuntosCliente devolvió: " + 
                    JSON.stringify(ret))
            })
            callNewMovimiento(movimientoData).then(res=>{
                if(articulos.length > 0 || artUsoInt.length > 0){
                    /*
                    var montoSalida: number = 0;
                    var unidadesSalida: number = 0;

                    articulos.map(producto=>{
                        montoSalida += parseFloat(producto.precioVenta) * parseInt(producto.cantidad);
                        unidadesSalida += parseInt(producto.cantidad);
                    })*/
                    console.log("RegistroServicio.submitServicio articulos: " +
                        JSON.stringify(articulos))
                    console.log("RegistroServicio.submitServicio artUsoInt: " +
                        JSON.stringify(artUsoInt))
                    const allArt = articulos.concat(artUsoInt) 
                    setArticulos(allArt)                    
                    console.log("RegistroServicio.submitServicio combinado: " +
                        JSON.stringify(allArt))
                    let salidaData = {                        
                        "id": "no asignado",        
                        "fecha": fecha,                        
                        "productos": allArt,
                        "responsable": window.localStorage['username'],
                        "concepto": "Venta",
                        "comentarios": "Venta con id " + regServId,
                        "formaPago": formaPago,
                        "activo" : 1
                    }
                    console.log("RegistroServicio.submitServicio salidaData: " +
                        JSON.stringify(salidaData))
                    callNewSalidaInv(salidaData).then(salidaRes=>{
                        salidaData.productos.map((prod:any)=>{
                            if(prod.ajustarInv > 0){
                                const diffStock = prod.cantidad - prod.ajustarInv
                                prod.cantidad = prod.ajustarInv
                                const artArr = [prod]
                                const entradaAjuste = {
                                    "id": "undefined",        
                                    "fecha": fecha,
                                    "proveedor": "N/A",
                                    "articulos": artArr,
                                    "responsable": window.localStorage['username'],
                                    "total": 0,
                                    "factura": "AJUSTEINV",
                                    "formaPago": undefined,
                                    "activo": 1
                                }
                                callNewEntradaInv(entradaAjuste).then(resAj=>{
                                    console.log(
                                        "Se registró la entrada de ajusteInv, " +
                                        "se actualizará stock con la cantidad: "
                                        + diffStock + ", " + prod.granel)
                                    callUpdateStock(prod.producto.id, 
                                        diffStock * -1, 
                                        prod.granel)
                                    .then(stockRes=>{
                                        console.log("resultado updateStock: " + 
                                            JSON.stringify(res))                                        
                                    }).catch(errUpdStock=>
                                        console.log("Error al actualizar stock: " 
                                            + JSON.stringify(errUpdStock)))
                                })
                                .catch(errAjuste=>{console.log(
                                            "Error al registrar ajuste de inventario: "
                                            + JSON.stringify(errAjuste))
                                    }
                                )
                            }
                            else
                                callUpdateStock(prod.producto.id, 
                                    prod.cantidad * -1, prod.granel)
                            .then(stockRes=>{
                                console.log("resultado updateStock: " + JSON.stringify(res))                                        
                                })
                            .catch(errUpdStock=>
                                console.log("Error al actualizar stock: " 
                                    + JSON.stringify(errUpdStock)))
                            //console.log("producto actual: " + prod.medicamento);                
                        })
                        setShowToast(true)
                        setToastMessage("Se guardó la información")
                        setServicio(undefined)
                        setResponsable(undefined)
                        setFecha("")
                        setFormaPago("")
                        setSubjetivo("")
                        setObjetivo({})
                        setInterpretacion("")
                        setPlan("")       
                        window.localStorage.removeItem("pacienteSelected")
                        resetForm()
                        setShowLoading(false)
                        setTipoPropietario("existente")
                        window.localStorage.removeItem("clienteSelected")
                        setShowRedimirBtn(false)
                        setPctDescCli(0)
                    })
                } 
                else{
                    setShowToast(true)
                        setToastMessage("Se guardó la información")
                        setServicio(undefined)
                        setResponsable(undefined)
                        setFecha("")
                        setFormaPago("")
                        setSubjetivo("")
                        setObjetivo({})
                        setInterpretacion("")
                        setPlan("")       
                        window.localStorage.removeItem("pacienteSelected")
                        resetForm()
                        setShowLoading(false)
                        setTipoPropietario("existente")
                        window.localStorage.removeItem("clienteSelected")
                        setShowRedimirBtn(false)
                        setPctDescCli(0)
                }               
            })
            /*
            var movimientos = isNullOrUndefined(window.localStorage["movimientos"])?
                []: JSON.parse(window.localStorage["movimientos"]);
            movimientos.push(movimientoData);
            window.localStorage["movimientos"] = JSON.stringify(movimientos);
            */            
        })
        /*
        //alert(JSON.stringify(infoServicio))
        if(window.localStorage["bitacoraServicios"] === undefined){            
            let newBitacora = [ infoServicio]
            window.localStorage["bitacoraServicios"] = JSON.stringify(newBitacora)
        }
        else{            
            var currentBitacora: InfoServicio[] = 
                JSON.parse(window.localStorage["bitacoraServicios"])
            currentBitacora.push(infoServicio)
            window.localStorage["bitacoraServicios"] = JSON.stringify(currentBitacora)
        }
        */        

    }

    function getPuntosForService(){
        if(tipoPropietario == "generico")
            return 0  
        else{
            if(pctDescCli == 0)
                return Math.round(total * pctAcumCli)
            else
                return metaPtosCli * -1
        }
        

    }

    function submitNewCliente(){
        if(nombreCli == "" || nombreCli === undefined){
            setShowToast(true)                            
            setToastMessage("Por favor, ingrese el nombre del cliente")
            return;
        }
        /* if(emailCli == "" || emailCli === undefined){
            setShowToast(true)                            
            setToastMessage(
                "Por favor, ingrese la dirección de correo del cliente")
            return;
        } */
        if(emailCli != "" && !validateEmail(emailCli)){
            setShowToast(true)
            setToastMessage("Por favor, ingrese una dirección de correo válida")
            return;
        }
        /* if(fechaNacCli == "" || fechaNacCli === undefined){
            setShowToast(true)                            
            setToastMessage(
                "Por favor, indique la fecha de nacimiento del cliente")
            return;
        } */
        if(telefonoCli == "" || telefonoCli === undefined){
            setShowToast(true)                            
            setToastMessage(
                "Por favor, ingrese el teléfono del cliente")
            return;
        }
        var clienteData = {
            id: "-1",
            nombre: nombreCli,
            email: emailCli,
            fechaNacimiento: fechaNacCli,
            telefono: telefonoCli
            //,fechaRegistro: (new Date()).toISOString()
        }
        setShowLoading(true);
        callNewCliente(clienteData).then(res=>{
            console.log(JSON.stringify(res))
            clienteData["id"] = res as string//JSON.stringify(res)
            window.localStorage["clienteSelected"] = JSON.stringify(clienteData)
            resetClienteForm()
            //setToastMessage("Se registró el cliente")
            //setShowToast(true)
            setshowNewPaciente(true)
            setShowLoading(false)
        }).catch(error=>console.log("Error al registrar al nuevo cliente: " + 
            JSON.stringify(error)))
        setShowNewCliente(false)

    }

    function submitNewPaciente(){
        if(nombrePac == "" || nombrePac === undefined){
            setShowToast(true)                            
            setToastMessage("Por favor, ingrese el nombre del paciente")
            return;
        }
        setShowLoading(true)
        const propietario = JSON.parse(window.localStorage["clienteSelected"])
        var pacienteData={
            nombre: nombrePac,
            fechaNacimiento: fechaNacPac,
            especie: especie,
            raza: raza,
            sexo: sexo,
            color: color,
            edadAcogida: edadAcogida,
            fechaAcogida: fechaAcogida,
            nombrePropietario: propietario.nombre,
            idPropietario: propietario.id,
            id: "-1"
        }
        callNewPaciente(pacienteData).then(res=>{
            console.log("resultado callNewPaciente: " + JSON.stringify(res))
            pacienteData["id"] = res as string
            window.localStorage["pacienteSelected"] = 
                JSON.stringify(pacienteData)
            resetPacienteForm()
            
            //setTipoPropietario("newPacExtCli")
            //setNewPacExtCli(true)
            
            //setToastMessage("Se registró el paciente")
            //setShowToast(true)                        
            /*callgetPacientesByCliente(propietario.id).then(
                res=>{
                    console.log("pacientes recibidos: " + 
                    JSON.stringify(res))
                    setPacientes(res as any[])
                    setShowLoading(false)
                }
            ).catch(error=>{
                setToastMessage("Error al consultar pacientes")
                setShowToast(true)
                console.log("error al consultar pacientes: " + 
                    JSON.stringify(error))
                setShowLoading(false)
                })*/
            setShowLoading(false) 
        })
        setshowNewPaciente(false)
    }

    const validateEmail = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regex.test(email);
    }

    function getPacienteObj(){
        if(window.localStorage['pacienteSelected'] === undefined)
            return undefined 
        const pacienteSelected = 
            JSON.parse(window.localStorage['pacienteSelected'])
        return {
            id: pacienteSelected.id,
            nombre: pacienteSelected.nombre
        }
    }
    function getClienteObj(){
        if(window.localStorage['clienteSelected'] === undefined)
            return undefined 
        const clienteSelected = 
            JSON.parse(window.localStorage['clienteSelected'])
        return {
            id: clienteSelected.id,
            nombre: clienteSelected.nombre
        }
    }

    function getPacienteById(id:string){
        let objReturn: any = {}
        console.log("getPacienteById pacientes.length: " + pacientes.length)
        for (let index = 0; index < pacientes.length; index++) {            
            const curPac = pacientes[index];
            console.log("getPacienteById curPac.id: " + curPac.id)
            if(curPac.id == id)
            objReturn = curPac
        }
        console.log("getPacienteById objReturn: " + JSON.stringify(objReturn))
        return objReturn
    }

    function getNewMovimientoId(){
        var idReturn: number = -1;
        if(isNullOrUndefined(window.localStorage['movimientoNewId']))
            idReturn = 1;
        else
            idReturn = Number.parseInt(window.localStorage['movimientoNewId']);
        window.localStorage['movimientoNewId'] = idReturn+1;
        return idReturn;
    }
    function getNewBitacoraId(){
        var idReturn: number = -1;
        if(isNullOrUndefined(window.localStorage['bitacoraNewId']))
            idReturn = 1;
        else
            idReturn = Number.parseInt(window.localStorage['bitacoraNewId']);
        window.localStorage['bitacoraNewId'] = idReturn+1;
        return idReturn;
    }

    function validateKeyCurrency(event: any){        
        const regex = /^[0-9]|\./        
        if(!regex.test(event.data))
            event.preventDefault();
    }

    function isCurProdGranel(){
        if(!window.localStorage["productoSelected"])
            return false;
        const prod = JSON.parse(window.localStorage["productoSelected"])
        if(prod.granel == true)
            return true;
        else
            return false;
    }

    function getUnidadGranel(){
        if(!window.localStorage["productoSelected"]){
            //console.log("getUnidadGranel no hay producto seleccionado")
            return ""
        }
        const prod = JSON.parse(window.localStorage["productoSelected"])
        if(prod.granel == true){
            //console.log("getUnidadGranel sí es granel: " + prod.unidad)
            return prod.unidad;
        }            
        else{
            //console.log(
            //    "getUnidadGranel el producto seleccionado no se vende a granel")
            return "";
        }
    }

    function validateKey(event: any){                
        //console.log("validateKey data: " + event.data + 
        //" test: " + /\d/.test(event.data))        
        if(!/\d/.test(event.data))
            event.preventDefault();            
    }

    function changeTipoPropietario(tipo: string){
        setTipoPropietario(tipo)
        if(tipo=="generico"){
            const cli = {'nombre':'', id:'-1'}
            const pac = {'nombre':'', id:'-1'}
            window.localStorage["clienteSelected"] =
                JSON.stringify(cli)
            window.localStorage["pacienteSelected"] =
                JSON.stringify(pac)
        }
        if(tipo!="existente")
            setPctDescCli(0)
        else
            window.localStorage.removeItem("clienteSelected")
    }

    function getPropietarioExistente(){
        //console.log("getPropietarioExistente: " +  tipoPropietario)
        return tipoPropietario == "existente"
    }

    function getPropietarioNuevo(){
        return tipoPropietario == "nuevo"
    }
    function getPropietarioGenerico(){
        return tipoPropietario == "generico"
    }
    const extrasCols = [
        {
            name: '',
            selector: (extra: any) =>
                <IonFabButton onClick={()=>removeExtra(extra)} color="danger" size="small">
                    <IonIcon icon={trash}/>
                </IonFabButton>,
            grow: 0.2
        },
        {
            name: 'Concepto',
            selector: (extra: any) => extra.nombre,
            grow: 2
        },
        {
            name: 'Cargo',
            selector: (extra: any) => formatter.format(extra.monto),
            grow: 1
        }        
    ]

    const articulosCols = [
        {
            name: '',
            selector: (art: any) =>
                <IonFabButton onClick={()=>removeArticulo(art)} color="danger" size="small">
                    <IonIcon icon={trash}/>
                </IonFabButton>,
            grow: 0.2
        },
        {
            name: 'Producto',
            selector: (art: any) => art.producto.nombre,
            grow: 2
        },
        {

            name: 'Cantidad',
            selector: (art: any) => art.cantidad
        },
        {
            name: 'Cargo',
            selector: (art: any) => formatter.format(art.cantidad * art.precioVenta),
            grow: 1
        }        
    ]

    const artUsoIntCols = [
        {
            name: '',
            selector: (art: any) =>
                <IonFabButton onClick={()=>removeArtUsoInt(art)} color="danger" size="small">
                    <IonIcon icon={trash}/>
                </IonFabButton>,
            grow: 0.2
        },
        {
            name: 'Producto',
            selector: (art: any) => art.producto.nombre,
            grow: 2
        },
        {

            name: 'Cantidad',
            selector: (art: any) => art.cantidad
        }                
    ]

    if(!checkAccessToModule('Registro Servicio'))
        return (<Redirect to="/pages/home"/>)
    
    function getPacientesAndSaldo() {
        let curCli = JSON.parse(window.localStorage["clienteSelected"]) 
        callGetPacientesByCliente(curCli.id).then(
            res=>{
                const arr = res as any[]
                //console.log(
                //    "getPacientesAndSaldo pacientes recibidos para el cliente " + curCli.id + 
                //    ": " + JSON.stringify(arr))                
                setPacientes(arr)
                setShowLoading(false)
                callGetPuntosCliente(curCli.id, curCli.puntosIniciales, 
                    curCli.fechaRegistro).then(
                    res2=>{
                        console.log("callGetPuntosCliente recibido: " + 
                        JSON.stringify(res2))
                        const objReturned = res2 as any
                        setSaldoCliente(objReturned.saldo as number)
                        setShowSaldo(true)
                        setShowRedimirBtn(objReturned.saldo>=objReturned.objetivo)
                        setPctDescCli(0)
                        setPctAcumCli(objReturned.pctAcum)
                        setMetaPtosCli(objReturned.objetivo)
                        //console.log("getPacientesAndSaldo pacientes: " + 
                        //    JSON.stringify(pacientes))
                        if(!isNullOrUndefined(props.match.params.pacienteID)){
                            console.log("no es nulo el pacienteID: " + 
                                props.match.params.pacienteID
                            )
                            window.localStorage["pacienteSelected"] = JSON.stringify(
                                getPacienteById(props.match.params.pacienteID))
                        }
                    }
                )
            }
        ).catch(error=>console.log("error al consultar pacientes: " +  JSON.stringify(error)))
    }
    if(isPlatform("desktop"))
    return(
        <IonPage>
            <IonContent>
                <IonLoading
                    isOpen={showLoading}
                    message="Guardando..."
                    />
                <IonHeader>
                    <IonToolbar>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="8">
                                    <IonTitle>REGISTRO DE SERVICIO</IonTitle>
                                </IonCol>
                            </IonRow>
                        </IonGrid>  
                        <IonMenuButton slot="start"></IonMenuButton>    
                    </IonToolbar>
                </IonHeader>
                <IonCard>
                    {/* <IonCardTitle>
                        <IonCardHeader>
                        Registro de Servicio
                        </IonCardHeader>                    
                    </IonCardTitle> */}
                    <IonCardContent>
                        <form>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="2">
                                        Servicio
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonSelect value={props.match.params.servicio || servicio}
                                            disabled={props.match.params.servicio!==undefined}
                                            onIonChange={servicioChange}>
                                        {servicios.map((svc)=>
                                                <IonSelectOption value={svc.nombre}
                                                    key={svc.nombre}>
                                                    {svc.nombre}
                                                </IonSelectOption>   
                                            )}
                                        </IonSelect>
                                    </IonCol>
                                    <IonCol size="2">
                                        Responsable
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonSelect placeholder="Seleccione un@" 
                                            value={props.match.params.responsable || responsable}
                                            onIonChange={responsableChange}>
                                            {colaboradores.map((colab)=>
                                                <IonSelectOption value={colab.nombre}
                                                    key={colab.nombre}>
                                                    {colab.nombre}
                                                </IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="2">
                                        Fecha
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput type="date" value={fecha}
                                            onIonChange={fechaChange}/>
                                    </IonCol>
                                    <IonCol hidden={!getMontoVisibility()} size="2">
                                        Cuota
                                    </IonCol>
                                    <IonCol hidden={!getMontoVisibility()} size="4">
                                        <IonInput                                             
                                            onIonChange={montoChange}
                                            value={ monto == 0 ? null : 
                                                    monto?.toString() }
                                            onBeforeInput={validateKeyCurrency}
                                            clearInput={true}                                            
                                            />
                                    </IonCol>                                
                                    <IonCol hidden={!getTarifasVisibility()} size="2">
                                        Tarifa
                                    </IonCol>
                                    <IonCol hidden={!getTarifasVisibility()} size="4">
                                    <IonSelect placeholder="Seleccione una" 
                                            value={tarifa}
                                            onIonChange={tarifaChange}>
                                            {tarifas.map((tarifa)=>
                                                <IonSelectOption value={tarifa.nombre}>
                                                    {tarifa.nombre + " - " + 
                                                        formatter.format(tarifa.monto)} 
                                                </IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="2">
                                        Forma de Pago
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonSelect placeholder="Seleccione una"
                                        onIonChange={formaPagoChange}
                                        value={formaPago}>
                                            {
                                            formasPago.map(fp=>
                                                <IonSelectOption value={fp.nombre}
                                                    key={fp.nombre}>
                                                    {fp.nombre}
                                                </IonSelectOption>)
                                            }
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="2" hidden={!getPropietarioVisibility()}>
                                        Propietario
                                    </IonCol>
                                    <IonCol size="6" hidden={!getPropietarioVisibility()}>
                                        <IonGrid>
                                            <IonRow>
                                                <IonRadioGroup>
                                                    <IonCol>
                                                        <IonLabel>
                                                            Registrado
                                                        </IonLabel>
                                                        <IonRadio checked={getPropietarioExistente()}
                                                            onClick={()=>
                                                            changeTipoPropietario("existente")                                                            
                                                        }/>
                                                    </IonCol>
                                                    <IonCol>
                                                        <IonLabel>
                                                            Nuevo
                                                        </IonLabel>
                                                        <IonRadio checked={getPropietarioNuevo()} 
                                                            onClick={()=>{
                                                                changeTipoPropietario("nuevo")
                                                                setShowNewCliente(true)
                                                            }
                                                        }/>
                                                    </IonCol>
                                                    <IonCol>
                                                        <IonLabel>
                                                            No registrar
                                                        </IonLabel>
                                                        <IonRadio checked={getPropietarioGenerico()} onClick={()=>
                                                            changeTipoPropietario("generico")}/>
                                                    </IonCol>
                                                </IonRadioGroup>

                                            </IonRow>                                            
                                        </IonGrid>                                            
                                    </IonCol> 
                                    <IonCol>
                                        <IonInput type="search" hidden={!getPropietarioExistente()&&!newPacExtCli}
                                                placeholder="Ingrese el propietario"
                                                value=
                                                    {isNullOrUndefined(
                                                        window.localStorage["clienteSelected"])?"Seleccione un propietario" : 
                                                        JSON.parse(window.localStorage["clienteSelected"]).nombre}
                                                onIonFocus={()=>setShowPickPropietario(true)}                                     
                                                />
                                        <IonInput hidden={getPropietarioExistente()} disabled
                                            value=
                                            {isNullOrUndefined(window.localStorage["clienteSelected"])?
                                                "" : 
                                                JSON.parse(window.localStorage["clienteSelected"]).nombre}/>
                                    </IonCol>                                    
                                </IonRow>
                                <IonRow>                                
                                    <IonCol size="2" hidden={!getPacienteVisibility()}>
                                        Paciente
                                    </IonCol>
                                    <IonCol size="4" hidden={!getPacienteVisibility()}>
                                        {/* <IonInput type="search" hidden={!getPropietarioExistente()}
                                            placeholder="Ingrese el paciente"
                                            value=
                                                {isNullOrUndefined(
                                                    window.localStorage["pacienteSelected"])?"Seleccione un paciente" : 
                                                    JSON.parse(window.localStorage["pacienteSelected"]).nombre}
                                            onIonFocus={()=>setShowPickPaciente(true)}                                     
                                            ></IonInput> */}
                                        <IonSelect hidden={!getPropietarioExistente()||
                                            getPropietarioExistente()&&newPacExtCli} 
                                             value={isNullOrUndefined(
                                                window.localStorage["pacienteSelected"])?"Seleccione un paciente" : 
                                                JSON.parse(window.localStorage["pacienteSelected"]).id}
                                            onIonChange={pacienteChanged}
                                            disabled={isNullOrUndefined(
                                                window.localStorage["clienteSelected"])}>
                                        {
                                        pacientes.map(p=>
                                            <IonSelectOption value={p.id}>
                                                {p.nombre}
                                            </IonSelectOption>)
                                        }
                                            <IonSelectOption value="nuevo">
                                                Nuevo...
                                            </IonSelectOption>
                                        </IonSelect>
                                        <IonInput hidden={getPropietarioExistente()&&!newPacExtCli} disabled
                                            value=
                                            {isNullOrUndefined(window.localStorage["pacienteSelected"])?
                                                "" : 
                                                JSON.parse(window.localStorage["pacienteSelected"]).nombre}/>
                                    </IonCol>
                                    <IonCol size="2" hidden={!getCelVisibility()}>
                                        Tel. Contacto
                                    </IonCol>
                                    <IonCol size="4" hidden={!getCelVisibility()}>
                                        <IonInput value={celPropietario} 
                                            onIonChange={celPropietarioChange} />
                                    </IonCol>
                                </IonRow>                            
                                <IonRow hidden={!getSoipVisibility() }>
                                <IonCol>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="2">
                                                Subjetivo
                                            </IonCol>
                                            <IonCol>
                                                <IonTextarea className="textarea" 
                                                    value={subjetivo}
                                                    onIonChange={subjetivoChange} />
                                            </IonCol>                                            
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size="2">
                                                Objetivo
                                            </IonCol>
                                            <IonCol>
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol>
                                                            EM
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.EM}
                                                                onIonChange={emChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            RT
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.RT}
                                                                onIonChange={rtChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            PP
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.PP}
                                                                onIonChange={ppChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            T
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.T}
                                                                onIonChange={tChange} />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            MM
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.MM}
                                                                onIonChange={mmChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            RD
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.RD}
                                                                onIonChange={rdChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            CP
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.CP}
                                                                onIonChange={cpChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            Peso
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.Peso}
                                                                onIonChange={pesoChange} />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            TLLC
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.TLLC}
                                                                onIonChange={tllcChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            FC
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.FC}
                                                                onIonChange={fcChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            PA
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.PA}
                                                                onIonChange={paChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            CC
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.CC}
                                                                onIonChange={ccChange} />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            LN
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.LN}
                                                                onIonChange={lnChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            FR
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.FR}
                                                                onIonChange={frChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            Pulso
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.Pulso}
                                                                onIonChange={pulsoChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            DH
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.DH}
                                                                onIonChange={dhChange} />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="2">
                                                            Observaciones
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonTextarea className="textarea" 
                                                                value={objetivo.Observaciones}
                                                                onIonChange={observacionesChange} />
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                            </IonCol>                                            
                                        </IonRow>                                        
                                    </IonGrid>
                                    </IonCol>                                
                                </IonRow>
                                <IonRow hidden={!getSoipVisibility() }>
                                    <IonCol size="2">
                                        Interpretación
                                    </IonCol>
                                    <IonCol>
                                        <IonTextarea className="textarea" 
                                            value={interpretacion}
                                            onIonChange={interpretacionChange} />
                                    </IonCol>
                                </IonRow>
                                <IonRow hidden={!getSoipVisibility() }>
                                    <IonCol size="2">
                                        Plan
                                    </IonCol>
                                    <IonCol>
                                        <IonTextarea className="textarea" value={plan}
                                            onIonChange={planChange} />
                                    </IonCol>
                                </IonRow>
                                <IonRow hidden={!getProductosVisibility()}>                                
                                    <IonCol size="2">Artículos:</IonCol>
                                    <IonCol size="10">  
                                        
                                    <DataTable columns={articulosCols} data={articulos}
                                            noDataComponent={
                                                <IonGrid>                                                    
                                                    <IonRow>
                                                        <IonCol size="4"></IonCol>
                                                        <IonCol>
                                                            No se han agregado artículos
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow><IonCol>&nbsp;</IonCol></IonRow>
                                                </IonGrid>
                                            }/>
                                        <IonFab vertical="top" horizontal="end">
                                            <IonFabButton onClick={()=>setShowAddArt(true)}
                                                color="success">
                                                <IonIcon icon={add} />
                                            </IonFabButton>
                                        </IonFab>                                                                        
                                    </IonCol>
                                </IonRow>
                                <IonRow hidden={!getExtrasVisibility()}>                                
                                    <IonCol size="2">Vacunas:</IonCol>
                                    <IonCol size="10">                                            
                                        <DataTable columns={extrasCols} data={extras}
                                            noDataComponent={
                                                <IonGrid>                                                    
                                                    <IonRow>
                                                        <IonCol size="4"></IonCol>
                                                        <IonCol>
                                                            No se han agregado extras
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow><IonCol>&nbsp;</IonCol></IonRow>
                                                </IonGrid>
                                            }/>
                                        <IonFab vertical="top" horizontal="end">
                                            <IonFabButton onClick={()=>setShowAddExtra(true)}
                                                color="success">
                                                <IonIcon icon={add} />
                                            </IonFabButton>
                                        </IonFab>                                                                
                                    </IonCol>
                                </IonRow>
                                {/* Inicio Uso Interno */}
                                <IonRow hidden={!getUsoInternoVisibility()}>                                
                                    <IonCol size="2">Uso Interno:</IonCol>
                                    <IonCol size="10">  
                                        
                                    <DataTable columns={artUsoIntCols} data={artUsoInt}
                                            noDataComponent={
                                                <IonGrid>                                                    
                                                    <IonRow>
                                                        <IonCol size="4"></IonCol>
                                                        <IonCol>
                                                            No se han agregado artículos
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow><IonCol>&nbsp;</IonCol></IonRow>
                                                </IonGrid>
                                            }/>
                                        <IonFab vertical="top" horizontal="end">
                                            <IonFabButton onClick={()=>setShowAddArtUsoInt(true)}
                                                color="success">
                                                <IonIcon icon={add} />
                                            </IonFabButton>
                                        </IonFab>                                                                        
                                    </IonCol>
                                </IonRow>
                                {/* Fin Uso Interno */}
                                <IonRow>
                                    <IonCol className="totalLabel" size="8">
                                        Total
                                    </IonCol>
                                    <IonCol className="totalValue">
                                        {formatter.format(total)}
                                    </IonCol>
                                </IonRow>
                                <IonRow hidden={!showSaldo}>
                                    <IonCol size='3'>
                                        &nbsp;
                                    </IonCol>
                                    <IonCol size="3" className='formKeyLabel'>
                                        Saldo actual del cliente:
                                    </IonCol>
                                    <IonCol size='2' className='formKeyLabel'>
                                        {saldoCliente} huellitas
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonButton hidden={!showRedimirBtn}
                                            onClick={
                                                ()=>setShowRedimirPopup(true)}>
                                            {pctDescCli > 0 ? "-" + pctDescCli + "%"
                                                : "Redimir"}
                                        </IonButton>
                                    </IonCol>
                                    <IonCol className="totalValue">
                                        { pctDescCli > 0 ? 
                                            formatter.format(
                                                total * (1 - (pctDescCli * 0.01))) 
                                                : ""}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="5"/>
                                    <IonCol>
                                        <IonButton onClick={submitServicio}>
                                            Guardar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </form>
                    </IonCardContent>
                </IonCard>
                <IonModal isOpen={showAddArt} backdropDismiss={false}>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>
                                Datos del artículo
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow>
                                <IonCol size='4'>Producto:</IonCol>
                                <IonCol size="8">
                                <IonInput type="search" 
                                    placeholder="Ingrese el producto"
                                    value=
                                        {isNullOrUndefined(
                                            window.localStorage["productoSelected"])?"Seleccione un producto" : 
                                            JSON.parse(window.localStorage["productoSelected"]).nombre}
                                    onIonFocus={()=>setShowPickProducto(true)}                                     
                                />
                                </IonCol>
                                </IonRow>
                                <IonRow hidden={!isCurProdGranel()}>
                                    <IonCol>
                                        Venta parcial
                                    </IonCol>
                                    <IonCol>
                                        <IonCheckbox checked={granel}
                                         onIonChange={granelChanged}/>                                        
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='4'>
                                        Precio Unitario:
                                    </IonCol>
                                    <IonCol size='8'>
                                        <IonInput type="number" value={precioVenta.toString()}
                                            onIonChange={precioVentaChange}/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                <IonCol size='4'>Cantidad:</IonCol>
                                <IonCol size={cantidadSizeCol}>
                                    <IonInput value={cantidad.toString()} 
                                        type="number" 
                                        onIonChange={cantidadChange}
                                        // onBeforeInput={validateKey}
                                        />                                    
                                </IonCol>
                                <IonCol size='4'>
                                <IonLabel hidden={
                                        !granel}>
                                            {getUnidadGranel()}
                                    </IonLabel>
                                </IonCol>
                                </IonRow>                                
                                <IonRow>
                                    <IonCol>
                                        <IonButton onClick={()=>submitArticulo(true)}>Agregar</IonButton>
                                        <IonButton onClick={()=>cancelAddProduct()} color="medium">
                                            Cancelar</IonButton>
                                    </IonCol>
                                </IonRow>                                
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </IonModal>
                {/* Inicio Uso Interno */}
                <IonModal isOpen={showAddArtUsoInt} backdropDismiss={false}>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>
                                Datos del artículo
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow>
                                <IonCol size='4'>Producto:</IonCol>
                                <IonCol size="8">
                                <IonInput type="search" 
                                    placeholder="Ingrese el producto"
                                    value=
                                        {isNullOrUndefined(
                                            window.localStorage["productoSelected"])?"Seleccione un producto" : 
                                            JSON.parse(window.localStorage["productoSelected"]).nombre}
                                    onIonFocus={()=>setShowPickProducto(true)}                                     
                                />
                                </IonCol>
                                </IonRow>
                                <IonRow hidden={!isCurProdGranel()}>
                                    <IonCol>
                                        Venta parcial
                                    </IonCol>
                                    <IonCol>
                                        <IonCheckbox checked={granel}
                                         onIonChange={granelChanged}/>                                        
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                <IonCol size='4'>Cantidad:</IonCol>
                                <IonCol size={cantidadSizeCol}>
                                    <IonInput value={cantidad.toString()} 
                                        type="number" 
                                        onIonChange={cantidadChange}
                                        // onBeforeInput={validateKey}
                                        />                                    
                                </IonCol>
                                <IonCol size='4'>
                                <IonLabel hidden={
                                        !granel}>
                                            {getUnidadGranel()}
                                    </IonLabel>
                                </IonCol>
                                </IonRow>                                
                                <IonRow>
                                    <IonCol>
                                        <IonButton onClick={()=>submitArtUsoInt(true)}>Agregar</IonButton>
                                        <IonButton onClick={()=>cancelAddProdUsoInt()} color="medium">
                                            Cancelar</IonButton>
                                    </IonCol>
                                </IonRow>                                
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </IonModal>
                {/* Fin Uso Interno */}
                <IonModal isOpen={showPickProducto} backdropDismiss={false}>
                    <EntitySearch tipoEntidad="producto"/>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={()=>setShowPickProducto(false)} 
                                    color="medium">
                                Cancelar
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={()=>setShowPickProducto(false)}>
                                    Continuar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>                                        
                </IonModal>
                <IonModal isOpen={showPickPaciente} backdropDismiss={false}>
                    <EntitySearch tipoEntidad="pacienteVet"/>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={()=>setShowPickPaciente(false)} 
                                    color="medium">
                                Cancelar
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={()=>{
                                    setShowPickPaciente(false);
                                    //setPaciente(JSON.parse(
                                    //        window.localStorage["pacienteSelected"]))
                                    }}>
                                    Continuar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>                                        
                </IonModal>
                <IonModal isOpen={showPickPropietario} backdropDismiss={false}>
                    <EntitySearch tipoEntidad="cliente"/>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={()=>setShowPickPropietario(false)} 
                                    color="medium">
                                Cancelar
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={()=>{
                                    setShowPickPropietario(false);
                                    setShowLoading(true)
                                    getPacientesAndSaldo()
                                    //setPaciente(JSON.parse(
                                    //        window.localStorage["pacienteSelected"]))
                                    }}>
                                    Continuar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>                                        
                </IonModal>
                <IonModal isOpen={showAddExtra} backdropDismiss={false}>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>
                                Añadir extra
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>Concepto:</IonCol>
                                    <IonCol>
                                        <IonSelect placeholder="Elija uno"
                                            value={extra}
                                            onIonChange={extraChange}>
                                            {extrasDisponibles.map((ext)=>
                                                <IonSelectOption value={ext.nombre}>
                                                    {ext.nombre}
                                                </IonSelectOption>   
                                            )}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>                                                                
                                <IonRow>
                                    <IonCol>
                                        <IonButton onClick={submitExtra}>Agregar</IonButton>
                                        <IonButton onClick={()=>cancelAddExtra()} color="medium">
                                            Cancelar</IonButton>
                                    </IonCol>
                                </IonRow>                                
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </IonModal>
                <IonModal isOpen={showNewCliente} backdropDismiss={false}>
                    <IonContent>
                    <form>
                    <IonToolbar>
                        <IonTitle>Datos del cliente</IonTitle>
                    </IonToolbar>                
                    <IonGrid>
                        <IonRow>
                            <IonCol size="3.5">
                                Nombre Completo
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={nombreCliChange}
                                    value={nombreCli}/>
                            </IonCol>
                        </IonRow>
                        {/* <IonRow>
                            <IonCol size="3.5">
                            Fecha de Nacimiento
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={fechaNacCliChange}
                                    value={fechaNacCli} type="date"/>
                            </IonCol>
                        </IonRow> */}
                        <IonRow>
                            <IonCol size="3.5" className="colAlineada">
                                Correo Electrónico
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={emailCliChange}
                                    value={emailCli} type="email"/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="3.5" className="colAlineada">
                                Teléfono
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={telefonoCliChange}
                                    value={telefonoCli}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={()=>{
                                    setShowNewCliente(false)
                                    resetClienteForm()
                                }} 
                                color="medium">Cancelar</IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={submitNewCliente}>
                                    Guardar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    </form>
                    </IonContent>
                </IonModal>
                {/***** Modal nuevo paciente *****/}
                <IonModal isOpen={showNewPaciente} backdropDismiss={false}>
                    <IonContent>
                    <IonLoading 
                        isOpen={showLoading}
                        message="Guardando..."
                    />
                    <form>
                        <IonToolbar>
                            <IonTitle>Datos del paciente</IonTitle>
                        </IonToolbar>                
                        <IonGrid>
                            <IonRow>
                                <IonCol size="3.5">
                                    Nombre
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonInput onIonChange={nombrePacChange}
                                        value={nombrePac}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5">
                                Fecha de Nacimiento
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonInput onIonChange={fechaNacPacChange}
                                        value={fechaNacPac} type="date"/>
                                </IonCol>
                            </IonRow>                            
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Especie
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonSelect onIonChange={especieChange} 
                                        value={especie}>
                                    {especies.map((esp:any)=>
                                        <IonSelectOption value={esp.nombre}
                                            key={esp.id}>
                                            {esp.nombre}</IonSelectOption>)}
                                    </IonSelect>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Raza
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonSelect onIonChange={razaChange} value={raza}>
                                    {razasFiltradas?.map((raza:any)=>
                                        <IonSelectOption value={raza.nombre}>{raza.nombre}</IonSelectOption>)}
                                    </IonSelect>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Sexo
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonSelect onIonChange={sexoChange} value={sexo}>
                                        <IonSelectOption value="hembra">
                                            Hembra
                                        </IonSelectOption>
                                        <IonSelectOption value="macho">
                                            Macho
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Peso
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonInput onIonChange={pesoChange}
                                        value={peso}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Color
                                </IonCol>
                                <IonCol size="8.5">
                                <IonSelect onIonChange={colorChange} value={color}>
                                    {colores.map((color:any)=>
                                        <IonSelectOption value={color.nombre}
                                         key={color.nombre}>{color.nombre}</IonSelectOption>)}
                                    </IonSelect>
                                </IonCol>
                            </IonRow>                                                                                                               
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Edad al acogerlo
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonInput onIonChange={edadAcogidaChange}
                                        value={edadAcogida}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5">
                                Fecha de acogida
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonInput onIonChange={fechaAcogidaChange}
                                        value={fechaAcogida} type="date"/>
                                </IonCol>
                            </IonRow>                            
                            <IonRow>
                                <IonCol>
                                <IonButton onClick={()=>{
                                    setshowNewPaciente(false)
                                    setNewPacExtCli(false)
                                }} 
                                color="medium">Cancelar</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={submitNewPaciente}>Guardar</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>                                
                        </form> 
                    </IonContent>
                </IonModal>
                {/***** Modal nuevo paciente *****/}
                {/***** Modal redimir puntos *****/}
                <IonModal isOpen={showRedimirPopup} backdropDismiss={false}>
                    <IonContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    Ingrese el porcentaje de descuento a aplicar
                                </IonCol>
                            </IonRow>
                            <IonRow>    
                                <IonCol>
                                    <IonInput onIonChange={pctDescChange}
                                            value={ pctDescCli == 0 ? null : 
                                                    pctDescCli?.toString() }
                                            onBeforeInput={validateKeyCurrency}
                                            clearInput={true}                                            />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                <IonButton onClick={()=>{
                                    setShowRedimirPopup(false)
                                    setPctDescCli(0)                                 
                                }} 
                                color="medium">Cancelar</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={()=>{
                                        setShowRedimirPopup(false)
                                    }}>Guardar</IonButton>
                                </IonCol>
                            </IonRow>                            
                        </IonGrid>

                    </IonContent>
                </IonModal>
                {/***** Modal redimir puntos *****/}
                <IonToast isOpen={showToast} message={toastMessage}
                    onDidDismiss={()=>setShowToast(false)} duration={1500} position="middle"/>
                <IonAlert isOpen={showInvAlert} header='Precaución'
                    message='No hay existencias suficientes en el inventario ¿desea continuar?'
                    buttons={[
                        {
                          text: 'No',
                          role: 'cancel',
                          handler: () => {
                            console.log('Alert canceled');
                            setShowInvAlert(false)
                          },
                        },
                        {
                          text: 'Sí',
                          role: 'confirm',
                          handler: () => {
                            console.log('Alert confirmed');
                            setShowInvAlert(false)                            
                            submitArticulo(false)
                          },
                        },
                      ]}
                />
            </IonContent>
        </IonPage>
        );    
    else
    return(
        <IonPage>
            <IonContent>
                <IonLoading
                    isOpen={showLoading}
                    message="Guardando..."
                    />
                <IonHeader>
                    <IonToolbar>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="8">
                                    <IonTitle>REGISTRO DE SERVICIO</IonTitle>
                                </IonCol>
                            </IonRow>
                        </IonGrid>  
                        <IonMenuButton slot="start"></IonMenuButton>    
                    </IonToolbar>
                </IonHeader>
                <IonCard>
                    {/* <IonCardTitle>
                        <IonCardHeader>
                        Registro de Servicio
                        </IonCardHeader>                    
                    </IonCardTitle> */}
                    <IonCardContent>
                        <form>
                            <IonGrid>
                                <IonRow>
                                    {/* <IonCol size="2">
                                        Servicio
                                    </IonCol> */}
                                    <IonCol>
                                        <IonSelect value={props.match.params.servicio || servicio}
                                            disabled={props.match.params.servicio!==undefined}
                                            onIonChange={servicioChange}
                                            placeholder='Tipo de servicio'>
                                        {servicios.map((svc)=>
                                                <IonSelectOption value={svc.nombre}>
                                                    {svc.nombre}
                                                </IonSelectOption>   
                                            )}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>                                    
                                    <IonCol>
                                        <IonSelect placeholder="Responsable" 
                                            value={props.match.params.responsable || responsable}
                                            onIonChange={responsableChange}>
                                            {colaboradores.map((colab)=>
                                                <IonSelectOption value={colab.nombre}>
                                                    {colab.nombre}
                                                </IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>                                    
                                    <IonCol size="12" style={{"background":"white"}}>                                    
                                        <IonDatetime placeholder='Fecha'
                                            displayFormat="DD/MM/YYYY"
                                            value={fecha}
                                            onIonChange={fechaChange}/>

                                         {/* <IonInput type="search" readonly
                                            value={fecha==""?undefined:                                                                                  
                                            (new Date(fecha)).toLocaleDateString()}
                                            onIonFocus={()=>
                                                setShowFecha(true)}
                                                placeholder="Fecha"  /> */}
                                        {/* <IonModal
                                            isOpen={showFecha}>
                                            <IonContent>                                                
                                                <IonDatetime placeholder='Fecha'
                                                    displayFormat="DD/MM/YYYY"
                                                    value={fecha}
                                                    onIonChange={fechaChange}/>                             
                                            </IonContent>                                            
                                        </IonModal>  */}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol hidden={!getMontoVisibility()}>
                                        <IonInput                                             
                                            onIonChange={montoChange}
                                            value={ monto == 0 ? null : 
                                                    monto?.toString() }
                                            onBeforeInput={validateKeyCurrency}
                                            clearInput={true}                                            
                                            placeholder='Cuota'/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>                                
                                    <IonCol hidden={!getTarifasVisibility()} size="12">
                                    <IonSelect placeholder="Tarifa" 
                                            value={tarifa}
                                            onIonChange={tarifaChange}>
                                            {tarifas.map((tarifa)=>
                                                <IonSelectOption value={tarifa.nombre}>
                                                    {tarifa.nombre + " - " + 
                                                        formatter.format(tarifa.monto)} 
                                                </IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>                                    
                                    <IonCol>
                                        <IonSelect placeholder="Forma de pago"
                                        onIonChange={formaPagoChange}
                                        value={formaPago}>
                                            {
                                            formasPago.map(fp=>
                                                <IonSelectOption value={fp.nombre}>
                                                    {fp.nombre}
                                                </IonSelectOption>)
                                            }
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12" hidden={!getPropietarioVisibility()}>
                                        Propietario
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12" hidden={!getPropietarioVisibility()}>
                                        <IonGrid>
                                            <IonRow>
                                                <IonRadioGroup>
                                                    <IonCol>
                                                        <IonLabel>
                                                            Registrado
                                                        </IonLabel>
                                                        <IonRadio checked={getPropietarioExistente()}
                                                            onClick={()=>
                                                            changeTipoPropietario("existente")                                                            
                                                        }/>
                                                    </IonCol>
                                                    <IonCol>
                                                        <IonLabel>
                                                            Nuevo
                                                        </IonLabel>
                                                        <IonRadio checked={getPropietarioNuevo()} 
                                                            onClick={()=>{
                                                                changeTipoPropietario("nuevo")
                                                                setShowNewCliente(true)
                                                            }
                                                        }/>
                                                    </IonCol>
                                                    <IonCol>
                                                        <IonLabel>
                                                            No registrar
                                                        </IonLabel>
                                                        <IonRadio checked={getPropietarioGenerico()} onClick={()=>
                                                            changeTipoPropietario("generico")}/>
                                                    </IonCol>
                                                </IonRadioGroup>
                                            </IonRow>                                            
                                        </IonGrid>                                            
                                    </IonCol> 
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonInput type="search" hidden={!getPropietarioExistente()&&!newPacExtCli}
                                                placeholder="Ingrese el propietario"
                                                value=
                                                    {isNullOrUndefined(
                                                        window.localStorage["clienteSelected"])?"Seleccione un propietario" : 
                                                        JSON.parse(window.localStorage["clienteSelected"]).nombre}
                                                onIonFocus={()=>setShowPickPropietario(true)}                                     
                                                />
                                        <IonInput hidden={getPropietarioExistente()} disabled
                                            value=
                                            {isNullOrUndefined(window.localStorage["clienteSelected"])?
                                                "" : 
                                                JSON.parse(window.localStorage["clienteSelected"]).nombre}/>
                                    </IonCol>                                    
                                </IonRow>
                                <IonRow>                                                                    
                                    <IonCol size="12" hidden={!getPacienteVisibility()}>
                                        {/* <IonInput type="search" hidden={!getPropietarioExistente()}
                                            placeholder="Ingrese el paciente"
                                            value=
                                                {isNullOrUndefined(
                                                    window.localStorage["pacienteSelected"])?"Seleccione un paciente" : 
                                                    JSON.parse(window.localStorage["pacienteSelected"]).nombre}
                                            onIonFocus={()=>setShowPickPaciente(true)}                                     
                                            ></IonInput> */}
                                        <IonSelect hidden={!getPropietarioExistente()||
                                            getPropietarioExistente()&&newPacExtCli} 
                                                value={isNullOrUndefined(
                                                window.localStorage["pacienteSelected"])?"Seleccione un paciente" : 
                                                JSON.parse(window.localStorage["pacienteSelected"]).id}
                                            onIonChange={pacienteChanged}
                                            disabled={isNullOrUndefined(
                                                window.localStorage["clienteSelected"])}
                                                placeholder='Seleccione un paciente'>
                                        {
                                        pacientes.map(p=>
                                            <IonSelectOption value={p.id}>
                                                {p.nombre}
                                            </IonSelectOption>)
                                        }
                                            <IonSelectOption value="nuevo">
                                                Nuevo...
                                            </IonSelectOption>
                                        </IonSelect>
                                        <IonInput hidden={getPropietarioExistente()&&!newPacExtCli} disabled
                                            value=
                                            {isNullOrUndefined(window.localStorage["pacienteSelected"])?
                                                "" : 
                                                JSON.parse(window.localStorage["pacienteSelected"]).nombre}/>
                                    </IonCol>
                                    <IonCol size="12" hidden={!getCelVisibility()}>                                    
                                        <IonInput value={celPropietario} 
                                            onIonChange={celPropietarioChange} 
                                            placeholder='Tel. Contacto'/>
                                    </IonCol>
                                </IonRow>                            
                                <IonRow hidden={!getSoipVisibility() }>
                                <IonCol>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size='12'>
                                                <IonTextarea className="textarea" 
                                                    value={subjetivo}
                                                    onIonChange={subjetivoChange} 
                                                    placeholder='Subjetivo'/>
                                            </IonCol>                                            
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size="12">
                                                Objetivo
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol>
                                                            EM
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.EM}
                                                                onIonChange={emChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            RT
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.RT}
                                                                onIonChange={rtChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            PP
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.PP}
                                                                onIonChange={ppChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            T
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.T}
                                                                onIonChange={tChange} />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            MM
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.MM}
                                                                onIonChange={mmChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            RD
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.RD}
                                                                onIonChange={rdChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            CP
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.CP}
                                                                onIonChange={cpChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            Peso
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.Peso}
                                                                onIonChange={pesoChange} />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            TLLC
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.TLLC}
                                                                onIonChange={tllcChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            FC
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.FC}
                                                                onIonChange={fcChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            PA
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.PA}
                                                                onIonChange={paChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            CC
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.CC}
                                                                onIonChange={ccChange} />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            LN
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.LN}
                                                                onIonChange={lnChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            FR
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.FR}
                                                                onIonChange={frChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            Pulso
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.Pulso}
                                                                onIonChange={pulsoChange} />
                                                        </IonCol>
                                                        <IonCol>
                                                            DH
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonInput value={objetivo.DH}
                                                                onIonChange={dhChange} />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="12">                                                        
                                                            <IonTextarea className="textarea" 
                                                                value={objetivo.Observaciones}
                                                                onIonChange={observacionesChange} 
                                                                placeholder='Observaciones'/>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                            </IonCol>                                            
                                        </IonRow>                                        
                                    </IonGrid>
                                    </IonCol>                                
                                </IonRow>
                                <IonRow hidden={!getSoipVisibility() }>
                                    <IonCol size='12'>
                                        <IonTextarea className="textarea" 
                                            value={interpretacion}
                                            onIonChange={interpretacionChange} 
                                            placeholder='Interpretación'/>
                                    </IonCol>
                                </IonRow>
                                <IonRow hidden={!getSoipVisibility() }>
                                    <IonCol size='12'>
                                        <IonTextarea className="textarea" value={plan}
                                            onIonChange={planChange} 
                                            placeholder='Plan'/>
                                    </IonCol>
                                </IonRow>
                                <IonRow hidden={!getProductosVisibility()}>                                
                                    <IonCol size="12">Artículos:</IonCol>
                                </IonRow>
                                <IonRow hidden={!getProductosVisibility()}>
                                    <IonCol size="12">  
                                        
                                    <DataTable columns={articulosCols} data={articulos}
                                            noDataComponent={
                                                <IonGrid>                                                    
                                                    <IonRow>
                                                        <IonCol size="4"></IonCol>
                                                        <IonCol>
                                                            No se han agregado artículos
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow><IonCol>&nbsp;</IonCol></IonRow>
                                                </IonGrid>
                                            }/>
                                        <IonFab vertical="top" horizontal="end">
                                            <IonFabButton onClick={()=>setShowAddArt(true)}
                                                color="success">
                                                <IonIcon icon={add} />
                                            </IonFabButton>
                                        </IonFab>                                                                        
                                    </IonCol>
                                </IonRow>
                                <IonRow hidden={!getExtrasVisibility()}>                                
                                    <IonCol size="12">Vacunas:</IonCol>
                                </IonRow>
                                <IonRow hidden={!getExtrasVisibility()}>
                                    <IonCol size="12">                                            
                                        <DataTable columns={extrasCols} data={extras}
                                            noDataComponent={
                                                <IonGrid>                                                    
                                                    <IonRow>
                                                        <IonCol size="4"></IonCol>
                                                        <IonCol>
                                                            No se han agregado extras
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow><IonCol>&nbsp;</IonCol></IonRow>
                                                </IonGrid>
                                            }/>
                                        <IonFab vertical="top" horizontal="end">
                                            <IonFabButton onClick={()=>setShowAddExtra(true)}
                                                color="success">
                                                <IonIcon icon={add} />
                                            </IonFabButton>
                                        </IonFab>                                                                
                                    </IonCol>
                                </IonRow>
                                 {/* Inicio Uso Interno */}
                                 <IonRow hidden={!getUsoInternoVisibility()}>                                
                                    <IonCol size="12">Uso Interno:</IonCol>
                                </IonRow>
                                <IonRow hidden={!getUsoInternoVisibility()}>                                
                                    <IonCol size="12">  
                                        
                                    <DataTable columns={artUsoIntCols} data={artUsoInt}
                                            noDataComponent={
                                                <IonGrid>                                                    
                                                    <IonRow>
                                                        <IonCol size="4"></IonCol>
                                                        <IonCol>
                                                            No se han agregado artículos
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow><IonCol>&nbsp;</IonCol></IonRow>
                                                </IonGrid>
                                            }/>
                                        <IonFab vertical="top" horizontal="end">
                                            <IonFabButton onClick={()=>setShowAddArtUsoInt(true)}
                                                color="success">
                                                <IonIcon icon={add} />
                                            </IonFabButton>
                                        </IonFab>                                                                        
                                    </IonCol>
                                </IonRow>
                                {/* Fin Uso Interno */}                                
                                <IonRow>
                                    <IonCol className="totalLabel" size="8">
                                        Total
                                    </IonCol>
                                    <IonCol className="totalValue">
                                        {formatter.format(total)}
                                    </IonCol>
                                </IonRow>
                                <IonRow hidden={!showSaldo}>
                                    <IonCol size='3'>
                                        &nbsp;
                                    </IonCol>
                                    <IonCol size="3" className='formKeyLabel'>
                                        Saldo actual del cliente:
                                    </IonCol>
                                    <IonCol size='2' className='formKeyLabel'>
                                        {saldoCliente} huellitas
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonButton hidden={!showRedimirBtn}
                                            onClick={
                                                ()=>setShowRedimirPopup(true)}>
                                            {pctDescCli > 0 ? "-" + pctDescCli + "%"
                                                : "Redimir"}
                                        </IonButton>
                                    </IonCol>
                                    <IonCol className="totalValue">
                                        { pctDescCli > 0 ? 
                                            formatter.format(
                                                total * (1 - (pctDescCli * 0.01))) 
                                                : ""}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="5"/>
                                    <IonCol>
                                        <IonButton onClick={submitServicio}>
                                            Guardar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </form>
                    </IonCardContent>
                </IonCard>
                <IonModal isOpen={showAddArt} backdropDismiss={false}>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>
                                Datos del artículo
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow>
                                <IonCol size='4'>Producto:</IonCol>
                                <IonCol size="8">
                                <IonInput type="search" 
                                    placeholder="Ingrese el producto"
                                    value=
                                        {isNullOrUndefined(
                                            window.localStorage["productoSelected"])?"Seleccione un producto" : 
                                            JSON.parse(window.localStorage["productoSelected"]).nombre}
                                    onIonFocus={()=>setShowPickProducto(true)}                                     
                                />
                                </IonCol>
                                </IonRow>
                                <IonRow hidden={!isCurProdGranel()}>
                                    <IonCol>
                                        Venta parcial
                                    </IonCol>
                                    <IonCol>
                                        <IonCheckbox checked={granel}
                                            onIonChange={granelChanged}/>                                        
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='4'>
                                        Precio Unitario:
                                    </IonCol>
                                    <IonCol size='8'>
                                        <IonInput type="number" value={precioVenta.toString()}
                                            onIonChange={precioVentaChange}/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                <IonCol size='4'>Cantidad:</IonCol>
                                <IonCol size={cantidadSizeCol}>
                                    <IonInput value={cantidad.toString()} 
                                        type="tel" 
                                        onIonChange={cantidadChange}
                                        onBeforeInput={validateKey}/>                                    
                                </IonCol>
                                <IonCol size='4'>
                                <IonLabel hidden={
                                        !granel}>
                                            {getUnidadGranel()}
                                    </IonLabel>
                                </IonCol>
                                </IonRow>                                
                                <IonRow>
                                    <IonCol>
                                        <IonButton onClick={()=>submitArticulo(true)}>Agregar</IonButton>
                                        <IonButton onClick={()=>cancelAddProduct()} color="medium">
                                            Cancelar</IonButton>
                                    </IonCol>
                                </IonRow>                                
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </IonModal>
                <IonModal isOpen={showPickProducto} backdropDismiss={false}>
                    <EntitySearch tipoEntidad="producto"/>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={()=>setShowPickProducto(false)} 
                                    color="medium">
                                Cancelar
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={()=>setShowPickProducto(false)}>
                                    Continuar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>                                        
                </IonModal>
                <IonModal isOpen={showPickPaciente} backdropDismiss={false}>
                    <EntitySearch tipoEntidad="pacienteVet"/>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={()=>setShowPickPaciente(false)} 
                                    color="medium">
                                Cancelar
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={()=>{
                                    setShowPickPaciente(false);
                                    //setPaciente(JSON.parse(
                                    //        window.localStorage["pacienteSelected"]))
                                    }}>
                                    Continuar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>                                        
                </IonModal>
                <IonModal isOpen={showPickPropietario} backdropDismiss={false}>
                    <EntitySearch tipoEntidad="cliente"/>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={()=>setShowPickPropietario(false)} 
                                    color="medium">
                                Cancelar
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={()=>{
                                    setShowPickPropietario(false);
                                    setShowLoading(true)
                                    getPacientesAndSaldo()
                                    //setPaciente(JSON.parse(
                                    //        window.localStorage["pacienteSelected"]))
                                    }}>
                                    Continuar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>                                        
                </IonModal>
                <IonModal isOpen={showAddExtra} backdropDismiss={false}>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>
                                Añadir extra
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>Concepto:</IonCol>
                                    <IonCol>
                                        <IonSelect placeholder="Elija uno"
                                            value={extra}
                                            onIonChange={extraChange}>
                                            {extrasDisponibles.map((ext)=>
                                                <IonSelectOption value={ext.nombre}>
                                                    {ext.nombre}
                                                </IonSelectOption>   
                                            )}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>                                                                
                                <IonRow>
                                    <IonCol>
                                        <IonButton onClick={submitExtra}>Agregar</IonButton>
                                        <IonButton onClick={()=>cancelAddExtra()} color="medium">
                                            Cancelar</IonButton>
                                    </IonCol>
                                </IonRow>                                
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </IonModal>
                <IonModal isOpen={showNewCliente} backdropDismiss={false}>
                    <IonContent>
                    <form>
                    <IonToolbar>
                        <IonTitle>Datos del cliente</IonTitle>
                    </IonToolbar>                
                    <IonGrid>
                        <IonRow>
                            <IonCol size="3.5">
                                Nombre Completo
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={nombreCliChange}
                                    value={nombreCli}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="3.5">
                            Fecha de Nacimiento
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={fechaNacCliChange}
                                    value={fechaNacCli} type="date"/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="3.5" className="colAlineada">
                                Correo Electrónico
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={emailCliChange}
                                    value={emailCli} type="email"/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="3.5" className="colAlineada">
                                Teléfono
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={telefonoCliChange}
                                    value={telefonoCli}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={()=>{
                                    setShowNewCliente(false)
                                    resetClienteForm()
                                }} 
                                color="medium">Cancelar</IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={submitNewCliente}>
                                    Guardar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    </form>
                    </IonContent>
                </IonModal>
                {/***** Modal nuevo paciente *****/}
                <IonModal isOpen={showNewPaciente} backdropDismiss={false}>
                    <IonContent>
                    <IonLoading 
                        isOpen={showLoading}
                        message="Guardando..."
                    />
                    <form>
                        <IonToolbar>
                            <IonTitle>Datos del paciente</IonTitle>
                        </IonToolbar>                
                        <IonGrid>
                            <IonRow>
                                <IonCol size="3.5">
                                    Nombre
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonInput onIonChange={nombrePacChange}
                                        value={nombrePac}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5">
                                Fecha de Nacimiento
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonInput onIonChange={fechaNacPacChange}
                                        value={fechaNacPac} type="date"/>
                                </IonCol>
                            </IonRow>                            
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Especie
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonSelect onIonChange={especieChange} 
                                        value={especie}>
                                    {especies.map((esp:any)=>
                                        <IonSelectOption value={esp.nombre}>
                                            {esp.nombre}</IonSelectOption>)}
                                    </IonSelect>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Raza
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonSelect onIonChange={razaChange} value={raza}>
                                    {opcionesRazas?.map((raza:any)=>
                                        <IonSelectOption value={raza.nombre}>{raza.nombre}</IonSelectOption>)}
                                    </IonSelect>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Sexo
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonSelect onIonChange={sexoChange} value={sexo}>
                                        <IonSelectOption value="hembra">
                                            Hembra
                                        </IonSelectOption>
                                        <IonSelectOption value="macho">
                                            Macho
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Peso
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonInput onIonChange={pesoChange}
                                        value={peso}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Color
                                </IonCol>
                                <IonCol size="8.5">
                                <IonSelect onIonChange={colorChange} value={color}>
                                    {colores.map((color:any)=>
                                        <IonSelectOption value={color.nombre}>{color.nombre}</IonSelectOption>)}
                                    </IonSelect>
                                </IonCol>
                            </IonRow>                                                                                                               
                            <IonRow>
                                <IonCol size="3.5" className="colAlineada">
                                    Edad al acogerlo
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonInput onIonChange={edadAcogidaChange}
                                        value={edadAcogida}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3.5">
                                Fecha de acogida
                                </IonCol>
                                <IonCol size="8.5">
                                    <IonInput onIonChange={fechaAcogidaChange}
                                        value={fechaAcogida} type="date"/>
                                </IonCol>
                            </IonRow>                            
                            <IonRow>
                                <IonCol>
                                <IonButton onClick={()=>{
                                    setshowNewPaciente(false)
                                    setNewPacExtCli(false)
                                }} 
                                color="medium">Cancelar</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={submitNewPaciente}>Guardar</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>                                
                        </form> 
                    </IonContent>
                </IonModal>
                {/***** Modal nuevo paciente *****/}
                {/***** Modal redimir puntos *****/}
                <IonModal isOpen={showRedimirPopup} backdropDismiss={false}>
                    <IonContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    Ingrese el porcentaje de descuento a aplicar
                                </IonCol>
                            </IonRow>
                            <IonRow>    
                                <IonCol>
                                    <IonInput onIonChange={pctDescChange}
                                            value={ pctDescCli == 0 ? null : 
                                                    pctDescCli?.toString() }
                                            onBeforeInput={validateKeyCurrency}
                                            clearInput={true}                                            />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                <IonButton onClick={()=>{
                                    setShowRedimirPopup(false)
                                    setPctDescCli(0)                                 
                                }} 
                                color="medium">Cancelar</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={()=>{
                                        setShowRedimirPopup(false)
                                    }}>Guardar</IonButton>
                                </IonCol>
                            </IonRow>                            
                        </IonGrid>

                    </IonContent>
                </IonModal>
                {/***** Modal redimir puntos *****/}
                {/* Inicio Uso Interno */}
                <IonModal isOpen={showAddArtUsoInt} backdropDismiss={false}>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>
                                Datos del artículo
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow>
                                <IonCol size='4'>Producto:</IonCol>
                                <IonCol size="8">
                                <IonInput type="search" 
                                    placeholder="Ingrese el producto"
                                    value=
                                        {isNullOrUndefined(
                                            window.localStorage["productoSelected"])?"Seleccione un producto" : 
                                            JSON.parse(window.localStorage["productoSelected"]).nombre}
                                    onIonFocus={()=>setShowPickProducto(true)}                                     
                                />
                                </IonCol>
                                </IonRow>
                                <IonRow hidden={!isCurProdGranel()}>
                                    <IonCol>
                                        Venta parcial
                                    </IonCol>
                                    <IonCol>
                                        <IonCheckbox checked={granel}
                                         onIonChange={granelChanged}/>                                        
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                <IonCol size='4'>Cantidad:</IonCol>
                                <IonCol size={cantidadSizeCol}>
                                    <IonInput value={cantidad.toString()} 
                                        type="number" 
                                        onIonChange={cantidadChange}
                                        // onBeforeInput={validateKey}
                                        />                                    
                                </IonCol>
                                <IonCol size='4'>
                                <IonLabel hidden={
                                        !granel}>
                                            {getUnidadGranel()}
                                    </IonLabel>
                                </IonCol>
                                </IonRow>                                
                                <IonRow>
                                    <IonCol>
                                        <IonButton onClick={()=>submitArtUsoInt(true)}>Agregar</IonButton>
                                        <IonButton onClick={()=>cancelAddProdUsoInt()} color="medium">
                                            Cancelar</IonButton>
                                    </IonCol>
                                </IonRow>                                
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </IonModal>
                {/* Fin Uso Interno */}
                <IonToast isOpen={showToast} message={toastMessage}
                    onDidDismiss={()=>setShowToast(false)} duration={1500} position="middle"/>
                <IonAlert isOpen={showInvAlert} header='Precaución'
                    message='No hay existencias suficientes en el inventario ¿desea continuar?'
                    buttons={[
                        {
                            text: 'No',
                            role: 'cancel',
                            handler: () => {
                            console.log('Alert canceled');
                            setShowInvAlert(false)
                            },
                        },
                        {
                            text: 'Sí',
                            role: 'confirm',
                            handler: () => {
                            console.log('Alert confirmed');
                            setShowInvAlert(false)                            
                            submitArticulo(false)
                            },
                        },
                        ]}
                />
            </IonContent>
        </IonPage>
        );    
}
export default RegistroServicio
