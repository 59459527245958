import React, { useEffect, useState } from "react";
import { isNullOrUndefined } from "util";
import { IonSearchbar, IonList, IonItem, IonItemSliding, IonLabel, IonItemOptions, IonItemOption, IonContent, IonLoading } from "@ionic/react";
import './EntitySearch.css';
import { PropsType } from "ionicons/dist/types/stencil.core";
import { callGetClientes, callGetFabricantes, callGetPacientes, callGetProductos } from "../firebaseConfig";

type Props={
    tipoEntidad: string,
    callback?: any
}

const EntitySearch:  React.FC<Props> = (props) =>{    
    
    
    var startupSearch = "";
    

    const[ selectedKey, setSelectedKey] = useState("")
    const [selectedId, setSelectedId] = useState(
        isNullOrUndefined(window.localStorage[selectedKey]) ? "" : 
        JSON.parse(window.localStorage[selectedKey]).id);
    const [searchText, setSearchText] = useState(startupSearch);  
    const[ cloudEntities, setCloudEntities] = useState([] as any[])  
    const[ displayKey, setDisplayKey] = useState("")    
    const[ showLoading, setShowLoading] = useState(true)
    
    //console.log("cloudEntities: " + JSON.stringify(cloudEntities))
    var filteredArray: any[] = cloudEntities

    useEffect(() => {
        async function loadData() {
            //console.log("tipoEntidad:"+props.tipoEntidad);
            switch (props.tipoEntidad) {        
                case "paciente":
                    if(!isNullOrUndefined(window.localStorage['pacientes']))
                        setCloudEntities(
                            JSON.parse(window.localStorage['pacientes']));
                    else
                        setCloudEntities([]);
                    setSelectedKey("pacienteSelected");
                    setDisplayKey("nombreCompleto")
                    startupSearch = 
                        isNullOrUndefined(window.localStorage[selectedKey]) ? 
                        "" : JSON.parse(window.localStorage[selectedKey])
                        .nombreCompleto
                    break;
                case "pacienteVet":
                    /* if(!isNullOrUndefined(window.localStorage['pacientesVet']))
                        setCloudEntities(
                            JSON.parse(window.localStorage['pacientesVet']));
                    else
                        setCloudEntities([]); */
                    callGetPacientes().then(res=>{
                        setCloudEntities(res as any[])
                        setShowLoading(false)
                    })
                    setSelectedKey("pacienteSelected");
                    setDisplayKey("nombre")
                    startupSearch = 
                        isNullOrUndefined(window.localStorage[selectedKey]) ? 
                        "" : JSON.parse(window.localStorage[selectedKey]).nombre
                    break;
                case "fabricante":
                    /*
                    if(!isNullOrUndefined(window.localStorage["fabricantes"]))
                        entityArray = JSON.parse(window.localStorage["fabricantes"]);
                    else
                        entityArray = [{"codigo":1,"tipo":"laboratorio","nombre":"Astra Zeneca"},{"codigo":2,"tipo":"alimento","nombre":"Pedigree"},{"codigo":3,"tipo":"material","nombre":"Degasa"}];
                    */
                    callGetFabricantes().then(res=>{
                        //console.log("EntitySearch.callGetFabricantes res: " + 
                        //    JSON.stringify(res))
                        let temp = res as any[];
                        //console.log("EntitySearch.callGetFabricantes temp: " + 
                        //    JSON.stringify(temp))
                        setCloudEntities(temp)
                        setShowLoading(false)
                        //console.log("EntitySearch.callGetFabricantes cloudEntities: " + 
                        //JSON.stringify(cloudEntities))
                    })
                    setSelectedKey("fabricanteSelected");
                    setDisplayKey("nombre")
                    startupSearch = isNullOrUndefined(window.localStorage[selectedKey]) ? "" : 
                        JSON.parse(window.localStorage[selectedKey]).nombre
                    break;
                case "producto":
                    /*
                    if(!isNullOrUndefined(window.localStorage["productos"]))
                        setCloudEntities(
                            JSON.parse(window.localStorage["productos"]));
                    else{
                        setCloudEntities( 
                            [
                                {"nombre":"Pouch Razas Pequeñas Pollo","id":1,
                                 "codigoBarras":"","fabricante":{"codigo":2,
                                 "tipo":"alimento","nombre":"Pedigree"},
                                 "stockMinimo":"20","stockActual":0},
                                 {"nombre":"Vendas 5m","id":2,"codigoBarras":"",
                                 "fabricante":{"codigo":3,"tipo":"material",
                                 "nombre":"Degasa"},"stockMinimo":"10",
                                 "stockActual":0},{"nombre":"Ranitidina 150mg",
                                 "id":3,"codigoBarras":"","fabricante":{
                                "codigo":1,"tipo":"laboratorio","nombre":
                                "Astra Zeneca"},"stockMinimo":"10","stockActual":
                                0}]);
                            window.localStorage["productos"] = 
                                JSON.stringify(cloudEntities)
                        }
                    */    
                    callGetProductos().then(res=>{
                        setCloudEntities(res as [])
                        setShowLoading(false)
                    })
                    setSelectedKey("productoSelected");
                    setDisplayKey("nombre");
                    startupSearch = isNullOrUndefined(window.localStorage[selectedKey]) ? "" :
                        JSON.parse(window.localStorage[selectedKey]).nombre
                    break;
                    case "cliente":
                        callGetClientes().then(res=>{
                            setCloudEntities(res as any[])
                            setShowLoading(false)
                        }).catch(err=>{
                            console.log("error al consultar clientes: " + 
                                JSON.stringify(err))
                        })
                        setSelectedKey("clienteSelected");
                        setDisplayKey("nombre")
                        startupSearch = 
                            isNullOrUndefined(window.localStorage[selectedKey]) ? 
                            "" : JSON.parse(window.localStorage[selectedKey]).nombre
                        break;
                default:
                    break;
            }  
        }
    
        loadData();
      }, []);
      

    useEffect(()=>{
        //console.log("useEffect entityArray: " + JSON.stringify(cloudEntities))
        /*
        filteredArray = 
            cloudEntities.filter(                
                item => { 
                    console.log("useEffect item: " +  item)
                    console.log(" item displayKey lowerCase: " + item[displayKey].toLowerCase())
                  return  item[displayKey].toLowerCase().indexOf(searchText.toLowerCase()) > -1 
                })
        console.log("useEffect filteredArray: " +  JSON.stringify(filteredArray))
        */

    },[searchText])
          

    function renderItem(ent: any) {
        //console.log("renderItem: " +  JSON.stringify(ent) + " displayKey: " +
        //    displayKey)
        
        if(isNullOrUndefined(searchText) || ent[displayKey].toLowerCase().indexOf(searchText.toLowerCase()) > -1)
        return (
            //  <IonMenuToggle key={menu.title} auto-hide="false">
                       <IonItemSliding key={ent.id? ent.id : ent.codigo}>
                       <IonItem onClick={()=>{selectEntity(ent)}}                        
                       className={ent.id == selectedId || ent.codigo == selectedId ? "selectedItem": "normalItem"}>
                         <IonLabel> {ent[displayKey]}</IonLabel>
                       </IonItem>
                     </IonItemSliding>
                    //    </IonMenuToggle>
          )
      }

    function selectEntity(ent:any){
        //console.log("selectEntity ent: " +  JSON.stringify(ent) +
        //    "selectedKey: " +  selectedKey + " props.callback: " + 
        //    JSON.stringify(props.callback))
        window.localStorage[selectedKey] = JSON.stringify(ent);
        if(ent.id){
            //console.log("ent.id: " + ent.id)
            setSelectedId(ent.id);
        }            
        else{
            //console.log("ent.codigo: " +  ent.codigo)
            setSelectedId(ent.codigo)
        }
        if(!isNullOrUndefined(props.callback))
            props.callback();                 
    }

    return(
        <>
            <IonLoading isOpen={showLoading}
                message="Cargando..."
                />
            <IonSearchbar value={searchText} onIonChange={
                e => {
                    //console.log("ionSearchBar change! nuevo valor: " 
                    //    + e.detail.value + " filteredArray: " +  filteredArray +
                    //    " cloudEntities: " +  cloudEntities)
                    setSearchText(e.detail.value!)}
                }
                animated={true} placeholder="Seleccione uno"></IonSearchbar>
            <IonContent>
            <IonList>
              {filteredArray.map((ent: any) => renderItem(ent))}                            
            </IonList>
            </IonContent>            
        </>
    );
}
export default EntitySearch
