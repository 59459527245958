import React, { useState, useContext, ChangeEvent } from "react";
import { IonContent, IonItem, IonHeader, IonToolbar, IonTitle, IonMenuButton, IonModal, IonGrid, IonRow, IonCol, IonButton, IonSelect, IonSelectOption, IonInput, IonFab, IonFabButton, IonIcon, isPlatform, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonAlert, IonText } from "@ionic/react";
import { isNullOrUndefined } from "util";
import EntitySearch from "../components/EntitySearch";
import { add, create, trash, returnLeft, returnRight, checkmarkCircleOutline } from "ionicons/icons";
import { Cita } from "../App";
import './CitasPage.css';
import SideMenu from "../components/SideMenu";
//import {DayPilot, DayPilotCalendar} from "@daypilot/daypilot-lite-react";
const {DayPilotCalendar, DayPilot, DayPilotNavigator} = require('@daypilot/daypilot-lite-react');


const CitasPage2: React.FunctionComponent= ()=>{    

    var citas: Array<Cita> = [];    
    if(!isNullOrUndefined(window.localStorage['citas']))
            citas = JSON.parse(window.localStorage['citas']);
    if(isNullOrUndefined(window.localStorage['colores'])){
        const colores = [{'codigo':11,'nombre':'Jaspeado'}];
        window.localStorage['colores'] = JSON.stringify(colores)
    }
        
    if(isNullOrUndefined(window.localStorage['especies'])){
        const especies = [{'codigo':1,'nombre':'Perro'},{'codigo':2,'nombre':'Gato'}]
        window.localStorage['especies'] = JSON.stringify(especies)
        
    }
    if(isNullOrUndefined(window.localStorage['pacientesVet'])){
        const pacientesVet = [{"id":1622158951380,"nombre":"Sid","especie":"Perro","raza":"Maltés","sexo":"macho","peso":"2.7","color":"Jaspeado","cicatrices":"Nanai","cirugias":"Nones","condicion":"Deplorable","fechaNacimiento":"2007-05-16","edadAcogida":"aprox 3 años","fechaAcogida":"2009-08-15","procedencia":"Dudosa","entorno":"Hostil","estiloVida":"Nini","nutricion":"Excesiva","observaciones":"Re chispa","urlFotoPerfil":"https://firebasestorage.googleapis.com/v0/b/fir-cloud-a589a.appspot.com/o/vet_profile_images%2F1622158951380.jpg?alt=media&token=cba9a140-fa45-42bb-9df8-2a45e0e2c6a3"},{"id":1623712933246,"nombre":"Cujo","especie":"Perro","raza":"Dalmata","sexo":"macho","peso":"45kg","color":"Jaspeado","cicatrices":"ninguna","cirugias":"hartas","condicion":"poseído","fechaNacimiento":"1978-07-15","edadAcogida":"100","fechaAcogida":"1980-08-01","procedencia":"Hell","entorno":"Macabro","estiloVida":"Thug's life","nutricion":"Carne cruda","observaciones":"Es asesino","urlFotoPerfil":"https://firebasestorage.googleapis.com/v0/b/fir-cloud-a589a.appspot.com/o/vet_profile_images%2F1623712933246.jpg?alt=media&token=6aa0e5f0-20ad-4ee7-a8d1-fd8489e6dad2"}]
        window.localStorage['pacientesVet'] = JSON.stringify(pacientesVet)
    }
    if(isNullOrUndefined(window.localStorage["razas"])){
        let razastmp = [{"codigo":1,"nombre":"Labrador","parentId":"Perro"},{"codigo":2,"nombre":"French Poodle","parentId":"Perro"},{"codigo":3,"nombre":"Siamés","parentId":"Gato"}]
        window.localStorage["razas"] = JSON.stringify(razastmp)
        window.localStorage["razaNewId"] = "4"
    }
    const [showPickPaciente, setShowPickPaciente] = useState(false);
    const [showNewCita, setShowNewCita] = useState(false);
    const [showEditCita, setShowEditCita] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [events, setEvents] = useState(getEventsFromCitas());
    
    
    const [sucursal, setSucursal] = useState("Tenayuca");
    const [especialidad, setEspecialidad] = useState("");
    const [servicio, setServicio] = useState("");
    const [encargado, setEncargado] = useState("");
    //const [fecha, setFecha] = useState(new Date());
    const [horaInicio, setHoraInicio] = useState("");
    const [horaFin, setHoraFin] = useState("");
    //const [duracion, setDuracion] = useState(0);
    const [comentarios, setComentarios] = useState("");
    const [fechaIni, setFechaIni] = useState(DayPilot.Date.today())
    const [selectedCita, setSelectedCita] = useState({} as Cita)    
    const [fechaForm, setFechaForm] = useState(DayPilot.Date.today())
    const [editable, setEditable] = useState(true);
    function initFechaCal(){
        var initDate = new Date()
        initDate.setTime(new Date().getTime() - (24*3600000) )
        return initDate;
    }
    const [fechaCal, setFechaCal] = useState(initFechaCal());
      const styles = {
        wrap: {            
          display: "flex",
          height: "500px"
        },
        left: {
          marginRight: "10px"
        },
        main: {
          flexGrow: "1"
        },
        topdiv: {
            align: "center"
        }

      };
    
    function getEventsFromCitas(){
        var arrReturn: Array<any> = [];
        citas.forEach(cita => {
            arrReturn.push(buildEvent(cita));
        });
        return arrReturn;
    }
      
    function buildEvent(cita:Cita){
        const objReturn = {
            id: cita.id,
            text: cita.especialidad,
            start: cita.fecha + "T" + cita.horaInicio ,
            end: cita.fecha + "T" + cita.horaFin
          }
        console.log("evento: " + JSON.stringify(objReturn));
        return objReturn;
    }

    function submitCita(tag: any){ 
         
        let citaData: Cita = {     
            "id": showNewCita? Date.now() : selectedCita.id,                   
            "sucursal": sucursal,
            "especialidad": especialidad,
            "servicio": servicio,
            "encargado": encargado,
            "fecha": fechaForm.toLocaleString().substring(0,10),
            "horaInicio": horaInicio.length == 8? horaInicio : horaInicio + ":00",
            "horaFin": horaFin.length == 8? horaFin: horaFin + ":00",                        
            "paciente": JSON.parse(window.localStorage["pacienteSelected"]),
            "comentarios": comentarios
        }                    
        if(showNewCita)    
            citas.push(citaData);
        else
        {
            for(var i=0; i<citas.length; i++){
                if(citas[i].id == selectedCita.id){
                    citas.splice(i, 1, citaData);
                    setSelectedCita(citaData);
                }
            }
        }
        window.localStorage['citas'] = JSON.stringify(citas);
        //this.arrTemp = this.state.events;
        //this.arrTemp.push();          
        //setEvents([...events, buildEvent(citaData)]);
        //console.log("Arreglo citas en localstorage despues de guardar: " + 
        //    JSON.stringify(window.localStorage['citas']));
        setEvents(getEventsFromCitas);
        console.log("longitud citas despues de guardar: " + citas.length);
        console.log("longitud eventos nuevo: " + events.length);
        
          setShowNewCita(false);
          setShowEditCita(false);
          //setSucursal("");
          setEspecialidad("");
          setServicio("");
          setEncargado("");
          setFechaForm(new Date());
          setHoraInicio("");
          setHoraFin("");
          //setDuracion(0);
          setComentarios("");
          //setPaciente("")
        
        window.localStorage.removeItem('pacienteSelected');
        //arrSchedule.push(buildRange(citaData));
        //setSchedule(arrSchedule);
        }

        function sucursalChange(event: CustomEvent){        
            setSucursal(event.detail.value);
          }
        function especialidadChange(event: CustomEvent){        
            setEspecialidad(event.detail.value);
        }
        function servicioChange(event: CustomEvent){        
            setServicio(event.detail.value );
        }
        function encargadoChange(event: CustomEvent){        
            setEncargado(event.detail.value);
        }
        function fechaChange(event: CustomEvent){  
            console.log("fechaChange: " + event.detail.value);   
            //setFechaIni("");
            setFechaForm(new Date(event.detail.value));
          }
          function fecha2Change(event: CustomEvent){  
            //alert("fechaChange arg: " + event.detail.value);   
            const d = new Date(event.detail.value)
            //alert("fechaChange date: " + d.toLocaleString())
            setFechaCal(d);
            //setFecha(new Date(event.target.value));
          }
        function fechaFormChange(event: CustomEvent){
            setFechaForm(event.detail.value)
        }
        function horaInicioChange(event: CustomEvent){     
            console.log("horaInicioChange: " +  event.detail.value);   
            setHoraInicio(event.detail.value);
        }
        function horaFinChange(event: CustomEvent){     
            console.log("horaFinChange: " +  event.detail.value);   
            setHoraFin(event.detail.value);
        }
        /*
        function duracionChange(event: CustomEvent){        
            setDuracion(event.detail.value);
          }
        */
        function comentariosChange(event: CustomEvent){        
            setComentarios(event.detail.value);
          }

          function getYesterday(){
            let today = new Date();
            let yesterday = new Date();
            yesterday. setDate(today. getDate() - 1);
            return yesterday;
        }

        function eventClick(index:[number, number]){
            console.log("eventClick: " + JSON.stringify(index));
            alert("click! " +  index[0]);
        }

        function setCitaParameters(inicio: Date, fin: Date){ 
            console.log("fechaCita:" + inicio.toLocaleString().substr(0,10))
            setFechaForm(inicio.toLocaleString().substr(0,10));            
            setHoraInicio(inicio.toLocaleString().substr(11,5))
            setHoraFin(fin.toLocaleString().substr(11,5));
            
        }

        function getSelectedCita(citaId: number){
            var objReturn = {} as Cita
            citas.forEach(cita => {
                if(cita.id == citaId)
                    objReturn = cita
            });            
            return objReturn;
        }

        function showEditForm(){
            setShowEditCita(true);
            setSucursal(selectedCita.sucursal);
            setEspecialidad(selectedCita.especialidad);
            setEncargado(selectedCita.encargado);
            setServicio(selectedCita.servicio);
            setFechaForm(selectedCita.fecha);
            setHoraInicio(selectedCita.horaInicio);
            setHoraFin(selectedCita.horaFin);            
            window.localStorage["pacienteSelected"] = JSON.stringify(selectedCita.paciente);
            setComentarios(selectedCita.comentarios);
        }
        function showEditFormMobile(cita: Cita){
            setShowEditCita(true);
            setSucursal(cita.sucursal);
            setEspecialidad(cita.especialidad);
            setEncargado(cita.encargado);
            setServicio(cita.servicio);
            setFechaForm(cita.fecha);
            setHoraInicio(cita.horaInicio);
            setHoraFin(cita.horaFin);            
            window.localStorage["pacienteSelected"] = JSON.stringify(cita.paciente);
            setComentarios(cita.comentarios);
        }

        function deleteCita(){
            for(var i=0; i<citas.length; i++){
                if(citas[i].id == selectedCita.id){
                    citas.splice(i, 1);
                    setSelectedCita({} as Cita);
                    window.localStorage['citas'] = JSON.stringify(citas);                    
                    setEvents(getEventsFromCitas);
                }
            }            
        }

        function selectedMonth(){    
            //alert("fechaCal.toUTCString(): " + fechaCal.toUTCString())  
            const selDay = new Date()                        
            selDay.setTime(new Date(fechaCal).getTime() + (24*3600000)) 
            return selDay.toLocaleString('default', { month: 'long' }).toUpperCase();
        }

        function getDisplayDate(){
            const selDay = new Date()            
            //alert("fechaCal: " + fechaCal.toLocaleString().substr(0,10))
            //alert("fechaCal sin locale: " + JSON.stringify(fechaCal))
            selDay.setTime(new Date(fechaCal).getTime() + (30*3600000)) 
            //alert("displayDate: " + JSON.stringify(selDay))
            const tmpArray = selDay.toLocaleString().split(" ");
            return tmpArray[0];
        }        

        function clearForm(){
            setShowEditCita(false);
            //setSucursal(selectedCita.sucursal);
            setEspecialidad("");
            setEncargado("");
            setServicio("")
            setFechaForm("");
            setHoraInicio("");
            setHoraFin("");            
            window.localStorage.removeItem("pacienteSelected")
            setComentarios("");
        }

        if(isPlatform('desktop'))
        return (            
            <React.Fragment>                
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            Citas Agendadas
                        </IonTitle>
                        <IonMenuButton slot="start"></IonMenuButton>
                    </IonToolbar>                
                </IonHeader>
                <IonContent>                                                        
                <div style={styles.wrap}>
                    <div style={styles.left}>
                        <DayPilotNavigator
                            selectMode="week"
                            locale="es-mx"
                            onTimeRangeSelected={ (args: { day: any; }) => {
                                setFechaIni(args.day)                                
                              }}                            
                            />
                        <IonCard hidden={isNullOrUndefined(selectedCita.id)}>
                            <IonCardHeader>
                                <IonCardTitle>
                                    <IonText color="primary">
                                    {!isNullOrUndefined(selectedCita.id)? 
                                    selectedCita.especialidad:""}
                                    </IonText>                                    
                                </IonCardTitle>
                                <IonCardSubtitle>
                                    <IonText color="primary">
                                    {!isNullOrUndefined(selectedCita.id)?
                                    selectedCita.encargado:""}
                                    </IonText>
                                </IonCardSubtitle>                                
                            </IonCardHeader>
                            <IonCardContent>
                                <IonText color="primary">Paciente</IonText>:                                 
                                    {!isNullOrUndefined(selectedCita.id)?
                                    selectedCita.paciente.nombre:""}
                                <br></br>                                
                                <IonText color="primary">Servicio</IonText>: {!isNullOrUndefined(selectedCita.id)?
                                selectedCita.servicio:""}
                                <br></br>
                                <IonText color="primary">Comentarios</IonText>: {!isNullOrUndefined(selectedCita.id)?
                                selectedCita.comentarios:""}
                            </IonCardContent>                            
                        </IonCard>
                        <IonFab vertical="center"  horizontal="end">
                            <IonFabButton onClick={()=>setShowNewCita(true)}>
                                <IonIcon icon={add}/>
                            </IonFabButton>
                        </IonFab> 
                        <IonFab hidden={isNullOrUndefined(selectedCita.id)} 
                            
                            slot="fixed">
                            <IonRow>
                                <IonCol>
                                    <IonFabButton onClick={()=>showEditForm()}
                                        color="warning">
                                        <IonIcon icon={create}/>
                                    </IonFabButton>
                                </IonCol>
                                <IonCol>
                                    <IonFabButton onClick={()=>setShowConfirm(true)}
                                        color="danger">
                                        <IonIcon icon={trash}/>
                                    </IonFabButton>
                                </IonCol>
                                <IonCol>
                                    <IonFabButton color="secondary">
                                        <IonIcon icon={checkmarkCircleOutline}/>
                                    </IonFabButton>
                                </IonCol>
                            </IonRow>                                                
                        </IonFab>
                    </div>
                     <div >
                        <DayPilotCalendar
                            viewType={"Week"}
                            dayBeginsHour="9"
                            eventClickHandling = "Select"
                            allowMultiSelect = "false"
                            startDate={fechaIni}
                            headerDateFormat="dddd d"
                            days="3"
                            events={events}
                            locale="es-mx"
                            onTimeRangeSelected={(args: any)=>{
                                setCitaParameters(args.start,args.end);

                                
                            }}
                            onEventClick={(args: any)=>{
                                console.log(JSON.stringify(args.e.data.id));
                                //alert(JSON.stringify(args.e.data.id))
                                setSelectedCita(getSelectedCita(args.e.data.id))
                            }}
                            />
                    </div>                     
                 
                </div>

                    <IonModal isOpen={showPickPaciente} backdropDismiss={false}>
                        <EntitySearch tipoEntidad="pacienteVet"/>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton onClick={()=>setShowPickPaciente(false)} 
                                        color="medium">
                                    Cancelar
                                    </IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={()=>{
                                        setShowPickPaciente(false);
                                        //setPaciente(JSON.parse(
                                        //        window.localStorage["pacienteSelected"]))
                                        }}>
                                        Continuar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>                                        
                    </IonModal>
                    <IonModal isOpen={showNewCita||showEditCita} backdropDismiss={false}>
                            <IonToolbar>
                                <IonTitle>Ingrese los datos de la cita</IonTitle>
                            </IonToolbar>
                            <IonContent>                            
                                <IonGrid>
                                    <IonRow>
                                        <IonCol>
                                            Sucursal
                                        </IonCol>
                                        <IonCol>
                                            <IonSelect placeholder={sucursal}
                                                onIonChange={sucursalChange}
                                                value={sucursal}
                                                disabled={true}>
                                                <IonSelectOption>Sucursal 1</IonSelectOption>
                                            </IonSelect>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Especialidad
                                        </IonCol>
                                        <IonCol>
                                            <IonSelect placeholder="Seleccione una especialidad"
                                                onIonChange={especialidadChange}
                                                value={especialidad}>
                                                <IonSelectOption>Cirugía</IonSelectOption>
                                                <IonSelectOption>Oncología</IonSelectOption>
                                                <IonSelectOption>Fisioterapia</IonSelectOption>
                                            </IonSelect>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Servicio
                                        </IonCol>
                                        <IonCol>
                                            <IonSelect placeholder="Seleccione un servicio"
                                                onIonChange={servicioChange}
                                                value={servicio}>
                                                <IonSelectOption>Consulta</IonSelectOption>
                                                <IonSelectOption>Diagnóstico</IonSelectOption>
                                                <IonSelectOption>Tratamiento</IonSelectOption>
                                            </IonSelect>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Profesional Encargado
                                        </IonCol>
                                        <IonCol>
                                            <IonSelect placeholder="Seleccione un encargado"
                                                onIonChange={encargadoChange}
                                                value={encargado}>
                                                <IonSelectOption>Profesional 1</IonSelectOption>
                                                <IonSelectOption>Profesional 2</IonSelectOption>
                                                <IonSelectOption>Profesional 3</IonSelectOption>
                                            </IonSelect>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Fecha
                                        </IonCol>
                                        <IonCol>
                                            <IonInput type="date" placeholder="Seleccione la fecha"
                                                onIonChange={fechaFormChange}
                                                 value={fechaForm}
                                                >
                                            </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Hora inicio
                                        </IonCol>
                                        <IonCol>
                                            <IonInput type="time" placeholder="Seleccione la hora"
                                                onIonChange={horaInicioChange}
                                                value={horaInicio}>
                                            </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Hora final
                                        </IonCol>
                                        <IonCol>
                                            <IonInput type="time" placeholder="Seleccione la hora"
                                                onIonChange={horaFinChange}
                                                value={horaFin}>
                                            </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    {/* <IonRow>
                                        <IonCol>
                                            Duración
                                        </IonCol>
                                        <IonCol>
                                            <IonInput type="text" placeholder="Ingrese la duración"
                                                onIonChange={duracionChange}
                                                value={duracion.toString()}>
                                            </IonInput>
                                        </IonCol>
                                    </IonRow> */}
                                    <IonRow>
                                        <IonCol>
                                            Paciente
                                        </IonCol>
                                        <IonCol>                                    
                                            <IonInput type="search" 
                                            placeholder="Ingrese el paciente"
                                            value=
                                                {isNullOrUndefined(
                                                    window.localStorage["pacienteSelected"])?"Seleccione un paciente" : 
                                                    JSON.parse(window.localStorage["pacienteSelected"]).nombre}
                                            onIonFocus={()=>setShowPickPaciente(true)}                                     
                                            ></IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Comentarios
                                        </IonCol>
                                        <IonCol>
                                            <IonInput type="text"  placeholder="Ingrese comentarios "
                                                onIonChange={comentariosChange}
                                                value={comentarios}>
                                            </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton onClick={()=>{
                                                setShowNewCita(false);
                                                setShowEditCita(false)
                                            }}
                                             color="medium">
                                                Cancelar</IonButton> 
                                        </IonCol>
                                        <IonCol>
                                            <IonButton onClick={submitCita}>Guardar</IonButton> 
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            &nbsp;
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>                            
                            </IonContent>                        
                    </IonModal>    
                    
                    <IonAlert isOpen={showConfirm}
                        onDidDismiss={() => setShowConfirm(false)}                        
                        header={'¿Desea eliminar la cita?'}                        
                        buttons={[
                            {
                              text: 'Cancelar',
                              role: 'cancel',              
                              cssClass:'cancelButton',                
                              handler: () => {
                                console.log('Confirm Cancel');
                              }
                            },
                            {
                              text: 'Confirmar',                               
                              cssClass: 'okButton',
                              handler: () => {
                                console.log('Confirm Ok');
                                deleteCita();
                              }
                            }
                          ]}
                        />       
                </IonContent>
                </React.Fragment>                
          );  
        else
        return(
            <React.Fragment>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            Citas Agendadas
                        </IonTitle>
                        <IonMenuButton slot="start"></IonMenuButton>
                    </IonToolbar>                
                </IonHeader>
                <IonContent>                    
                    {/* <div style={styles.moviltop}>                     */}                    
                    <div className="topdiv">                        
                        <IonFab horizontal="start">
                            <IonFabButton onClick={()=>{                                
                                var newDate = new Date()
                                newDate.setTime(fechaCal.getTime()-(24*3600000))
                                setFechaCal(newDate)
                            }}>
                                <IonIcon icon={returnLeft}/>
                            </IonFabButton>                        
                        </IonFab>
                        <IonFab horizontal="end">
                            <IonFabButton onClick={()=>{
                                //alert("current: " +  current.toLocaleString())
                                var newDate = new Date()                                
                                newDate.setTime(fechaCal.getTime()+(24*3600000))
                                //alert("new: " + newDate.toLocaleString())
                                setFechaCal(newDate)
                            }}>
                                <IonIcon icon={returnRight}/>
                            </IonFabButton>                        
                        </IonFab>
                        <label>{selectedMonth()}</label><br/>
                        <input type="date"
                         value={getDisplayDate()}
                         placeholder={getDisplayDate()}
                         onInput={(e: any)=>{

                             /*if(isNullOrUndefined(e.target.value) || 
                                e.target.value.toString().length != 10) 
                                return;
                            else{*/
                                //alert("onIonChange: " + e.target.value)
                                var cur = new Date(e.target.value)
                                var newVal = new Date()
                                newVal.setTime(cur.getTime()-(24*3600000))
                                setFechaCal(newVal)                             
                            //}
                             
                         }}
                         />
                        
                    </div> 
                     
                    <div className="calendarDiv">
                    
                    <DayPilotCalendar
                        viewType={"Days"}
                        dayBeginsHour="9"   
                        businessEndsHour="20"                 
                        eventClickHandling = "Select"
                        allowMultiSelect = "false"
                        startDate={fechaCal}
                        days="3"
                        headerDateFormat="dddd d"
                        events={events}
                        locale="es-mx"
                        columnWidthSpec="fixed"
                        columnWidth="50"
                        
                        onTimeRangeSelected={(args: any)=>{
                            setCitaParameters(args.start,args.end);
                            //alert("TimeRangeSelected! " + args.start + " " + args.end +
                            //" " + args.resource)
                        }}
                        onEventClick={(args: any)=>{
                            console.log(JSON.stringify(args.e.data.id));
                            //alert(JSON.stringify(args.e.data.id))
                            const selCita = getSelectedCita(args.e.data.id); 
                            setEditable(false)                           
                            setSelectedCita(selCita);
                            showEditFormMobile(selCita)
                        }}

                        />  
                    </div>                                                                                                                   
                     
                    <IonModal isOpen={showPickPaciente} backdropDismiss={false}>
                        <EntitySearch tipoEntidad="pacienteVet"/>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton onClick={()=>setShowPickPaciente(false)} 
                                        color="medium">
                                    Cancelar
                                    </IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={()=>{
                                        setShowPickPaciente(false);
                                        //setPaciente(JSON.parse(
                                        //        window.localStorage["pacienteSelected"]))
                                        }}>
                                        Continuar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>                                        
                    </IonModal>
                    <IonModal isOpen={showNewCita||showEditCita} backdropDismiss={false}>
                            <IonToolbar>
                                <IonTitle>{editable? "Ingrese los datos de la cita" : 
                                "Datos de la cita"} </IonTitle>
                            </IonToolbar>
                            <IonContent>                            
                                <IonGrid>
                                    <IonRow>
                                        <IonCol>
                                            Sucursal
                                        </IonCol>
                                        <IonCol>
                                            <IonSelect placeholder={sucursal}
                                                onIonChange={sucursalChange}
                                                value={sucursal}
                                                disabled={true}>
                                                <IonSelectOption>Sucursal 1</IonSelectOption>
                                            </IonSelect>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Especialidad
                                        </IonCol>
                                        <IonCol>
                                            <IonSelect placeholder="Seleccione una especialidad"
                                                onIonChange={especialidadChange}
                                                value={especialidad}
                                                disabled={!editable}>
                                                <IonSelectOption>Cirugía</IonSelectOption>
                                                <IonSelectOption>Oncología</IonSelectOption>
                                                <IonSelectOption>Fisioterapia</IonSelectOption>
                                            </IonSelect>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Servicio
                                        </IonCol>
                                        <IonCol>
                                            <IonSelect placeholder="Seleccione un servicio"
                                                onIonChange={servicioChange}
                                                value={servicio}
                                                disabled={!editable}>
                                                <IonSelectOption>Consulta</IonSelectOption>
                                                <IonSelectOption>Diagnóstico</IonSelectOption>
                                                <IonSelectOption>Tratamiento</IonSelectOption>
                                            </IonSelect>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Profesional Encargado
                                        </IonCol>
                                        <IonCol>
                                            <IonSelect placeholder="Seleccione un encargado"
                                                onIonChange={encargadoChange}
                                                value={encargado}
                                                disabled={!editable}>
                                                <IonSelectOption>Profesional 1</IonSelectOption>
                                                <IonSelectOption>Profesional 2</IonSelectOption>
                                                <IonSelectOption>Profesional 3</IonSelectOption>
                                            </IonSelect>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Fecha
                                        </IonCol>
                                        <IonCol>
                                            <IonInput type="date" placeholder="Seleccione la fecha"
                                                onIonChange={fechaFormChange}
                                                 value={fechaForm}                                                
                                                 disabled={!editable}>
                                            </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Hora inicio
                                        </IonCol>
                                        <IonCol>
                                            <IonInput type="time" placeholder="Seleccione la hora"
                                                onIonChange={horaInicioChange}
                                                value={horaInicio}
                                                disabled={!editable}>
                                            </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Hora final
                                        </IonCol>
                                        <IonCol>
                                            <IonInput type="time" placeholder="Seleccione la hora"
                                                onIonChange={horaFinChange}
                                                value={horaFin}
                                                disabled={!editable}>
                                            </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    {/* <IonRow>
                                        <IonCol>
                                            Duración
                                        </IonCol>
                                        <IonCol>
                                            <IonInput type="text" placeholder="Ingrese la duración"
                                                onIonChange={duracionChange}
                                                value={duracion.toString()}>
                                            </IonInput>
                                        </IonCol>
                                    </IonRow> */}
                                    <IonRow>
                                        <IonCol>
                                            Paciente
                                        </IonCol>
                                        <IonCol>                                    
                                            <IonInput type="search" 
                                            placeholder="Ingrese el paciente"
                                            value=
                                                {isNullOrUndefined(
                                                    window.localStorage["pacienteSelected"])?"Seleccione un paciente" : 
                                                    JSON.parse(window.localStorage["pacienteSelected"]).nombre}
                                            onIonFocus={()=>setShowPickPaciente(true)}                                     
                                            
                                            disabled={!editable}/>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            Comentarios
                                        </IonCol>
                                        <IonCol>
                                            <IonInput type="text"  placeholder="Ingrese comentarios "
                                                onIonChange={comentariosChange}
                                                value={comentarios}
                                                disabled={!editable}>
                                            </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton onClick={()=>{
                                                setShowNewCita(false);
                                                setShowEditCita(false);
                                                setEditable(true);
                                                clearForm()
                                            }}
                                             color="medium">
                                                {editable? "Cancelar" : "Aceptar"}</IonButton> 
                                        </IonCol>
                                        <IonCol>
                                            <IonButton onClick={(e:any)=>{
                                                submitCita(e);
                                                clearForm()}}
                                                hidden={!editable}>
                                                Guardar
                                            </IonButton> 
                                            <IonButton onClick={()=>{
                                                setEditable(true)
                                                }}
                                                hidden={editable}>
                                                Modificar
                                            </IonButton> 
                                        </IonCol>
                                        <IonCol>
                                            <IonButton onClick={()=>{
                                                setShowNewCita(false)
                                                setShowEditCita(false)
                                                setEditable(true)                                                
                                                setShowConfirm(true)
                                                }}
                                                hidden={editable}
                                                color="danger">
                                                Eliminar

                                            </IonButton> 
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            &nbsp;
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>                            
                            </IonContent>                        
                    </IonModal>    
                    <IonAlert isOpen={showConfirm}
                        onDidDismiss={() => setShowConfirm(false)}                        
                        header={'¿Desea eliminar la cita?'}                        
                        buttons={[
                            {
                              text: 'Cancelar',
                              role: 'cancel',              
                              cssClass:'cancelButton',                
                              handler: () => {
                                console.log('Confirm Cancel');
                              }
                            },
                            {
                              text: 'Confirmar',                               
                              cssClass: 'okButton',
                              handler: () => {
                                console.log('Confirm Ok');
                                deleteCita();
                              }
                            }
                          ]}
                        /> 
                    
                    <IonFab vertical="center"  horizontal="end">
                        <IonFabButton onClick={()=>setShowNewCita(true)}>
                            <IonIcon icon={add}/>
                        </IonFabButton>
                    </IonFab> 
                </IonContent>
            </React.Fragment>                
        
        );
}
export default CitasPage2