import React, { useEffect } from 'react';
import { Redirect, Route, RouteComponentProps, Router } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonViewDidEnter,
  IonPage,
  IonToolbar,
  IonRouterLink,
  IonFooter,
  IonContent,
  IonSplitPane
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { apps, flash, send } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import Details from './pages/Details';

import RoleSelector from './pages/RoleSelector';
import TabRoot from './pages/TabRoot';
import NewProveedor from './pages/NewProveedor';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

//import '@mobiscroll/react/dist/css/mobiscroll.min.css';



/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import LandingPage from './pages/LandingPage';
import SideMenu from './components/SideMenu';
import ProveedorComponent from './components/ProveedorComponent';
import { MedicamentosPage } from './pages/MedicamentosPage';
import { InventarioPage } from './pages/InventarioPage';
import { EntradasInventarioPage } from './pages/EntradasInventarioPage';
import { SalidasInventarioPage } from './pages/SalidasInventarioPage';
import { IngresosEgresosPage } from './pages/IngresosEgresosPage';
import { PacientesPage } from './pages/PacientesPage';
import { ArchivoImagenesPage } from './pages/ArchivoImagenesPage';
import { CatalogoGenericoPage } from './pages/CatalogoGenericoPage';
import { PacientesVetPage } from './pages/PacientesVetPage';
import {testPage} from './pages/testPage';
import { isNullOrUndefined } from 'util';
import CitasPage2 from './pages/CitasPage2';
import { FacturasPage } from './pages/FacturasPage';
import { ProductosPage } from './pages/ProductosPage';
import RegistroServicio from './pages/RegistroServicio';
import { BitacoraServiciosPage } from './pages/BitacoraServiciosPage';
import ClientesPage from './pages/ClientesPage';



const App: React.FC = () => {
  useIonViewDidEnter(() => {
    //console.log('ionViewWillEnter event fired')
    window.localStorage['tab1Title'] = '';
    }
  );
  
  
  return(
      <IonReactRouter>
        <div className="App">        
          <IonApp>
          <IonSplitPane contentId="main">         
          {isNullOrUndefined(window.localStorage['username'])?
          <br/>:<SideMenu></SideMenu>
        }            
            <IonPage id="main">        
            <IonRouterOutlet>
          <Route path="/tab1" component={Tab1} exact={true} />
          <Route path="/tab2" component={Tab2} exact={true} />
           <Route path="/tab3" component={Tab3} />           
          <Route path="/"  component={LandingPage} exact />
          <Route path="/proveedores" component={ProveedorComponent} exact />       
          <Route path="/medicamentos" component={MedicamentosPage} exact />
          <Route path="/productos" component={ProductosPage} exact />
          <Route path="/proveedores/new" component={NewProveedor}/>
          <Route path="/clientes/:clientID" component={LandingPage}/>
          <Route path="/pages/home" component={LandingPage}/>
          <Route path="/inventario" component={InventarioPage} exact />  
          <Route path="/inventario/entradas" component={EntradasInventarioPage} exact/>
          <Route path="/inventario/salidas" component={SalidasInventarioPage} exact/>
          <Route path="/movimientos" component={IngresosEgresosPage} exact/>
          {/* <Route path="/pacientes/:pacienteID" component={PacientesPage} exact />
          <Route path="/pacientes" component={PacientesPage} exact /> */}
          <Route path="/imagenes" component={ArchivoImagenesPage}/>
          <Route path="/catalogos/:tipo" component={CatalogoGenericoPage} exact/>
          <Route path="/test1" component={testPage} exact/>
          <Route path="/pacientesVet/:pacienteID" component={PacientesVetPage} exact />
          <Route path="/pacientesVet" component={PacientesVetPage} exact />
          <Route path="/citas" component={CitasPage2} exact/>
          <Route path="/facturas" component={FacturasPage} exact/>
          <Route path="/servicio" component={RegistroServicio} exact/>
          <Route path="/servicio/:clienteEmail" component={RegistroServicio} exact/>
          <Route path="/servicio/cliente/:clienteID/paciente/:pacienteID" component={RegistroServicio} exact/>
          <Route path="/bitacora" component={BitacoraServiciosPage} exact/>
          <Route path="/bitacora/:pacienteID" component={BitacoraServiciosPage} exact />
          <Route path="/clientes" component={ClientesPage} exact/>
        </IonRouterOutlet>
            </IonPage>
            </IonSplitPane>
          </IonApp>
        </div>
      </IonReactRouter>
  /*
  <IonApp>
    <IonReactRouter>
      <IonTabs>      
        <IonRouterOutlet>
          <Route path="/tab1" component={Tab1} exact={true} />
          <Route path="/tab2" component={Tab2} exact={true} />
           <Route path="/tab3" component={Tab3} />           
          <Route path="/" render={() => <Redirect to="/pages/home" />} exact={true} />       
          
          <Route path="/proveedores/new" component={NewProveedor}/>
          <Route path="/clientes/:clientID" component={LandingPage}/>
          <Route path="/pages/home" component={LandingPage}/>      
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/tab1">
            <IonIcon icon={flash} />
            <IonLabel>Catálogos</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/tab2">
            <IonIcon icon={apps} />
           <IonLabel>Citas</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab3" href="/tab3">
            <IonIcon icon={send} />
            <IonLabel>Inventarios</IonLabel>
  </IonTabButton>
        </IonTabBar>
      </IonTabs>      
    </IonReactRouter>
    
    
</IonApp>*/
  );
  
  };

export default App;

export type Cita= {   
    id?: number,       
    paciente: PacienteVet,
    sucursal: string,
    especialidad: string,
    servicio: string,
    encargado: string,
    fecha: string,
    horaInicio: string,
    horaFin: string,    
    comentarios: string
}

export type PacienteVet = {    
  id: Number,    
  nombre: string,
  especie: string,
  raza: string,
  sexo: string,
  peso: string,
  color: string,
  cicatrices: string,
  cirugias: string,
  condicion: string,    
  fechaNacimiento: string,  
  edadAcogida: string,
  fechaAcogida: string,
  procedencia: string,
  entorno: string,
  estiloVida: string,
  nutricion: string,    
  observaciones: string,    
  foto: string,
}

export type Factura ={
  numFactura: string,
  subtotal: number,
  impuestos: number,
  fechaEmision: string,
  fechaPago?: string,
  proveedor: string
}

export type Tarifa = {
  nombre: string,
  monto: number
}

export type Servicio = {
  nombre: string,
  tarifas?: Tarifa[],
  extras?: Tarifa[],
  formServicio?: FormServicio
  validaciones?: string[]
}

export type FormServicio = {  
  monto: boolean, 
  tarifas: boolean, 
  paciente: boolean,
  propietario: boolean,
  celPropietario: boolean,
  soip: boolean,
  productos: boolean,
  extras: boolean,
  usoInterno: boolean
}

export type InfoServicio = {
  id: number,
  servicio: string,
  responsable: string,  
  formaPago: string,
  fecha: string,
  total: number,
  tarifa?: string,
  monto?: number,
  paciente?: any,
  propietario: any,
  //celPropietario?: string,
  subjetivo?: string,
  objetivo?: Objetivo,
  interpretacion?: string,
  plan?: string,
  articulos?: any[],
  extras?: Tarifa[],
  puntos: number,
  articulosUsoInt?: any[]
}

export type Objetivo = {
	EM?: string,
	MM?: string,
	TLLC?: string,
	LN?: string,
	RT?: string,
	RD?: string,
	FC?: string,
	FR?: string,
	PP?: string,
	CP?: string,
	PA?: string,
	Pulso?: string,
	T?: string,
	Peso?: string,
	CC?: string,
  DH?: string,
  Observaciones?: string
}