import React from 'react';
import { IonIcon, IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, 
  IonItem,  IonMenuToggle, IonRouterLink, IonAlert, } from '@ionic/react';
import { Link } from 'react-router-dom';
import { apps, home, send, business, contacts, document, analytics, book, 
  person, paw, construct, clipboard, cash, swap, calendar } from 'ionicons/icons';
import { isNullOrUndefined } from 'util';


class SideMenu extends React.Component<any, any> {
  perfil = JSON.parse(window.localStorage['perfil'])
  modulos = this.perfil.modulos
  constructor(props: any){
    super(props);    
    //console.log("SideMenu.constructor perfil: " + JSON.stringify(this.perfil))    
    //console.log("SideMenu.constructor modulos: " +  JSON.stringify(this.modulos))
    this.state = {      
      //isLoggedIn: localStorage.getItem("isLogin") ? localStorage.getItem("isLogin") :"false",   
      routes:  {
        //appPages: 
        //[
          //{ title: 'Home', path: '/', icon: home },
          //{ title: 'Catalogos', path: '/tab1', icon: apps},
          //{ title: 'Citas', path: '/tab2', icon: send},
          //{ title: 'Pacientes', path: '/pacientes', icon: contacts}
        //]
        //,
        vetPages: [
          { title: 'Inventario', path: '/inventario', icon: home},
          { title: 'Ingresos/Egresos', path: '/movimientos', icon: business},
          // { title: 'Clientes', path: '/clientes', icon: person},
          { title: 'Pacientes vet', path: '/pacientesVet', icon: contacts},
          { title: 'Facturas', path: '/facturas', icon: document},
          { title: 'Registro Servicio', path: '/servicio', icon: analytics},
          { title: 'Bitácora de Servicios', path: '/bitacora', icon: book}
        ]        
        /*,
        loggedInPages: [
          { title: 'My Profile', path: '/profile/'+localStorage.getItem("username"), icon: 'person'},        
          { title: 'New Article', path: '/newarticle', icon: 'create' },
          { title: 'Settings', path: '/settings', icon: 'settings' },
          { title: 'Logout', path: '/login', icon: 'log-out' }
        ],
        loggedOutPages: [
          { title: 'Login', path: '/login', icon: 'log-in' },
        ] */ },
        username: isNullOrUndefined(window.localStorage['username'])? "" : window.localStorage.getItem('username')?.toUpperCase(),
        showConfirm: false
    }
    /*
    window.addEventListener('loggedIn', (e: any) => {            
      this.setState({
        isLoggedIn : e['detail'].toString(),
        routes : {
          appPages: [
            { title: 'Home', path: '/', icon: 'home' },         
          ],
          loggedInPages: [
            { title: 'My Profile', path: '/profile/'+localStorage.getItem("username"), icon: 'person'},        
            { title: 'New Article', path: '/newarticle', icon: 'create' },
            { title: 'Settings', path: '/settings', icon: 'settings' },
            { title: 'Logout', path: '/login', icon: 'log-out' }
          ],
          loggedOutPages: [
            { title: 'Login', path: '/login', icon: 'log-in' },
          ]  }        
      })      
 
    });  */
  } 
  
  getIcono(nombreIcono: string){
    switch(nombreIcono){
      case "apps":
        return apps        
      case "home":
        return home;      
      case "send":
        return send;
      case "business":
        return business;
      case "contacts":
        return contacts;
      case "document":
        return document;        
      case "analytics":
        return analytics;
      case "book":
        return book;
      case "person":
        return person;
      case "construct":
        return construct;
      case "clipboard":
        return clipboard;
      case "cash":
        return cash;
      case "swap":
        return swap;
      /* case "calendar":
        return calendar; */
      default:
        return paw;
    }
  }

   renderMenuItem(menu: any) {
    console.log("SideMenu.renderMenuItem menu: " + JSON.stringify(menu))    
    if(menu == null)
      return;
    return (        
        //  <IonMenuToggle key={menu.title} auto-hide="false">
                   <IonItem key={menu.nombre}>
                     <IonIcon icon={this.getIcono(menu.icono)} color="primary" ></IonIcon>
                     &nbsp;
                     <IonRouterLink  className="sidemenu-link" href={menu.path} >{menu.nombre}</IonRouterLink> 
                   </IonItem>
                //    </IonMenuToggle>
      )
  }
  
  logout(){
    window.localStorage.removeItem('username')
    window.localStorage.removeItem('perfil')
    window.location.href = "/"
  }

  render() {
    function getMenuItems():any[] {
      var arrReturn:any[] = []
      if(JSON.stringify(window.localStorage['perfil']) != "" ){

      }
      return arrReturn;
    }

      return (  
        <IonMenu side="start" menuId="first" contentId="main">
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Menú</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              {/* {this.state.routes.appPages.map((art: any) => this.renderMenuItem(art))}
              
              {this.state.username === "ETON" ? <> {this.state.routes.vetPages.map((art: any) =>
                this.renderMenuItem(art))} </> :<></> } */}
              <> {this.modulos.map((art: any) =>
                this.renderMenuItem(art))} </>
              <IonItem>
                <IonRouterLink onClick={()=>this.setState({showConfirm:true})}
                  color='medium'>
                  Cerrar sesión
                </IonRouterLink>
              </IonItem>
            </IonList>
            <IonAlert isOpen={this.state.showConfirm}
                        onDidDismiss={()=>this.setState({showConfirm:false})}                    
                        header="Advertencia"
                        message="¿Realmente desea cerrar su sesión?"
                        buttons={[
                            {
                                text: "Cancelar",
                                role: "cancel",
                                cssClass: "cancelButton",
                                handler: () => this.setState({showConfirm:false})
                            },
                            {
                                text: "Continuar",
                                // cssClass: "dangerButton",
                                handler: this.logout
                            }
                        ]}
                    />           
          </IonContent>
        </IonMenu>
      )
  }
}
export default SideMenu