import React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { isNullOrUndefined } from "util";
import { IonHeader, IonContent, IonToast, IonCardContent, IonCard, IonGrid, 
        IonRow, IonCol, IonModal, IonSelectOption, IonSelect, IonInput, 
        IonButton, IonFab, IonFabList, IonFabButton, IonIcon, IonTitle, 
        IonToolbar, IonItem, IonAlert, IonLoading } from 
        "@ionic/react";
import { add, returnLeft, returnRight, create, removeCircle } from "ionicons/icons";
import "./IngresosEgresos.css";
import { callDeleteMovimiento, callGetConceptos, callGetFormasPago, callGetMovimientos, callModifyMovimiento, callNewMovimiento } from "../firebaseConfig";
import { checkAccessToModule } from "../helper";

type State={
    concepto: string,
    monto: number,
    fecha: string,
    detalle: string,
    showNew: boolean,
    tipo: number,
    showToast: boolean,
    inicio: string,
    fin: string,
    totalIngresos: number,
    totalEgresos: number,
    formaPago: string,
    operacion: number,
    idEdit: string,
    showPasswordPopup: boolean ,
    showConfirm: boolean,
    toastMessage: string,
    movFiltrados: Array<any>,
    movimientos: any[],
    formasPago: any[],
    conceptosIngresos: any[],
    conceptosEgresos: any[],
    showLoading: boolean,
    loadingMsg: string
}

export class IngresosEgresosPage extends React.Component<RouteComponentProps, 
    State>{
    
    //movimientos: Array<any> = [];
    //movFiltrados: Array<any> = [];
    //conceptosIngresos: Array<any> = [];
    //conceptosEgresos: Array<any> = [];
    //formasPago: Array<any> = [];
    //inicio: Date;
    //fin: Date;

    constructor(props: any){
        super(props)
        if(isNullOrUndefined(window.localStorage["adminPassword"]))
            window.localStorage["adminPassword"] = "justwinbaby";
        //if(!isNullOrUndefined(window.localStorage["movimientos"]))
        //    this.movimientos = JSON.parse(window.localStorage["movimientos"]);        
        this.getMovimientosFromCloud()
        var date = new Date();
        //this.inicio = new Date(date.getFullYear(), date.getMonth(), 1);
        //this.fin = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let fDate = new Date(date.getFullYear(), date.getMonth(), 1)
        let lDate = 
            new Date(date.getFullYear(), date.getMonth() + 1, 0)
        let firstDay = this.dateFormatter.format(fDate) //fDate.getFullYear() + "-" +  fDate.getMonth() + "-" + 
            //fDate.getDate()            
        let lastDay = this.dateFormatter.format(lDate)//lDate.getFullYear() + "-" + lDate.getMonth() + "-" + 
            //lDate.getDate()
            
        this.filtrarMovimientos = this.filtrarMovimientos.bind(this);   
        this.getMovimientosFromCloud = this.getMovimientosFromCloud.bind(this)
        this.getConceptosFromCloud = this.getConceptosFromCloud.bind(this)
        this.state = {
            concepto: "",
            monto: 0,
            fecha: "",
            detalle: "",
            showNew: false,
            tipo: 0,
            showToast: false,
            inicio: firstDay,
            fin: lastDay,
            totalIngresos: 0, // this.getTotal(1, firstDay, lastDay),
            totalEgresos: 0, //this.getTotal(2, firstDay, lastDay),
            //totalIngresos: this.getTotal(1, this.inicio, this.fin),
            //totalEgresos: this.getTotal(2, this.inicio, this.fin),
            formaPago: "",
            operacion: 1,
            idEdit: "",
            showPasswordPopup: false,
            showConfirm: false,
            toastMessage: "",
            //movFiltrados: [this.filtrarMovimientos(
            //    firstDay.toString(), lastDay.toString())],
            movFiltrados: [],
            movimientos: [],
            formasPago: [],
            conceptosIngresos: [],
            conceptosEgresos: [],
            showLoading: true,
            loadingMsg: "Cargando..."
        }                

        this.getConceptosFromCloud()
        /*
        this.conceptosIngresos = [{id:1, nombre: "Venta medicamentos"},{id:2, nombre: "Consulta"}]
        this.conceptosEgresos = [{id:1, nombre: "Pago Servicios"},
            {id:2, nombre: "Compra Inventario"}, {id:3, nombre: "Nómina"}];
        */

        this.getFormasPagoFromCloud()    
        /*
        if(window.localStorage["formasPago"] === undefined){
            var fPagoDefault = [{id: 1, nombre: "Efectivo"}, {id:2, nombre: "Tarjeta Bancaria"},
                {id: 3, nombre: "Transferencia"}]
            window.localStorage["formasPago"] = JSON.stringify(fPagoDefault)
        }
        this.formasPago = JSON.parse(window.localStorage['formasPago'])
        */
    }

    getConceptosFromCloud(){
        callGetConceptos().then(res=>{
            const conceptos = res as any[]
            let entrada = [] as any[]
            let salida = [] as any[]
            conceptos.map((item)=>{
                if(item.tipo == 1)
                    entrada.push(item)
                else
                    salida.push(item)
            })
            this.setState({
                conceptosIngresos: entrada,
                conceptosEgresos: salida
            })
        })
    }

    getFormasPagoFromCloud(){
        callGetFormasPago().then(res=>{
            this.setState({
                formasPago: res as []
            })
        })
    }

    getMovimientosFromCloud(){
        callGetMovimientos().then(res=>{
            this.setState({
                movimientos: res as [],
                movFiltrados: this.filtrarMovimientos(res as [], 
                    this.state.inicio.toLocaleString(), this.state.fin.toLocaleString()),
                totalIngresos: this.getTotal(res as [], 1, 
                    this.state.inicio.toLocaleString(),
                    this.state.fin.toLocaleString()),
                totalEgresos: this.getTotal(res as [], 2, 
                    this.state.inicio.toLocaleString(),
                    this.state.fin.toLocaleString()),
                    showLoading: false
                })
        })
    }

    filtrarMovimientos(movArray: any[], inicioString: string, finString: string){        
        //this.setState({movFiltrados: []});
        let inicio = new Date(inicioString + "T12:00:00")
        let fin = new Date(finString + "T12:00:00")
        inicio.setHours(0,0,1)
        fin.setHours(23,59,0)          
        var movFiltrados = [];        
        for(let i = 0; i < movArray.length; i++){            
            let fechaMov = new Date(movArray[i].fecha)
            fechaMov.setHours(12,0,0)              
            //console.log("fechaMov: " + fechaMov + " inicio: " +  inicio + " fin: " + fin)
            //console.log("fechaMov >= inicio?" +  (fechaMov >= inicio))
            //console.log("fechaMov <= fin?" +  (fechaMov <= fin))
           /*  if(fechaMov >= inicio)
                alert("fechaMov: " + fechaMov.toLocaleDateString() + " es mayor que inicio: " +
                    inicio)
            if(fechaMov <= fin)
                alert("fechaMov: " + fechaMov.toLocaleDateString() + " es menor que fin: " +
                    fin) */
            if(fechaMov.getTime() >= inicio.getTime() && fechaMov.getTime() <= fin.getTime())
                movFiltrados.push(movArray[i]); 
            /* if(new Date(this.movimientos[i].fecha) >= this.inicio &&
                new Date(this.movimientos[i].fecha) <= this.fin)
                    movFiltrados.push(this.movimientos[i]);  */
        }
        //console.log("filtrados " + movFiltrados.length + " movimientos con " + 
        //    inicio + " y " + fin + ": " +  JSON.stringify(movFiltrados))        
        return movFiltrados;        
        //alert("movimientos filtrados: " +  this.state.movFiltrados )
    }

    conceptoChange = (event: CustomEvent) => {
        this.setState({ concepto: event.detail.value});
        } 
    montoChange = (event: CustomEvent) => {        
        // if(/\d+(?:[.]\d+)?/.test(event.detail.value)){
            const montoVal = event.detail.value? 
                parseFloat(event.detail.value):0
            console.log(montoVal)
            this.setState({ 
                monto: montoVal
                });        
        /*
            }
        else{
            this.setState({monto:0})
        } */        
            
        }         
    fechaChange = (event: CustomEvent) => {
        this.setState({ fecha: event.detail.value});
        } 
    detalleChange = (event: CustomEvent) => {
        this.setState({ detalle: event.detail.value});
        } 
    inicioChange = (event: CustomEvent) => {
        //console.log("valor recibido: " + event.detail.value);
        //var temp = event.detail.value.toString();
        //let i = temp.indexOf('T');               
        //this.inicio = 
        //let inicio = new Date(temp.substring(0,i))
        //this.setState({ inicio: new Date(temp.substring(0,i)) });
        /* this.setState({totalIngresos: this.getTotal(1, this.inicio, this.fin),
                    totalEgresos: this.getTotal(2, this.inicio, this.fin),
                    movFiltrados: this.filtrarMovimientos()});         */

        /* this.setState({
            totalIngresos: this.getTotal(this.state.movimientos, 1, 
                event.detail.value, this.state.fin),
            totalEgresos: this.getTotal(this.state.movimientos, 2, 
                event.detail.value, this.state.fin),
            movFiltrados: this.filtrarMovimientos(this.state.movimientos, 
                event.detail.value, this.state.fin.toString()),
            inicio: event.detail.value});         */
            this.setState({
                inicio: event.detail.value
            })
            this.updateTotales()

        }
    finChange = (event: CustomEvent) => {
        //var temp = event.detail.value.toString();
        //let i = temp.indexOf('T');
        //console.log(temp.substring(0,i)+"T23:59:59");           
        //this.setState({ fin: new Date(temp.substring(0,i)+"T23:59:59")});        
        //this.fin = new Date(temp.substring(0,i)+"T23:59:59")
        //let fin = new Date(temp.substring(0,i)+"T23:59:59") 

        /* console.log("IngresosEgresosPage.finChange valor: " + event.detail.value)
        const newIngresos = this.getTotal(this.state.movimientos, 1, 
            this.state.inicio, event.detail.vale)
        console.log("IngresosEgresosPage.finChange newIngresos: " + newIngresos)
        const newEgresos = this.getTotal(this.state.movimientos, 2, 
            this.state.inicio, event.detail.value)
        console.log("IngresosEgresosPage.finChange newEgresos: " + newEgresos)
        const newFiltrados = this.filtrarMovimientos(this.state.movimientos, 
            this.state.inicio.toString(), event.detail.value)
        this.setState({
            totalIngresos: newIngresos,
            totalEgresos: newEgresos,
            movFiltrados: newFiltrados,
            fin: event.detail.value});       */

        this.setState({
            fin: event.detail.value
        })
        this.updateTotales()

        /* this.setState({totalIngresos: this.getTotal(1, this.inicio, this.fin),
            totalEgresos: this.getTotal(2, this.inicio, this.fin),
            movFiltrados: this.filtrarMovimientos()});         */
        }

    updateTotales(){        
        const newIngresos = this.getTotal(this.state.movimientos, 1, 
            this.state.inicio.toString(), this.state.fin.toString())
        //console.log("updateTotales newIngresos: " + newIngresos)
        const newEgresos = this.getTotal(this.state.movimientos, 2, 
            this.state.inicio.toString(), this.state.fin.toString())
        //console.log("updateTotales newEgresos: " + newEgresos)
        const newFiltrados = this.filtrarMovimientos(this.state.movimientos, 
            this.state.inicio.toString(), this.state.fin.toString())
        this.setState({
            totalIngresos: newIngresos,
            totalEgresos: newEgresos,
            movFiltrados: newFiltrados});      
    }
    
    formaPagoChange = (event: CustomEvent) => {
        this.setState({formaPago: event.detail.value});
    }   

    getNewId(){
        var idReturn: number = -1;
        if(isNullOrUndefined(window.localStorage['movimientoNewId']))
            idReturn = 1;
        else
            idReturn = Number.parseInt(window.localStorage['movimientoNewId']);
        window.localStorage['movimientoNewId'] = idReturn+1;
        return idReturn;
    }

    submitMovimiento = () =>{   
        if(this.state.concepto == ""){
            this.setState({
                toastMessage: "Elija un concepto para continuar",
                showToast: true                
                })
            return;
            }
        if(this.state.monto <= 0){
            this.setState({
                toastMessage: "Ingrese el monto para continuar",
                showToast: true                
                })
            return;
            }
        if(this.state.fecha == ""){
            this.setState({
                toastMessage: "Ingrese la fecha para continuar",
                showToast: true                
                })
            return;
            }
        if(this.state.formaPago == ""){
            this.setState({
                toastMessage: "Elija una forma de pago para continuar",
                showToast: true                
                })
            return;
            }        
        this.setState({ 
            showLoading: true,
            loadingMsg: "Guardando..."
        })      
        let movimientoData = {
            tipo: this.state.tipo,
            id: this.state.operacion == 1? "undefined" : this.state.idEdit,
            concepto: this.state.concepto,
            monto: this.state.monto,
            fecha: this.state.fecha,
            detalle: this.state.detalle,
            formaPago: this.state.formaPago
        }
        //console.log("objeto a guardar: " + JSON.stringify(movimientoData));
        if(this.state.operacion == 1)
            callNewMovimiento(movimientoData).then(res=>{
                this.getMovimientosFromCloud()                
                this.showSaveSuccess("Se guardó la información")
            })
        else
            callModifyMovimiento(movimientoData).then(res=>{
                this.getMovimientosFromCloud()
                this.showSaveSuccess("Se guardó la información")
            })        
        /* window.localStorage['movimientos'] = 
            JSON.stringify(this.state.movimientos);
        this.setState({
            totalIngresos: this.getTotal(this.state.movimientos, 1, 
                this.state.inicio, this.state.fin),
            //totalIngresos: this.getTotal(1, this.inicio, this.fin),
            totalEgresos: this.getTotal(this.state.movimientos, 2, 
                this.state.inicio, this.state.fin),
            //totalEgresos: this.getTotal(2, this.inicio, this.fin),
            concepto: "",
            monto: 0,
            fecha: "",
            detalle: "",
            showToast: true,
            tipo: 0,
            showNew: false,
            formaPago: "",
            operacion: 1,
            toastMessage: "Se guardó la información",
            movFiltrados: this.filtrarMovimientos(this.state.movimientos, 
                this.state.inicio.toISOString(), this.state.fin.toISOString())
        });    */      
        }

        showSaveSuccess(msg: string){
            this.setState({
                concepto: "",
                monto: 0,
                fecha: "",
                detalle: "",
                showToast: true,
                tipo: 0,
                showNew: false,
                formaPago: "",
                operacion: 1,
                toastMessage: msg,
                showLoading: false
            }); 
        }

        cancelNew(){
            this.setState({                
                concepto: "",
                monto: 0,
                fecha: "",
                detalle: "",
                tipo: 0,
                showNew: false,
                formaPago: "",
                operacion: 1
            });
        }

        nuevaClick(action:number){
            this.setState({
                tipo:action,
                showNew: true,
                operacion:1});
        }
                
        getTotal(movArray: any[], tipo: number, inicio: string, fin: string){                        
            //console.log("IngresosEgresosPage.getTotal movArray.length: " + 
            //    movArray.length + " tipo: " +  tipo +  " inicio: " +
            //    inicio + " fin: " + fin)
            
            if(isNullOrUndefined(inicio)){
                //console.log("inicio es nulo o indefinido")
                inicio = new Date(new Date().getFullYear(), new Date().getMonth()
                    , 1).toString();
            }
            else{
                //console.log("inicio no es nulo o indefinido: " + inicio)
            }
            if(isNullOrUndefined(fin)){
                //console.log("fin es nulo o indefinido")
                fin = new Date(new Date().getFullYear(), new Date().getMonth() + 1
                    , 0).toString();
            }
            else{
                //console.log("fin no es nulo o indefinido: " + fin)
            }
            var total = 0;
            for(let i = 0; i < movArray.length; i++){
                var movimiento = movArray[i];
                //console.log("IngresosEgresosPage.getTotal " + new Date(
                //    movimiento.fecha).toDateString() + " >= " + inicio)
                //console.log("IngresosEgresosPage.getTotal " + new Date(
                //    movimiento.fecha).toDateString() + " <= " + fin)
                const movFecha = new Date(movimiento.fecha)
                const iniFecha = new Date(inicio + "T12:00:00")
                const finFecha = new Date(fin + "T12:00:00")
                iniFecha.setHours(0,0,1)
                finFecha.setHours(23,59,0)  
                movFecha.setHours(12,0,0)              
                /* const dateMov = new Date(movFecha.getFullYear(),movFecha.getMonth(),
                    movFecha.getDate(),18,0,0,0)
                const dateIni = new Date(iniFecha.getFullYear(),iniFecha.getMonth(),
                    iniFecha.getDate(),6,0,1,0)
                const dateFin = new Date(finFecha.getFullYear()+1,finFecha.getMonth(),
                    finFecha.getDate(),5,59,59,0)
                console.log("dateMov: " + dateMov.toDateString() + " dateIni: "
                    + dateIni.toDateString() + " dateFin: " + 
                    dateFin.toDateString() + " indice: " + i)     */               
                //console.log("movFecha: " + dateMov.toDateString() + " inicio: "
                //    + inicio + " fin: " + 
                //    fin + " indice: " + i)                                   
                //console.log(dateMov + " >= " + inicio + " = " + (dateMov >= inicio))
                //console.log(dateMov + " <= " + fin + " = " + (dateMov <= fin))
                //console.log(dateMov.getTime() + " >= " + dateIni.getTime() + " = " + (dateMov.getTime() >= dateIni.getTime()))
                //console.log(dateMov + " <= " + dateFin + " = " + (dateMov <= dateFin))
                //if(dateMov.getTime() >= dateIni.getTime() && dateMov.getTime() <= dateFin.getTime()){
                //if(dateMov >= inicio && dateMov <= fin){
                //console.log("movFecha: " + movFecha.toString() + " iniFecha: " +
                //    iniFecha.toString() + " finFecha: " + finFecha.toString())
                if(movFecha.getTime() >= iniFecha.getTime() && movFecha.getTime() <= finFecha.getTime()){
                    //console.log("sí entró " + JSON.stringify(movimiento))                    
                    if(movimiento.tipo == tipo)
                       total += parseFloat(movimiento.monto);                    
                    }
            }
            return total;
        }

        editMovimiento(){            
            let mov = this.getMovimientoById(this.state.idEdit);
            console.log(JSON.stringify(mov));
            this.setState({
                showNew: true,
                operacion: 2,
                //idEdit: mov.id,
                tipo: mov.tipo,
                concepto: mov.concepto,
                monto: mov.monto,
                fecha: mov.fecha,
                detalle: mov.detalle,
                formaPago: mov.formaPago});            
        }

        deleteMovimiento(){            
            let mov = this.getMovimientoById(this.state.idEdit);
            console.log("Se va a eliminar: " +JSON.stringify(mov));
            this.setState({
                showLoading: true,
                loadingMsg: "Guardando..."
            })
            callDeleteMovimiento(this.state.idEdit).then(res=>{
                this.getMovimientosFromCloud()
                this.showSaveSuccess("Se eliminó la información")
            })
            /* for(var i=0; i<this.state.movimientos.length; i++){
                if(this.state.movimientos[i].id == this.state.idEdit){
                    this.state.movimientos.splice(i,1);
                }
            }        
            window.localStorage['movimientos'] = 
                JSON.stringify(this.state.movimientos);
            this.setState({
                //totalIngresos: this.getTotal(1, this.inicio, this.fin),
                //totalEgresos: this.getTotal(2, this.inicio, this.fin),
                totalIngresos: this.getTotal(this.state.movimientos, 1, 
                    this.state.inicio, this.state.fin),
                totalEgresos: this.getTotal(this.state.movimientos, 2, 
                    this.state.inicio, this.state.fin),
                showToast: true,
                tipo: 0,
                showNew: false,
                operacion: 1,
                toastMessage: "Se eliminó la información"
            });*/
            
        }

        getMovimientoById(id: string){
            for(let i=0; i< this.state.movimientos.length;i++){
                if(this.state.movimientos[i].id == id)
                    return this.state.movimientos[i];
            }
            return {};
        }

        getFormTitle(){
            //console.log("operacion vale: " + this.state.operacion);
            if(this.state.operacion == 1)
                return this.state.tipo==1?"Nuevo ingreso":"Nuevo egreso";
            else
                return this.state.tipo==1?"Editar ingreso":"Editar egreso"
        }

        triggerPasswordPopup(operacion: number, id: string){
            this.setState({
                operacion: operacion,
                showPasswordPopup: true,
                idEdit: id
            })
        }
        validateKeyCurrency(event: any){        
            const regex = /^[0-9]|\./        
            if(!regex.test(event.data))
                event.preventDefault();
        }

        private curFormatter  = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'narrowSymbol'
          });

        private dateFormatter = new Intl.DateTimeFormat('sv-SE')

    render(){  
        if(!checkAccessToModule('Ingresos/Egresos'))
            return(<Redirect to="/pages/home"/>)
        
        return(
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                    INGRESOS/EGRESOS
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>  
                <IonLoading
                    isOpen={this.state.showLoading}
                    message={this.state.loadingMsg}
                />                 
                <IonAlert isOpen={this.state.showPasswordPopup}
                    onDidDismiss={()=>this.setState({showPasswordPopup: false})}                    
                    header="Verificación"
                    message="Ingrese la contraseña de administrador"
                    inputs={[
                        {
                            name:"password",
                            type:"password"
                        }
                    ]}
                    buttons={[
                        {
                            text: "Cancelar",
                            role: "cancel",
                            cssClass: "cancelButton",
                            handler: () =>{
                                this.setState({showPasswordPopup:false});
                            }
                        },
                        {
                            text: "Continuar",
                            handler: data =>{
                                if(data.password == window.localStorage["adminPassword"]){
                                    this.setState({
                                        showPasswordPopup: false});
                                    if(this.state.operacion==2)
                                        this.editMovimiento();
                                    else
                                        this.setState({showConfirm: true});
                                }
                                else{
                                    this.setState({
                                        showToast: true,
                                        toastMessage: 
                                        "Contraseña incorrecta, intente de nuevo"});
                                        return false;
                                }
                                    
                            }
                        }
                    ]}/>
                <IonAlert isOpen={this.state.showConfirm}
                    onDidDismiss={()=>this.setState({showConfirm: false})}                    
                    header="Advertencia"
                    message="¿Realmente desea eliminar el registro?"
                    buttons={[
                        {
                            text: "Cancelar",
                            role: "cancel",
                            cssClass: "cancelButton",
                            handler: () =>{
                                this.setState({showPasswordPopup:false});
                            }
                        },
                        {
                            text: "Continuar",
                            cssClass: "dangerButton",
                            handler: () =>this.deleteMovimiento()                                    
                        }
                    ]}
                />
                <IonToast
                    isOpen={this.state.showToast}
                    onDidDismiss={()=>this.setState({ showToast: false})}
                    message={this.state.toastMessage}
                    duration={800}
                    position="middle"
                />
                <IonCard>
                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem>
                                    Total Ingresos: &nbsp;                                                                            
                                    {this.curFormatter.format(
                                        this.state.totalIngresos)}
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem>
                                    Total Egresos: &nbsp;                                   
                                    {this.curFormatter.format( 
                                        this.state.totalEgresos)}
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="gridHeaderCol">
                                    Fecha Inicial:
                                </IonCol>
                                <IonCol className="gridHeaderCol">
                                    Fecha Final:
                                </IonCol>                                    
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    {/*  <IonDatetime
                                        displayFormat="DD/MM/YYYY" 
                                        placeholder= {this.inicio.toLocaleDateString()}
                                        onIonChange={this.inicioChange}/> */}
                                    <IonInput
                                        type="date"
                                        value= {this.state.inicio.toLocaleString()||new Date().toLocaleString()}
                                        placeholder={this.state.inicio.toLocaleString()}
                                        onIonChange={this.inicioChange}/>
                                </IonCol>
                                <IonCol>
                                    {/* <IonDatetime
                                        displayFormat="DD/MM/YYYY" 
                                        placeholder= {this.fin.toLocaleDateString()}
                                        onIonChange={this.finChange}/> */}
                                    <IonInput
                                        type="date"
                                        value= {this.state.fin.toLocaleString()||new Date().toLocaleString()}
                                        placeholder={this.state.fin.toLocaleString()}
                                        onIonChange={this.finChange}/>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonGrid>
                            <IonRow>
                                <IonCol className="gridHeaderCol" size ="1">Tipo</IonCol>
                                <IonCol className="gridHeaderCol" size ="2">Concepto</IonCol>
                                <IonCol className="gridHeaderCol" size ="2">Monto</IonCol>
                                <IonCol className="gridHeaderCol" size="2">Fecha</IonCol>                                                                                                            
                                <IonCol className="gridHeaderCol" size="2">Detalle</IonCol>
                                <IonCol className="gridHeaderCol" size="2">Forma pago</IonCol>
                                <IonCol>&nbsp;</IonCol>
                            </IonRow>
                            {                                
                            this.state.movFiltrados.length > 0?
                            this.state.movFiltrados.map((movimiento)=>

                            <IonRow>
                                <IonCol size="1">{movimiento.tipo==1? "I" : "E"}</IonCol>
                                <IonCol size ="2">{movimiento.concepto}</IonCol>
                                <IonCol size="2">{this.curFormatter.format(
                                    movimiento.monto)}</IonCol>
                                <IonCol size="2">{new Date(movimiento.fecha)
                                    .toLocaleDateString()}</IonCol>
                                <IonCol size="2">{movimiento.detalle}</IonCol>
                                <IonCol size="2">{movimiento.formaPago}</IonCol>
                                <IonCol><IonIcon icon={create} 
                                    onClick={()=>this.triggerPasswordPopup(2, movimiento.id)}
                                    className="gridEditIcon"/>
                                </IonCol>
                                <IonCol><IonIcon icon={removeCircle} 
                                    onClick={()=>this.triggerPasswordPopup(3, movimiento.id)}
                                    className="gridRemoveIcon"/>
                                </IonCol>
                            </IonRow>
                            
                            ):
                            <IonRow>
                                <IonCol>No hay movimientos registrados para el periodo de fechas elegido</IonCol>
                            </IonRow>                                
                        }
                        </IonGrid>                            
                        <IonModal isOpen={this.state.showNew} backdropDismiss={false}>
                            <form>
                            <IonHeader>
                                <IonToolbar>                                    
                                    <IonTitle>
                                    {this.getFormTitle()}
                                    </IonTitle>                                    
                                </IonToolbar>      
                            </IonHeader>                          
                            <IonGrid>                                                                                                                                                          
                                <IonRow>
                                    <IonCol className="formKeyLabel">Concepto</IonCol>
                                    <IonCol>
                                        <IonSelect value={this.state.concepto} 
                                        onIonChange={this.conceptoChange}>
                                        {
                                            this.state.tipo == 1?
                                            this.state.conceptosIngresos.map((concepto)=>
                                                <IonSelectOption value={concepto.nombre}>
                                                    {concepto.nombre}
                                                </IonSelectOption>
                                            ) :
                                            this.state.conceptosEgresos.map((concepto)=>
                                                <IonSelectOption value={concepto.nombre}>
                                                    {concepto.nombre}
                                                </IonSelectOption>
                                            )
                                        }
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="formKeyLabel">Monto</IonCol>
                                    <IonCol>
                                        <IonInput value={
                                            this.state.monto==0?
                                            null : this.state.monto?.toString()
                                        }
                                        onBeforeInput={this.validateKeyCurrency}
                                        clearInput={true}
                                        onIonChange={this.montoChange}/>
                                    </IonCol>                                    
                                </IonRow>
                                <IonRow>
                                    <IonCol className="formKeyLabel">Fecha de pago</IonCol>
                                    <IonCol>
                                        <IonInput type="date" onIonChange={this.fechaChange}
                                        value={this.state.fecha}/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="formKeyLabel">Detalle</IonCol>
                                    <IonCol>
                                        <IonInput type="text" onIonChange={this.detalleChange}
                                        value={this.state.detalle}/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="formKeyLabel">Forma de pago</IonCol>
                                    <IonCol>
                                        <IonSelect 
                                            value={this.state.formaPago}
                                            onIonChange={this.formaPagoChange}>
                                            {this.state.formasPago.map((fdp)=>
                                                <IonSelectOption value={fdp.nombre}>
                                                    {fdp.nombre}
                                                </IonSelectOption>   
                                            )}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton onClick={this.submitMovimiento}>
                                            Guardar
                                        </IonButton>
                                        <IonButton onClick={()=>this.setState({                
                                            concepto: "",
                                            monto: 0,
                                            fecha: "",
                                            detalle: "",
                                            tipo: 0,
                                            showNew: false,
                                            formaPago: ""
                                        })} color="medium">
                                            Cancelar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                            </form>
                        </IonModal>
                    </IonCardContent>
                </IonCard>
                <IonFab vertical="top" horizontal="end">
                    <IonFabButton  title="Registrar nuevo...">
                            <IonIcon icon={add}/>
                            </IonFabButton>
                            <IonFabList side="bottom">                                    
                                <IonFabButton onClick={()=>this.nuevaClick(1)}
                                color="success" title="Ingreso">
                                    <IonIcon icon={returnLeft} />
                                </IonFabButton>
                                <IonFabButton onClick={()=>this.nuevaClick(2)}
                                color="danger" title="Egreso">
                                    <IonIcon icon={returnRight}/>
                                </IonFabButton>
                            </IonFabList>
                        </IonFab>
            </IonContent>                                     
        </>
        );
    }
        
}