import React from "react";
import { IonItem, IonGrid, IonRow, IonCol } from "@ionic/react";
import { isNullOrUndefined } from "util";

type Props={
    tipo: string,
    producto: string,
    cantidad: number,
    precioCompra?: number,
    precioVenta: number,
    precioMayoreo?: number,
    numeroLote?: string
}

const ProductoESCard: React.FunctionComponent<Props> = (props) =>{

    var productos: any[] = [];
    if(!isNullOrUndefined(window.localStorage['productos']))
        productos = JSON.parse(window.localStorage['productos']);
    
    function getMedName(id:number){
        let item = productos.find(i => i.id === id);
        return item? item.nombre : "";
    }
    
    if(props.tipo == "entrada")
    return(

        <IonRow>
{/*                     <IonCol>
                Medicamento:
            </IonCol>
*/}         <IonCol class="itemCardValue" size="3">{props.producto}</IonCol>
{/*                     <IonCol>
                Número de lote:
            </IonCol>
*/}         <IonCol class="itemCardValue" size="1">{props.cantidad}</IonCol>
{/*                     <IonCol>
                Cantidad:
            </IonCol>
*/}         <IonCol class="itemCardValue" size="2">{props.numeroLote}</IonCol>
{/*                     <IonCol>
                Precio Compra:
            </IonCol>
*/}         <IonCol class="itemCardValue" size="2">{props.precioCompra}</IonCol>
{/*                     <IonCol>
                Precio Venta:
            </IonCol>
*/}         <IonCol class="itemCardValue" size="2">{props.precioVenta}</IonCol>
{/*                     <IonCol>
                Precio Mayoreo:
            </IonCol>
*/}         <IonCol class="itemCardValue" size="2">{props.precioMayoreo}</IonCol>
        </IonRow>
    );
    else
    return(

        <IonRow>
         <IonCol class="itemCardValue" size="6">{props.producto}</IonCol>
         <IonCol class="itemCardValue" size="2">{props.cantidad}</IonCol>
         <IonCol class="itemCardValue" size="4">{props.precioVenta}</IonCol>
        </IonRow>
    );

}
export default ProductoESCard