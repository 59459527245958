import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton } from '@ionic/react';
import { RouteComponentProps } from 'react-router';

interface tab3Props extends RouteComponentProps<{ }> {}

const Tab3: React.FunctionComponent<tab3Props> = ({history}) => {
  return (
    <React.Fragment>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Tab Three</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonButton onClick={e =>{
          e.preventDefault();
          window.localStorage['username'] = "";
          history.push('/roleSelector');

        }}>Cierre su sesión</IonButton>
      </IonContent>
    </React.Fragment>
  );
};

export default Tab3;
