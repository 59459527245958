import { MedicamentosPage } from "../pages/MedicamentosPage"
import React from "react";
import { IonItem, IonGrid, IonRow, IonCol } from "@ionic/react";
import { isNullOrUndefined } from "util";

type Props={
    nombre: string,
    codigo: string,
    codigoBarras: string,
    fabricante:string,
    stockMinimo: number,
    stockActual: number
}

const MedicamentoCard: React.FunctionComponent<Props> = (props) =>{

    const laboratorios: any[] = isNullOrUndefined(
            window.localStorage['fabricantes']) ? 
                [{"codigo":1,"tipo":"laboratorio","nombre":"Astra Zeneca"},{"codigo":2,"tipo":"alimento","nombre":"Pedigree"},{"codigo":3,"tipo":"material","nombre":"Degasa"}] 
                : [];

    function getLabName(codigo:string){
        let item = laboratorios.find(i => i.codigo === codigo);
        return item? item.nombre : "";
    }

    return(
        <IonItem>            
            <IonGrid>
                <IonRow>
                    <IonCol className="itemCardTitle">
                    {props.nombre}
                    </IonCol>
                </IonRow>
                <IonRow>
                    {/* <IonCol className="itemCardKey">Codigo:</IonCol>
                    <IonCol className="itemCardValue">{props.codigo}</IonCol> */}
                    <IonCol className="itemCardKey">Fabricante:</IonCol>
                    <IonCol className="itemCardValue">{props.fabricante}</IonCol>
                    <IonCol className="itemCardKey">Stock Minimo:</IonCol>
                    <IonCol className="itemCardValue">{props.stockMinimo}</IonCol>
                    <IonCol className="itemCardKey">Stock Actual:</IonCol>
                    <IonCol className="itemCardValue">{props.stockActual}</IonCol>
                </IonRow>
            </IonGrid>
        </IonItem>
    );

}
export default MedicamentoCard