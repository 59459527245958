import React, {useEffect, useState} from 'react';
import { IonHeader, IonTitle, IonContent, IonCard, IonItem, isPlatform, IonInput, IonButton, IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol, IonCardContent, IonLabel, IonRouterLink, IonModal, IonList, IonMenuButton, IonToolbar, IonAlert, IonLoading } from '@ionic/react';
import './ProveedorComponent.css';
import { RouteComponentProps } from 'react-router';
import { attachProps } from '@ionic/react/dist/types/components/utils';
import { isNullOrUndefined } from 'util';
import ProveedorCard from './ProveedorCard';
import { Link } from 'react-router-dom';
import { callDeleteProveedor, callGetProveedores, callModifyProveedor } from '../firebaseConfig';
import { statement } from '@babel/template';

type Props = { 
    idExistent: number
} ;
/*
type State = {     
    razonSocial: string, direccion: string, colonia: string, cp: string, pais: string,    
    estado: string, ciudad: string, telefono: string, email: string
    };
*/

const ProveedorComponent:  React.FC = () =>{
    //var proveedores: any[] = [];
    /*
    if(isNullOrUndefined(window.localStorage['proveedores'])){
         let provArr = [
            {'razonSocial':'Macro Distribuidora Medivet S.A. de C.V.',
            'direccion':'Av. Vicente Guerrero, Mz 7 Lt 23 y 24',
            'colonia':'Poesía Mexicana',
            'cp':'55249',
            'pais':'México',
            'estado':'Estado de México',
            'ciudad':'Ecatepec de Morelos',
            'telefono':'55 - 4747 - 5256',
            'email':'medivet@distribuidoramedivet.com.mx'
            }]            
            window.localStorage['proveedores'] = JSON.stringify(provArr)
    }
    */   
    
    useEffect(()=>{
        getProveedoresFromCloud()
    },[])
    
    function getProveedoresFromCloud(){
        callGetProveedores().then(ret =>{
            //console.log("ret: " +  ret)
            const colProveedores = ret as []
            //console.log("conteo: " + colProveedores.length)
            setProveedores(colProveedores)
            setShowLoading(false)
        })
    }
    //proveedores = JSON.parse(window.localStorage['proveedores']);    
    
    //const proveedor = proveedores[props.idExistent];
    const[editId, setEditId] = useState("");
    const[showModal, setShowModal] = useState(false);
    const[razonSocial, setRS] = useState("");
    const[direccion, setDireccion] = useState("");
    const[colonia, setColonia] = useState("");
    const[cp, setCP] = useState("");
    const[pais, setPais] = useState("");
    const[estado, setEstado] = useState("");
    const[ciudad, setCiudad] = useState("");
    const[telefono, setTelefono] = useState("");
    const[email, setEmail] = useState("");    
    const[mode, setMode] = useState("view");
    const[showEdit, setShowEdit] = useState(true);
    const[showConfirm, setShowConfirm] = useState(false);
    const[proveedores, setProveedores] = useState([] as any[])
    const[showLoading, setShowLoading] = useState(true)
    const[loadingMsg, setLoadingMsg] = useState("Cargando...")

    //console.log("proveedor a mostrar :" + JSON.stringify(proveedor));
    //console.log("RS:" + razonSocial);
    

    const razonSocialChange = (event: CustomEvent) => {        
        setRS(event.detail.value);
        console.log("nuevo valor de RS:" + razonSocial);
      }
    const direccionChange = (event: CustomEvent) => {
        setDireccion(event.detail.value);          
      }
    const  coloniaChange = (event: CustomEvent) => {        
        setColonia(event.detail.value);
      }
    const  cpChange = (event: CustomEvent) => {
        setCP(event.detail.value);          
      }
    const  paisChange = (event: CustomEvent) => {        
        setPais(event.detail.value);
      }
    const  estadoChange = (event: CustomEvent) => {
        setEstado(event.detail.value);          
      }
    const  ciudadChange = (event: CustomEvent) => {        
        setCiudad(event.detail.value);
      }
    const  telefonoChange = (event: CustomEvent) => {
        setTelefono(event.detail.value);          
      }
    const  emailChange = (event: CustomEvent) => {
        setEmail(event.detail.value);
      }     
    const submitProveedor = (tag: any) =>{ 

        if(razonSocial == ""){
            alert("Por favor ingrese la Razón Social")
            return;
        }
         setShowLoading(true)
         setLoadingMsg("Guardando...")
        let proveedorData = {      
            "id": editId,      
            "razonSocial": razonSocial,
            "direccion": direccion,
            "colonia": colonia,
            "cp": cp,
            "pais":pais,
            "estado": estado,
            "ciudad": ciudad,
            "telefono": telefono,
            "email":email
        }
        //console.log("proveedor modificado: " + JSON.stringify(proveedorData));
        //console.log("Valor actual de proveedores: " + JSON.stringify(proveedores));
        //console.log("indice: " + JSON.stringify(editIndex));
        
        //proveedores[editIndex] = proveedorData;
        callModifyProveedor(proveedorData).then(()=>{
            console.log("callModifyProveedor proveedorData:" +  
            JSON.stringify(proveedorData))
            getProveedoresFromCloud();
            
            closeModal();
        }).catch(error=>{
            console.log("Error callModifyProveedor: " +  JSON.stringify(error))
            alert("Error al actualizar los datos del proveedor")
        })
        
        //console.log("Valor nuevo de proveedores: " + JSON.stringify(proveedores));
        //window.localStorage['proveedores'] = JSON.stringify(proveedores);        
        
        }
    
        const toggleEdit = () =>{
        setMode("edit");
        setShowEdit(false);
        }
        const showDetail= (index:number) =>{
            const proveedor = proveedores[index];
        console.log("Se va a editar el indice: " + index + ": " + JSON.stringify(proveedor));
        setRS(proveedor.razonSocial);
        setDireccion(proveedor.direccion);
        setColonia(proveedor.colonia);
        setCP(proveedor.cp);
        setPais(proveedor.pais);
        setEstado(proveedor.estado);
        setTelefono(proveedor.telefono);
        setCiudad(proveedor.ciudad);
        setEmail(proveedor.email);
        setMode("view");
        }
        const closeModal= ()=>{
            setShowModal(false);
            setMode('view');
            setShowEdit(true);
            getProveedoresFromCloud();
            }
        const deleteEntry = ()=>{   
            /*         
            proveedores.splice(editIndex, 1);
            window.localStorage["proveedores"] = JSON.stringify(proveedores);                    
            closeModal();
            */
           setShowLoading(true)
           setLoadingMsg("Guardando...")
           callDeleteProveedor(editId).then(res=>{
            setShowLoading(false)
            closeModal()
           })
        }        
    if(isPlatform('desktop'))
        return(
        <>
        <IonHeader>
        <IonToolbar>
            <IonGrid>
                <IonRow>
                    <IonCol size="8">
                        <IonTitle>PROVEEDORES</IonTitle>
                    </IonCol>
                    <IonCol size="4">
                        <IonRouterLink href="/proveedores/new">Registrar nuevo Proveedor</IonRouterLink>
                    </IonCol>
                </IonRow>
            </IonGrid>  
            <IonMenuButton slot="start"></IonMenuButton>    
            </IonToolbar>                      
        </IonHeader>
        <IonModal isOpen={showModal} onDidDismiss={closeModal} backdropDismiss={false}>            
            <IonGrid className="gridHeader">
                <IonRow>
                    <IonCol size="10">
                        Detalle del Proveedor
                    </IonCol>
                    <IonCol size ="2">
                        <IonRouterLink onClick={closeModal}>Cerrar</IonRouterLink>
                    </IonCol>
                </IonRow>
            </IonGrid>                      
            <IonCard hidden={mode == "edit"? false:true}>
                <IonCardContent>
                    <form onSubmit={submitProveedor}>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                &nbsp;
                            </IonCol>
                        </IonRow>
                        <IonRow>                            
                            <IonCol>
                                <IonInput value={razonSocial} type="text" 
                                    placeholder="Razón social" 
                                    onIonChange={razonSocialChange}/>
                            </IonCol>              
                            <IonCol>
                                <IonInput value={direccion} type="text" placeholder="Dirección" onIonChange={direccionChange}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>            
                            <IonCol>
                                <IonInput value={colonia} type="text" placeholder="Colonia" onIonChange={coloniaChange}/>
                            </IonCol>            
                            <IonCol>
                                <IonInput value={cp} type="text" placeholder="Código Postal" onIonChange={cpChange}/>
                            </IonCol>
                            <IonCol>
                                <IonInput value={pais} type="text" placeholder="País" onIonChange={paisChange}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>              
                            <IonCol>
                                <IonInput value={estado} type="text" placeholder="Estado" onIonChange={estadoChange}/>
                            </IonCol>
                            <IonCol>
                                <IonInput value={ciudad} type="text" placeholder="Ciudad" onIonChange={ciudadChange}/>
                            </IonCol>              
                            <IonCol>
                                <IonInput value={telefono} type="text" placeholder="Teléfono" onIonChange={telefonoChange}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>                            
                            <IonCol size="4">
                                <IonInput value={email} type="email" placeholder="Correo electrónico" onIonChange={emailChange}/>
                            </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol size="4">                                    
                            </IonCol>
                            <IonCol size="4">
                                <IonButton expand="block" onClick={submitProveedor}>Guardar</IonButton>    
                            </IonCol>
                            <IonCol size="4">                                
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    </form>
                </IonCardContent>
            </IonCard>                                     
            <IonCard hidden={!showEdit}>
                <IonCardContent>              
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="keyLabelCard">Razón Social</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>{razonSocial}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="keyLabelCard">Dirección</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>{direccion}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="keyLabelCard">Colonia</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>{colonia}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="keyLabelCard">Código Postal</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>{cp}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="keyLabelCard">País</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>{pais}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="keyLabelCard">Estado</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>{estado}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="keyLabelCard">Ciudad</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>{ciudad}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="keyLabelCard">Teléfono</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>{telefono}</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="keyLabelCard">Correo Electrónico</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>{email}</IonLabel>
                            </IonCol>
                        </IonRow>                        
                    </IonGrid>                         
                </IonCardContent>
            </IonCard> 
            <IonGrid>
                <IonRow>
                    <IonCol size="3">
                        <IonButton hidden={!showEdit} color="danger"
                            onClick={()=>setShowConfirm(true)}>
                            Eliminar
                        </IonButton>
                    </IonCol>
                    <IonCol>                        
                    &nbsp; 
                    </IonCol>
                    <IonCol>
                        <IonButton hidden={!showEdit} onClick={toggleEdit}>Editar
                        </IonButton>                        
                    </IonCol>                    
                </IonRow>
            </IonGrid>                         
        </IonModal>
        <IonAlert isOpen={showConfirm}
                        onDidDismiss={()=>setShowConfirm(false)}                    
                        header="Advertencia"
                        message="¿Realmente desea eliminar el registro?"
                        buttons={[
                            {
                                text: "Cancelar",
                                role: "cancel",
                                cssClass: "cancelButton",
                                handler: () => setShowConfirm(false)
                            },
                            {
                                text: "Continuar",
                                cssClass: "dangerButton",
                                handler: deleteEntry
                            }
                        ]}
                    />
        <IonContent>
        <IonLoading isOpen={showLoading}
            message={loadingMsg}
        />
        <IonList>
                {        
                proveedores.length > 0 ?          
                proveedores.map((proveedor:any, index:number) =>
                <IonItem key={proveedor.id} onClick={()=>{
                  showDetail(index);
                  setEditId(proveedor.id);
                  setShowModal(true);
                }
                }>
                <ProveedorCard razonSocial={proveedor.razonSocial} direccion={proveedor.direccion}
                  colonia={proveedor.colonia} cp={proveedor.cp} pais={proveedor.pais}
                  estado={proveedor.estado} ciudad={proveedor.ciudad} telefono={proveedor.telefono}
                  email={proveedor.email}></ProveedorCard></IonItem>
                ):
                <IonItem>
                    No hay proveedores registrados
                </IonItem>
                }
              </IonList>              
        </IonContent>
        </>
    );
    else
    return(
        <>
          <IonHeader>
              <IonToolbar>
              <IonGrid>
                <IonRow>
                  <IonCol size="8">
                    <IonTitle>PROVEEDORES</IonTitle>
                  </IonCol>
                  <IonCol size="4">
                  <IonRouterLink href="/proveedores/new">Nuevo</IonRouterLink>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonMenuButton slot="start"></IonMenuButton>   
              </IonToolbar>                         
            </IonHeader>
          <IonContent>
          <IonLoading isOpen={showLoading}
            message="Cargando..."
            />
            <IonModal isOpen={showModal} backdropDismiss={false}>            
            <IonList hidden={!showEdit}>
              <IonItem> <IonLabel color="primary">Razón Social: </IonLabel>{razonSocial}</IonItem>
              <IonItem> <IonLabel color="primary">Dirección: </IonLabel><IonLabel></IonLabel>{direccion}</IonItem>
              <IonItem> <IonLabel color="primary">Colonia: </IonLabel><IonLabel></IonLabel>{colonia}</IonItem>
              <IonItem> <IonLabel color="primary">Código Postal: </IonLabel><IonLabel></IonLabel>{cp}</IonItem>              
              <IonItem> <IonLabel color="primary">País: </IonLabel><IonLabel></IonLabel>{pais}</IonItem>
              <IonItem> <IonLabel color="primary">Ciudad: </IonLabel><IonLabel></IonLabel>{ciudad}</IonItem>              
              <IonItem> <IonLabel color="primary">Teléfono: </IonLabel><IonLabel></IonLabel>{telefono}</IonItem>              
              <IonItem> <IonLabel color="primary">Correo Electrónico: </IonLabel><IonLabel></IonLabel>{email}</IonItem>              
              <IonGrid>
                <IonRow>
                    <IonCol size="3">
                        <IonButton hidden={!showEdit} color="danger"
                            onClick={()=>setShowConfirm(true)}>
                            Eliminar
                        </IonButton>
                    </IonCol>
                    <IonCol>                        
                    &nbsp; 
                    </IonCol>
                    <IonCol>
                        <IonButton hidden={!showEdit} onClick={toggleEdit}>Editar
                        </IonButton>                        
                    </IonCol>                    
                </IonRow>
            </IonGrid>
            </IonList>
            <IonItem hidden={mode == "edit"? false:true}>
                <form onSubmit={submitProveedor}>
                    <IonInput type="text" placeholder="Razón social" onIonChange={razonSocialChange} 
                        value={razonSocial}></IonInput>
                    <IonInput type="text" placeholder="Dirección"  onIonChange={direccionChange} 
                        value={direccion}></IonInput>
                    <IonInput type="text"  placeholder="Colonia" onIonChange={coloniaChange} 
                        value={colonia}></IonInput>
                    <IonInput type="number" placeholder="Código Postal" onIonChange={cpChange} 
                        value={cp}></IonInput>
                    <IonInput type="text" placeholder="País"  onIonChange={paisChange} 
                        value={pais}></IonInput>
                    <IonInput type="text"  placeholder="Estado" onIonChange={estadoChange} 
                        value={estado}></IonInput>
                    <IonInput type="text" placeholder="Ciudad" onIonChange={ciudadChange} 
                        value={ciudad}></IonInput>
                    <IonInput type="tel" placeholder="Teléfono"  onIonChange={telefonoChange} 
                        value={telefono}></IonInput>
                    <IonInput type="email"  placeholder="Correo electrónico" onIonChange={emailChange} 
                        value={email}></IonInput>
                    <IonButton expand="block" onClick={submitProveedor}>Guardar</IonButton>        
                </form>
            </IonItem>
            <IonItem>
                <IonRouterLink onClick={closeModal}>Cerrar</IonRouterLink>
            </IonItem>
            
            </IonModal>
            <IonAlert isOpen={showConfirm}
                        onDidDismiss={()=>setShowConfirm(false)}                    
                        header="Advertencia"
                        message="¿Realmente desea eliminar el registro?"
                        buttons={[
                            {
                                text: "Cancelar",
                                role: "cancel",
                                cssClass: "cancelButton",
                                handler: () => setShowConfirm(false)
                            },
                            {
                                text: "Continuar",
                                cssClass: "dangerButton",
                                handler: deleteEntry
                            }
                        ]}
                    />
            <IonList>
            {     
                proveedores.length > 0 ?           
                proveedores.map((proveedor:any, index:number) =>
                <IonItem key={proveedor.id} onClick={()=>{                 
                    showDetail(index);
                    setEditId(proveedor.id);                    
                    setShowModal(true);}}>
                    {proveedor.razonSocial}</IonItem> 
                ):
                <IonItem>
                    No hay Proveedores registrados
                </IonItem>}
            </IonList>            
          </IonContent>
        </>
    );
} 
export default ProveedorComponent