import React from "react";
import { IonContent, IonHeader, IonToolbar, IonGrid, IonRow, IonCol, IonTitle, IonRouterLink, IonMenuButton, IonToast, IonList, IonItem, IonButton, IonInput, IonCardContent, IonCard, IonSelect, IonSelectOption } from "@ionic/react";
import { Link, RouteComponentProps } from "react-router-dom";
import CatalogoHeader from "../components/CatalogoHeader";
import { isNullOrUndefined } from "util";
import MedicamentoCard from "../components/MedicamentoCard";

type State ={    
    nombre: string,
    codigo: string,
    codigoBarras: string,
    laboratorio: string,
    stockMinimo: number,
    toastState: boolean,
    showModal: boolean,
    action: number,
}

export class MedicamentosPage extends React.Component<RouteComponentProps, State>{

    medicamentos: any[] = [];
    proveedores: any[] = [];
    laboratorios: any[] = [];        
    constructor(props: any){
        super(props);
        //console.log("Medicamentos Page")
        if(!isNullOrUndefined(window.localStorage['medicamentos']))
            this.medicamentos = JSON.parse(window.localStorage['medicamentos']);
        else{
            this.medicamentos.push({
                nombre: "Meloxicam 1mg",
                codigo: "1452354",
                codigoBarras: "709809",
                laboratorio: 1,
                stockMinimo: 10,
                stockActual: 0
            })
            window.localStorage['medicamentos'] = JSON.stringify(this.medicamentos);
        }
        if(!isNullOrUndefined(window.localStorage['proveedores']))
            this.proveedores = JSON.parse(window.localStorage['proveedores']);
        if(!isNullOrUndefined(window.localStorage['laboratorios']))
            this.laboratorios = JSON.parse(window.localStorage['laboratorios']);
        this.state ={
            nombre: "",
            codigo: "",
            codigoBarras: "",
            laboratorio: "",
            stockMinimo: 0,
            toastState: false,
            showModal: false,
            action: 1
        }
        console.log(JSON.stringify(this.state));
    }

    nombreChange = (event: CustomEvent) => {        
        this.setState({ nombre: event.detail.value });
      }
    codigoChange = (event: CustomEvent) => {        
        this.setState({ codigo: event.detail.value });
        }
    codigoBarrasChange = (event: CustomEvent) => {        
        this.setState({ codigoBarras: event.detail.value });
        }
    laboratorioChange = (event: CustomEvent) => {        
        this.setState({ laboratorio: event.detail.value });
        }
    stockMinimoChange = (event: CustomEvent) => {
        this.setState({ stockMinimo: event.detail.value});
        }  
    
    submitMedicamento = (tag: any) =>{         
        let medicamentoData = {            
            "nombre": this.state.nombre,
            "codigo": this.state.codigo,
            "codigoBarras": this.state.codigoBarras,
            "laboratorio": this.state.laboratorio,
            "stockMinimo": this.state.stockMinimo,
            "stockActual": 0
        }
        
        //console.log("Se agrega el objeto al arreglo " + JSON.stringify(proveedorData));
        this.medicamentos.push(medicamentoData);        
        window.localStorage['medicamentos'] = JSON.stringify(this.medicamentos);          
        console.log("Arreglo medicamentos en localstorage despues de guardar: " + 
            JSON.stringify(window.localStorage['medicamentos']));
        this.setState({
            toastState: true,
            nombre: "", 
            codigo: "", 
            codigoBarras: "", 
            laboratorio: "",
            stockMinimo: 0
        });
        this.changeAction(1);
        }
    
    
        clickLink(arg:number){
        console.log("Click: " + arg );        
        this.changeAction(arg);
    }

    changeAction(arg:number){
        this.setState({action:arg})
    }   

    render(){
        return(
            <>
            <CatalogoHeader title="MEDICAMENTOS" linkCaption="Nuevo" clickFunc={()=>this.clickLink(2)}></CatalogoHeader>
            <IonContent>
                <IonToast
                    isOpen={this.state.toastState}
                    onDidDismiss={() => 
                    this.setState(() => ({ toastState: false,
                        action: 1
                        }))                              
                    }
                    message= "Se guardó la información"
                    duration={400}
                ></IonToast>
                <IonCard>
                    <IonCardContent>
                    <IonList hidden= {this.state.action != 1}>
                {                  
                this.medicamentos.map((med:any) =>                
                <MedicamentoCard nombre={med.nombre} codigo={med.codigo} 
                    codigoBarras={med.codigoBarras} fabricante={med.laboratorio}
                    stockMinimo={med.stockMinimo} stockActual={med.stockActual}/>
                )}
                </IonList>
                <form>
                <IonGrid hidden={this.state.action != 2}>
                    <IonRow>
                        <IonCol size="12">
                            Ingrese los datos del medicamento
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            Nombre
                        </IonCol>
                        <IonCol>
                            <IonInput type="text" value={this.state.nombre} onIonChange={this.nombreChange}></IonInput>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                    <IonCol>
                            Codigo
                        </IonCol>
                        <IonCol>
                            <IonInput type="text" value={this.state.codigo} onIonChange={this.codigoChange}></IonInput>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                    <IonCol>
                            Laboratorio
                        </IonCol>
                        <IonCol>
                            <IonSelect value={this.state.laboratorio} 
                                onIonChange={this.laboratorioChange}>
                                {this.laboratorios.map((lab)=>
                                <IonSelectOption value={lab.codigo}>{lab.nombre}</IonSelectOption>)}
                            </IonSelect>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            Stock Mínimo
                        </IonCol>
                        <IonCol>
                            <IonInput type="number" value={this.state.stockMinimo.toString()} 
                                onIonChange={this.stockMinimoChange}></IonInput>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRouterLink onClick={()=>this.changeAction(1)}>Regresar</IonRouterLink>
                        </IonCol>
                        <IonCol>
                            <IonButton onClick={this.submitMedicamento}>Continuar</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                </form>
                    </IonCardContent>
                </IonCard>                
            </IonContent>
            </>
        )
    }

}