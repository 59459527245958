import React from "react";
import { isNullOrUndefined } from "util";
import CatalogoHeader from "../components/CatalogoHeader";
import { IonContent, IonCardContent, IonCard, IonButton, IonList, IonToast, IonGrid, IonRow, IonCol, IonInput, IonSelect, IonSelectOption, IonModal, IonItem, IonCheckbox } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import ESCard from "../components/ESCard";
import ProductoESCard from "../components/ProductoESCard";
import "./EntradasInventarioPage.css"
import { Factura } from "../App";

type State ={
    fecha:string,
    productos: any[],
    proveedor: string,
    action: number,
    toastState: boolean,
    medicamento: string,
    cantidad: number,
    precioCompra: number,
    precioVenta: number,
    precioMayoreo: number,
    numeroLote: string,
    showAddMed: boolean,
    formaPago: string,
    numFactura: string,
    subtotal: number,
    impuestos: number,
    fechaEmision: string,
    fechaPago?: string,
    total: number,
    facturaPagada: boolean
}

export class EntradasInventarioPage extends React.Component<RouteComponentProps, State>{

    entradas: any[] = [];
    proveedores: any[] = [];
    medicamentos: any[] = [];
    formasPago: any[] = [];

    nuevaClick(action:number){
        this.setState({action:action});
    }

    constructor(props:any){
        super(props)
        if(!isNullOrUndefined(window.localStorage['entradas']))
            this.entradas = JSON.parse(window.localStorage['entradas']);
        if(!isNullOrUndefined(window.localStorage['proveedores']))
            this.proveedores = JSON.parse(window.localStorage['proveedores']);
        if(!isNullOrUndefined(window.localStorage['medicamentos']))
            this.medicamentos = JSON.parse(window.localStorage['medicamentos']);
        this.formasPago = [{id:1, nombre:"Efectivo"}, {id:2, nombre:"Tarjeta"}];
        this.state ={
            fecha: "",
            productos: [],
            proveedor: "",
            action: 1,
            toastState: false,
            medicamento: "",
            cantidad: 0,
            precioCompra: 0,
            precioVenta:0,
            precioMayoreo: 0,
            numeroLote: "",
            showAddMed: false,
            formaPago: "",
            numFactura: "",
            subtotal: 0,
            impuestos: 0,
            fechaEmision: "",
            fechaPago: "",
            total: 0,
            facturaPagada: false
        }
    }

    fechaChange = (event: CustomEvent) => {
        this.setState({ fecha: event.detail.value});
        } 
    proveedorChange = (event: CustomEvent) => {
        this.setState({ proveedor: event.detail.value});
        }
    medicamentoChange = (event: CustomEvent) => {
        this.setState({ medicamento: event.detail.value});
        }
    cantidadChange = (event: CustomEvent) => {
        this.setState({ cantidad: event.detail.value});
        }
    precioCompraChange = (event: CustomEvent) => {
        this.setState({ precioCompra: event.detail.value});
        }
    precioVentaChange = (event: CustomEvent) => {
        this.setState({ precioVenta: event.detail.value});
        }
    precioMayoreoChange = (event: CustomEvent) => {
        this.setState({ precioMayoreo: event.detail.value});
        }
    numeroLoteChange = (event: CustomEvent) => {
        this.setState({ numeroLote: event.detail.value});
        }
    formaPagoChange = (event: CustomEvent) => {
            this.setState({formaPago: event.detail.value});
        }
    impuestosChange = (event: CustomEvent) => {
        this.setState({
            impuestos: event.detail.value,
            total: this.state.subtotal + parseFloat(event.detail.value)})
    }
    numFacturaChanged = (event: CustomEvent) => {
        this.setState({numFactura: event.detail.value});
    }
    pagadaChanged = (event: CustomEvent) => {
        this.setState({facturaPagada: event.detail.checked})
    }

    getNewId(){
        var idReturn: number = -1;
        if(isNullOrUndefined(window.localStorage['entradaNewId']))
            idReturn = 1;
        else
            idReturn = Number.parseInt(window.localStorage['entradaNewId']);
        window.localStorage['entradaNewId'] = idReturn+1;
        return idReturn;
    }
    
    submitEntrada = (tag: any) =>{    
        
        var montoEntrada: number = 0;
        var unidadesEntrada: number = 0;

        this.state.productos.map(producto=>{
            montoEntrada += parseFloat(producto.precioCompra) * parseInt(producto.cantidad);
            unidadesEntrada += parseInt(producto.cantidad);
        })
        
        let entradaData = {
            "id": this.getNewId(),        
            "fecha": this.state.fecha,
            "proveedor": this.state.proveedor,
            "productos": this.state.productos,
            "responsable": window.localStorage['username'],
            "total": this.state.total,
            "factura": this.state.numFactura
        }
        
        //console.log("Se agrega el objeto al arreglo " + JSON.stringify(proveedorData));
        this.entradas.push(entradaData);        
        window.localStorage['entradas'] = JSON.stringify(this.entradas);
        entradaData.productos.map((prod:any)=>{
            //console.log("producto actual: " + prod.medicamento);
            this.medicamentos.map((med:any)=>{
                //console.log("medicamento actual: " + med.codigo);
                if(med.codigo === prod.medicamento){
                    //console.log("producto y medicamento son iguales");
                    //console.log("stock actual de medicamento: " + med.stockActual);
                    med.stockActual = Number.parseInt(med.stockActual) + 
                        Number.parseInt(prod.cantidad);
                    //console.log("nuevo stock: " + med.stockActual);
                }
            })
        })        
        window.localStorage['medicamentos'] = JSON.stringify(this.medicamentos);        

        let facturaData: Factura ={
            numFactura: this.state.numFactura,
            subtotal: this.state.subtotal,
            fechaEmision: this.state.fechaEmision,
            impuestos: this.state.impuestos,
            fechaPago: this.state.facturaPagada == true ? this.state.fechaPago : undefined
        }
        var facturas: Array<Factura> = []
        if(!isNullOrUndefined(window.localStorage['facturas']))
            facturas = JSON.parse(window.localStorage['facturas'])
        facturas.push(facturaData);
        window.localStorage['facturas'] = JSON.stringify(facturas);
        
        if(this.state.facturaPagada == true){
            let movimientoData = {
                tipo: 2,
                id: this.getNewMovimientoId(),        
                concepto: "Compra inventario",
                monto: this.state.total,
                fecha: this.state.fecha,
                detalle: unidadesEntrada + " unidades, proveedor: " + this.state.proveedor,
                formaPago: this.state.formaPago
            }
    
            var movimientos = isNullOrUndefined(window.localStorage["movimientos"])?
                []: JSON.parse(window.localStorage["movimientos"]);
            movimientos.push(movimientoData);
            window.localStorage["movimientos"] = JSON.stringify(movimientos);
        }

       

        //console.log("Arreglo entradas en localstorage despues de guardar: " + 
        //    JSON.stringify(window.localStorage['entradas']));
        this.setState({
            toastState: true,
            fecha: "", 
            proveedor: "", 
            productos: [],
            formaPago: ""
        });
        this.setState({action:1});
        }

        getNewMovimientoId(){
            var idReturn: number = -1;
            if(isNullOrUndefined(window.localStorage['movimientoNewId']))
                idReturn = 1;
            else
                idReturn = Number.parseInt(window.localStorage['movimientoNewId']);
            window.localStorage['movimientoNewId'] = idReturn+1;
            return idReturn;
        }

    showAddProductoModal(){
        this.setState({showAddMed: true}); 
        //console.log("valor de shoeAddMed: " + this.state.showAddMed);     
    }   
    

    submitProducto = (tag: any) =>{         
        let productoData = {
            medicamento: this.state.medicamento,
            cantidad: this.state.cantidad,
            precioCompra: this.state.precioCompra,
            precioVenta: this.state.precioVenta,
            precioMayoreo: this.state.precioMayoreo,
            numeroLote: this.state.numeroLote
        }
        
        //console.log("Se agrega el objeto al arreglo " + JSON.stringify(proveedorData));
        this.state.productos.push(productoData);        
        //window.localStorage['entradas'] = JSON.stringify(this.entradas);          
        //console.log("Arreglo entradas en localstorage despues de guardar: " + 
        //    JSON.stringify(window.localStorage['entradas']));
        let newSubtotal = this.state.subtotal + (this.state.cantidad * this.state.precioCompra)
        this.setState({
            medicamento: "",
            cantidad: 0,
            precioCompra: 0,
            precioVenta:0,
            precioMayoreo: 0,
            numeroLote: "",
            showAddMed: false,
            subtotal: newSubtotal
        });
        //this.setState({action:1});
        }

        cancelNewEntrada(){
            this.setState({                
                fecha: "", 
                proveedor: "", 
                productos: []
            });
            this.setState({action:1});
        }
        cancelAddProduct(){
            this.setState({
                medicamento: "",
                cantidad: 0,
                precioCompra: 0,
                precioVenta:0,
                precioMayoreo: 0,
                numeroLote: "",
                showAddMed: false
            });
        }

    render(){
        return(
        <>
            <CatalogoHeader title="ENTRADAS" linkCaption="Nueva" clickFunc={()=>this.nuevaClick(2)}/>
            <IonContent>
                <IonToast
                    isOpen={this.state.toastState}
                    onDidDismiss={()=>this.setState({ toastState: false})}
                    message= "Se guardó la información"
                    duration={400}
                />
                <IonCard>
                    <IonCardContent hidden= {this.state.action != 1}>
                        <IonList>
                            <IonGrid>
                                <IonRow>
                                <IonCol  className="gridHeaderCol"size="2">Fecha</IonCol>
                                <IonCol  className="gridHeaderCol"size="4">Proveedor</IonCol>
                                <IonCol  className="gridHeaderCol"size="2">Responsable</IonCol>
                                <IonCol  className="gridHeaderCol"size="2">Responsable</IonCol>
                                <IonCol  className="gridHeaderCol"size="2">&nbsp;</IonCol>
                            </IonRow>                            
                            {
                            this.entradas.length > 0?
                                this.entradas.map((entrada)=>
                                    <ESCard tipo="entrada" id={entrada.id} fecha={entrada.fecha} 
                                        proveedor={entrada.proveedor} 
                                        responsable={entrada.responsable}
                                        productos={entrada.productos}
                                        formaPago={entrada.formaPago}
                                        factura={entrada.factura}/>
                                ):
                                <IonItem>No hay entradas de inventario registradas</IonItem>
                            }
                            </IonGrid>
                        </IonList>
                    </IonCardContent>
                    <IonCardContent hidden= {this.state.action != 2}>
                        <form>
                        <IonGrid hidden={this.state.action != 2}>                            
                            <IonRow>
                                <IonCol size="2" className="formKeyLabel">Fecha</IonCol>
                                <IonCol size="3">
                                    <IonInput type="date" value={this.state.fecha} 
                                        onIonChange={this.fechaChange}></IonInput>
                                </IonCol>
                                <IonCol size="2" className="formKeyLabel">Proveedor</IonCol>
                                <IonCol size="5">
                                    <IonSelect placeholder="Seleccione uno de la lista" value={this.state.proveedor} 
                                        onIonChange={this.proveedorChange}>
                                        {this.proveedores.map((prov)=>
                                        <IonSelectOption value={prov.razonSocial}>
                                            {prov.razonSocial}
                                        </IonSelectOption>)}
                                    </IonSelect>
                                </IonCol>
                            </IonRow>                            
                            <IonRow>                                
                                <IonCol size="2" className="formKeyLabel">Artículos:</IonCol>
                                <IonCol size="10">
                                    <IonItem>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol className="gridHeaderCol" size="3">Medicamento</IonCol>
                                            <IonCol className="gridHeaderCol" size="1">Cant</IonCol>
                                            <IonCol className="gridHeaderCol" size="2">Lote</IonCol>
                                            <IonCol className="gridHeaderCol" size="2">Compra</IonCol>
                                            <IonCol className="gridHeaderCol" size="2">Venta</IonCol>
                                            <IonCol className="gridHeaderCol" size="2">Mayoreo
                                            </IonCol>
                                        </IonRow>                                
                                    
                                        {
                                        this.state.productos.length > 0?
                                            this.state.productos.map((prod:any)=>
                                            <ProductoESCard tipo="entrada"
                                                producto={prod.medicamento}
                                                cantidad={prod.cantidad} 
                                                numeroLote={prod.numeroLote}
                                                precioCompra={prod.precioCompra}
                                                precioVenta={prod.precioVenta}
                                                precioMayoreo={prod.precioMayoreo}/>
                                        ): <IonRow>
                                            <IonCol size="12">
                                                No se han agregado artículos
                                            </IonCol>
                                        </IonRow>
                                        }
                                    </IonGrid>
                                    </IonItem>
                                </IonCol>
                             </IonRow>
                            <IonRow>
                                <IonCol size="9"></IonCol>
                                <IonCol size="3">
                                    <IonButton onClick={()=>this.setState({showAddMed:true})}
                                        color="secondary">
                                        Agregar Artículo
                                    </IonButton>
                                </IonCol>
                            </IonRow> 
                            <IonRow>
                            <IonCol size="2" className="formKeyLabel">
                                        Número de factura
                                </IonCol>
                                <IonCol size="2">
                                    <IonInput onIonChange={this.numFacturaChanged} />
                                </IonCol>
                                <IonCol size="2" className="formKeyLabel">
                                    Fecha emisión
                                </IonCol>
                                <IonCol size="2">
                                    <IonInput type="date"/>
                                </IonCol>
                                <IonCol size="2" className="formKeyLabel">
                                        Subtotal
                                </IonCol>
                                <IonCol>
                                       {this.state.subtotal} 
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="2" className="formKeyLabel">
                                        ¿Pagada?                                        
                                </IonCol>
                                <IonCol size="1">
                                <IonCheckbox checked={this.state.facturaPagada}
                                    onIonChange={this.pagadaChanged}/>
                                </IonCol>
                                <IonCol size="5"/>
                                <IonCol size="2" className="formKeyLabel">
                                        Impuestos
                                </IonCol>
                                <IonCol size="2">
                                    <IonInput type="number" 
                                    value={this.state.impuestos.toString()}
                                    onIonChange={this.impuestosChange}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="2" 
                                    className="formKeyLabel">Forma de Pago</IonCol>
                                <IonCol size="2" >
                                    <IonSelect disabled={!this.state.facturaPagada}
                                        onIonChange={this.formaPagoChange}>
                                        {this.formasPago.map((fdp)=>
                                            <IonSelectOption value={fdp.nombre}>
                                                {fdp.nombre}
                                            </IonSelectOption>   
                                        )}
                                    </IonSelect>
                                </IonCol>
                                <IonCol size="2" className="formKeyLabel">
                                        Fecha Pago
                                </IonCol>
                                <IonCol size="2">
                                        <IonInput disabled={!this.state.facturaPagada} 
                                            type="date"/>
                                </IonCol>                                
                                <IonCol size="2" className="formKeyLabel">
                                    Total
                                </IonCol>
                                <IonCol size="2">
                                    {this.state.total}
                                </IonCol>
                            </IonRow>                                                       
                        </IonGrid>
                        </form>
                        <IonButton onClick={this.submitEntrada}>Guardar</IonButton>
                        <IonButton onClick={()=>this.cancelNewEntrada()} color="medium">
                            Cancelar</IonButton>
                    </IonCardContent>                    
                </IonCard>
                <IonModal isOpen={this.state.showAddMed} backdropDismiss={false}>
                    <IonCard>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow>
                                <IonCol>Medicamento:</IonCol>
                                <IonCol>
                                    <IonSelect value={this.state.medicamento} 
                                        onIonChange={this.medicamentoChange}>
                                        {this.medicamentos.map((med:any)=>
                                            <IonSelectOption value={med.codigo}>
                                                {med.nombre}
                                            </IonSelectOption>
                                        )}
                                    </IonSelect>
                                </IonCol>
                                </IonRow>
                                <IonRow>
                                <IonCol>Cantidad:</IonCol>
                                <IonCol>
                                    <IonInput value={this.state.cantidad.toString()} type="number" 
                                        onIonChange={this.cantidadChange}/>
                                </IonCol>
                                </IonRow>
                                <IonRow>
                                <IonCol>Número de Lote:</IonCol>
                                <IonCol>
                                    <IonInput value={this.state.numeroLote} type="text" 
                                        onIonChange={this.numeroLoteChange}/>
                                </IonCol>
                                </IonRow>
                                <IonRow><IonCol>Precio Compra:</IonCol>
                                <IonCol>
                                    <IonInput value={this.state.precioCompra.toString()} 
                                        type="number"  onIonChange={this.precioCompraChange}/>
                                </IonCol>
                                </IonRow>
                                <IonRow>
                                <IonCol>Precio Venta:</IonCol>
                                <IonCol>
                                    <IonInput value={this.state.precioVenta.toString()}
                                        type="number" onIonChange={this.precioVentaChange}/>
                                </IonCol>
                                </IonRow>                                
                                <IonRow>
                                    <IonCol>Precio Mayoreo:</IonCol>
                                <IonCol>
                                    <IonInput value={this.state.precioMayoreo.toString()}
                                        type="number" onIonChange={this.precioMayoreoChange}/>
                                </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton onClick={this.submitProducto}>Agregar</IonButton>
                                        <IonButton onClick={()=>this.cancelAddProduct()} color="medium">
                                            Cancelar</IonButton>
                                    </IonCol>
                                </IonRow>                                
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </IonModal>
            </IonContent>
        </>
        );
    }

}