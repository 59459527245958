import React, { useState, useEffect } from 'react'
import { IonHeader, IonToolbar, IonTitle, IonGrid, IonRow, IonCol, IonSelect, 
    IonSelectOption, IonInput, IonButton, IonLoading } from '@ionic/react'
import { isNullOrUndefined } from 'util'
import { callGetConceptos, callGetFacturaByNumero, callGetFormasPago, 
    callModifyFactura, callModifyMovimiento, callNewMovimiento } 
    from '../firebaseConfig'

type pagoProps = {
    closeAction: Function,
    tipo: number,
    operacion: number,
    idEdit?: number,
    concepto?: string,
    numFactura?: string,
    monto?: number,
    detalle?: string
}


const Pago: React.FC<pagoProps> = (props) =>{

    const[concepto, setConcepto] = 
        useState<string | undefined>(undefined)
    const[monto, setMonto] = useState<number | undefined>(undefined)
    const[fecha, setFecha] = useState("")
    const[detalle, setDetalle] = useState<string | undefined>(undefined)
    const[formaPago, setFormaPago] = useState("")    
    const[numFactura, setNumFactura] = 
        useState<string | undefined>(undefined)
    const[conceptosIngresos, setConceptosIngresos] = useState([] as any[])
    const[conceptosEgresos, setConceptosEgresos] = useState([] as any[])
    const[formasPago, setFormasPago] = useState([] as any[])
    const[showLoading, setShowLoading] = useState(false)

    //if(!isNullOrUndefined(props.numFactura) && numFactura == "")
    //    setNumFactura(props.numFactura)

    //const conceptosIngresos = [{id:1, nombre: "Venta medicamentos"},{id:2, nombre: "Consulta"}]
    //const conceptosEgresos = [{id:1, nombre: "Pago Servicios"}, 
    //    {id:2, nombre: "Compra Inventario"}, {id:3, nombre: "Nómina"}, {id:4, nombre: "Factura"}];
    
    /*
    if(window.localStorage["formasPago"] === undefined){
        var fPagoDefault: any[] = [{id: 1, nombre: "Efectivo"}, {id:2, nombre: "Tarjeta Bancaria"},
            {id: 3, nombre: "Transferencia"}]
        window.localStorage["formasPago"] = JSON.stringify(fPagoDefault)
    }
    const formasPago: any[] = JSON.parse(window.localStorage['formasPago'])
    */

    //var movimientos = isNullOrUndefined(window.localStorage['movimientos']) ?
    //    [] as any[] : JSON.parse(window.localStorage['movimientos'])

        useEffect(()=>{
            getConceptosFromCloud()
            callGetFormasPago().then(res=>{
                setFormasPago(res as [])
            })
        }, [])

    function getConceptosFromCloud(){
        callGetConceptos().then(res=>{
            const conceptos = res as any[]
            let entrada = [] as any[]
            let salida = [] as any[]
            conceptos.map((item)=>{
                if(item.tipo == 1)
                    entrada.push(item)
                else
                    salida.push(item)
            })
            setConceptosIngresos(entrada)
            setConceptosEgresos(salida)
            })
        
    }

    function conceptoChange(event: CustomEvent){
        setConcepto(event.detail.value)
    }
    function montoChange(event: CustomEvent){
        setMonto(event.detail.value)
    }
    function fechaChange(event: CustomEvent){
        setFecha(event.detail.value)
    }
    function detalleChange(event: CustomEvent){
        setDetalle(event.detail.value)
    }
    function formaPagoChange(event: CustomEvent){
        setFormaPago(event.detail.value)
    }
    function numFacturaChanged(event: CustomEvent){
        setNumFactura(event.detail.value)        
    }


    function getNewId(){
        var idReturn: number = -1;
        if(isNullOrUndefined(window.localStorage['movimientoNewId']))
            idReturn = 1;
        else
            idReturn = Number.parseInt(window.localStorage['movimientoNewId']);
        window.localStorage['movimientoNewId'] = idReturn+1;
        return idReturn;
    }
    
    function submitMovimiento(){     
        //TODO: Validar si la factura ingresada está registrada y tiene saldo pendiente??                
        let movimientoData = {
            tipo: props.tipo,
            id: props.operacion == 1? getNewId(): props.idEdit,
            concepto: props.concepto===undefined? concepto : props.concepto,
            monto: props.monto===undefined? monto : props.monto,
            fecha: fecha,
            detalle: props.detalle||detalle,
            formaPago: formaPago
        }                
        //TODO: Implementar validación definitiva    
        if(movimientoData.concepto === undefined || movimientoData.concepto == "" || 
            movimientoData.monto === undefined || movimientoData.monto == 0 ||
            movimientoData.fecha === undefined || movimientoData.fecha == "" ||
            movimientoData.formaPago === undefined || movimientoData.formaPago == ""){
                alert("Verifique los datos para poder continuar")
                return;
            }
        setShowLoading(true)
        console.log("objeto a guardar: " + JSON.stringify(movimientoData));        
        if(props.operacion == 1)            
            //movimientos.push(movimientoData);        
            callNewMovimiento(movimientoData).then(res=>{                
                if(props.concepto === "Factura")
                    pagarFacura()
            })
        else{
            callModifyMovimiento(movimientoData).then(res=>{
                if(props.concepto === "Factura")
                    pagarFacura()
            })
            /*
            for(var i=0; i<movimientos.length; i++){
                if(movimientos[i].id == props.idEdit){
                    movimientos.splice(i, 1, movimientoData);
                }
            }
            */
        }
        /*
        if(props.concepto === "Factura"){
            var facturas: Array<Factura> = JSON.parse(window.localStorage['facturas'])
            for(var i=0; i<facturas.length; i++){
                if(facturas[i].numFactura == props.numFactura)
                    {
                    facturas[i].fechaPago = fecha;
                    window.localStorage['facturas'] = JSON.stringify(facturas);
                    }
            }
        }        
        window.localStorage['movimientos'] = JSON.stringify(movimientos);        
        cleanForm()        
        props.closeAction()  
        */        
        }

        function pagarFacura(){
            //console.log("Pago.pagarFactura se buscará el número de factura: " +  
            //    props.numFactura)
            callGetFacturaByNumero(props.numFactura!).then((factura:any)=>{
                //console.log("Pago.pagarFactura se recibió: " + 
                //    JSON.stringify(factura))
                const id = Object.keys(factura)[0]
                const factObj = factura[id]
                const facturaData = {
                    "id": id,
                    "fechaPago": fecha,
                    numFactura: props.numFactura,
                    subtotal: factObj.subtotal,
                    fechaEmision: factObj.fechaEmision,
                    impuestos: factObj.impuestos      
                }
                //factura.fechaPago = fecha
                //console.log("Pago.pagarFactura se va a actualizar el objeto " +
                //    JSON.stringify(facturaData))
                callModifyFactura(facturaData).then(res=>{
                    cleanForm()        
                    props.closeAction()          
                })
            })
        }

        function cleanForm(){
            setConcepto("")
            setMonto(0)
            setFecha("")
            setDetalle(undefined)
            setFormaPago("")        
        }

        function getConcepto(){            
            if(!isNullOrUndefined(props.numFactura)){
                //alert("número de factura: " +  props.numFactura)
                return "Factura"}
            else{
                alert("no hay número de factura")
                return ""
            }
                
        }

        function getFactura(){
            alert("factura en LS: " + window.localStorage['facturaPago']);
            return window.localStorage['facturaPago']
        }
        
        function hideFacturaInput(){
            if(props.numFactura!==undefined)
                return false;
            else            
                return concepto!="Factura"
        }

    return(
    <>
        <IonLoading
            isOpen={showLoading}
            message="Guardando..."
            />
        <form>
            <IonHeader>
                <IonToolbar>                                    
                    <IonTitle>
                    Ingrese los datos del {props.tipo==1?"ingreso" : "egreso"}
                    </IonTitle>                        
                </IonToolbar>      
            </IonHeader>                          
            <IonGrid>                                                                                                                                                          
                <IonRow>
                    <IonCol className="formKeyLabel">Concepto</IonCol>
                    <IonCol>
                        <IonSelect value={props.concepto||concepto} 
                        onIonChange={conceptoChange}
                        disabled={props.concepto!==undefined}>
                        {
                            props.tipo == 1?
                            conceptosIngresos.map((concepto)=>
                                <IonSelectOption value={concepto.nombre}>
                                    {concepto.nombre}
                                </IonSelectOption>
                            ) :
                            conceptosEgresos.map((concepto)=>
                                <IonSelectOption value={concepto.nombre}>
                                    {concepto.nombre}
                                </IonSelectOption>
                            )
                        }
                        </IonSelect>
                    </IonCol>
                </IonRow>
                <IonRow hidden={hideFacturaInput()}>
                    <IonCol className="formKeyLabel">No. de Factura</IonCol>
                    <IonCol>
                        <IonInput 
                            value={props.numFactura||numFactura} 
                            onIonChange={numFacturaChanged}
                            disabled={props.numFactura!==undefined}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="formKeyLabel">Monto</IonCol>
                    <IonCol>
                        <IonInput type="number" 
                        value={props.monto?.toString()||monto?.toString()}
                        onIonChange={montoChange}
                        disabled={props.monto!==undefined}/>
                    </IonCol>                                    
                </IonRow>
                <IonRow>
                    <IonCol className="formKeyLabel">Fecha de pago</IonCol>
                    <IonCol>
                        <IonInput type="date" onIonChange={fechaChange}
                        value={fecha}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="formKeyLabel">Detalle</IonCol>
                    <IonCol>
                        <IonInput type="text" onIonChange={detalleChange}
                        value={props.detalle||detalle}
                        disabled={props.detalle!==undefined}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="formKeyLabel">Forma de pago</IonCol>
                    <IonCol>
                        <IonSelect 
                            value={formaPago}
                            onIonChange={formaPagoChange}>
                            {formasPago.map((fdp:any)=>
                                <IonSelectOption value={fdp.nombre}>
                                    {fdp.nombre}
                                </IonSelectOption>   
                            )}
                        </IonSelect>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton onClick={submitMovimiento}>
                            Guardar
                        </IonButton>
                        <IonButton onClick={()=>{
                            cleanForm()
                            props.closeAction()                
                            }} color="medium">
                            Cancelar
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            </form>
    </>)

}
export default Pago