export function checkAccessToModule(name:string) {
    if(window.localStorage['username'] == "" || 
        window.localStorage['username'] == undefined)
        return false;
    else{
        let perfil = JSON.parse(window.localStorage['perfil'])        
        let modulos: any[] = perfil.modulos
        var result: boolean = false    
        modulos.forEach(element => {
            if(element != null){
                //console.log("checkAccessToModule element.nombre: " +  element.nombre)
                if(element.nombre == name){
                    //console.log("checkAccessToModule Se encontró el módulo " + 
                    //    name + " en el objeto perfil de local storage")
                    result = true
                }                
            }        
        });
        return result;
    }    
}