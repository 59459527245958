import React from "react";
import { IonHeader, IonMenuButton, IonToolbar, IonGrid, IonRow, IonCol, IonTitle, IonRouterLink, IonContent, IonList, IonItem } from "@ionic/react";

export const CatalogosMenu: React.FC = ()=> {

    return(

        <>
            <IonHeader>
                <IonToolbar>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="8">
                                <IonTitle>CATÁLOGOS</IonTitle>
                            </IonCol>
                        </IonRow>
                    </IonGrid>  
                    <IonMenuButton slot="start"></IonMenuButton>    
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonRouterLink href="/proveedores">Proveedores</IonRouterLink>
                    </IonItem>
                    <IonItem>
                        <IonRouterLink href="/productos">Productos</IonRouterLink>
                    </IonItem>
                    <IonItem>
                        <IonRouterLink href="/catalogos/fabricantes">Fabricantes</IonRouterLink>
                    </IonItem>
                    <IonItem>
                        <IonRouterLink href="/catalogos/especies">Especies</IonRouterLink>
                    </IonItem>
                    <IonItem>
                        <IonRouterLink href="/catalogos/razas">Razas</IonRouterLink>
                    </IonItem>
                    <IonItem>
                        <IonRouterLink href="/catalogos/colores">Colores</IonRouterLink>
                    </IonItem>
                </IonList>
            </IonContent>
        </>
    );
}