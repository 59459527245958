import React, {useEffect, useState} from 'react';
import { isNullOrUndefined } from 'util';
import { Factura } from '../App';
//import CatalogoHeader from './CatalogoHeader';
import { IonContent, IonToast, IonCard, IonCardContent, IonList, IonGrid, 
        IonRow, IonCol, IonItem, IonInput, IonSelect, IonSelectOption, 
        IonButton, IonCheckbox, IonModal, IonCardHeader, IonCardTitle, IonIcon, 
        IonFabButton, IonLoading, IonAlert} from '@ionic/react';
//import ESCard from './ESCard';
//import ProductoESCard from './ProductoESCard';
//import { isMainThread } from 'worker_threads';
import EntitySearch from './EntitySearch';
import DataTable from 'react-data-table-component';
import { create, trash } from "ionicons/icons";
import { callGetFormasPago, callGetProveedores, callNewEntradaInv, 
        callNewFactura, callNewMovimiento, callNewProducto, callUpdateStock } from 
        '../firebaseConfig';

/* type State ={
    fecha:string,
    productos: any[],
    proveedor: string,
    
    
    medicamento: string,
    cantidad: number,
    precioCompra: number,
    precioVenta: number,
    precioMayoreo: number,
    numeroLote: string,
    showAddMed: boolean,
    formaPago: string,
    numFactura: string,
    subtotal: number,
    impuestos: number,
    fechaEmision: string,
    fechaPago?: string,
    total: number,
    facturaPagada: boolean
} */

type compraProps = {
    closeAction: Function
}

const Compra: React.FC<compraProps> = (props)=> {
//export class Compra extends React.Component<State>{    
    const[action, setAction] = useState(1);
    const[toastState, setToastState] = useState(false)
    const[fecha, setFecha] = useState("");
    const[proveedor, setProveedor] = useState("");
    const[articulos, setArticulos] = useState([] as any[]);
    const[showAddMed, setShowAddMed] = useState(false);
    const[numFactura, setNumFactura] = useState("");
    const[subtotal, setSubtotal] = useState(0);
    const[facturaPagada, setFacturaPagada] = useState(false);
    const[impuestos, setImpuestos] = useState(0);
    const[total, setTotal] = useState(0);
    const[formaPago, setFormaPago] = useState("");
    const[fechaEmision, setFechaEmision] = useState("")
    const[fechaPago, setFechaPago] = useState("")
    //const[producto, setProducto] = useState("")
    const[cantidad, setCantidad] = useState(0)
    const[numeroLote, setNumeroLote] = useState("")
    const[precioCompra, setPrecioCompra] = useState(0)
    const[precioVenta, setPrecioVenta] = useState(0)
    const[precioMayoreo, setPrecioMayoreo] = useState(0)
    const[showPickProducto, setShowPickProducto] = useState(false)
    const[editArticulo, setEditArticulo] = useState(false)
    const[toastMessage, setToastMessage] = useState("Se guardó la información")
    const[proveedores, setProveedores] = useState([] as any[])
    const[formasPago, setFormasPago] = useState([] as any[])
    const[showLoading, setShowLoading] = useState(false)
    const[showNewProd, setShowNewProd] = useState(false)

    //var proveedores: any[] = [];
    var productos: any[] = [];  
    if(window.localStorage["formasPago"] === undefined){
        var fPagoDefault = [{id: 1, nombre: "Efectivo"}, {id:2, nombre: "Tarjeta Bancaria"},
            {id: 3, nombre: "Transferencia"}]
        window.localStorage["formasPago"] = JSON.stringify(fPagoDefault)
    }  
    //let formasPago = JSON.parse(window.localStorage['formasPago'])
    /*
    if(isNullOrUndefined(window.localStorage['proveedores'])){
        let provArr = [
           {'razonSocial':'Macro Distribuidora Medivet S.A. de C.V.',
           'direccion':'Av. Vicente Guerrero, Mz 7 Lt 23 y 24',
           'colonia':'Poesía Mexicana',
           'cp':'55249',
           'pais':'México',
           'estado':'Estado de México',
           'ciudad':'Ecatepec de Morelos',
           'telefono':'55 - 4747 - 5256',
           'email':'medivet@distribuidoramedivet.com.mx'
           }]            
           window.localStorage['proveedores'] = JSON.stringify(provArr)
   }*/
   //proveedores = JSON.parse(window.localStorage['proveedores']);   
    if(isNullOrUndefined(window.localStorage['productos']))
        window.localStorage['productos'] = JSON.stringify(
            [{"nombre":"Pouch Razas Pequeñas Pollo","id":1,"codigoBarras":"","fabricante":{"codigo":2,"tipo":"alimento","nombre":"Pedigree"},"stockMinimo":"20","stockActual":0},{"nombre":"Vendas 5m","id":2,"codigoBarras":"","fabricante":{"codigo":3,"tipo":"material","nombre":"Degasa"},"stockMinimo":"10","stockActual":0},{"nombre":"Ranitidina 150mg","id":3,"codigoBarras":"","fabricante":{"codigo":1,"tipo":"laboratorio","nombre":"Astra Zeneca"},"stockMinimo":"10","stockActual":0}]
        )
    productos = JSON.parse(window.localStorage['productos']);

    useEffect(()=>{
        callGetProveedores().then(res=>{
            setProveedores(res as [])
        })
        callGetFormasPago().then(res=>{
            setFormasPago(res as [])
        })
    },[])

    const styles={
        gridDiv:{
            height: "40px"
            
        }
    }

    const artCols = [
        {
            name: 'Producto',
            selector: (row: any) => row.nombre,
            sortable:true,
            grow: 4
        },
        {
            name: 'Cant',
            selector: (row: any) => row.cantidad,
            sortable: true,
            grow: 0.3
        },
        {
            name: 'Precio',
            selector: (row: any) => curFormatter.format(row.precioCompra),
            sortable: true,
            grow: 0.3
        },
        {
            name: 'Monto',
            selector: (row: any) => curFormatter.format(row.cantidad * 
                row.precioCompra),
            sortable: true,
            grow:1
        },
        {
            name: '',
            selector: (articulo:any) => 
                <IonFabButton size="small" color="warning" 
                    onClick={e=>showEditForm(articulo)}>
                    <IonIcon icon={create}/>
                </IonFabButton>,
            sortable: false,
            grow: 0.3
        },
        {
            name: '',
            selector: (articulo:any) =>                             
                <IonFabButton size="small" color="danger"
                    onClick={e=>removeArticulo(articulo)}>
                    <IonIcon icon={trash}/>
                </IonFabButton>,
            sortable: false,
            grow: 0.3
        }
    ];
    const decFormatter  = new Intl.NumberFormat('es-MX', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      });
      const curFormatter  = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol'
      });
    /*
    constructor(props:any){
        super(props)
        if(!isNullOrUndefined(window.localStorage['entradas']))
            this.entradas = JSON.parse(window.localStorage['entradas']);
        
        
        this.formasPago = [{id:1, nombre:"Efectivo"}, {id:2, nombre:"Tarjeta"}];
        this.state ={
            fecha: "",
            productos: [],
            proveedor: "",
            action: 1,
            toastState: false,
            medicamento: "",
            cantidad: 0,
            precioCompra: 0,
            precioVenta:0,
            precioMayoreo: 0,
            numeroLote: "",
            showAddMed: false,
            formaPago: "",
            numFactura: "",
            subtotal: 0,
            impuestos: 0,
            fechaEmision: "",
            fechaPago: "",
            total: 0,
            facturaPagada: false
        }
    } */


    function fechaChange(event: CustomEvent){
        setFecha(event.detail.value);
        } 
    function proveedorChange(event: CustomEvent){
        setProveedor(event.detail.value);
        }
    function numFacturaChanged(event: CustomEvent){
        setNumFactura(event.detail.value);
    }
    function pagadaChanged(event: CustomEvent){
        setFacturaPagada(event.detail.checked)
    }
    function impuestosChange(event: CustomEvent){                
        const impVal = event.detail.value? 
            parseFloat(event.detail.value) : 0
        setImpuestos(impVal)
        setTotal(subtotal + impVal)
    }
    function formaPagoChange(event: CustomEvent){
        setFormaPago(event.detail.value);
    }
    /* function productoChange(event: CustomEvent){
        setProducto(event.detail.value);
        } */
    function cantidadChange(event: CustomEvent){        
            setCantidad(event.detail.value);                        
        }
    function numeroLoteChange(event: CustomEvent){
        setNumeroLote(event.detail.value);
        }
    function precioCompraChange(event: CustomEvent){
        const regex = /^[0-9]+(\.[0-9]{1,2}){0,1}$/
        //console.log("valor: " + event.detail.value +  " res: " + 
        //    regex.test(event.detail.value))
        if(regex.test(event.detail.value))
            setPrecioCompra(event.detail.value);
        }
    function precioVentaChange(event: CustomEvent){
        const regex = /^[0-9]+(\.[0-9]{1,2}){0,1}$/
        //console.log("valor: " + event.detail.value +  " res: " + 
        //    regex.test(event.detail.value))
        if(regex.test(event.detail.value))
            setPrecioVenta(event.detail.value);
        }
    function precioMayoreoChange(event: CustomEvent){
        const regex = /^[0-9]+(\.[0-9]{1,2}){0,1}$/ 
        if(regex.test(event.detail.value))
            setPrecioMayoreo(event.detail.value);
        }
    function fechaEmisionChanged(event: CustomEvent){
        setFechaEmision(event.detail.value)
        }
    function fechaPagoChanged(event: CustomEvent){
        setFechaPago(event.detail.value)
    }

    function validateKey(event: any){                
        //console.log("validateKey data: " + event.data + 
        //" test: " + /\d/.test(event.data))        
        if(!/\d/.test(event.data))
            event.preventDefault();            
    }

    function validateKeyCurrency(event: any){        
        const regex = /^[0-9]|\./        
        if(!regex.test(event.data))
            event.preventDefault();
    }

    function submitEntrada(){            
        if(fecha == ""){
            setToastMessage("Ingrese la fecha de la compra para continuar")
            setToastState(true)
            //setShowLoading(false)
            return;
        }
        if(proveedor == ""){
            setToastMessage("Seleccione un proveedor para continuar")
            setToastState(true)
            //setShowLoading(false)
            return;
        }
        

        var montoEntrada: number = 0;
        var unidadesEntrada: number = 0;

        articulos.map(articulo=>{
            montoEntrada += parseFloat(articulo.precioCompra) * 
                parseInt(articulo.cantidad);
            unidadesEntrada += parseInt(articulo.cantidad);
        })
        
        if(montoEntrada <= 0){
            setToastMessage(
                "El monto de la compra no es válido, verifique los artículos")
            setToastState(true)
            //setShowLoading(false)
            return;
        }
        if(numFactura == ""){
            setToastMessage("Ingrese el número de la factura para continuar")
            setToastState(true)
            //setShowLoading(false)
            return;
        }
        if(fechaEmision == ""){
            setToastMessage(
                "Indique la fecha de emisión de la factura para continuar")
            setToastState(true)
            //setShowLoading(false)
            return;
        }
        
        setShowLoading(true)

        let entradaData = {
            "id": "undefined",        
            "fecha": fecha,
            "proveedor": proveedor,
            "articulos": articulos,
            "responsable": window.localStorage['username'],
            "total": total,
            "factura": numFactura,
            "formaPago": facturaPagada? formaPago: undefined,
            "activo": 1
        }
                
        //console.log("Se agrega el objeto al arreglo " + JSON.stringify(proveedorData));
        
        callNewEntradaInv(entradaData).then(res=>{
            //console.log("Se ejecutó correctamente newEntradaInv")
            entradaData.articulos.map((art:any)=>{
                //console.log("producto actual: " + prod.medicamento);
                callUpdateStock(art.producto, art.cantidad, false).then(res=>{
                    //console.log("se actualizó el stock de " + 
                    //    JSON.stringify(art))                                                                                         
                })
            })
            let facturaData: Factura ={
                numFactura: numFactura,
                subtotal: subtotal,
                fechaEmision: fechaEmision,
                impuestos: impuestos,
                fechaPago: facturaPagada == true ? fechaPago : undefined,
                proveedor: proveedor
            }
            callNewFactura(facturaData).then(res=>{ 
                //console.log("Se ejecutó correctamente newFactura")
                if(facturaPagada == true){
                    let movimientoData = {
                        tipo: 2,
                        id: getNewMovimientoId(),        
                        concepto: "Compra inventario",
                        monto: total,
                        fecha: fecha,
                        detalle: unidadesEntrada + " unidades, proveedor: " + 
                            proveedor,
                        formaPago: formaPago
                    }                        
                    callNewMovimiento(movimientoData).then(res=>{                                                
                        setToastState(true)
                        setToastMessage("Se guardó la información")
                        //setAction(1);
                        closeModal();
                                                    
                    }).catch(error=>{
                        setToastState(true)
                        setToastMessage(
                            "Ocurrió un error al guardar movimiento.")
                        console.log("Compra.submtEntrada - Error al " +
                            "guardar movimiento: " + JSON.stringify(error))
                    })                        
                }
                else{
                    setToastState(true)
                    setToastMessage("Se guardó la información")
                    setShowLoading(false)
                    //setAction(1);
                    closeModal();
                }                
            }).catch(error=>{
                setToastState(true)
                setToastMessage(
                    "Ocurrió un error al guardar la factura.")
                setShowLoading(false)
                console.log("Compra.submtEntrada - Error al " +
                    "guardar factura: " + JSON.stringify(error))
            })
        }).catch(error=>{
            setToastState(true)
            setToastMessage(
                "Ocurrió un error al guardar en inventario.")
            setShowLoading(false)
            console.log("Compra.submtEntrada - Error al " +
                "guardar entrada de inventario: " + JSON.stringify(error))
        })
        /*
        var entradas: any[] = [];
        if(!isNullOrUndefined(window.localStorage['entradas']))
            entradas = JSON.parse(window.localStorage['entradas'])
        entradas.push(entradaData);        
        window.localStorage['entradas'] = JSON.stringify(entradas);
        */

        /*
        entradaData.articulos.map((art:any)=>{
            //console.log("producto actual: " + prod.medicamento);
            productos.map((art:any)=>{
                //console.log("medicamento actual: " + med.codigo);
                if(art.id === art.producto){
                    //console.log("producto y medicamento son iguales");
                    //console.log("stock actual de medicamento: " + med.stockActual);
                    art.stockActual = Number.parseInt(art.stockActual) + 
                        Number.parseInt(art.cantidad);
                    //console.log("nuevo stock: " + med.stockActual);
                }
            })
        })                        
        window.localStorage['productos'] = JSON.stringify(productos);        
        */
       /*
        let facturaData: Factura ={
            numFactura: numFactura,
            subtotal: subtotal,
            fechaEmision: fechaEmision,
            impuestos: impuestos,
            fechaPago: facturaPagada == true ? fechaPago : undefined
        }
        var facturas: Array<Factura> = []
        if(!isNullOrUndefined(window.localStorage['facturas']))
            facturas = JSON.parse(window.localStorage['facturas'])
        facturas.push(facturaData);
        window.localStorage['facturas'] = JSON.stringify(facturas);
        
        if(facturaPagada == true){
            let movimientoData = {
                tipo: 2,
                id: getNewMovimientoId(),        
                concepto: "Compra inventario",
                monto: total,
                fecha: fecha,
                detalle: unidadesEntrada + " unidades, proveedor: " + proveedor,
                formaPago: formaPago
            }
    
            var movimientos = isNullOrUndefined(window.localStorage["movimientos"])?
                []: JSON.parse(window.localStorage["movimientos"]);
            movimientos.push(movimientoData);
            window.localStorage["movimientos"] = JSON.stringify(movimientos);
        }

        setToastState(true)
        setToastMessage("Se guardó la información")
        //setAction(1);
        closeModal();
        */
        }


        function getNewId(){
            var idReturn: number = -1;
            if(isNullOrUndefined(window.localStorage['entradaNewId']))
                idReturn = 1;
            else
                idReturn = Number.parseInt(window.localStorage['entradaNewId']);
            window.localStorage['entradaNewId'] = idReturn+1;
            return idReturn;
        }
        function getNewMovimientoId(){
            var idReturn: number = -1;
            if(isNullOrUndefined(window.localStorage['movimientoNewId']))
                idReturn = 1;
            else
                idReturn = Number.parseInt(window.localStorage['movimientoNewId']);
            window.localStorage['movimientoNewId'] = idReturn+1;
            return idReturn;
        }

        function submitArticulo(tag: any){              
            if(window.localStorage["productoSelected"] === undefined){
                setToastMessage("Elija un producto para continuar")
                setToastState(true)
                return;
            }
            if(cantidad <= 0){
                setToastMessage("Indique la cantidad de artículos para continuar")
                setToastState(true)
                return;
            }
            if(precioCompra <= 0){
                setToastMessage("Ingrese el precio de compra para continuar")
                setToastState(true)
                return;
            }
            let prod =  JSON.parse(window.localStorage["productoSelected"])
            //console.log("submitArticulo prod: " +  JSON.stringify(prod))   
            let articuloData = {
                producto: prod.id,
                cantidad: cantidad,
                precioCompra: precioCompra,
                precioVenta: precioVenta,
                precioMayoreo: precioMayoreo,
                numeroLote: numeroLote,
                nombre: prod.nombre
            }
            var newSubtotal = 0
            if(editArticulo){
                //console.log("Se va a modificar el articulo: " + JSON.stringify(articuloData))
                var montoAnterior = 0
                for(var i=0; i<articulos.length; i++){
                    //console.log("Compra.submitArticulo articulos[i]: " + JSON.stringify(
                    //    articulos[i]))
                    if(articulos[i].nombre == prod.nombre && articulos[i].numeroLote == numeroLote){
                        //console.log("Compra.submitArticulo entra al if: prod.id: " + prod.id + 
                        //    " numeroLote: " + numeroLote)
                        montoAnterior = articulos[i].cantidad * articulos[i].precioCompra
                        newSubtotal = subtotal - montoAnterior + (cantidad * precioCompra)                                                
                        articulos.splice(i, 1, articuloData);                        
                        }
                    }
            }
            else{
                //TODO: validar que no exista ya la combinación de artículo y lote en arreglo????
                //console.log("Se va a agregar el articulo: " +  JSON.stringify(articuloData))
                articulos.push(articuloData);    
                newSubtotal = (subtotal*1) + (cantidad * precioCompra)
            }
            //console.log("Se agrega el objeto al arreglo " + JSON.stringify(proveedorData));
            
            //window.localStorage['entradas'] = JSON.stringify(this.entradas);          
            //console.log("Arreglo entradas en localstorage despues de guardar: " + 
            //    JSON.stringify(window.localStorage['entradas']));            
            //setProducto("")
            //console.log("arreglo de articulos: " +  JSON.stringify(articulos))
            setCantidad(0)
            setPrecioCompra(0)
            setPrecioVenta(0)
            setPrecioMayoreo(0)
            setNumeroLote("")
            setShowAddMed(false)
            setSubtotal(newSubtotal) 
            setTotal((newSubtotal*1)+(impuestos*1));       
            //this.setState({action:1});
            window.localStorage.removeItem("productoSelected")
            setEditArticulo(false)
            }
        function cancelAddProduct(){
            //setProducto("")
            setCantidad(0)
            setPrecioCompra(0)
            setPrecioVenta(0)
            setPrecioMayoreo(0)
            setNumeroLote("")
            setShowAddMed(false)
            setEditArticulo(false)
            window.localStorage.removeItem("productoSelected")
        }

        function closeModal(){
            setFecha("") 
            setProveedor("") 
            setArticulos([])
            setFormaPago("");
            setNumFactura("");
            setFechaEmision("");
            setSubtotal(0);
            setFacturaPagada(false);
            setImpuestos(0);
            setFechaPago("");
            setTotal(0);
            props.closeAction();
    
        }
    
        function showEditForm(articulo: any){
            //setProducto("")
            setEditArticulo(true)
            setCantidad(articulo.cantidad)
            setPrecioCompra(articulo.precioCompra)
            setPrecioVenta(articulo.precioVenta)
            setPrecioMayoreo(articulo.precioMayoreo)
            setNumeroLote(articulo.numeroLote)
            setShowAddMed(true)
            //TODO: es el mismo tipo de objeto??
            //console.debug("Compra.showEditForm articulo: " + JSON.stringify(articulo))
            window.localStorage["productoSelected"] = JSON.stringify(articulo)
        }

        function removeArticulo(prod: any){
            //console.log("Se va a buscar el artículo " + JSON.stringify(prod) + " en " + 
            //JSON.stringify(articulos))
            var newSubtotal = 0
            for(var i=0; i<articulos.length; i++){
                if(articulos[i].id == prod.id && articulos[i].numeroLote == prod.numeroLote){                    
                    newSubtotal = 
                        subtotal - (articulos[i].cantidad * articulos[i].precioCompra)                                                
                    articulos.splice(i, 1);                        
                    }
                }
                setSubtotal(newSubtotal) 
                setTotal(newSubtotal+impuestos);       
        }


        /*
        

    showAddProductoModal(){
        this.setState({showAddMed: true}); 
        //console.log("valor de shoeAddMed: " + this.state.showAddMed);     
    }   
    

   

        cancelNewEntrada(){
            this.setState({                
                fecha: "", 
                proveedor: "", 
                productos: []
            });
            this.setState({action:1});
        }
        
*/
    //render(){
        return(
        <>            
            <IonContent>                
                <IonLoading isOpen={showLoading}
                    message="Guardando..."/>
                <IonToast
                    isOpen={toastState}
                    onDidDismiss={()=>setToastState(false)}
                    message= {toastMessage}
                    duration={800}
                    position="middle"
                />
                <IonCard>
                    {/* 
                    <IonCardContent hidden= {action != 1}>
                        <IonList>
                            <IonGrid>
                                <IonRow>
                                <IonCol  className="gridHeaderCol"size="2">Fecha</IonCol>
                                <IonCol  className="gridHeaderCol"size="4">Proveedor</IonCol>
                                <IonCol  className="gridHeaderCol"size="2">Responsable</IonCol>
                                <IonCol  className="gridHeaderCol"size="2">Responsable</IonCol>
                                <IonCol  className="gridHeaderCol"size="2">&nbsp;</IonCol>
                            </IonRow>                            
                            {
                            entradas.length > 0?
                                entradas.map((entrada: any)=>
                                    <ESCard tipo="entrada" id={entrada.id} fecha={entrada.fecha} 
                                        proveedor={entrada.proveedor} 
                                        responsable={entrada.responsable}
                                        productos={entrada.productos}
                                        formaPago={entrada.formaPago}/>
                                ):
                                <IonItem>No hay entradas de inventario registradas</IonItem>
                            }
                            </IonGrid>
                        </IonList>
                    </IonCardContent>
                    
 */}                   
                    <IonCardHeader>
                        <IonCardTitle>
                            Ingrese los datos de la compra
                        </IonCardTitle>

                    </IonCardHeader>
                    <IonCardContent hidden= {action != 1}>
                         
                        <form>
                        <IonGrid hidden={action != 1}>                            
                            <IonRow>
                                <IonCol size="2" className="formKeyLabel">
                                    Fecha</IonCol>
                                <IonCol size="3">
                                    <IonInput type="date" value={fecha} 
                                        onIonChange={fechaChange}></IonInput>
                                </IonCol>
                                <IonCol size="2" className="formKeyLabel">
                                    Proveedor</IonCol>
                                <IonCol size="5">
                                    <IonSelect placeholder=
                                        "Seleccione uno de la lista" 
                                        value={proveedor} 
                                        onIonChange={proveedorChange}>
                                        {proveedores.map((prov)=>
                                        <IonSelectOption 
                                            value={prov.razonSocial}>
                                            {prov.razonSocial}
                                        </IonSelectOption>)}
                                    </IonSelect>
                                </IonCol>
                            </IonRow>                            
                            <IonRow>                                
                                <IonCol size="2" className="formKeyLabel">
                                    Artículos:</IonCol>
                                <IonCol size="10">  
                                    <DataTable columns={artCols} 
                                        data={articulos} 
                                        noDataComponent={
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>
                                                        No se han agregado 
                                                        artículos
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        }
                                    /> 
                                    {/*}
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol className="gridHeaderCol" size="3">Producto</IonCol>
                                            <IonCol className="gridHeaderCol" size="1">Cant</IonCol>
                                            <IonCol className="gridHeaderCol" size="2">Lote</IonCol>
                                            <IonCol className="gridHeaderCol" size="2">Compra</IonCol>
                                            <IonCol className="gridHeaderCol" size="2">Venta</IonCol>
                                            <IonCol className="gridHeaderCol" size="2">Mayoreo
                                            </IonCol>
                                        </IonRow>                                
                                        <div className="scrollgrid">    
                                        {
                                            articulos.length > 0?
                                            articulos.map((art:any)=>
                                            <ProductoESCard tipo="entrada"
                                                producto={art.producto}
                                                cantidad={art.cantidad} 
                                                numeroLote={art.numeroLote}
                                                precioCompra={art.precioCompra}
                                                precioVenta={art.precioVenta}
                                                precioMayoreo={art.precioMayoreo}/>
                                        ): <IonRow>
                                            <IonCol size="12">
                                                No se han agregado artículos
                                            </IonCol>
                                        </IonRow>
                                        }                                        
                                    </div> 
                                    </IonGrid>{*/}
                                                                       
                                </IonCol>
                             </IonRow>
                            <IonRow>
                                <IonCol size="9"></IonCol>
                                <IonCol size="3">
                                    <IonButton onClick={()=>setShowAddMed(true)}
                                        color="secondary">
                                        Agregar Artículo
                                    </IonButton>
                                </IonCol>
                            </IonRow> 
                            <IonRow>
                            <IonCol size="2" className="formKeyLabel">
                                        Número de factura
                                </IonCol>
                                <IonCol size="2">
                                    <IonInput onIonChange={numFacturaChanged} 
                                        value={numFactura}/>
                                </IonCol>
                                <IonCol size="2" className="formKeyLabel">
                                    Fecha emisión
                                </IonCol>
                                <IonCol size="2">
                                    <IonInput type="date" value={fechaEmision}
                                        onIonChange={fechaEmisionChanged}/>
                                </IonCol>
                                <IonCol size="2" className="formKeyLabel">
                                        Subtotal
                                </IonCol>
                                <IonCol>
                                       {curFormatter.format(subtotal)} 
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="2" className="formKeyLabel">
                                        ¿Pagada?                                        
                                </IonCol>
                                <IonCol size="1">
                                <IonCheckbox checked={facturaPagada}
                                    onIonChange={pagadaChanged}/>
                                </IonCol>
                                <IonCol size="5"/>
                                <IonCol size="2" className="formKeyLabel">
                                        Impuestos
                                </IonCol>
                                <IonCol size="2">
                                    <IonInput type="tel"                                     
                                    value={impuestos==0?null:
                                        impuestos?.toString()}
                                    onIonChange={impuestosChange}
                                    onBeforeInput={validateKeyCurrency}
                                    clearInput={true}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="2" 
                                    className="formKeyLabel">Forma de Pago
                                    </IonCol>
                                <IonCol size="2" >
                                    <IonSelect disabled={!facturaPagada}
                                        onIonChange={formaPagoChange}
                                        value={formaPago}>
                                        {formasPago.map((fdp:any)=>
                                            <IonSelectOption value={fdp.nombre}>
                                                {fdp.nombre}
                                            </IonSelectOption>   
                                        )}
                                    </IonSelect>
                                </IonCol>
                                <IonCol size="2" className="formKeyLabel">
                                        Fecha Pago
                                </IonCol>
                                <IonCol size="2">
                                        <IonInput disabled={!facturaPagada} 
                                            type="date" value={fechaPago}
                                            onIonChange={fechaPagoChanged}/>
                                </IonCol>                                
                                <IonCol size="2" className="formKeyLabel">
                                    Total
                                </IonCol>
                                <IonCol size="2">
                                    {curFormatter.format(total)}
                                </IonCol>
                            </IonRow>                                                       
                        </IonGrid>
                        </form>
                        <IonButton 
                            disabled={false/*doSave*/}
                            onClick={()=>{
                                    //setShowLoading(true)                                    
                                    submitEntrada()
                                    }
                                }>
                            Guardar
                        </IonButton>
                        <IonButton onClick={closeModal} color="medium">
                            Cancelar</IonButton>

                    </IonCardContent>                    
                </IonCard>
 
                <IonModal isOpen={showAddMed} backdropDismiss={false}>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>
                                Datos del artículo
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <form>
                            <IonGrid>
                                <IonRow>
                                <IonCol size="3">Producto:</IonCol>
                                <IonCol size="3">
                                <IonButton size="small" 
                                    onClick={()=>setShowNewProd(true)}>
                                    Nuevo</IonButton>
                                </IonCol>
                                <IonCol>
                                <IonInput type="search" 
                                    placeholder="Ingrese el producto"
                                    value=
                                        {isNullOrUndefined(
                                            window.localStorage[
                                            "productoSelected"])?
                                            "Seleccione un producto" : 
                                            JSON.parse(window.localStorage[
                                            "productoSelected"]).nombre}
                                    onIonFocus={()=>setShowPickProducto(true)}                                     
                                />
                                </IonCol>
                                </IonRow>
                                <IonRow>
                                <IonCol>Cantidad:</IonCol>
                                <IonCol>
                                    <IonInput type="tel"
                                        value={cantidad==0?null:
                                            cantidad?.toString()}                                                                                 
                                        onIonChange={cantidadChange} 
                                                                           
                                        onBeforeInput={validateKey}/>
                                </IonCol>
                                </IonRow>
                                <IonRow>
                                <IonCol>Número de Lote:</IonCol>
                                <IonCol>
                                    <IonInput value={numeroLote} type="text" 
                                        onIonChange={numeroLoteChange}/>
                                </IonCol>
                                </IonRow>
                                <IonRow><IonCol>Precio Compra:</IonCol>
                                <IonCol>
                                    <IonInput value={precioCompra == 0?
                                        null: precioCompra?.toString()} 
                                        type="tel"  
                                        onIonChange={precioCompraChange}
                                        clearInput={true}
                                        onBeforeInput={validateKeyCurrency}/>
                                </IonCol>
                                </IonRow>
                                <IonRow>
                                <IonCol>Precio Venta:</IonCol>
                                <IonCol>
                                    <IonInput value={precioVenta == 0? null :
                                        precioVenta?.toString()}
                                        type="tel" 
                                        onIonChange={precioVentaChange}
                                        clearInput={true}
                                        onBeforeInput={validateKeyCurrency}/>
                                </IonCol>
                                </IonRow>                                
                                <IonRow>
                                    <IonCol>Precio Mayoreo:</IonCol>
                                <IonCol>
                                    <IonInput 
                                        value={precioMayoreo == 0? null :
                                        precioMayoreo?.toString()}
                                        type="tel" 
                                        onIonChange={precioMayoreoChange}
                                        clearInput={true}
                                        onBeforeInput={validateKeyCurrency}/>
                                </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton onClick={submitArticulo}>
                                            Agregar</IonButton>
                                        <IonButton 
                                            onClick={()=>cancelAddProduct()} 
                                            color="medium">
                                            Cancelar</IonButton>
                                    </IonCol>
                                </IonRow>                                
                            </IonGrid>
                            </form>
                        </IonCardContent>
                    </IonCard>
                </IonModal>
                <IonModal isOpen={showPickProducto} backdropDismiss={false}>
                        <EntitySearch tipoEntidad="producto"/>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton 
                                        onClick={()=>setShowPickProducto(false)} 
                                        color="medium">
                                    Cancelar
                                    </IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={
                                        ()=>setShowPickProducto(false)}>
                                        Continuar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>                                        
                    </IonModal>                    
                    <IonAlert isOpen={showNewProd}
                    onDidDismiss={()=>setShowNewProd(false)}                    
                    header="Nuevo producto"
                    message="Ingresa el nombre del nuevo producto"
                    inputs={[
                        {
                            name:"nombreProd",
                            type:"text"
                        }
                    ]}
                    buttons={[
                        {
                            text: "Cancelar",
                            role: "cancel",
                            cssClass: "cancelButton",
                            handler: () =>{
                                setShowNewProd(false)
                            }
                        },
                        {
                            text: "Continuar",
                            handler: data =>{
                                if(data.nombreProd != ""){
                                    console.log("El nombre del producto no está vacío: " + data.nombreProd)
                                    setShowLoading(true)
                                    let productoData = {            
                                        "nombre": data.nombreProd,
                                        "id": "",
                                        "codigoBarras": "",
                                        "fabricante": "",
                                        "stockMinimo": 0,                                        
                                        "granel" : false,
                                        "capacidad" : 0,
                                        "unidad" : "",
                                        "stockActual": 0,
                                        "stockGranel": 0
                                    }
                                    callNewProducto(productoData).then(res=>{
                                        setShowLoading(false)
                                        alert("Se registró el nuevo producto, " +
                                           "ya lo puedes agregar a esta " +
                                           "compra, pero no olvides completar " + 
                                           "posteriormente los datos en el catálogo.")
                                    }).catch(err=>{
                                        setShowLoading(false)
                                        setToastState(true)
                                        setToastMessage(
                                            "Ocurrió un error al registrar el " +
                                            "nuevo producto. Intente más tarde.")
                                        console.log("Compra.tsx Error al " +
                                            "registrar producto nuevo: " + 
                                            JSON.stringify(err))
                                    })
                                }
                                else{
                                    
                                        setToastState(true)
                                        setToastMessage( 
                                        "Ingresa el nombre del producto")
                                        return false;
                                }
                                    
                            }
                        }
                    ]}/>
            </IonContent>
        </>
        );
    //}




}//Clase
export default Compra