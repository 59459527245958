import React from "react";
import { RouteComponentProps, Redirect } from 'react-router';
import { isNullOrUndefined } from "util";
import LoginComponent from "../components/LoginComponent";
import { IonRouterLink, isPlatform } from "@ionic/react";
import { CatalogosMenu } from "../components/CatalogosMenu";
import ProveedorComponent from "../components/ProveedorComponent";

interface Props extends RouteComponentProps<{
    clientID: string
}> {}

interface State{
    username: string
}

class LandingPage extends  React.Component<Props, State>{

    constructor(props: Props){
        super(props);       
        if(!isNullOrUndefined(this.props.match.params.clientID)){
            window.localStorage['username'] = this.props.match.params.clientID;
            console.log("Valor de clientId en el constructor :"  + 
                this.props.match.params.clientID);
        }
        else{
            console.log("clientId es nulo o undefined");    
            console.log("username en localStorage: " +  window.localStorage['username'])               
            }
        this.state ={
            username: isNullOrUndefined(window.localStorage['username']) ? 
            "" : window.localStorage['username']
            }
        console.log("username en state: " +  this.state.username)
                        
    }
    
    ionViewDidEnter(){
        
    }

    render(){        
        if(this.state.username == ""){
            console.log("state.username está vacío")
            return <>
                    <LoginComponent></LoginComponent>
                    </>
        }            
        else{ 
            let perfil = JSON.parse(window.localStorage["perfil"])
            let modulos: any[] = perfil.modulos
            console.log("redirect a " +  modulos[1].path)
            return(
                <Redirect to={modulos[1].path}/>
              );
            /*           
            if(this.state.username.toUpperCase() == "ETON")
            return( 
              <Redirect to="/tab1"/>
              )
            else 
                if(this.state.username.toUpperCase() == "RAFA")
                    return <ProveedorComponent></ProveedorComponent>                          
                else
                return <>
                    <LoginComponent></LoginComponent>
                    </>
            */    
                }
            

    }
    

}
export default LandingPage