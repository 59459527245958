import React, {useState} from 'react';
import { IonHeader, IonTitle, IonContent, IonCard, IonItem, isPlatform, IonInput, IonButton, IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol, IonCardContent } from '@ionic/react';
import './LoginComponent.css';
import { callGetPerfilById, callGetUsuarioLogin } from '../firebaseConfig';

const LoginComponent:  React.FC = () =>{

    const[username, setUsername] = useState(""); 
    const[password, setPassword] = useState("");       
    function doLogin(){
        if(username == ""){
            alert("Ingrese su nombre de usuario");
            return;
        }
        if(password == ""){
            alert("Ingrese su contraseña");
            return
        }
        callGetUsuarioLogin({username: username, password: password}).then(
            res=>{                
                let objReturn = res as any                
                //alert(JSON.stringify(objReturn))
                if(objReturn.username !== null && objReturn.username !== undefined){
                    //alert("no es nulo ni undefined")
                    window.localStorage['username'] = username;
                    callGetPerfilById(objReturn.idPerfil).then(res=>{
                        console.log("LoginComponent.callGetPerfilById return: " +  
                        JSON.stringify(res))
                        window.localStorage['perfil'] = JSON.stringify(res)
                        window.location.href ="/pages/home";
                    })
                    
                }
                else{
                    alert("Datos incorrectos")
                }
            }
        )
        if(username != "" && password != ""){
            
        }
    }
        if(isPlatform('desktop'))
            return(
                <>                    
                    <IonHeader>
                        <IonTitle>
                            Inicio de Sesión
                        </IonTitle>
                    </IonHeader>
                    <IonCard className="loginCard">
                    <IonCardContent>

                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonInput type="text" 
                                    placeholder="Nombre de usuario" 
                                    onIonChange={(e:CustomEvent) =>{
                                    setUsername(e.detail.value);
                            }}/>
                                </IonCol>
                            </IonRow>  
                            <IonRow>
                                <IonCol>
                                    <IonInput type="password"
                                    placeholder="*********"
                                    onIonChange={(e:CustomEvent)=>{
                                        setPassword(e.detail.value)
                                    }}/>
                                </IonCol>                                
                            </IonRow>                              
                        </IonGrid>
                        <IonButton onClick={() =>{doLogin()}}>Continuar</IonButton>
                            </IonCardContent>
                            </IonCard>
                </>
            );
        else
            return(
                <>
                    <IonHeader>
                        <IonTitle>
                            Inicio de Sesión
                        </IonTitle>
                    </IonHeader>
                    <IonContent>

                            <IonInput type="text" onIonChange={(e:CustomEvent) =>{
                                    setUsername(e.detail.value);
                            }} placeholder="Nombre de usuario"/>
                            <IonInput type="password"
                                    placeholder="*********"
                                    onIonChange={(e:CustomEvent)=>{
                                        setPassword(e.detail.value)
                                    }}/>
                            <IonButton onClick={() =>{doLogin()}}>Continuar</IonButton>
                    </IonContent>
                </>
            );
}
export default LoginComponent