import React from "react";
import { RouteComponentProps } from "react-router";
import { isNullOrUndefined } from "util";
import { IonToolbar, IonHeader, IonTitle, IonContent, IonGrid, IonRow, IonCol, 
        IonFabButton, IonFab, IonIcon, IonModal, IonInput, IonButton, IonAlert, IonToast, 
        IonSelect, 
        IonSelectOption,
        IonLoading} from "@ionic/react";
import {add, create, removeCircle} from "ionicons/icons"
import "./CatalogoGenericoPage.css"
import { callDeleteColor, callDeleteEspecie, callDeleteFabricante, callDeleteRaza, callGetColores, callGetEspecies, callGetFabricantes, callGetRazas, callModifyColor, callModifyEspecie, callModifyFabricante, 
        callModifyRaza, 
        callNewColor, 
        callNewEspecie, 
        callNewFabricante, 
        callNewRaza} from "../firebaseConfig";
import DataTable from 'react-data-table-component';
interface CatGenProps extends RouteComponentProps<{
    tipo: string
    }>{}

type State = {
    nombre: string,
    showNew: boolean,
    showEdit: boolean,
    showToast: boolean,
    toastMessage: string,
    selected: String,
    showConfirm: boolean,
    parentId: string,
    entidadesGrid: Array<any>,
    entidades: any[],
    entidadesPadre: Array<any>,
    showLoading: boolean,
    loadingMessage: string   
}

export class CatalogoGenericoPage extends React.Component<CatGenProps, State>{

    entidades: Array<any> = [];
    //entidadesPadre: Array<any> = [];    
    hasParent: boolean = false;

    constructor(props: any){
        super(props);
        this.state = {
            nombre: "",
            showEdit: false,
            showNew: false,
            showToast: false,
            toastMessage: "",
            selected: "",
            showConfirm: false, 
            entidades: [],
            entidadesGrid: [],
            entidadesPadre: [],
            parentId: "",
            showLoading: true,
            loadingMessage: "Cargando..."   
        }
        if(!isNullOrUndefined(this.props.match.params.tipo)){
            switch (this.props.match.params.tipo) {
                case "fabricantes":                    
                   this.getFabricantesFromCloud();
                    break;                    
                case "laboratorios":
                    if(!isNullOrUndefined(window.localStorage["laboratorios"]))
                        this.entidades = JSON.parse(window.localStorage['laboratorios']);
                    break;
                case "especies":
                    // if(!isNullOrUndefined(window.localStorage["especies"]))
                    //     this.entidades = JSON.parse(window.localStorage['especies']);                
                    this.getEspeciesFromCloud();
                    break;
                case "razas":
                    this.hasParent = true;              
                /* if(isNullOrUndefined(window.localStorage["razas"])){
                    let razastmp = [{"id":1,"nombre":"Labrador","parentId":"Perro"},{"id":2,"nombre":"French Poodle","parentId":"Perro"},{"id":3,"nombre":"Siamés","parentId":"Gato"}]
                    window.localStorage["razas"] = JSON.stringify(razastmp)
                    window.localStorage["razaNewId"] = "4"
                }                     
                this.entidades = JSON.parse(window.localStorage['razas']);  
                */
               this.getRazasFromCloud();
               this.getEntidadesPadreFromCloud();
               /*
                if(!isNullOrUndefined(window.localStorage["especies"])){
                    this.entidadesPadre = JSON.parse(window.localStorage['especies']);
                    console.log("parentId: " + this.entidadesPadre[0].nombre );
                    this.setState({                                                
                        parentId: this.entidadesPadre[0]
                    })
                    console.log("state.parentId:" + this.state.parentId) 
                }*/
                    break;
                case "colores":
                    //if(!isNullOrUndefined(window.localStorage["colores"]))
                    //    this.entidades = JSON.parse(window.localStorage['colores']);
                    this.getColoresFromCloud();
                    break;
                default:
                    break;
            }
                                                                                    
        }        
        console.log("entities:" + JSON.stringify(this.state.entidadesGrid))
         
    }    

    componentDidMount(){
        this.submitEntity = this.submitEntity.bind(this);                                  
        this.getChildEntities = this.getChildEntities.bind(this);                                          
        /*
        this.setState({
            entidadesGrid : !this.hasParent ? this.entidades : this.getChildEntities()
        }) 
        */
    }

    getChildEntities(){
        let colReturn = this.entidades.filter(entity=>entity.padre.id == this.state.parentId);
        console.log("parentId:" + this.state.parentId)
        console.log("childEntities: " + JSON.stringify(colReturn));
        return colReturn;
    }

    getNewId(){
        var idReturn: number = -1;
        let entityKey: string = this.props.match.params.tipo.substr(0, 
            this.props.match.params.tipo.length - 1) + "NewId"
        //this.props.match.params.tipo == "laboratorios" ?
        //    "laboratorioNewId" : "especieNewId";
        if(isNullOrUndefined(window.localStorage[entityKey]))
            idReturn = 1;
        else
            idReturn = Number.parseInt(window.localStorage[entityKey]);
        window.localStorage[entityKey] = idReturn+1;
        return idReturn;
   }

   deleteEntidad(){
    this.setState({
        showLoading: true,
        loadingMessage: "Guardando..."
    })
    switch(this.props.match.params.tipo){
        case "fabricantes":
            callDeleteFabricante(this.state.selected).then(res=>{
                this.resetState();
            })
            break;
        case "especies":
            callDeleteEspecie(this.state.selected).then(res=>{
                this.resetState();
            })
            break;
        case "razas":
            callDeleteRaza(this.state.selected).then(res=>{
                this.resetState();
            })
            break;
        case "colores":
            callDeleteColor(this.state.selected).then(res=>{
                this.resetState();
            })
    }
    /*
    for(var i=0; i<this.entidades.length; i++){
        if(this.entidades[i].id == this.state.selected){            
            this.entidades.splice(i, 1);
            window.localStorage[this.props.match.params.tipo] = 
                JSON.stringify(this.entidades);
            this.resetState();
            }
        }*/
    }

    resetState(){
        this.setState({
            selected: "",
            nombre: "",
            showNew: false,
            showEdit: false,
            showLoading: false
            //entidadesGrid: this.hasParent ? this.getChildEntities() : this.entidades
        })
        switch(this.props.match.params.tipo){
            case "fabricantes":
                this.getFabricantesFromCloud();
                break;
            case "especies":
                this.getEspeciesFromCloud();
                break;
            case "razas":
                this.getRazasFromCloud();                
                break;
            case "colores":
                this.getColoresFromCloud();
        }
    }  
    parentChange = (event: CustomEvent) => {
        this.setState({ parentId: event.detail.value});
        this.setState({entidadesGrid : this.getChildEntities()});
        }   
    nombreChange = (event: CustomEvent) => {
        this.setState({ nombre: event.detail.value});
        }  
    submitEntity(){
        if(this.state.nombre == ""){
            this.setState({
                showToast: true,
                toastMessage: "Ingrese el nombre para continuar"});
                return false;                                        
        }
        else{           
            this.setState({
                showLoading: true,
                loadingMessage: "Guardando..."
            }) 
            let entity = this.hasParent ? {
                id: this.state.showNew? 
                 "new" : this.state.selected,
                 nombre: this.state.nombre,
                 padre : {id:this.state.parentId}
            } :
            {
                id: this.state.showNew? 
                "new" : this.state.selected,
                nombre: this.state.nombre
            }            
            if(this.state.showNew){
                //this.entidades.push(entity);                                            
                //TODO:
                switch (this.props.match.params.tipo) {
                    case "fabricantes" :
                        callNewFabricante(entity).then(res=>{
                            this.resetState();
                        })
                        break;
                    case "especies":
                        callNewEspecie(entity).then(res=>{
                            this.resetState();
                        })
                        break;
                    case "razas":
                        callNewRaza(entity).then(res=>{
                            this.resetState();
                        })
                        break;
                    case "colores":
                        callNewColor(entity).then(res=>{
                            this.resetState();
                        })
                } 
            }
            else{
                switch(this.props.match.params.tipo){
                    case "fabricantes" :
                        //console.log("submitEntity entity: " + 
                        //    JSON.stringify(entity))                        
                        callModifyFabricante(entity).then(res=>{
                            this.resetState();
                        })                        
                        break;
                    case "especies":
                        callModifyEspecie(entity).then(res=>{
                            this.resetState();
                        })
                        break;
                    case "razas":
                        callModifyRaza(entity).then(res=>{
                            this.resetState();
                        })
                        break;
                    case "colores":
                        callModifyColor(entity).then(res=>{
                            this.resetState();
                        })
                }
                /*
                for(var i=0; i<this.entidades.length; 
                    i++){
                    if(this.entidades[i].id == 
                        entity.id){
                        this.entidades.splice(i, 1, 
                            entity);
                    }
                }*/
            }
            /*
            window.localStorage[
                this.props.match.params.tipo] =
                JSON.stringify(this.entidades);
            this.resetState();
            */
        }
    } 

    getFabricantesFromCloud(){
        callGetFabricantes().then(ret=>{
            //console.log("getFabricantesFromCloud ret: " + 
            //    JSON.stringify(ret))
            this.entidades = ret as [];                            
            this.setState({
                entidadesGrid: this.entidades,
                showLoading: false
            })
        })
    }
    getEspeciesFromCloud(){
        callGetEspecies().then(ret=>{
            //console.log("getEspeciesFromCloud ret: " + 
            //    JSON.stringify(ret))
            this.entidades = ret as [];                
            this.setState({
                entidadesGrid: this.entidades,
                showLoading: false
            })
        })
    }
    getRazasFromCloud(){
        callGetRazas().then(ret=>{
            //console.log("getRazasFromCloud ret: " + 
            //    JSON.stringify(ret))
            this.entidades = ret as [];
            if(this.state.parentId!=""){
                console.log("Hay padre seleccionado: " + this.state.parentId)
                this.setState({entidadesGrid: this.getChildEntities()})
                }
            this.setState({showLoading: false})
            })
    }
    getEntidadesPadreFromCloud(){
        switch(this.props.match.params.tipo){
            case "razas":
                callGetEspecies().then(res=>{
                    this.setState({entidadesPadre: res as [],
                    showLoading: false})
                    
                })
                break;
        }
    }
    getColoresFromCloud() {
        callGetColores().then(ret=>{
            //console.log("getColoresFromCloud ret: " + 
            //    JSON.stringify(ret))
            this.entidades = ret as [];                            
            this.setState({
                entidadesGrid: this.entidades,
                showLoading: false
            })
        })
    }

    columns = [
        {
            name: 'Nombre',
            selector: (row: any) => row.nombre,
            sortable:true
        }
    ];

    render(){
        return( 
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        {this.props.match.params.tipo.toUpperCase()}
                    </IonTitle>
                </IonToolbar>                
            </IonHeader>            
            <IonAlert isOpen={this.state.showConfirm}
                        onDidDismiss={()=>this.setState({showConfirm: false})}                    
                        header="Advertencia"
                        message="¿Realmente desea eliminar el registro?"
                        buttons={[
                            {
                                text: "Cancelar",
                                role: "cancel",
                                cssClass: "cancelButton",
                                handler: () =>{
                                    this.setState({showConfirm:false});
                                }
                            },
                            {
                                text: "Continuar",
                                cssClass: "dangerButton",
                                handler: () =>this.deleteEntidad()                                    
                            }
                        ]}
                    />
            
           {/*  <IonAlert isOpen={this.state.showNew || this.state.showEdit}
                        onDidDismiss={()=>this.setState({showNew: false,
                            showEdit:false})}                    
                        header={
                            this.state.showNew ? 
                                "Nuevo registro" : "Editar registro"}
                        message="Ingrese el nombre"
                        inputs={[
                            {
                                name:"nombre",
                                type: "text",
                                value: this.state.nombre
                            }
                        ]}
                        buttons={[
                            {
                                text: "Cancelar",
                                role: "cancel",
                                cssClass: "cancelButton",
                                handler: () =>{
                                    this.setState({showNew: false,
                                        showEdit:false});
                                }
                            },
                            {
                                text: "Guardar",
                                handler: data =>{
                                    if(data.nombre == ""){
                                        this.setState({
                                            showToast: true,
                                            toastMessage: "Ingrese el nombre para continuar"});
                                            return fsalse;                                        
                                    }
                                    else{                                        
                                        let entity = {
                                            codigo: this.state.showNew? 
                                             this.getNewId() : this.state.selected,
                                             nombre: data.nombre
                                        }
                                        if(this.state.showNew){
                                            this.entidades.push(entity);                                            
                                        }
                                        else{
                                            for(var i=0; i<this.entidades.length; 
                                                i++){
                                                if(this.entidades[i].codigo == 
                                                    entity.codigo){
                                                    this.entidades.splice(i, 1, 
                                                        entity);
                                                }
                                            }
                                        }
                                        window.localStorage[
                                            this.props.match.params.tipo] =
                                            JSON.stringify(this.entidades);
                                        this.resetState();
                                        return true;
                                    }
                                        
                                }
                            }
                        ]}/>   */}

            <IonModal isOpen={this.state.showNew || this.state.showEdit} backdropDismiss={false}>  
                <form>
                    <IonHeader>
                        <IonToolbar>                                    
                            <IonTitle>
                            {
                            this.state.showNew ? 
                                "Nuevo registro" : "Editar registro"}
                            </IonTitle>                                    
                        </IonToolbar>      
                    </IonHeader>                          
                    <IonGrid>                                                     
                        <IonRow>
                            <IonCol className="formKeyLabel">Nombre</IonCol>
                            <IonCol>
                                <IonInput value={this.state.nombre.toString()}
                                onIonChange={this.nombreChange}/>
                            </IonCol>                                    
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={this.submitEntity}>
                                    Guardar
                                </IonButton>
                                <IonButton onClick={()=> this.setState({showNew: false,
                                        showEdit:false})
                                } color="medium">
                                    Cancelar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>    
            </IonModal>
            <IonContent>  
                <IonLoading isOpen={this.state.showLoading}
                    message={this.state.loadingMessage}
                    />              
                <IonGrid>
                    {   
                        this.hasParent? 
                            <IonRow>
                            <IonCol className="formKeyLabel" size="4">Especie</IonCol>
                            <IonCol size="4">
                                <IonSelect placeholder="Seleccione una opción" value={this.state.parentId} 
                                onIonChange={this.parentChange}>
                                {                                    
                                    this.state.entidadesPadre.map((padre)=>
                                        <IonSelectOption value={padre.id}>
                                            {padre.nombre}
                                        </IonSelectOption>                                    
                                    )
                                }
                                </IonSelect>
                            </IonCol>
                        </IonRow>
                        :  <br></br>
                        }
                    {/* <IonRow className="gridHeaderCol">
                        <IonCol>
                            Nombre
                        </IonCol>
                    </IonRow> */}
                   {/*  {
                       !isNullOrUndefined(this.state.entidadesGrid)?
                            this.state.entidadesGrid.map((entidad)=>
                                <IonRow key={entidad.id} onClick={()=>this.setState({
                                        selected: entidad.id,
                                        nombre: entidad.nombre})}
                                        className={this.state.selected == 
                                            entidad.id ? "selectedRow itemCardValue" : 
                                            "normalRow itemCardValue"}>
                                    <IonCol>
                                        {entidad.nombre}
                                    </IonCol>
                                </IonRow>)
                            :<IonRow>
                                <IonCol>
                                    No hay registros que mostrar 
                                </IonCol>
                            </IonRow>
                    } */}
                <IonRow>
                <DataTable data={this.state.entidadesGrid} columns={this.columns}
                    pagination={true}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[5,10,15]}                    
                    dense={true}
                    selectableRows={true}
                    selectableRowsSingle={true}
                    onSelectedRowsChange={selected=>{
                        let entidad = selected.selectedRows[0];
                        //console.log("renglón seleccionado: " + JSON.stringify( 
                        //selected.selectedRows[0]))
                         this.setState({
                            selected: entidad.id,
                            nombre: entidad.nombre})
                            }                         
                        }
                    striped
                    noDataComponent={
                        <IonGrid>                                                    
                            <IonRow>
                                <IonCol size="4"></IonCol>
                                <IonCol>
                                    No hay registros
                                </IonCol>
                            </IonRow>
                            <IonRow><IonCol>&nbsp;</IonCol></IonRow>
                        </IonGrid>
                    }
                    />
                </IonRow>
                </IonGrid>
                <IonFab vertical="top" horizontal="end">
                    <IonFabButton onClick={()=>this.setState({showNew:true,
                        nombre: "",
                        selected: ""})}>
                        <IonIcon icon={add}/>
                    </IonFabButton>
                    <IonFabButton disabled={this.state.selected==""} color="warning"
                        onClick={()=>this.setState({showEdit:true})}>
                        <IonIcon icon={create}/>
                    </IonFabButton>
                    <IonFabButton disabled={this.state.selected==""} color="danger"
                        onClick={()=>this.setState({showConfirm:true})}>
                        <IonIcon icon={removeCircle}/>
                    </IonFabButton>
                </IonFab>                
            </IonContent>
            <IonToast isOpen={this.state.showToast} 
                message={this.state.toastMessage}
                onDidDismiss={()=>this.setState({showToast: false})} 
                duration={1000}/>
        </>
        )
    }
}