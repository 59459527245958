import React from "react";
import { IonItem, IonCardTitle, IonCardSubtitle, IonCard, IonCardHeader, IonCardContent } from "@ionic/react";
type Props = {
    imagenPaciente: any,
    clickFunction: any
}
const ImagenCard: React.FunctionComponent<Props> = (props) =>{

    return(
    <IonCard 
    // onClick={props.clickFunction}
    >
        <img src={props.imagenPaciente.url}/>
        <IonCardHeader>
            <IonCardTitle>{props.imagenPaciente.fecha}</IonCardTitle>
        </IonCardHeader>        
        <IonCardContent>{props.imagenPaciente.comentarios}</IonCardContent>
    </IonCard>
    )
}
export default ImagenCard