import React from 'react';
import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar,
  IonSelect, IonSelectOption, IonButton } from '@ionic/react';
//import { CitasPage } from './CitasPage';

import { RouteComponentProps, Redirect } from 'react-router';
import CitasPage from './CitasPage';
import CitasPage2 from './CitasPage2';

interface Tab2Props extends RouteComponentProps<{}> {}

const Tab2: React.FunctionComponent<Tab2Props>= ({history, location, match}) => {
  console.log("Entrando a tab 2");
  return (
    //<CitasPage history={history} location={location} match={match}></CitasPage>
    //<CitasPage></CitasPage>
    //<CitasPage2></CitasPage2>
    <Redirect to="/clientes/eton"></Redirect>
  );
};

export default Tab2;