import React from 'react';
import {  IonAvatar, IonItem, IonIcon, IonLabel, IonGrid, IonCol, IonRow, IonItemSliding, IonItemOptions, IonItemOption} from '@ionic/react'
import './ProveedorCard.css';
type Props = {  
    razonSocial: string, 
    direccion: string, 
    colonia: string, 
    cp: string, 
    pais: string, 
    estado: string, 
    ciudad: string, 
    telefono: string, 
    email: string
  }

  class ProveedorCard extends React.Component<Props>{

    constructor(props: Props){
        super(props);
    }

    getDireccionString() : string {

        return this.props.direccion + ", Col. " + this.props.colonia + //" , CP " + this.props.cp +
            ", " + this.props.ciudad  ;
            //+ ", " + this.props.estado;
    };

    render(){
    return(
        <>

                <IonLabel>
                    <label className="rowTitle">{this.props.razonSocial}</label>
                    <IonGrid>
                        <IonRow>
                            <IonCol  >
                                <label className="keyLabelCard">DIRECCIÓN:</label>
                            </IonCol>
                            <IonCol >
                            <label className="valueLabelCard">{this.getDireccionString()}</label>
                            </IonCol>                            
                            <IonCol>
                                <label className="keyLabelCard">TELÉFONO:</label>
                            </IonCol>
                            <IonCol>
                                <label className="valueLabelCard">{this.props.telefono}</label>
                            </IonCol>
                            <IonCol>
                                <label className="keyLabelCard">E-MAIL:</label>
                            </IonCol>
                            <IonCol>
                                <label className="emailLabelCard">{this.props.email}</label>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonLabel>
        </>
    )}

  }
  export default ProveedorCard