import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCheckbox, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonMenuButton, IonModal, IonNote, IonPage, IonRouterLink, IonRow, IonThumbnail, IonTitle, IonToast, IonToolbar } from "@ionic/react"
import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { callGetClientes, callGetPacientesByCliente, callNewCliente } from "../firebaseConfig"
import { trash, create, paw, add } from "ionicons/icons"


const ClientesPage: React.FC = ()=>{    

const[showToast, setShowToast] = useState(false)
const[toastMessage, setToastMessage] = useState("")
const[clientes, setClientes] = useState([] as any[])
const[idDelete, setIdDelete] = useState("")
const[showConfirm, setShowConfirm] = useState(false)
const[showForm, setShowForm] = useState(false)
const[showLoading, setShowLoading] = useState(true)
const[pacientes, setPacientes] = useState([] as any[])
const[showPacientes, setShowPacientes] = useState(false)
const[showNew, setShowNew] = useState(false)
const[nombre, setNombre] = useState("")
// const[fechaNac, setFecha] = useState("")
const[email, setEmail] = useState("")
const[telefono, setTelefono] = useState("")
useEffect(()=>{
    window.localStorage.removeItem("pacienteSelected")
    /* callGetClientes().then(res=>{
        setShowLoading(false)
        setClientes(res as any[])
    }).catch(err=>{
        setShowToast(true)
        setToastMessage("Ocurrió un error al obtener los clientes")
        console.log("ClientesPage useEffect Error al obtener clientes: " +  
            JSON.stringify(err))
    }) */
    getClientesFromCloud()
},[])
function getClientesFromCloud(){
    callGetClientes().then(res=>{
        setShowLoading(false)
        setClientes(res as any[])
    }).catch(err=>{
        setShowToast(true)
        setToastMessage("Ocurrió un error al obtener los clientes")
        console.log("ClientesPage useEffect Error al obtener clientes: " +  
            JSON.stringify(err))
    })
}
function nombreChange(event: CustomEvent){
    setNombre(event.detail.value)
}
/* function fechaNacChange(event: CustomEvent){
    setFecha(event.detail.value)
} */
function emailChange(event: CustomEvent){
    setEmail(event.detail.value)
}
function telefonoChange(event: CustomEvent){
    setTelefono(event.detail.value)
}
function editCliente(id:string){
    setShowForm(true)
    return null;
}
function getPacientes(id: any){
    setShowLoading(true)
    callGetPacientesByCliente(id).then(res=>{
        setShowLoading(false)
        setPacientes(res as any[])
        setShowPacientes(true)
    }).catch(err=>{
        setShowLoading(false)
        setShowToast(true)
        setToastMessage("Ocurrió un error al obtener las mascotas, intenta más tarde")
        console.log(
            "ClientesPage.showPacientes Error al obtener pacientes del cliente: "
            + JSON.stringify(err))
        
    })
}
function submitNew(){
    if(nombre == "" || nombre === undefined){
        setShowToast(true)                            
        setToastMessage("Por favor, ingrese el nombre del cliente")
        return;
    }
    /* if(emailCli == "" || emailCli === undefined){
        setShowToast(true)                            
        setToastMessage(
            "Por favor, ingrese la dirección de correo del cliente")
        return;
    } */
    if(email != "" && !validateEmail(email)){
        setShowToast(true)
        setToastMessage("Por favor, ingrese una dirección de correo válida")
        return;
    }
    /* if(fechaNacCli == "" || fechaNacCli === undefined){
        setShowToast(true)                            
        setToastMessage(
            "Por favor, indique la fecha de nacimiento del cliente")
        return;
    } */
    if(telefono == "" || telefono === undefined){
        setShowToast(true)                            
        setToastMessage(
            "Por favor, ingrese el teléfono del cliente")
        return;
    }
    var clienteData = {
        id: "-1",
        nombre: nombre,
        email: email,
        fechaNacimiento: "",//fechaNac,
        telefono: telefono
        //,fechaRegistro: (new Date()).toISOString()
    }
    setShowLoading(true);
    callNewCliente(clienteData).then(res=>{
        console.log(JSON.stringify(res))
        clienteData["id"] = res as string//JSON.stringify(res)
        //window.localStorage["clienteSelected"] = JSON.stringify(clienteData)
        getClientesFromCloud()
        resetForm()
        setToastMessage("Se registró el cliente")
        setShowToast(true)        
        setShowLoading(false)
    }).catch(error=>{
        console.log("Error al registrar al nuevo cliente: " +
            JSON.stringify(error))
        setShowToast(true)
        setToastMessage("Ocurrió un error al registrar al cliente")
        })    
    setShowNew(false)

}
const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email);
}
const columns =[
    {
        name: 'Nombre',
        selector: (row: any) => (row.nombre as string).toLocaleUpperCase(),
        sortable:true
    },
    {
        name: 'Teléfono',
        selector: (row: any) => row.telefono,
        sortable: true
    },
     {
        name: 'Email',
        selector: (row: any) => row.email,            
        sortable: true
    },   
    {
        name: 'App',
        selector: (row:any) => row.fbid? <IonIcon icon={paw} color="primary"/> : "",
        sortable: false
    },
    {
        name: '',
        selector: (row:any) => <IonRouterLink onClick={()=>getPacientes(row.id)}>
            Mascotas
        </IonRouterLink>,
        sortable: false
    }
    /* {
        name: '',
        selector: (row: any) =>
            <IonGrid>
                <IonRow>
                <IonCol>
                    <IonFabButton size="small" 
                        onClick={()=>editCliente(row.id)}>
                        <IonIcon icon={create}/>
                    </IonFabButton>
                </IonCol>
                <IonCol>
                    <IonFabButton size="small" color="danger"
                        onClick={()=>{
                            setIdDelete(row.id)
                            setShowConfirm(true)
                        }}>
                        <IonIcon icon={trash}/>
                    </IonFabButton>
                </IonCol>
                </IonRow>
            </IonGrid>,
        sortable: true
    } */
]
    function resetForm() {
        
    }

return(
    <IonPage>
        <IonContent>
        <IonHeader>
                <IonToolbar>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="8">
                                <IonTitle>CLIENTES</IonTitle>
                            </IonCol>
                        </IonRow>
                    </IonGrid>  
                    <IonMenuButton slot="start"></IonMenuButton>    
                </IonToolbar>
                <IonFab horizontal="end" vertical="top">
                    <IonFabButton onClick={()=>{                        
                        setShowNew(true)
                        }}>
                        <IonIcon icon={add}/>
                    </IonFabButton>
                </IonFab>
            </IonHeader>
            <IonCard>
                <IonCardContent>
                    <DataTable columns={columns} data={clientes} 
                        pagination={true} paginationPerPage={5}/>
                </IonCardContent>
            </IonCard>
            <IonModal isOpen={showForm} backdropDismiss={false}>
                <IonContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonItem>
                                        <IonLabel>Nombre Completo</IonLabel>               
                                    </IonItem>
                                    <IonItem>
                                        <IonInput />
                                    </IonItem>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton color="medium" size="small"
                                    onClick={()=>{setShowForm(false)
                                        resetForm()}}>
                                    Cancelar</IonButton>
                            </IonCol>
                        </IonRow>    
                    </IonGrid>
                </IonContent>
            </IonModal>
            <IonModal isOpen={showPacientes} backdropDismiss={false}>
                <IonCard>
                    <IonList>
                    {pacientes.map(paciente=>
                        <IonItem key={paciente.id}>
                            <IonThumbnail>
                                <IonIcon size="large" icon={paw} color="primary"/>
                            </IonThumbnail>
                            <IonLabel>{paciente.nombre}</IonLabel>
                            {/* <IonNote slot="end"> */}
                            <IonRouterLink href={"/pacientesVet/"+paciente.id}>
                                Detalles</IonRouterLink>
                            {/* </IonNote>                                 */}
                        </IonItem>)}
                    </IonList>
                </IonCard>                
                <IonButton size="small" color="tertiary"
                    onClick={()=>setShowPacientes(false)}>Cerrar</IonButton>                
            </IonModal>
            <IonModal isOpen={showNew} backdropDismiss={false}>
                    <IonContent>
                    <form>
                    <IonToolbar>
                        <IonTitle>Datos del cliente</IonTitle>
                    </IonToolbar>                
                    <IonGrid>
                        <IonRow>
                            <IonCol size="3.5">
                                Nombre Completo
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={nombreChange}
                                    value={nombre}/>
                            </IonCol>
                        </IonRow>
                        {/* <IonRow>
                            <IonCol size="3.5">
                            Fecha de Nacimiento
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={fechaNacChange}
                                    value={fechaNac} type="date"/>
                            </IonCol>
                        </IonRow> */}
                        <IonRow>
                            <IonCol size="3.5" className="colAlineada">
                                Correo Electrónico
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={emailChange}
                                    value={email} type="email"/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="3.5" className="colAlineada">
                                Teléfono
                            </IonCol>
                            <IonCol size="8.5">
                                <IonInput onIonChange={telefonoChange}
                                    value={telefono}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={()=>{
                                    setShowNew(false)
                                    resetForm()
                                }} 
                                color="medium">Cancelar</IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={submitNew}>
                                    Guardar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    </form>
                    </IonContent>
                </IonModal>
            <IonToast isOpen={showToast} message={toastMessage}
                onDidDismiss={()=>setShowToast(false)} duration={1500} position="middle"/>
            <IonLoading
                    isOpen={showLoading}
                    message="Cargando..."
                    />
        </IonContent>
    </IonPage>
)
} 
export default ClientesPage


